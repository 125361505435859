import { Injectable } from '@angular/core';
import { get } from 'lodash-es';
import { UtilsService } from '../utils/utilservice';
import { ErrorPageHandlerService } from './error-page-handler.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CabHttpService extends HttpClient {
  constructor(private utilsService: UtilsService, errorPageHandler: ErrorPageHandlerService) {
    super(errorPageHandler);
  }

  // TODO: refactor to remove this one and use the other one below.
  apiUrl(context: ContextType, path: string): string {
    return cabApiUrl(path, context, this.utilsService);
  }
}

export const enum ContextType {
  DISCOVERY = 'discovery',
  CAB = 'cab',
}

export function cabApiUrl(path: string, context: ContextType = null, utilsService: UtilsService): string {
  const urls = utilsService.environmentValue('cabApiUrl');
  if (!utilsService.isMocked() && urls) {
    return get(urls, context) ? get(urls, context) + path : path;
  }
  return path;
}
