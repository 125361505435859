<lib-profile-section [audience]="audience" [dedupeDisplayName]="dedupeDisplayName" (emitCurrentAudience)="onDisplayNameHandler()"></lib-profile-section>

<lib-details-toggle-dropdown
  [caseInsensitive]="audience?.['query']?.queryFlags?.caseInsensitive"
  [caseInsensitiveDisabled]="true"
></lib-details-toggle-dropdown>

<div class="container" *ngIf="dataTypeOperators.length && !!builderAudience">
  <div class="header" *ngIf="showHeader">
    <div class="header-count">
      <div class="outer">
        <div class="inner">
          <i coreUIIcon="userProfile" class="card-icon" scale="3"></i>
        </div>
      </div>
      <div>
        <p *ngIf="productType !== 'DCDP'"class="header-title">Count ({{ dedupeDisplayName }})</p>
        <span *ngIf="productType === 'DCDP'">
          <p class="header-title">
            Count <span class="card-title-sub">
              ({{ builderService.getAudienceDisplayName(this.audience?.['dedupeIdentityType'], getChannelType()) }})
            </span>
            </p>
          </span>

        <ng-container *ngIf="isAudience; else definition">
          <div class="header-value" [class.count-na]="!builderAudience?.idCount">
            {{ (builderAudience?.idCount | number: "1.0-0") || "__" }}
          </div>
        </ng-container>
        <ng-template #definition>
          <p *ngIf="!audience.count && !audience['countUpdatedOn']"><span class="text-style-muted-3"> N/A </span></p>
          <div *ngIf="audience.count || audience['countUpdatedOn']">
            <div *ngIf="audience['countStatus'] === 'SUCCESS'">
              {{ audience['idCount'] | number: "1.0-0" }}
              <div class="text-style-muted-3"> as of {{ audience['countUpdatedOn'] | date: 'MM/dd/YY hh:mm a': 'UTC' }} UTC </div>
            </div>
            <div *ngIf="audience['countStatus'] === 'FAILURE'">
              <ng-template #errorMessage>
                <span class="countErrorMessage">
                  {{ audience['countErrorMessage'] }}
                </span>
              </ng-template>
              <coreui-badge
                color="Apple"
                [filled]="true"
                [coreUITooltip]="errorMessage"
              >Error
              </coreui-badge>
              <div class="text-style-muted-3"> on {{ audience['countUpdatedOn'] | date: 'MM/dd/YY hh:mm a': 'UTC' }} UTC </div>
            </div>
            <div *ngIf="!audience['countStatus']">
              <coreui-badge
                color="Slate"
                [filled]="true"
                [hasIcon]="true"
              >
                <i coreUIIcon="spinner"></i
                >Processing
              </coreui-badge>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div>
      <span *ngIf="productType !== 'DCDP'">
        <p class="header-title">Data Universe</p>
        <p class="header-value">
          {{ dataUniverse?.displayName }}
        </p>
      </span>
    </div>
  </div>

  <div class="body">
    <div class="body-header-container">
      <div class="iconHeader">
        <lib-type-icon [type]="'include'"> </lib-type-icon>
        <h4>I want to include...</h4>
      </div>
    </div>

    <div *ngIf="builderAudience?.includeConditions">
      <lib-detail-node [cabAttributes]="cabAttributes$ | async" [dataTypeOperators]="dataTypeOperators"
        [nodes]="builderAudience?.includeConditions" [attributeDetails]="attributeDetails">
      </lib-detail-node>
    </div>
    <div class="body-header-container exclude">
      <div class="iconHeader">
        <lib-type-icon [type]="'exclude'"> </lib-type-icon>
        <h4>I want to exclude...</h4>
      </div>
    </div>

    <div *ngIf="builderAudience?.excludeConditions">
      <lib-detail-node [cabAttributes]="cabAttributes$ | async" [dataTypeOperators]="dataTypeOperators"
        [nodes]="builderAudience?.excludeConditions" [attributeDetails]="attributeDetails">
      </lib-detail-node>
    </div>
  </div>
</div>