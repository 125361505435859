import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import { FieldMapping, SchemaField } from '@epsilon-cdp/connector-lib/mapping';
import { AppState } from '../../../reducers';
import * as audienceBuilderActions from '../../../audience-builder/audience-builder.actions';
import * as tenantActions from '../../../tenant/tenant.actions';
import { selectTenant } from '../../../tenant/tenant.reducer';
import { convertAudienceDefinitionToBuilder } from '../../../audience/audience.utils';
import { FEATURE_OWNED_CHANNEL_MAPPING } from '../../../utils/feature-utils';
import { AUDIENCE_TXT, BUILDER_TXT, DEFINITION_TXT } from '../../../utils/utils';
import { Audience, AudienceDefinition } from '../../../audience/audience.models';
import { ConnectionPaginationModel } from '../../../tenant/connection.model';
import { Tenant } from '../../../tenant/tenant.model';
import { DataType } from '../../../enums/data-types';
import { AudienceBuilderService } from '../../../audience-builder/audience-builder.service';
import { AudienceService } from '../../../audience/audience.service';
import { TenantService } from '../../../tenant/tenant.service';
import { AttributeModel } from '../../../audience/attribute.model';
import { CampaignExtractService } from '../../../campaign-extract/campaign-extract.service';
import { SchemaFieldsAdapter } from './schema-fields.adapter';
import { MappingsAdapter } from './mappings.adapter';
import { FeatureService } from '../../../utils/feature-service';
import { selectContext } from '../../../context/context.reducer';
import { FieldMappingsAdapter } from './field-mappings.adapter';
import { CampaignExtract } from '../../../campaign-extract/campaign-extract.model';
import { Mapping } from './send-to.models';
import {Location} from '@angular/common';
import { UtilsService } from '../../../utils/utilservice';

@UntilDestroy()
@Component({
  selector: 'lib-send-to',
  templateUrl: 'send-to.component.html',
  styleUrls: ['send-to.component.sass']
})
export class SendToComponent implements OnInit {
  @ViewChild('sendToOwnedError', { static: true }) public sendToOwnedError;
  @ViewChild('scrollselect', { static: true })
  public scrollSelect: ElementRef;
  @ViewChild('resetFieldMappingsWarning') public resetFieldMappingsWarning;

  static readonly defaultLogo = 'epsilon_badgeLogo.svg';
  static readonly logos = [
    {name: 'amazonS3', logo: 'amazonS3_badgelogo.svg'},
    {name: 'epsilon', logo: 'epsilon_badgeLogo.svg'},
    {name: 'tradedesk', logo: 'tradeDesk_badgeLogo.svg'},
    {name: 'dv360', logo: 'googledv360_badgeLogo.svg'},
    {name: 'adobe', logo: 'adobe_badgeLogo.svg'},
    {name: 'googleads', logo: 'googleAds_badgeLogo.svg'},
    {name: 'marketo', logo: 'marketo_badgeLogo.svg'},
    {name: 'salesforce', logo: 'salesforce_badgeLogo.svg'},
    {name: 'pinterest', logo: 'pinterest_badgeLogo.svg'},
    {name: 'tiktok', logo: 'tiktok_badgeLogo.svg'},
    {name: 'twitter', logo: 'twitter_badgeLogo.svg'},
    {name: 'instagram', logo: 'instagram_badgeLogo.svg'},
    {name: 'facebook', logo: 'facebook_badgeLogo.svg'},
    {name: 'pinterest', logo: 'pinterest_badgeLogo.svg'},
    {name: 'verizon', logo: 'verizon_badgeLogo.svg'}
  ];
  static readonly partnerLogosPath = 'assets/partner-logos/';

  audienceDefinitionId: string;
  audienceDefinitionName: string;
  audienceDefinitionRouter: string;
  audienceListRouter: string;
  connectorsRouter: string;
  contextId: string;
  dataHubConnectionPagination: ConnectionPaginationModel;
  dataHubTenantId: string;
  dataUniverseId: string;
  definitionsRouter: string;
  errorMessage = 'Send not successful - Please try again.';
  sendTo: UntypedFormGroup;
  showOwnedChannelMapping: boolean;
  sourceMappingAttribute$ = new BehaviorSubject<SchemaField[]>([]);
  targetFieldMapping: FieldMapping[];
  tenantId: string;
  filterConnectionName: string;
  productType: string;
  fieldMappings$ = new BehaviorSubject<FieldMapping[]>([]);
  campaignExtracts: CampaignExtract[];
  mappings: Mapping[] = [];
  loadMappingComponent = true;
  previousMappingObject: any;
  previousConnectionObject: any;
  eventType: string;
  isFromAudienceListTable: boolean;
  audiencesRouter: string;

  constructor(
    public store: Store<AppState>,
    public formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private builderService: AudienceBuilderService,
    private tenantService: TenantService,
    private audienceService: AudienceService,
    public campaignExtractService: CampaignExtractService,
    private schemaFieldsAdaptor: SchemaFieldsAdapter,
    private mappingsAdapter: MappingsAdapter,
    private fieldMappingsAdapter: FieldMappingsAdapter,
    private featureService: FeatureService,
    private location: Location,
    private utilsService: UtilsService
  ) {
    this.sendTo = new UntypedFormGroup({
      connectors: new UntypedFormControl('', [Validators.required]),
      scheduling: new UntypedFormControl(''),
      mapping: new UntypedFormControl('new'),
      previousMappings: new UntypedFormControl('')
    });
  }

  ngOnInit(): void {
    this.isFromAudienceListTable = this.route.snapshot.queryParamMap.get('type') === 'list';
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.dataUniverseId = this.route.snapshot.paramMap.get('dataUniverseId');
    this.audienceDefinitionId = this.route.snapshot.paramMap.get('id');
    this.definitionsRouter =  `/${this.utilsService.getProductBaseUrl(this.router, this.route)}/${this.contextId}/${DEFINITION_TXT}`;
    this.audiencesRouter =  `/${this.utilsService.getProductBaseUrl(this.router, this.route)}/${this.contextId}/${AUDIENCE_TXT}`;
    this.audienceDefinitionRouter = `/${this.utilsService.getProductBaseUrl(this.router, this.route)}/${this.contextId}/${this.dataUniverseId}/${BUILDER_TXT}/edit/${this.audienceDefinitionId}`;
    this.audienceListRouter = `/${this.utilsService.getProductBaseUrl(this.router, this.route)}/${this.contextId}/${this.dataUniverseId}/${AUDIENCE_TXT}/view/${this.audienceDefinitionId}`;
    this.retrieveAudienceDefinitionAttributes();
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.productType = context?.productType;
        this.showOwnedChannelMapping = this.featureService .isFeatureEnabled(FEATURE_OWNED_CHANNEL_MAPPING);
      });
    this.store.select(selectTenant).pipe(untilDestroyed(this)).subscribe((tenant: Tenant) => {
      this.dataHubTenantId = tenant?.dataHubTenantId ? tenant?.dataHubTenantId : null;
      this.tenantId = tenant?.tenantId;
      if(this.dataHubTenantId) {
        this.getConnections();
      } else if(this.tenantId) {
        this.store.dispatch(new tenantActions.FetchDataHubTenant(this.tenantId));
      }
      this.connectorsRouter = `/tenant/${tenant?.tenantId}/connectors/catalog`;
    });
    this.builderService.fetchCabAttributeDetails(
     this.isFromAudienceListTable ? DataType.AUDIENCE_LIST :  DataType.AUDIENCE_DEFINITION,
      this.contextId,
      this.dataUniverseId,
      this.audienceDefinitionId).subscribe((data: any) => {
        const { audienceDefinition, isUsed, audienceList } = _get(data, 'entity');
        const audienceData = audienceDefinition ?? audienceList;
        this.audienceDefinitionName = audienceData?.displayName;

        let builderAudience = null;

        if(audienceData) {
          builderAudience = convertAudienceDefinitionToBuilder(
            new AudienceDefinition(audienceData),true
          );
          builderAudience = {...builderAudience, isUsed};
        } else {
          builderAudience = convertAudienceDefinitionToBuilder(
            new Audience(audienceData),true
          );
        }
        this.store.dispatch(new audienceBuilderActions.LoadPrebuiltAudience(builderAudience));
        this.store.dispatch(
          new audienceBuilderActions.SetPrebuiltAudience(
            builderAudience,
            this.contextId,
            DataType.AUDIENCE_DEFINITION
          )
        );
      });
  }

  backToDefinitions(): void {
    this.location.back();
  }

  fetchConnectorLogos(connectionName: string, connectionType: string): string | void {
    if (connectionName) {
      connectionName = connectionName?.replace(/\s/g, '').toLowerCase();
      for (const connector of SendToComponent.logos) {
        if (connectionType && connectionType === 'S3' && connector.name === 'amazonS3') {
          return SendToComponent.partnerLogosPath + connector.logo;
        }
        else if (connectionName.includes(connector.name)) {
          return SendToComponent.partnerLogosPath + connector.logo;
        }
      }
      return SendToComponent.partnerLogosPath + SendToComponent.defaultLogo;
    }
  }

  getConnections(connectionType = 'S3'): void {
    this.tenantService.fetchConnections(this.tenantId, this.dataHubTenantId, 0, connectionType).subscribe(dataHubConnections => {
      this.dataHubConnectionPagination = dataHubConnections;
    });
  }

  retrieveAudienceDefinitionAttributes(): void {
    this.audienceService.retrieveAttributesForDefinition(this.audienceDefinitionId, this.contextId, this.dataUniverseId, this.isFromAudienceListTable,).subscribe((attributes: AttributeModel[]) => {
      const goldenProfileIndex = attributes?.findIndex(element => element?.displayName === 'Golden Profile');
      const [element] = attributes?.splice(goldenProfileIndex, 1);
      attributes?.splice(1, 0, element);
      const transformedSourceAttributes = this.schemaFieldsAdaptor.adapt(attributes);
      this.sourceMappingAttribute$.next(transformedSourceAttributes);
    });
  }

  send() {
    const mappingArray = this.mappingsAdapter.adapt(this.targetFieldMapping);
    const payload: any = {
      sendExtractToTargetType: 'DATAHUB_FEED',
      sendToTargetInfo: {
        mapping: mappingArray,
        connectionId: this.sendTo.get('connectors')?.value?.id,
        connectionDisplayName: this.sendTo.get('connectors')?.value?.name
      }
    };
    if(this.isFromAudienceListTable) {
      payload.audienceListId = this.audienceDefinitionId;
    } else {
      payload.audienceDefinitionId = this.audienceDefinitionId;
    }
      payload.cabContextId = this.contextId;
      payload.sendToTargetType = 'DATAHUB_FEED';
      this.campaignExtractService.sendToForCampaignExtract(this.contextId, payload).subscribe(() => {
        this.router.navigate([this.utilsService.getProductBaseUrl(this.router, this.route), this.contextId, 'deliveries']);
      }, errorResponse => {
        if (errorResponse?.error?.errorDetails) {
          this.errorMessage = errorResponse.error.errorDetails[0]?.errorMessage;
        }
        this.sendToOwnedError.show();
      });

  }

  updateFieldMappings(fieldMappings: FieldMapping[]) {
    this.targetFieldMapping = fieldMappings;
  }

  selectLimitReached() {
    if(this.dataHubConnectionPagination.offset) {
      this.tenantService.fetchConnections(this.tenantId, this.dataHubTenantId, 0).subscribe(dataHubConnections => {
        this.dataHubConnectionPagination.results.concat(dataHubConnections.results);
        this.dataHubConnectionPagination.offset = dataHubConnections.offset;
      });
    }
  }

  filterConnectionByName(connectionName) {
    this.filterConnectionName = connectionName;

  }

  populateFieldMappings(campaignExtract: any) {
    this.previousMappingObject = this.sendTo.get('previousMappings').value;
    if (this.sendTo.get('previousMappings').value && this.sendTo.get('previousMappings').value !== campaignExtract) {
      this.eventType = 'mapping-change';
      this.showFieldMappingsWarning();
    }
    this.mappings = campaignExtract.sendToTargetInfo?.mapping;
    this.getFieldMappings(this.mappings);
  }

  getFieldMappings(mappings: Mapping[]) {
    if (mappings) {
      const preSelectedMappings = this.fieldMappingsAdapter.adapt(mappings);
      this.fieldMappings$.next(preSelectedMappings);
    }
  }

  resetFieldMappings() {
    this.loadMappingComponent = false;
    this.targetFieldMapping = [];
    this.fieldMappings$.next([]);
    setTimeout(() => (this.loadMappingComponent = true), 10);
  }

  connectionChange(connection: any) {
    this.eventType = 'connection-change';
    this.previousConnectionObject = this.sendTo.get('connectors')?.value;
    if (this.targetFieldMapping?.length) {
      this.showFieldMappingsWarning();
    } else {
      this.getMappings(connection);
    }
  }

  getMappings(sourceConnection?) {
    const connection = sourceConnection ? sourceConnection : this.sendTo.get('connectors')?.value;
    if (connection) {
      const contextId = this.route.snapshot.paramMap.get('contextId');
      const searchPayload = {
        ...this.isFromAudienceListTable ? {
          audienceListId:  this.audienceDefinitionId
        }: {
          audienceDefinitionId: this.audienceDefinitionId,
        },
        sendToConnectionId: connection.id,
        sendToTargetType: 'DATAHUB_FEED',
        sort: 'DESC'
      };
      console.log(searchPayload);
      this.campaignExtractService
        .searchCampaignExtracts(contextId, searchPayload)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.campaignExtracts = res.campaignExtracts;
          if (this.sendTo.get('mapping').value === 'previous' && !this.campaignExtracts?.length) {
            this.sendTo.get('mapping').setValue('new');
          }
        });
    }
  }

  radioSwitch() {
    this.eventType = 'radio-change';
    if (this.targetFieldMapping?.length) {
      this.showFieldMappingsWarning();
    }
  }

  showFieldMappingsWarning() {
    this.resetFieldMappingsWarning?.show();
  }

  continue() {
    this.resetFieldMappings();
    if (this.eventType === 'connection-change' || this.sendTo.get('mapping').value === 'new') {
      this.getMappings();
      this.sendTo.get('previousMappings').setValue('');
    } else if (this.eventType === 'mapping-change') {
      this.mappings = this.sendTo.get('previousMappings').value?.sendToTargetInfo?.mapping;
      this.getFieldMappings(this.mappings);
    }
    this.eventType = '';
    this.resetFieldMappingsWarning.hide();
  }

  cancel() {
    if (this.eventType === 'connection-change') {
      this.sendTo.get('connectors').setValue(this.previousConnectionObject);
      this.fieldMappings$.next(this.targetFieldMapping);
    } else if (this.eventType === 'radio-change') {
      if (this.sendTo.get('mapping').value === 'previous') {
        this.sendTo.get('mapping').setValue('new');
        this.fieldMappings$.next(this.targetFieldMapping);
      } else {
        this.sendTo.get('mapping').setValue('previous');
      }
    } else if (this.eventType === 'mapping-change') {
      this.sendTo.get('previousMappings').setValue(this.previousMappingObject);
    }
    this.eventType = '';
    this.resetFieldMappingsWarning.hide();
  }

  showMappingComponent() {
    return this.showOwnedChannelMapping && this.sendTo.get('connectors')?.value;
  }
}
