import { Action } from '@ngrx/store';
import {DataSets, SchemaData} from './admin.models';

export class LoadSchemas implements Action {
  static readonly type = 'ADMIN:LOAD_SCHEMAS';
  readonly type = LoadSchemas.type;

  constructor(public schemaData: SchemaData) {}
}

export class LoadSchemaTables implements Action {
  static readonly type = 'ADMIN:LOAD_SCHEMA_TABLES';
  readonly type = LoadSchemaTables.type;

  constructor(public dataSets: DataSets) {}
}

export class LoadDataUniverseTables implements Action {
  static readonly type = 'ADMIN:LOAD_DATAUNIVERSE_TABLES';
  readonly type = LoadDataUniverseTables.type;

  constructor(public dataUniverseTables: any) {}
}

export class GetSchemas implements Action {
  static readonly type = 'ADMIN:GET_SCHEMAS';
  readonly type = GetSchemas.type;

  constructor(public cabContextId: string, public dataUniverseId: string) {}
}

export class FetchTablesBySchema implements Action {
  static readonly type = 'ADMIN:FETCH_TABLES_BY_SCHEMA';
  readonly type = FetchTablesBySchema.type;

  constructor(public fetchTablesBySchemaPayload: any) {}
}

export class FetchTablesByDataUniverse implements Action {
  static readonly type = 'ADMIN:FETCH_TABLES_BY_DATAUNIVERSE';
  readonly type = FetchTablesByDataUniverse.type;

  constructor(public fetchTablesByDataUniversePayload: any) {}
}

export class RemoveTableByDatasetId implements Action {
  static readonly type = 'ADMIN:REMOVE_TABLE_BY_DATASETID';
  readonly type = RemoveTableByDatasetId.type;

  constructor(public cabContextId: string, public datasetId: string, public fetchTablesByDataUniversePayload) {}
}

export class ResetDatsets implements Action {
  static readonly type = 'ADMIN:RESET_DATASETS';
  readonly type = ResetDatsets.type;
}


export type ReducerActions =
  | LoadSchemas
  | LoadSchemaTables
  | LoadDataUniverseTables
  | GetSchemas
  | FetchTablesBySchema
  | FetchTablesByDataUniverse
  | RemoveTableByDatasetId
  | ResetDatsets;
