import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CabHttpService, ContextType } from '../services/cab-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../tenant/tenant.actions';
import { fetchResource } from '../utils/fetch-state';
import { Tenant } from './tenant.model';
import { ConnectionPaginationModel } from './connection.model';
import { CabConstants } from '../cab.constants';
import { UtilsService } from '../utils/utilservice';

@Injectable({
  providedIn: 'root',
})
export class TenantService {

  fetchTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FetchTenant.type),
      fetchResource((action) =>
        this.fetchTenant('DCDP', action.tenantId).pipe(
          mergeMap((tenant: Tenant) => [
            new actions.LoadTenant(tenant)
          ])
        )
      )
    )
  );

  fetchDataHubTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FetchDataHubTenant.type),
      fetchResource((action) =>
        this.fetchDataHubTenant( action.tenantId).pipe(
          mergeMap((dataHubTenantId: string) => [
            new actions.LoadDataHubTenantId(dataHubTenantId)
          ])
        )
      )
    )
  );

  constructor(private httpService: CabHttpService, private actions$: Actions, private http: HttpClient, private utilsService: UtilsService) {}

  fetchTenant(productType: string, tenantId: string): Observable<Tenant> {
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/cab-context/product-type/${productType}/tenant/${tenantId}`);
    return this.http.get(url)
      .pipe(
        map((res: any) => {
          return new Tenant(res);
        })
      );
  }

  fetchDataHubTenant(tenantId: string): Observable<string> {
    const headers = {};
    headers[CabConstants.ADH_TENANT_HEADER] = tenantId;
    return this.http.get(`${this.utilsService.getDataHubAPIUrl()}/tenants-api/v1/tenant`, {headers})
      .pipe(
        map((res: any) => {
          return res.id;
        })
      );
  }

  fetchConnections(tenantId: string, dataHubTenantId: string, offset = 0, connectionType = 'S3'): Observable<ConnectionPaginationModel> {
    const headers = {};
    headers[CabConstants.ADH_TENANT_HEADER] = tenantId;
    let queryString = `type=${connectionType}`;
    if(offset) {
      queryString += `pageOffset=${offset}`;
    }
    return this.http.get(`${this.utilsService.getDataHubAPIUrl()}/tenants-api/v1/connections/${dataHubTenantId}?pageSize=1000&${queryString}`, {headers})
      .pipe(
        map((res: ConnectionPaginationModel) => res
        )
      );
  }
}
