import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
  RuntimeChecks
} from '@ngrx/store';

import * as fromFetch from '../utils/fetch-state';
import * as fromContext from '../context/context.reducer';
import * as fromDataUniverse from '../data-universe/data-universe.reducer';
import * as fromAudience from '../audience/audience.reducer';
import * as fromAudienceBuilder from '../audience-builder/audience-builder.reducer';
import * as fromUser from '../user/user.reducer';
import * as fromCampaignExtract from '../campaign-extract/campaign-extract.reducer';
import * as fromAdmin from '../admin/admin.reducer';
import * as fromTenant from '../tenant/tenant.reducer';
import * as fromHasUnsavedChangesContext from '../hasUnsavedChanges/hasUnsavedChanges.reducer';
import * as fromFeedData from '../feed-data-list/feed-data-list.reducer'

export interface AppState {
  fetchStates: fromFetch.MetaFetchState;
  context: fromContext.State;
  dataUniverse: fromDataUniverse.State;
  audiences: fromAudience.State;
  audienceBuilder: fromAudienceBuilder.State;
  user: fromUser.State;
  campaignExtracts: fromCampaignExtract.State;
  admin: fromAdmin.State;
  tenant: fromTenant.State;
  application: fromHasUnsavedChangesContext.IApplicationState;
  feedData: fromFeedData.State
}

export const reducers: ActionReducerMap<AppState> = {
    fetchStates: fromFetch.fetchMetaReducer,
    context: fromContext.reducer,
    dataUniverse: fromDataUniverse.reducer,
    audiences: fromAudience.reducer,
    audienceBuilder: fromAudienceBuilder.reducer,
    user: fromUser.reducer,
    campaignExtracts: fromCampaignExtract.reducer,
    admin: fromAdmin.reducer,
    tenant: fromTenant.reducer,
    application: fromHasUnsavedChangesContext.reducer,
    feedData: fromFeedData.reducer
};

// use this helper function in `metaReducers` to console.log all NgRx actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

// You can think of meta-reducers as hooks into the action->reducer pipeline.
// Meta-reducers allow devs to pre-process actions before normal reducers are invoked.
export const metaReducers: MetaReducer<AppState>[] = []; // [debug]

export const runtimeChecks: Partial<RuntimeChecks> = {
  strictStateImmutability: false,
  strictActionImmutability: false,
  // TODO: fetch-state actions and state are not serializable
  // strictStateSerializability: true,
  // strictActionSerializability: true,
  strictActionWithinNgZone: true,
  strictActionTypeUniqueness: true,
};
