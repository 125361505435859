<coreui-notification notificationStyle="success" #successProductDataSetIds>
  <b>Success!</b>
  <br/>
  <b *ngIf="drawerContentResults.successProductDataSetIds">{{drawerContentResults.successProductDataSetIds.length}} are added successfully</b>
</coreui-notification>
<coreui-notification notificationStyle="error" #failedProductDataSetIds>
  <b>Failure!</b>
  <br/>
  <b *ngIf="drawerContentResults.failedProductDataSetIds">{{drawerContentResults.failedProductDataSetIds.length}} {{drawerContentResults.failedProductDataSetIds.length === 1 ? 'table was not added' : 'tables were not added'}}</b>
</coreui-notification>
<div class="tabs">
    <coreui-tab-group (tabChange)="tabChange($event)">
      <coreui-tab label="Tables" [active]="activeTab === 'admin'">
        <div class="datauniverse-container">
          <div class="datauniverse-select-container">
            <div class="dataUniverseBtn">Data universe </div>
            <form class="Core-Form" [formGroup]="formGroup">
              <coreui-select formControlName="dataUniverse" (valueChange)="onDataUniverseSelect($event)"
                [value]="activeDataUniverse">
                <coreui-option *ngFor="let dataUniverse of dataUniverses" [value]="dataUniverse">
                  {{ dataUniverse.displayName }}
                </coreui-option>
              </coreui-select>
            </form>
            <span class="tableBtn">
              <button coreUIButton (click)="onAddTableHandler()">Add Tables</button>
            </span>
          </div>
          <lib-dataset-table [contextId]="contextId" [dataUniverse]="activeDataUniverse" (addTableHandler)="showAddTableDrawer = true"></lib-dataset-table>
        </div>
      </coreui-tab>
      <coreui-tab label="Enumerations" [active]="activeTab === 'enumeration'">
        <lib-enumeration-table *ngIf="activeTab === 'enumeration'"  [contextId]="contextId"></lib-enumeration-table>
      </coreui-tab>
    </coreui-tab-group>
  </div>
  <coreui-drawer [(visible)]="showAddTableDrawer"  (onHide)="onHideTableHandler()" position="right">
    <h2>Select Tables and Add</h2>
    <div class="drawer-content--inside">
      <lib-add-table-modal *ngIf="showAddTableDrawer && activeDataUniverse" [contextId]="contextId" [dataUniverseId]="activeDataUniverse?.id" (onCloseDrawer)="onCloseDrawer($event)"></lib-add-table-modal>
    </div>
</coreui-drawer>
