import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CoreActivationService } from '@epsilon-ams/ams-core-activation';
import { AppState } from '../../../reducers';
import { selectUser } from '../../../user/user.reducer';
import { selectContext } from '../../../context/context.reducer';
import { AudienceDefinition } from '../../../audience/audience.models';
import { Context } from '../../../context/context.models';
import { User } from '../../../user/user.models';
import { AudienceService } from '../../../audience/audience.service';
import { CampaignExtractService } from '../../../campaign-extract/campaign-extract.service';
import { SaveAudience, SaveAudienceDefinition } from '../../../audience/audience.actions';
import { AudienceBuilderService } from '../../../audience-builder/audience-builder.service';
import { mergeMap, Subscription } from 'rxjs';
import { PrivacyCodeClassifications } from '../../../audience-builder/audience-builder.models';
import { DEFINITION_TXT } from '../../../utils/utils';
import { UtilsService } from '../../../utils/utilservice';
import { Environment } from '../../../environments/environment-config';

@UntilDestroy()
@Component({
  selector: 'lib-ams-activation-wrapper',
  templateUrl: './ams-activation-wrapper.component.html',
  styleUrls: ['./ams-activation-wrapper.component.sass']
})
export class AmsActivationWrapperComponent implements OnInit, OnDestroy {
  userDetails: User;
  amsAudience;
  returnRoute = '';
  createDestinationRoute = '';
  redirectOnSaveRoute = '';
  contextId: string;
  audienceDefinitionId: string;
  context: Context;
  audienceDefinition: any;
  audienceDefinitionApiResponse: AudienceDefinition;
  associateAmsAudienceId = true;
  isFromAudienceListTable: boolean;
  audienceCabId: string;
  loggedInUserEmail: string;
  subscription$: Subscription[] = [];

  constructor(private audienceService: AudienceService, private route: ActivatedRoute, private router: Router, private store: Store<AppState>,
    public campaignExtractService: CampaignExtractService, private coreActivationService: CoreActivationService, private audienceBuilderService: AudienceBuilderService, private utilsService: UtilsService, @Inject('ENVIRONMENT') private environment: Environment, @Inject('AUTHENTICATION_SERVICE') private authenticationService: any) {
    this.store
      .select(selectUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => (this.userDetails = user));
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe(ctx => {
        this.context = ctx;
        this.createDestinationRoute = `/tenant/${ctx?.tenantId}/connectors/catalog`;
      });
  }

  ngOnInit(): void {
    this.getLoggedInUserEmail();
    this.isFromAudienceListTable = this.route.snapshot.queryParamMap.get('type') === 'list';
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.audienceDefinitionId = this.route.snapshot.paramMap.get('id');
    this.returnRoute = this.utilsService.getProductBaseUrl(this.router, this.route) + '/';
    this.route.snapshot.parent?.parent?.parent?.url?.forEach(url => { this.returnRoute = this.returnRoute.concat(url.path).concat('/'); });
    this.route.snapshot.parent?.parent?.url?.forEach(url => { this.returnRoute = this.returnRoute.concat(url.path).concat('/'); });
    this.coreActivationService.parentRoute$.next('/'+this.returnRoute);
    this.redirectOnSaveRoute = `${this.returnRoute}deliveries`;
    this.returnRoute = `${this.returnRoute}${this.isFromAudienceListTable ? 'audience' : 'definition'}`;
    this.audienceService.fetchAudienceTableBasedonType(this.isFromAudienceListTable, this.audienceDefinitionId, this.contextId).pipe(mergeMap((definition: AudienceDefinition) => {
      this.audienceDefinitionApiResponse = definition;
      const {audienceListId, audienceDefinitionId} = this.getAudienceId(this.isFromAudienceListTable, definition.id)
      return this.audienceBuilderService.privacyCodeClassifications({cabContextId:this.contextId,audienceListId, audienceDefinitionId })
    })).subscribe((classificationCodesResponse: PrivacyCodeClassifications) => {
      const {classificationCodes} = classificationCodesResponse
        this.setActivationWrapper(this.audienceDefinitionApiResponse, classificationCodes);
    }, (err)=> {
      const {error} = err
      const errorMessage = error?.errorDetails?.[0]?.errorMessage || err.message;
        this.router.navigate([
          this.utilsService.getProductBaseUrl(this.router, this.route),
          this.contextId,
          DEFINITION_TXT,
        ], {state:{errorMessage}});
    });
    this.registerActivationAndMatchReportEvents();
  }

  ngOnDestroy() {
    this.subscription$.forEach(subscription$ => {
      subscription$.unsubscribe();
    });
  }

  getLoggedInUserEmail() {
    this.authenticationService.getUserEmail$().subscribe((email: string) => {
      this.loggedInUserEmail = email;
    }, (error) => {
      console.log(error)
    })
  }

  registerActivationAndMatchReportEvents() {
    const activationRequestedSubscription$ = this.coreActivationService.activationRequested$.subscribe(amsActivationData => {
      if(this.audienceDefinition && Object.keys(amsActivationData).length) {
        this.saveAMSData(amsActivationData);
      }
    });
    this.subscription$.push(activationRequestedSubscription$);

    const membershipRequestedSubscription$ = this.coreActivationService.membershipRequested$.subscribe(amsMembershipData => {
      if(this.audienceDefinition && Object.keys(amsMembershipData).length) {
        this.generateMatchReports(amsMembershipData);
      }
    });
    this.subscription$.push(membershipRequestedSubscription$);
  }

  setActivationWrapper(definition: AudienceDefinition, classificationCodes: string[]) {
    this.audienceDefinition = definition;
    this.coreActivationService.currentUser$.next({
      username: this.userDetails?.userName ? this.userDetails?.userName : this.loggedInUserEmail,
      email: this.userDetails?.email ? this.userDetails?.email : this.loggedInUserEmail
    });
    this.coreActivationService.companyId$.next(this.context?.metadata?.amsCompanyId);
    this.amsAudience = {
      id: definition.cabId,
      name: definition.displayName,
      amsAudienceId: definition.audienceAttributes?.amsAudienceId,
      idCount: definition.idCount,
      companyId: this.context?.metadata?.amsCompanyId,
      sourceSystem: this.context?.productType,
      dataSources: [
        {
          vendorName: this.context?.productType,
          percentage: 100,
          classificationCodes
        }
      ],
      clientContext: this.context.id,
      notes: this.environment,
      type: definition?.audienceAttributes?.isPVEAudience ? 'pve' : null,
      audienceClassification: this.isFromAudienceListTable? 'List' : 'Definition'
    };
  }

  getAudienceId(isFromAudienceListTable: boolean, definitionId: string): {audienceListId: string; audienceDefinitionId:string} {
    if(isFromAudienceListTable){
      return {audienceListId: definitionId, audienceDefinitionId: ''}
    }
    return {audienceListId: '', audienceDefinitionId:  definitionId}
  }

  saveAMSData(event) {
    const payload = {
      ...this.isFromAudienceListTable ? {
        audienceListId:  this.audienceDefinitionId
      }: {
        audienceDefinitionId: this.audienceDefinitionId,
      },
      audienceCabId: this.audienceDefinition?.cabId,
      cabContextId: this.contextId,
      sendToTargetType: 'AMS',
      sendToTargetInfo: {
        amsActivationId: event?.amsActivationId,
        amsAudienceId: event?.amsAudienceId
      }
    };
    if(event?.amsActivationState === 'draft') {
      if(this.isFromAudienceListTable) {
        if(this.associateAmsAudienceId && !this.audienceDefinition.audienceAttributes?.amsAudienceId) {
          this.associateAmsAudienceId = false;
          this.audienceDefinition.audienceAttributes['amsAudienceId'] = event.amsAudienceId;
          this.store.dispatch(new SaveAudience(this.audienceDefinition));
        }
      } else {
        if(this.associateAmsAudienceId && !this.audienceDefinition.audienceAttributes?.amsAudienceId) {
          this.associateAmsAudienceId = false;
          this.audienceDefinition.audienceAttributes.amsAudienceId = event.amsAudienceId;
          this.store.dispatch(new SaveAudienceDefinition(this.audienceDefinition));
        }
      }
    } else if(event?.amsActivationState === 'pending') {
      this.campaignExtractService.sendToForCampaignExtract(this.contextId, payload).subscribe(() => {
        // campaignExtractService subscribe
      });
    }
  }

  generateMatchReports(event) {
    const payload = {
      audienceCabId: this.audienceDefinition?.cabId,
      cabContextId: this.contextId,
      sendToTargetType: 'AMS_FORECAST_REPORT',
      sendToTargetInfo: {
        amsMatchReportId: event?.amsMatchReportID,
        amsAudienceId: event?.amsAudienceId
      }
    };
    this.campaignExtractService.sendToForCampaignExtract(this.contextId,payload).subscribe(() => {
      // campaignExtractService subscribe
    });
  }
}
