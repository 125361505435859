import { DataSetType } from '../enums/data-types';

export interface BuilderAudience {
  id?: string;
  displayName?: string;
  dataUniverseId?: string;
  selectExpressions?: any | null;
  includeConditions?: AudienceRuleGroup[] | null;
  excludeConditions?: AudienceRuleGroup[] | null;
  dedupeIdentityType?: string;
  idCount?: number;
  countUpdatedOn?: string;
  countRequestedAt?: string;
  countStatus?: string;
  version: number;
  isUsed?: boolean;
  cabContextId?: string;
  errorMessage?: string;
  description?: string;
  audienceAttributes?: AudienceAttributes;
  createdBy?: string;
  lastModifiedBy?: string;
  createdDate?: string;
  lastModifiedDate?: string;
  audienceQueryCaseInsensitive?: boolean;
}

export class AudienceAttributes {
  channelType: string;
  amsAudienceId?: string;
  isPVEAudience?: boolean;
  alternateKeyType?: string;
}

export interface BuilderAttribute {
  name: string;
  dataType: string;
  nativeDataType?: string;
  displayName: string;
  id: string;
  leaf: boolean;
  rootId?: string;
  cabId?: string;
  // data field is provided by audiences and definitions
  data?: AttributeDetail;
  path?: SegmentPath[];
  isAggregator?: boolean;
}

export interface AttributeDetail {
  cabId: string;
  id: string;
  displayName: string;
  dataType: BuilderAudienceDataType;
  nativeDataType?: string;
  dataSetType: DataSetType;
  validations: any;
  leaf: boolean;
  rootId?: string;
  name?: string;
  path?: SegmentPath[];
  entityEnumId: string;
}

export interface AttributeDetails {
  [cabId: string]: AttributeDetail;
}

export function newAudience(): BuilderAudience {
  return {
    displayName: '',
    selectExpressions: null,
    includeConditions: [emptyAudienceRuleGroup('Include')],
    excludeConditions: [emptyAudienceRuleGroup('Exclude')],
    audienceQueryCaseInsensitive: false,
    version: 0,
  };
}

export function emptyAudienceRuleGroup(
  typeName: BuilderAudienceTypeNameType
): AudienceRuleGroup {
  return { logicalOperator: 'AND', typeName, group: [], aggregationConditions: [] };
}

export type AudienceRuleEntry = AudienceRuleGroup;

export interface AudienceRuleGroup {
  logicalOperator?: AudienceBooleanType;
  typeName?: BuilderAudienceTypeNameType;
  group?: AudienceRuleGroup[] | AudienceExpressionGroup[];
  aggregationConditions?: AudienceRuleGroup[] | AudienceExpressionGroup[];
  genAIRequestId?: string;
  feedbackStatus?: string;
}

export interface AudienceExpressionGroup {
  logicalOperator?: AudienceBooleanType;
  expression?: AudienceExpression;
  displayName?: string;
  isAggregator?: boolean;
  path?: SegmentPath[];
  aggregationConditions?: AudienceExpressionGroup[] | AudienceRuleGroup[];
}

export interface AudienceExpression {
  expressionType?: ExpressionType;
  firstOperand?: AudienceExpressionOperand;
  comparisonOperator?: string;
  secondOperand?: AudienceExpressionOperand;
  operand?: AudienceExpressionOperand;
}

export interface AudienceExpressionOperand {
  valueType?: ExpressionValueType;
  values?: any[];
  id?: string;
  cabId?: string;
  name?: string;
  dataSetId?: string;
  dataType?: BuilderAudienceDataType;
  nativeDataType?: string;
  comparedAttribute?: BuilderAttribute;
  relativeDate?: string[];
  function?: string;
  arguments?: AudienceExpressionOperand[];
  path?: SegmentPath[];
  valueDescriptors?: EnumValueDescriptor[];
  attributeDataType?: string;
}

export interface AudienceDefinitionGenerateAIResponse {
  entity: AudienceDefinitionGenerateAIResponseEntity;
  resultStatus: string;
  errorDetails: any[];
}

export interface AudienceDefinitionGenerateAIRating {
  cabContextId: string;
  genAIRequestId: string;
  userRating: number;
  userComments: string;
}

export interface AudienceDefinitionGenerateAIResponseEntity {
  query: AudienceQuery;
  nodes: AttributeDetail[];
  genAIRequestId: string;
}

export interface AudienceQuery {
  selectExpressions: any;
  includeConditions: AudienceRuleGroup[] | [];
  excludeConditions: AudienceRuleGroup[] | [];
  queryFlags?: {
    caseInsensitive: boolean
  };
}

export interface SegmentPath {
  cabId: string;
  displayName: string;
}

export interface GenerateAudienceQueryFromTextRequest {
  cabContextId: string;
  dataUniverseId: string;
  dedupeIdentityType: string;
  queryText: string;
  queryForExcludingAudiences?: boolean;
  categories: string[];
}

export interface EnumValueDescriptor {
  enumValue: EnumValue;
}

export interface EnumValue {
  id: string;
  displayName: string;
}
export interface PrivacyCodeClassificationsPayload {
  audienceDefinitionId?: string;
  audienceListId?: string;
  cabContextId: string;
  cabId?: string;
  dataUniverseId?: string;
  dedupeIdentityType?: string;
}

export interface AlternateKeyPayload {
  cabContextId: string;
  dataSetId: string; 
  attributeId: string;
}
export interface AlternateKeyResponse {
  results?: AlternateValues[];
  offset?: number;
  totalCount?: number;
  hasMore?: boolean;
}

export interface AlternateValues {
  id?: string;
  displayName?: string;
}
export interface DataPrivacyClassifications {
  cabId: string;
  privacyCode: string; 
  privacyDataClassification: string
}

export interface PrivacyCodeClassifications { 
  classificationCodes: string[];
  dataPrivacyClassifications: DataPrivacyClassifications[];
}

export type BuilderAudienceTypeNameType = 'Include' | 'Exclude';
export type AudienceBooleanType = 'AND' | 'NOT' | 'OR';
//TODO: Need to think how we can use DataType Enum here. // NOSONAR
export type BuilderAudienceDataType =
  | 'Date'
  | 'Number'
  | 'String'
  | 'AudienceList'
  | 'AudienceDefinition';
export type ExpressionType = 'BasicComparison';
export type ExpressionValueType = 'Attribute' | 'Function' | 'Literal';

export const VALUE = 'Value';
export const RELATIVE_VALUE = 'Relative Value';
export const ATTRIBUTE = 'Attribute';
export const activeOperatorTabNames = [VALUE, RELATIVE_VALUE, ATTRIBUTE];
export type ActiveOperatorTabType =
  | typeof VALUE
  | typeof RELATIVE_VALUE
  | typeof ATTRIBUTE;
export const ERROR_GENERIC =
  'Something went wrong saving this audience. Please try again later.';
export const ERROR_NO_ATTRIBUTE =
  'At least one attribute is required in either Include or Exclude.';
export const ERROR_NO_ATTRIBUTE_VALUE =
  'At least one attribute is missing a value.';
export const ERROR_NAME =
  'There is an error with the audience definition name.';
export const ERROR_DEDUPE_TYPE = 'Missing dedupe types for this data universe.';
