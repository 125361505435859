import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { get, upperCase } from 'lodash-es';
import {BehaviorSubject} from 'rxjs';

import { DropListDirective } from '@epsilon/core-ui';
import { AppState } from '../reducers';
import { selectContext } from '../context/context.reducer';
import {
  AudienceBooleanType,
  AudienceExpressionGroup,
  AudienceRuleGroup,
  BuilderAttribute,
  BuilderAudienceDataType
} from '../audience-builder/audience-builder.models';
import {AudienceBuilderService} from '../audience-builder/audience-builder.service';
import { DragDropService } from '../services/drag-drop.service';
import {FEATURE_NOT_CONDITION} from '../utils/feature-utils';
import { FeatureService } from '../utils/feature-service';

@UntilDestroy()
@Component({
  selector: 'lib-builder-aggregate-group',
  templateUrl: './builder-aggregate.component.html',
  styleUrls: ['./builder-aggregate.component.sass'],
})
export class BuilderAggregateComponent
  implements OnInit {
  @Input() attribute: AudienceRuleGroup;
  @Input() aggregateAttributeIndex: number;
  @Input() groupArray: Array<AudienceRuleGroup | AudienceExpressionGroup>;
  @Input() dedupeType: UntypedFormControl;
  @ViewChildren(DropListDirective) dropLists?: QueryList<DropListDirective>;
  aggregateDropBoxIndex: Map<number, boolean> = new Map<number, boolean>();
  booleanOperators: AudienceBooleanType[];
  enableAggregateFilter = false;
  isDragging$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dropDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor( public dragDropService: DragDropService,  public store: Store<AppState>,public builderService: AudienceBuilderService, public featureService: FeatureService) {

  }

  identity(index, item) {
    return item.displayName;
  }

  ngOnInit(): void {
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.booleanOperators = this.featureService.isFeatureEnabled(FEATURE_NOT_CONDITION) ? ['AND', 'OR', 'NOT'] : ['AND', 'OR'];
      });
    if(this.attribute.group.length > 0) {
      this.enableAggregateFilter = true;
    }
    setTimeout(() => {
      if (this.dropLists) {
        this.dragDropService.register(
          this.dropLists.toArray().map((dropList) => dropList.id)
        );
        this.dropLists.changes.subscribe(() => {
          this.dragDropService.register(
            this.dropLists.toArray().map((dropList) => dropList.id)
          );
        });
      }
    }, 1000);
  }

  removeAggregation() {
    this.aggregateDropBoxIndex.set(this.attribute.aggregationConditions.length -1,false);
  }

  toggleAggregatorButtonClick() {
    this.aggregateDropBoxIndex.set(this.attribute.aggregationConditions.length -1, true);
  }

  isDropAllowed(event: any) {
     const isAllowed = event.item.dropContainer.data[0].dataSetType === 'DATA_SET_ATTRIBUTE';
     this.dropDisabled$.next(!isAllowed);
     return isAllowed;
  }

  enableDrop() {
   this.dropDisabled$.next(false);
  }

  convertAttributeToAggregateExpression(
    attribute: BuilderAttribute,
    isAggregator: boolean
  ): AudienceExpressionGroup {
    const expression: any = {
        expressionType: 'BasicComparison',
        firstOperand: {
          id: attribute.id,
          valueType: 'Function',
          name: attribute.displayName,
          dataSetId: attribute.rootId,
          cabId: attribute.cabId,
          path: attribute.path,
          function: 'COUNT'
        },
        comparisonOperator: '',
        secondOperand: {
          dataType: attribute.dataType as BuilderAudienceDataType,
          values: [],
          valueType: 'Literal'
        },
      };
    return {
      displayName: attribute.displayName,
      logicalOperator: 'AND',
      isAggregator,
      expression
    };
  }

  convertAttributeToExpression(
    attribute: BuilderAttribute
  ): AudienceExpressionGroup {

    const expression: any = {
      expressionType: 'BasicComparison',
      firstOperand: {
        id: attribute.id,
        valueType: 'Attribute',
        name: attribute.displayName,
        dataSetId: attribute.rootId,
        cabId: attribute.cabId,
        path: attribute.path
      },
      comparisonOperator: '',
      secondOperand: {
        dataType: attribute.dataType as BuilderAudienceDataType,
        values: [],
        valueType: 'Literal'
      },
    };
    return {
      displayName: attribute.displayName,
      logicalOperator: 'AND',
      expression
    };
  }

  public onDropped(event: any,
    groupIdx: number): void {
    const draggedItem: any = event.previousContainer.data[event.previousIndex];

    if (!this.isDropAllowed(event)) {
      this.enableDrop();
      return;
    }

    const dropListClasses = get(
      event,
      'previousContainer.element.nativeElement.className',
      ''
    );
    const previousContainerIsPickerNode =
      dropListClasses?.includes('pcbc-nav-tree-node');
    const attributeExpression = this.convertAttributeToAggregateExpression(draggedItem, event.container.element.nativeElement.classList.contains('drop-list-aggregation'));
    if (previousContainerIsPickerNode) {
        this.attribute.aggregationConditions.push(attributeExpression);
    }
    this.aggregateDropBoxIndex.set(groupIdx,false);
    this.builderService.audienceBuilderUpdatedManually$.next(true);
    this.builderService.audienceBuilderCriteriaManually$.next(true);

  }

  getLogicalOperator(idx) {
    if(idx < this.attribute.aggregationConditions.length -1) {
      return this.attribute.aggregationConditions[idx + 1];
    } else if(this.aggregateAttributeIndex < this.groupArray.length -1) {
      return this.groupArray[this.aggregateAttributeIndex + 1];
    }
    return '';
  }

  getFilterLogicalOperator(idx) {
    if(idx < this.attribute.group.length -1) {
      return this.attribute.group[idx + 1];
    }
    return '';
  }

  isBooleanSelected(
    aggregateConditionLogicalOperator,
    selectedBoolean: AudienceBooleanType
  ) {
    return upperCase(aggregateConditionLogicalOperator) === upperCase(selectedBoolean);
  }

  updateGroupLogicalOperator(operator, idx) {
    if(idx < this.attribute.aggregationConditions.length -1) {
      this.attribute.aggregationConditions[idx + 1].logicalOperator = operator;
    } else if(this.aggregateAttributeIndex < this.groupArray.length -1) {
      this.groupArray[this.aggregateAttributeIndex + 1].logicalOperator = operator;
    }
    this.builderService.audienceBuilderUpdatedManually$.next(true);
  }

  updateFilterLogicalOperator(operator, idx) {
    if(idx < this.attribute.group.length -1) {
      this.attribute.group[idx + 1].logicalOperator = operator;
    }
    this.builderService.audienceBuilderUpdatedManually$.next(true);
  }

  toggleAggregatorFilters() {
    this.enableAggregateFilter = !this.enableAggregateFilter;
    if(!this.enableAggregateFilter) {
      this.removeAggregationFilter();
    }
  }

  removeAggregationFilter() {
    this.attribute.group = [];
  }

  isFilterDropAllowed() {
    // isFilterDropAllowed event Handler
  }

  onFilterDropped(event: any) {
    const draggedItem: any = event.previousContainer.data[event.previousIndex];
    let attributeExpression = draggedItem;
    if (draggedItem.cabId) {
      attributeExpression = this.convertAttributeToExpression(draggedItem);
    }

    if (event.previousContainer.id !== event.container.id) {
      (event.container.data as any).splice(
        event.currentIndex,
        0,
        attributeExpression
      );
    } else {
      if (event.currentIndex < event.previousIndex) {
        (event.container.data as any).splice(
          event.currentIndex,
          0,
          attributeExpression
        );
        (event.container.data as any).splice(
          event.previousIndex+1,
          1
        );
      } else {
        (event.container.data as any).splice(
          event.currentIndex+1,
          0,
          attributeExpression
        );
        (event.container.data as any).splice(
          event.previousIndex,
          1
        );
      }
    }
    //this.attribute.group.push(attributeExpression);
  }

  removeAggregationGroup(){
    this.groupArray.splice(this.aggregateAttributeIndex, 1);
    if (this.groupArray.length >= this.aggregateAttributeIndex) {
      this.groupArray.splice(this.aggregateAttributeIndex, 1);
    }
  }

  toggleDrag() {
    this.isDragging$.next(!this.isDragging$.getValue());
  }

  getOperatorLabel(operator: AudienceBooleanType): string {
    if(operator === 'NOT') {
      return 'AND NOT';
    }
    return operator;
  }
}



