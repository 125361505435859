
export const FEATURE_AGGREGATIONS = 'aggregations';
export const FEATURE_NESTED_AUDIENCES = 'nestedAudiences';
export const FEATURE_NESTED_DEFINITIONS = 'nestedDefinitions';
export const FEATURE_NOT_CONDITION = 'notCondition';
export const FEATURE_RELATIVE_DATES = 'relativeDates';
export const FEATURE_SHOW_PCBC_NAV_TREE = 'showPCBCNavTree';
export const FEATURE_EXPORT_CAMPAIGN_EXTRACTS = 'exportCampaignExtracts';
export const FEATURE_ATTRIBUTE_SEARCH = 'attributeSearch';
export const FEATURE_SCHEDULING = 'scheduling';
export const FEATURE_AUDIENCE_BUILDER_WIZARD_FLOW = 'audienceBuilderWizardFlow';
export const FEATURE_AUDIENCES = 'audiences';
export const FEATURE_AUDIENCE_BUILDER_ENUM_SELECTION = 'audienceBuilderEnumSelection';
export const FEATURE_OWNED_CHANNEL_MAPPING = 'ownedChannelMapping';
export const FEATURE_CAB_ATTRIBUTE_ACTIONS = 'cabAttributeActions';
export const FEATURE_PAID_CONNECTIONS_INFO = 'paidConnectionsInfo';
export const FEATURE_AUDIENCE_QUERY_CASE_INSENSITIVE = 'audienceQueryCaseInsensitive';
export const FEATURE_ACTIVATION_SCHEDULES = 'activationSchedules';
export const FEATURE_GOLDEN_PROFILE = 'profile360GoldenProfile';
export const FEATURE_CONNECTORS_DESTINATION_PAID_EPSILON_DIGITAL = 'connectorsDestinationPaidEpsilonDigital';
export const FEATURE_CONNECTORS_DESTINATION_PAID_OTHER = 'connectorsDestinationPaidOther';
export const FEATURE_CONNECTORS_DESTINATION_TRUE_TARGET = 'connectorsDestinationTrueTarget';
export const FEATURE_ROLE_PERMISSION_SERVICE = 'rolePermissionService';
export const FEATURE_ASSET_LISTING = 'assertListing';
export const FEATURE_SAVED_SEARCH = 'savedSearch';
export const FEATURE_TAGS_FILTERING = 'tagsFilter';
export const FEATURE_GOLDEN_PROFILE_DEDUPE = 'goldenProfileDedupe';