import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LocalStorageService, utils } from '@epsilon-cdp/cab-lib';
import { ContextsService } from '../contexts/contexts.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit {
  showLogin = false;
  oktaRedirectParam;
  baseUiParam;
  oktaUrl;

  constructor(
    private storageService: LocalStorageService,
    private route: ActivatedRoute,
    private contextsService: ContextsService
  ) {}

  ngOnInit(): void {
    this.oktaRedirectParam = `${utils.REDIRECT_PARAM_NAME}=${utils.DEFAULT_ROUTE}`;
    if(this.route.snapshot.queryParams['redirectURL']) {
      this.oktaRedirectParam = `${utils.REDIRECT_PARAM_NAME}=${this.route.snapshot.queryParams['redirectURL']}`;
    }
    this.baseUiParam =
    this.contextsService.environmentValue('envName') === 'dev'
      ? `&${utils.BASE_URL_PARAM_NAME}=${this.contextsService.environmentValue('baseUiUrl')}`
      : '';
    this.oktaUrl = `${this.contextsService.environmentValue('baseApiUrl')}/cab/login?${
      this.oktaRedirectParam
    }${this.baseUiParam}`;

    const autoLogin = this.route.snapshot.queryParams['autoLogin'];
    this.storageService.remove(utils.OKTA_TOKEN);

    //fetch(auth_utils.logoutUrl(), { method: 'GET', mode: "no-cors"}).then(res => {
    if (autoLogin === 'true') {
      setTimeout(() => (window.location.href = this.oktaUrl), 2);
    } else {
      this.showLogin = true;
    }
    //}, err => console.log('error while logging out user'));
  }
}
