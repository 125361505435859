import { InjectionToken, Injector } from '@angular/core';
import { Observable } from 'rxjs';

export interface CabConfig {
    env: any;
    cdpContext$?: Observable<any>;
}

export const CAB_CONFIG = new InjectionToken<CabConfig>(
    'Cab Configuration',
    {
        factory: () => { return { env: {}}; }
    }
);

export class InjectorInstance {
    public static injector: Injector;
}
