<div>
  <coreui-table
    class="table tableScroll"
    #scrollTable
    coreUIInfiniteScroll
    [scrollTarget]="scrollTable.scrollContainer"
    [fixedHeader]="true"
    (scrollLimitReached)="limitReached()"
  >
    <thead coreUITableSort>
    <tr coreUITableHeaderRow>
      <th scope="col" coreUITableStickyColumn [attr.aria-sort]="sortOption==='name' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'name')">
          Job name
        </coreui-table-header-column>
      </th>
      <th scope="col" [attr.aria-sort]="sortOption==='scheduleType' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'scheduleType')">
          Job Type
        </coreui-table-header-column>
      </th>
      <th scope="col" [attr.aria-sort]="sortOption==='scheduleParams.name' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'scheduleParams.name')" [active]="true" sortDirection="ASC">
          Schedule Name
        </coreui-table-header-column>
      </th>
      <th scope="col" *ngIf='false' [attr.aria-sort]="sortOption==='scheduleIntervalInfo.startDate' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'scheduleIntervalInfo.startDate')">
          Scheduled Time
        </coreui-table-header-column>
      </th>
      <th scope="col">
        <coreui-table-header-column [disableSort]="true">
          Data Universe
        </coreui-table-header-column>
      </th>
      <th scope="col" *ngIf='false' [attr.aria-sort]="sortOption==='scheduleIntervalInfo.startDate' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'scheduleIntervalInfo.startDate')">
          Dedupe By
        </coreui-table-header-column>
      </th>
      <th scope="col" [attr.aria-sort]="sortOption==='createdBy' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'createdBy')">
          Created by
        </coreui-table-header-column>
      </th>
      <th scope="col" *ngIf='false' [attr.aria-sort]="sortOption==='scheduleIntervalInfo.startDate' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-multiple-header-column label="Count">
          <coreui-table-header-column (sort)="sort($event, 'scheduleIntervalInfo.startDate')">
            Count
          </coreui-table-header-column>
          <coreui-table-header-column (sort)="sort($event, 'createdBy')">
            User
          </coreui-table-header-column>
        </coreui-table-multiple-header-column>

      </th>
      <th scope="col" coreUITableStickyColumn="last">
        <coreui-table-header-column [disableSort]="true">
        </coreui-table-header-column>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of scheduleDetailSearch?.scheduleDetails; let idx=index" coreUITableRow>
      <td coreUITableStickyColumn>{{item.name}}</td>
      <td>{{item.scheduleType === 'EXTRACT_FROM_DEFINITION' ? 'Export Definition' : 'Create List'}}</td>
      <td>{{item.scheduleParams.name}}</td>
      <td *ngIf='false'>{{item.scheduleIntervalInfo.startDate}}</td>
      <td>{{getDataUniverseDisplayName(item.dataUniverseId)}}</td>
      <td *ngIf='false'>{{'dedupe by'}}</td>
      <td>{{item.createdBy}}</td>
      <td *ngIf='false'>{{'count'}}</td>
      <td coreUITableStickyColumn="last">
      <coreui-menu menuButtonStyle="link" icon="more">
        <coreui-menu-section>
          <button
            type="button"
            *ngFor="let action of dropdownListActions"
            (click)="action.onClick(item)"
            coreUIMenuItem
          >
            {{ action.display }}
          </button>
        </coreui-menu-section>
      </coreui-menu>
      </td>
    </tr>
    </tbody>

    <tbody *ngIf="!scheduleDetailSearch?.scheduleDetails">
    <tr>
      <td colspan="8" class="empty-list">
        <coreui-graphic-message graphic="empty" label="Nothing Here">
          There are no schedules.
        </coreui-graphic-message>
      </td>
    </tr>
    </tbody>
  </coreui-table>
</div>
