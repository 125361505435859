import { Action } from '@ngrx/store';
import {
  FeedDataPayloadResponse,
  FeedDataRequest,
} from './feed-data-list.model';

export class FeedDataList implements Action {
  static readonly type = 'FEED_DATA:FEED_DATA_LIST';
  readonly type = FeedDataList.type;

  constructor(
    public contextId: string,
    public searchRequest: FeedDataRequest
  ) {}
}

export class LoadFeedDataList implements Action {
  static readonly type = 'FEED_DATA:LOAD_FEED_DATA';
  readonly type = LoadFeedDataList.type;

  constructor(public feedData: FeedDataPayloadResponse) {}
}

export class DestroyFeedDataList implements Action {
  static readonly type = 'FEED_DATA:DESTROY_FEED_DATA_LIST';
  readonly type = DestroyFeedDataList.type;

  constructor() {
    // DestroyFeedDataList constructor
  }
}

export type ReducerActions = FeedDataList | LoadFeedDataList | DestroyFeedDataList;
