import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash-es';
import {
  combineLatest as observableCombineLatest
} from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { SetPrebuiltAudience } from '../audience-builder.actions';
import { AppState } from '../../reducers';
import { selectDependentDefinitions, selectPrebuiltAudience } from '../audience-builder.reducer';
import { selectContext } from '../../context/context.reducer';
import { selectDataUniverses } from '../../data-universe/data-universe.reducer';
import { AudienceDefinition } from '../../audience/audience.models';
import { BuilderAudience } from '../audience-builder.models';
import { DataType } from '../../enums/data-types';
import { convertAudienceDefinitionToBuilder } from '../../audience/audience.utils';
import { BUILDER_TXT, isDefined } from '../../utils/utils';
import { AudienceService } from '../../audience/audience.service';
import { AudienceBuilderService } from '../audience-builder.service';
import { CountsService } from '../../counts/counts.service';
import { UrlService } from '../../services/url.service';
import { CabConstants } from '../../cab.constants';
import { UtilsService } from '../../utils/utilservice';

@UntilDestroy()
@Component({
  selector: 'lib-audience-builder-details',
  templateUrl: './audience-builder-details.component.html',
  styleUrls: ['./audience-builder-details.component.sass']
})
export class AudienceBuilderDetailsComponent implements OnInit {
  dataUniverseName: string;
  isProductTypeDcdp = false;
  @ViewChild('dependentDefinitionModal')
  public dependentDefinitionModal;
  @ViewChild('saveDefinitionOnVersionChange')
  public saveDefinitionOnVersionChange;
  @Output() saveDefinition: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() screen;

  dependentDefinitions: AudienceDefinition[];
  createdDetails: string;
  modifiedDetails: string;
  audienceDefinitionId: any;
  contextId: string;
  dataUniverseId: string;
  currentDependentDefinition: AudienceDefinition;
  prebuiltAudience: BuilderAudience;
  channelType: string;
  identifier: string;
  count: string | number;
  audienceDefinitionName: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public actions$: Actions,
    public urlService: UrlService,
    public store: Store<AppState>,
    public builderService: AudienceBuilderService,
    public audienceService: AudienceService,
    public countsService: CountsService,
    public datePipe: DatePipe,
    private utilsService: UtilsService) {

      this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        if(context?.productType === CabConstants.DCDP_PRODUCT_NAME) {
          this.isProductTypeDcdp = true;
        }
      });

      this.store
      .select(selectDependentDefinitions)
      .pipe(untilDestroyed(this))
      .subscribe((dependentDefinitions) => {
        this.dependentDefinitions = dependentDefinitions;
      });

    this.store
      .select(selectPrebuiltAudience)
      .pipe(untilDestroyed(this))
      .subscribe((audience) => {
        if (audience) {
          this.prebuiltAudience = audience;
          this.audienceDefinitionId = audience.id;
          this.audienceDefinitionName = audience.displayName;
          this.createdDetails = this.datePipe.transform(audience.createdDate, 'M/d/YY h:mm a', 'UTC') + ' UTC by ' + audience.createdBy;
          this.modifiedDetails = this.datePipe.transform(audience.lastModifiedDate, 'M/d/YY h:mm a', 'UTC') + ' UTC by ' + audience.lastModifiedBy;
          this.channelType = audience?.audienceAttributes?.channelType ?? audience['audienceAttributes']?.channelType;
          this.identifier = audience?.dedupeIdentityType;
          this.count = audience?.idCount;
        }
      });

      observableCombineLatest([
        this.builderService.activeDataUniverseId$,
        store.select(selectDataUniverses).pipe(filter(isDefined))
      ])
        .pipe(untilDestroyed(this))
        .subscribe(([dataUniverseId, dataUniverse]) => {
          this.dataUniverseName = dataUniverse.find((item) => {
            return item.id === dataUniverseId;
          })?.displayName;
        });
  }
  ngOnInit(): void {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.dataUniverseId = this.route.snapshot.paramMap.get('dataUniverseId');
  }

  showDependentDefinitionClickModal(dependentDefinition) {
    this.currentDependentDefinition = this.dependentDefinitions.find(definition => definition.id === dependentDefinition.id);
    if(this.builderService.audienceBuilderUpdatedManually$.value){
      this.dependentDefinitionModal.show();
    }else{
      this.routeTONewDefinition();
    }
  }

  discardAudienceDefinitionChangesAndRoute() {
    this.discardAudienceDefinitionChanges(false);
    this.dependentDefinitionModal.hide();
    this.router.navigateByUrl(this.router.url.replace(this.prebuiltAudience.id, this.currentDependentDefinition.id));
    this.routeTONewDefinition();
  }

  private routeTONewDefinition() {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      this.dataUniverseId,
      BUILDER_TXT,
      'edit',
      this.currentDependentDefinition.id
    ]).then(() => {
      window.location.reload();
    });
  }

  discardAudienceDefinitionChanges(isVersionChangeError: boolean) {
    this.builderService
      .fetchCabAttributeDetails(
        DataType.AUDIENCE_DEFINITION,
        this.contextId,
        this.dataUniverseId,
        this.route.snapshot.paramMap.get('definitionId')
      )
      .pipe(take(1), untilDestroyed(this))
      .subscribe((data: any) => {
        const { audienceDefinition } = _get(data, 'entity');
        const audienceItem = this.utilsService.isMocked()
          ? new AudienceDefinition(audienceDefinition[0])
          : new AudienceDefinition(audienceDefinition);

        this.store.dispatch(
          new SetPrebuiltAudience(
            convertAudienceDefinitionToBuilder(audienceItem),
            this.contextId,
            DataType.AUDIENCE_DEFINITION
          )
        );
        if (isVersionChangeError) {
          this.saveDefinitionOnVersionChange.hide();
        }
      });
  }

  saveCurrentDefinitionChanges() {
    this.saveDefinition.emit(false);
    this.dependentDefinitionModal.hide();
    this.routeTONewDefinition();
  }
}
