<form [formGroup]="schedulerFormGroup">
  <coreui-form-tab-group [style.display]="!isEditScheduler ? '' : 'none'">
    <coreui-form-tab label="Run once now"></coreui-form-tab>
    <coreui-form-tab label="Run later on a schedule"></coreui-form-tab>
  </coreui-form-tab-group>
  <div *ngIf="isEditScheduler || isFutureSchedule" class="schedule-container">
    <coreui-form-field label="Schedule Name" class="scheduleName">
      <input [readonly]="scheduleDetails" coreUIInput formControlName="scheduleName" placeholder="Name this schedule for future reference"/>
      <coreui-form-error>Required</coreui-form-error>
    </coreui-form-field>
    <coreui-form-field class="Recurrence">
      <coreui-section-header title="Recurrence">
      </coreui-section-header>
      <coreui-form-field label="Repeat" [inlineLabel]="true">
        <coreui-select formControlName="schedulerFrequency">
          <coreui-option *ngFor="let scheduleFrequency of schedulerFrequencyList" [value]="scheduleFrequency.value"
                         class="frequency-container2">
            {{ scheduleFrequency.name }}
          </coreui-option>
        </coreui-select>
        <div class="timezoneBadge">
          <coreui-badge color="Slate" [hasIcon]="true"><i coreUIIcon="clock"></i>Timezone: UTC</coreui-badge>
        </div>
      </coreui-form-field>
    </coreui-form-field>

    <div class="run-select-container" *ngIf="schedulerFormGroup.get('schedulerFrequency')?.value !== 'Week' && schedulerFormGroup.get('schedulerFrequency')?.value !== 'Once'">
          <coreui-form-field class="run" >
            <label class="runeveryfield">Run every</label>
            <input name="run" formControlName="run" min=1 type="number" coreUIInput/>
            <coreui-form-error>Required</coreui-form-error>
          </coreui-form-field>
          <div class="run-hour text-style-3">
            {{schedulerFormGroup.get("schedulerFrequency")?.value?.toLowerCase()+"(s)"}}
          </div>

          <div class="run-day-picker-container" *ngIf="schedulerFormGroup.get('schedulerFrequency')?.value === 'Day'">
              <span class="text-style-3">at</span>
              <div class="run-day-time-container">
                <coreui-form-field [fullWidth]="false">
                  <coreui-time-picker [hourFormat]="hourFormat" formControlName="timePickerForDaysFrequency">
                  </coreui-time-picker>
                  <coreui-form-error>Required</coreui-form-error>
                </coreui-form-field>
              </div>
          </div>

        <div class="weekly" *ngIf="schedulerFormGroup.get('schedulerFrequency')?.value === 'Week'">
          <div>
            <div class="label-holder">Run</div>
            <div class="radio-holder">
              <div class="radio-holder-options-week">
                <coreui-radio-group orientation="vertical">
                  <coreui-form-field label="every week" class="every-week">
                    <input type="radio" coreUIRadio value="scheduleOptionForMonth" value="everyweek" formControlName="scheduleSectionForWeek" />
                  </coreui-form-field>
                  <coreui-checkbox-group orientation="horizontal" class="every-week-checkbox" [ngClass]="{'disableWeek': everySelection === 'every'}">
                    <div class="radio-holder-weeek-container">
                      <coreui-form-field class="item-width" *ngFor="let schedulerWeekForRun of schedulerDaysList" [value]="schedulerWeekForRun.value">
                        <span class="everyweek-list">
                          <input type="checkbox" coreUICheckbox
                            [formControlName]="schedulerWeekForRun.value" />
                        </span>
                        <span class="everyweek-container">{{schedulerWeekForRun.name }}</span>
                      </coreui-form-field>
                    </div>
                  </coreui-checkbox-group>
                </coreui-radio-group>
              </div>
              <div class="radio-holder-options">
                <coreui-radio-group orientation="vertical">
                  <div>
                  <coreui-form-field label="every">
                    <input type="radio" coreUIRadio value="scheduleOptionForWeeks" value="every"
                      formControlName="scheduleSectionForWeek" />
                  </coreui-form-field>
                </div>
                <div class="every-week-frequency-container" [ngClass]="{'disableWeek': everySelection === 'everyweek'}">
                  <coreui-form-field>
                    <coreui-select formControlName="selectEveryWeekFrequency" class="selectEveryWeekFrequency" placeholder="1">
                      <coreui-option *ngFor='let weekNumber of sequence(31)' [value]="weekNumber" >{{weekNumber}}</coreui-option>
                    </coreui-select>
                    <span class="weeks-label">Weeks</span>
                  </coreui-form-field>
                </div>
            </coreui-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class ="weekly" [style.display]="schedulerFormGroup.get('schedulerFrequency')?.value === 'Week' ? '' : 'none'" >
      <span class="at-container" id="atcoulmns">At</span>
      <span class="at-time-container">
            <coreui-time-picker [hourFormat]="hourFormat" formControlName="atWeeklyTimePicker">
            </coreui-time-picker>
          </span>
    </div>
    <div class="monthly" *ngIf="schedulerFormGroup.get('schedulerFrequency')?.value === 'Month'">
            <div class="label-holder text-style-3">
              On
            </div>
            <div class="radio-holder">
              <div class="radio-holder-options">
                <coreui-radio-group orientation="vertical">
                  <coreui-form-field>
                    <input type="radio" coreUIRadio value="DAY"
                           formControlName="dayOrRankForMonth" />
                  </coreui-form-field>
                </coreui-radio-group>
              </div>
              <div class="radio-holder-options">
                <coreui-radio-group orientation="vertical">
                  <coreui-form-field>
                    <input type="radio" coreUIRadio value="RANK_OF_THE_WEEK"
                           formControlName="dayOrRankForMonth" />
                  </coreui-form-field>
                </coreui-radio-group>
              </div>
            </div>
            <div class="form-holder">
              <div class="form-holder-options">
                <div class="form-holder-fields">
                  <coreui-form-field>
                    <coreui-select formControlName="dayOfMonth" placeholder="Select">
                      <coreui-option *ngFor='let day of sequence(31)' [value]='day'>{{day}}</coreui-option>
                    </coreui-select>
                  </coreui-form-field>
                </div>
                <div class="days-at text-style-3">
                  day at
                </div>
                <div class="time-picker-container">
                  <coreui-time-picker [hourFormat]="hourFormat" formControlName="timePickerForDayOfMonth">
                  </coreui-time-picker>
                </div>
              </div>
              <div class="coreui-select-container">
                <div class="coreui-select-field">
                  <coreui-form-field>
                    <coreui-select formControlName="rankOfDayInMonth" placeholder="Select">
                      <coreui-option *ngFor="let schedulerRank of schedulerRanksMonthList"
                                     [value]="schedulerRank.value">
                        {{ schedulerRank.name }}
                      </coreui-option>
                    </coreui-select>
                  </coreui-form-field>
                </div>
                <div class="schedulerDaysInMonthFrequency">
                  <coreui-select formControlName="dayInWeekForMonth" class="days-select-container">
                    <coreui-option *ngFor="let schedulerDays of schedulerDaysList" [value]="schedulerDays.value">
                      {{ schedulerDays.name }}
                    </coreui-option>
                  </coreui-select>
                </div>
                <div class="at-info text-style-3">
                  at
                </div>
                <div class="timePickerForWeekForMonthFrequency">
                  <coreui-time-picker [hourFormat]="hourFormat" formControlName="timePickerForWeekForMonth">
                  </coreui-time-picker>
                </div>
              </div>
            </div>
    </div>
    <!--Commenting the below code under the direction PO's Will use in future-->
    <coreui-section-header title="Recurrence Time Range" class="recurrence-label">
    </coreui-section-header>
    <coreui-form-field>
      <div class="start-container">
        <label class="starting-label-mrgn">Starting</label>
        <coreui-date-time-picker [minDate]="minDate" [maxDate]="schedulerFormGroup.get('recurrenceEndDateTime')?.value" formControlName="recurrenceStartDateTime"></coreui-date-time-picker>
      </div>
      <coreui-form-error>Required</coreui-form-error>
    </coreui-form-field>
    <div class="ending-date-container">
      <div class="ending-right-side-container">
        <coreui-radio-group orientation="vertical">
          <label class="text-style-3">Ending</label>
          <div class="ending-sub-div">
            <coreui-form-field>
              <input type="radio" coreUIRadio value="END_DATE" name="recurrenceTimeEndingRadio" formControlName="recurrenceTimeEndingRadio" />
            </coreui-form-field>
            <coreui-form-field>
              <coreui-date-time-picker class="dateMargin" [minDate]="schedulerFormGroup.get('recurrenceStartDateTime')?.value" formControlName="recurrenceEndDateTime"></coreui-date-time-picker>
              <coreui-form-error>Required</coreui-form-error>
            </coreui-form-field>  
          </div>
          <div class="ending-sub-div1">
            <coreui-form-field label="After">
              <input type="radio" coreUIRadio value="RECURRENCE" name="recurrenceTimeEndingRadio" formControlName="recurrenceTimeEndingRadio" />
            </coreui-form-field>
            <coreui-form-field class="stepper">
                <input type="number" min=1 formControlName="recurrenceAfterOccurrences" coreUIInput />
            </coreui-form-field>
            <label class="occurrences-label text-style-3">occurrences</label>
        </div>
          <coreui-form-field label="No end date" class="noEndDate">
            <input type="radio" coreUIRadio value="NO_END_DATE" name="recurrenceTimeEndingRadio" formControlName="recurrenceTimeEndingRadio" />
          </coreui-form-field>
        </coreui-radio-group>
      </div>
    </div>

  </div>
  <div class="informationTxt text-style-3" *ngIf="dataType === 'createList'">Create new list with “the same name + version number”
    (List #1, List #2, List #3…. )</div>

<coreui-notification notificationStyle="calendarSync" [isPersistent]="true" [autoShow]="true" class="calendarSyncInfo" *ngIf="isFutureSchedule">
  <span *ngIf="dataType === 'export'; else createlist">A new audience will be exported to “destination/ template”
    every 4 hours, starting from <span>01/20/2022 12:00 AM</span> and ending on <span>02/20/2022 11:59:59 PM.</span></span>
    <ng-template #createlist>
      A new list will be created <b>every 4 hours</b>, starting from <b>01/20/2022 12:00 AM</b> and ending on <b>02/20/2022 11:59:59 PM.</b>
    </ng-template>
</coreui-notification>
</form>
