import { Action } from '@ngrx/store';
import { Tenant } from './tenant.model';

export class ResetTenant implements Action {
  static readonly type = 'TENANT:RESET_TENANT';
  readonly type = ResetTenant.type;
}

export class FetchTenant implements Action {
  static readonly type = 'TENANT:FETCH_TENANT';
  readonly type = FetchTenant.type;

  constructor(public tenantId: string) {}
}

export class LoadTenant implements Action {
  static readonly type = 'TENANT:LOAD_TENANT';
  readonly type = LoadTenant.type;

  constructor(public tenant: Tenant) {}
}

export class FetchDataHubTenant implements Action {
  static readonly type = 'TENANT:FETCH_DATAHUB_TENANT';
  readonly type = FetchDataHubTenant.type;

  constructor(public tenantId: string) {}
}

export class LoadDataHubTenantId implements Action {
  static readonly type = 'TENANT:LOAD_DATAHUB_TENANT_ID';
  readonly type = LoadDataHubTenantId.type;

  constructor(public dataHubTenantId: string) {}
}

export class UpdateTenantRedirectStatus implements Action {
  static readonly type = 'TENANT:UPDATE_TENANT_REDIRECT_STATUS';
  readonly type = UpdateTenantRedirectStatus.type;

  constructor(public redirectTenant: boolean) {}
}

export type ReducerActions =
  | ResetTenant
  | FetchTenant
  | FetchDataHubTenant
  | LoadTenant
  | LoadDataHubTenantId
  | UpdateTenantRedirectStatus;
