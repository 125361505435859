import { keyBy, omit, map as _map } from 'lodash-es';
import { AudienceDefinition, Audience } from './audience.models';
import * as actions from './audience.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export interface State {
  audienceDefinitions: { [id: string]: AudienceDefinition };
  audiences: { [id: string]: Audience };
  hasMoreAudiences: boolean;
  hasMoreAudienceDefinitions: boolean;
  audienceForCreateList: AudienceDefinition;
  initialStateAudience: boolean;
  initialStateAudienceDefinitions: boolean;
}

const defaultState = {
  audienceDefinitions: {},
  audiences: {},
  hasMoreAudiences: false,
  hasMoreAudienceDefinitions: false,
  audienceForCreateList: null,
  initialStateAudience: true,
  initialStateAudienceDefinitions: true
};

export function reducer(
  state: State = defaultState,
  action: actions.ReducerActions
): State {
  switch (action.type) {
    case actions.LoadAudienceDefinition.type:
      return {
        ...state,
        audienceDefinitions: {
          ...state.audienceDefinitions,
          [action.audienceDefinition?.id]: action.audienceDefinition,
        },
      };
    case actions.LoadAudienceDefinitions.type:
      return {
        ...state,
        audienceDefinitions: keyBy(action.audienceDefinitions, 'id'),
        initialStateAudienceDefinitions: false
      };
    case actions.RemoveAudienceDefinition.type:
      return {
        ...state,
        audienceDefinitions: omit(
          state.audienceDefinitions,
          action.audienceDefinition.id
        ),
      };
    case actions.RemoveAudienceDefinitions.type:
      const audienceDefinitions = omit(
        state.audienceDefinitions,
        _map(action.audienceDefinitions, 'id')
      );
      return { ...state, audienceDefinitions };
    case actions.LoadAudience.type:
      return {
        ...state,
        audiences: {
          ...state.audiences,
          [action.audience.id]: action.audience,
        },
      };
    case actions.LoadAudiencesSearch.type:
      return { ...state, audiences: keyBy(action.audiencesSearch.audiences, 'id'), hasMoreAudiences: action.audiencesSearch.hasMore, initialStateAudience: false };
    case actions.LoadAudienceDefinitionsSearch.type:
      return {
        ...state,
        audienceDefinitions: keyBy(action.audiencesSearch.audienceDefinitions, 'id'),
        hasMoreAudienceDefinitions: action.audiencesSearch.hasMore,
        initialStateAudienceDefinitions: false
      };
    case actions.LoadAudiences.type:
      return { ...state, audiences: keyBy(action.audiences, 'id'), initialStateAudience: false };
    case actions.RemoveAudience.type:
      return { ...state, audiences: omit(state.audiences, action.audience.id) };
    case actions.RemoveAudiences.type:
      const audiences = omit(state.audiences, _map(action.audiences, 'id'));
      return { ...state, audiences };
    case actions.LoadAudienceForCreateList.type:
      return { ...state, audienceForCreateList: action.audienceDefinition};
    case actions.ResetAudiences.type:
      return {
        ...state,
        audienceDefinitions: {},
        audiences: {},
        initialStateAudience: true,
      };
    case actions.ResetAudienceDefinitions.type:
      return {
        ...state,
        audienceDefinitions: {},
        audiences: {},
        initialStateAudienceDefinitions: true
      };
    default:
      return state;
  }
}

const cabFeatureSelector = createFeatureSelector<AppState>('cab');

export const audiencesFeatureSelector = createSelector(cabFeatureSelector, (state: AppState) => state.audiences);
export const selectAudienceDefinitions = createSelector(audiencesFeatureSelector, (state: State) => state.audienceDefinitions);
export const selectAudiences = createSelector(audiencesFeatureSelector, (state: State) => state.audiences);
export const selectAudienceForCreateList = createSelector(audiencesFeatureSelector, (state: State) => state.audienceForCreateList);
export const selectHasMoreAudiences = createSelector(audiencesFeatureSelector, (state: State) => state.hasMoreAudiences);
export const selectHasMoreAudienceDefinitions = createSelector(audiencesFeatureSelector, (state: State) => state.hasMoreAudienceDefinitions);
export const selectInitialStateAudience = createSelector(audiencesFeatureSelector, (state: State) => state.initialStateAudience);
export const selectInitialStateAudienceDefinitions = createSelector(audiencesFeatureSelector, (state: State) => state.initialStateAudienceDefinitions);
