import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  constructor(private location: Location, private router: Router) {}

  replaceUrl(param: string[], route: ActivatedRoute) {
    const urlTree = this.router.serializeUrl(
      this.router.createUrlTree(param, {
        relativeTo: route,
      })
    );
    this.location.replaceState(urlTree);
  }
}
