<ng-container *ngFor="
    let expressionGroup of nodes;
    index as idx;
    first as isFirst;
    last as isLast
  ">
  <div class="logical-operator before-group" *ngIf="firstLogicalOperator && depth > 1 && isFirst">
    <coreui-form-field [includeBottomMargin] = "false" class="splitter">
        <coreui-select type="secondary" formControlName="beforeGroupFirstLogicalOperator" [placeholder]="firstLogicalOperator | titlecase" [disabled]="true">
          <coreui-option [value]="1"> {{ firstLogicalOperator | titlecase }}</coreui-option>
        </coreui-select>
    </coreui-form-field>
  </div>

  <div class="expression-container" [ngClass]=" aggregationBlock ? 'aggregation-block' : ''"
    *ngIf="expressionGroup['expression']">
    <div *ngLet="{
        showPath: displayFullSegmentPath$ | async,
        expression: expressionGroup['expression']
      } as opts" class="expression-path-container" [class.showing-path]="opts.showPath">
      <div class="full-segment-path-container" *ngIf="opts.showPath && getPathFromExpression(opts.expression)">
        <span class="full-segment-path text-style-muted-3" *libCheckOverflow="let hasOverflow = hasXOverflow">
          <lib-type-icon [type]="expressionDataType(setSecondOperand(opts.expression))"></lib-type-icon>
          <ng-container *ngLet="getPathFromExpression(opts.expression) as path">
            <ng-template #segmentPathTooltip>
              {{ path }}
            </ng-template>
            <span *ngIf="hasOverflow" [coreUITooltip]="segmentPathTooltip">{{
              path
              }}</span>
            {{ path }}
          </ng-container>
        </span>
        <ng-container *ngIf="opts.showPath && comparedAttribute(opts.expression)">
          <span class="full-segment-path text-style-muted-3" *libCheckOverflow="let hasOverflow = hasXOverflow">
            <ng-container *ngLet="getPathFromExpression(opts.expression, true) as path">
              <ng-template #compSegmentPathTooltip>{{ path }}</ng-template>
              <span *ngIf="hasOverflow" [coreUITooltip]="compSegmentPathTooltip">{{ path }}</span>
              {{ path }}
            </ng-container>
          </span>
        </ng-container>
      </div>
      <div class="expression" [ngClass]="aggregationBlock ? 'aggregation-block-expression' : ''"
        [class.segment-path-visible]="opts.showPath  && getPathFromExpression(opts.expression)">
        <ng-container *ngTemplateOutlet="
            isCabAttribute(opts.expression)
              ? cabAttribute
              : attributeExpression;
            context: expressionGroup
          ">
        </ng-container>
        <ng-template #attributeExpression>
          <div class="text-style-3" [innerHTML]="expressionHelper(opts.expression) | safeHtml"></div>
        </ng-template>
        <ng-template #cabAttribute>
          <div class="cab-attribute">
            <span [innerHTML]="
                cabAttributeExpressionHelper(opts.expression) | safeHtml
              "></span>
            <div class="details">
              <lib-type-icon *ngIf="!getPathFromExpression(opts.expression)" [type]="expressionDataType(setSecondOperand(opts.expression))"></lib-type-icon>
              <div class="display-name">
                {{ getCabAttributeDisplayName(expressionGroup) }}
              </div>
            </div>
          </div>
        </ng-template>

        <i coreUIIcon="information" scale="1.5" [coreUITooltip]="cabAttributeTooltip(expressionGroup['expression'])"
          *ngIf="isCabAttribute(opts.expression)" class="cab-attribute-details"
          (click)="detailsModal.showModalUsingExpressionGroup(expressionGroup)"></i>
      </div>
    </div>

    <div class="logical-operator in-group" *ngIf="!isLast && !isNextNodeAGroup(idx)">
      <coreui-form-field [includeBottomMargin] = "false" class="splitter">
        <coreui-select type="secondary" formControlName="inGroupNextLogicalOperator" [placeholder]="nodes[idx + 1].logicalOperator | titlecase" [disabled]="true">
          <coreui-option [value]="1"> {{ nodes[idx + 1].logicalOperator | titlecase }}</coreui-option>
        </coreui-select>
      </coreui-form-field>
    </div>
  </div>

  <div class="expression-container"
    *ngIf="expressionGroup.aggregationConditions?.length > 0; else nestedExpressionGroup">

    <div class="logical-operator before-group" *ngIf="firstLogicalOperator && !isFirst">
      <coreui-form-field [includeBottomMargin] = "false" class="splitter">
        <coreui-select type="secondary" formControlName="beforeGroupFirstLogicalOperator" [placeholder]="firstLogicalOperator | titlecase" [disabled]="true">
          <coreui-option [value]="1"> {{ firstLogicalOperator | titlecase }}</coreui-option>
        </coreui-select>
      </coreui-form-field>
    </div>

    <lib-detail-node [cabAttributes]="cabAttributes" [dataTypeOperators]="dataTypeOperators"
      [nodes]="expressionGroup.aggregationConditions" [firstLogicalOperator]="expressionGroup.logicalOperator"
      [aggregationBlock]="true" [attributeDetails]="attributeDetails">
    </lib-detail-node>

    <div *ngIf="expressionGroup.group?.length > 0">
      <div class="for-whose">
        <h5><span>WHOSE</span></h5>
      </div>

      <lib-detail-node [cabAttributes]="cabAttributes" [dataTypeOperators]="dataTypeOperators"
        [nodes]="expressionGroup.group" [firstLogicalOperator]="expressionGroup.logicalOperator"
        [attributeDetails]="attributeDetails">
      </lib-detail-node>
    </div>

    <div class="logical-operator in-group" *ngIf="!isLast && !isNextNodeAGroup(idx)">
      <coreui-form-field [includeBottomMargin] = "false" class="splitter">
        <coreui-select type="secondary" formControlName="inGroupLastNextLogicalOperator" [placeholder]="nodes[idx + 1].logicalOperator | titlecase" [disabled]="true">
          <coreui-option [value]="1"> {{ nodes[idx + 1].logicalOperator | titlecase }}</coreui-option>
        </coreui-select>
      </coreui-form-field>
    </div>
  </div>

  <ng-template #nestedExpressionGroup>
    <div class="logical-operator after-group" *ngIf="!isFirst && depth === 0 && expressionGroup.group?.length > 0">
      <coreui-form-field [includeBottomMargin] = "false" class="splitter">
        <coreui-select type="secondary" formControlName="expressionGroupLogicalOperator" [placeholder]="expressionGroup.logicalOperator | titlecase" [disabled]="true">
          <coreui-option [value]="1"> {{ expressionGroup.logicalOperator | titlecase }}</coreui-option>
        </coreui-select>
      </coreui-form-field>
    </div>

    <lib-detail-node [cabAttributes]="cabAttributes" [dataTypeOperators]="dataTypeOperators" [depth]="depth + 1"
      [nodes]="expressionGroup.group" [firstLogicalOperator]="expressionGroup.logicalOperator"
      [attributeDetails]="attributeDetails">
    </lib-detail-node>
  </ng-template>
</ng-container>