import { map as _map } from 'lodash-es';

export class ScheduleDetail {
  scheduleId?: string;
  name?: string;
  displayName?: string;
  scheduleEntityId?: string;
  cabContextId?: string;
  dataUniverseId?: string;
  scheduleIntervalInfo?: {
    scheduleRecurrenceType: string;
    scheduleRecurrence: any;
    endType: string;
    startDate: Date;
    endDate: Date;
    noOfOccurrences: number;
    scheduleDescription: string;
  };
  startTime: string;
  scheduleType?: ScheduleType;
  scheduleParams?: {
    name: string;
    displayName: string;
    productExtractInfo?: {
      templateId?: string;
      taxonomyCode?: string;
      promoChannelCode?: string;
    };
  };
  scheduleState?: string;
  createdBy?: string;

  constructor(data?: any) {
    this.scheduleId = data?.scheduleId;
    this.name = data?.name;
    this.displayName = data?.displayName;
    this.scheduleEntityId = data?.scheduleEntityId;
    this.cabContextId = data?.cabContextId;
    this.dataUniverseId = data?.dataUniverseId;
    this.scheduleIntervalInfo = data?.scheduleIntervalInfo;
    this.startTime = data?.startTime;
    this.scheduleType = data?.scheduleType;
    this.scheduleParams = data?.scheduleParams;
    this.scheduleState = data?.scheduleState;
    this.createdBy = data?.createdBy;
  }
}

export enum ScheduleType {
  AUDIENCE_LIST = 'AUDIENCE_LIST',
  EXTRACT_FROM_DEFINITION = 'EXTRACT_FROM_DEFINITION'
}

export class ScheduleDetailSearch {
  hasMore?: boolean;
  scheduleDetails: ScheduleDetail[];
}

export function transformScheduleDetail(scheduleDetails): ScheduleDetail[] {
  return _map(
    scheduleDetails,
    (scheduleDetail) => new ScheduleDetail(scheduleDetail)
  );
}
