import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { values } from 'lodash-es';
import moment from 'moment';
import { combineLatest as observableCombineLatest, Observable, Subject } from 'rxjs';
import { auditTime, map, pairwise, startWith, tap } from 'rxjs/operators';
import { TableHeaderColumnComponent } from '@epsilon/core-ui';

import { SearchCampaignExtracts, DestroyCampaignExtracts } from '../../../campaign-extract/campaign-extract.actions';
import { AppState } from '../../../reducers';
import {
  selectCampaignExtracts,
  selectCampaignExtractsInitialState,
  selectHasMore
} from '../../../campaign-extract/campaign-extract.reducer';
import { selectContext } from '../../../context/context.reducer';
import { selectTenantContext } from '../../../tenant/tenant.reducer';
import { selectUser } from '../../../user/user.reducer';
import { CampaignExtract, CampaignExtractStatus } from '../../../campaign-extract/campaign-extract.model';
import { Context } from '../../../context/context.models';
import { User } from '../../../user/user.models';
import { TableFilterService } from '../table-filter.service';
import {
  BE_STATUS_COMPLETE, BE_STATUS_ERROR, BE_STATUS_MONITOR_PROCESSING, BE_STATUS_SUCCESS,
  MONITOR_TXT,
  UI_STATUS_COMPLETE, UI_STATUS_ERROR
} from '../../../utils/utils';
import { LoadingService } from '../../../services/loading.service';
import { checkForSendToTargetType, getConnectorRoute } from '../../../audience/audience.utils';
import { CampaignExtractService } from '../../../campaign-extract/campaign-extract.service';
import { SendToComponent } from '../send-to/send-to.component';
import { UtilsService } from '../../../utils/utilservice';
import { FeatureAccessService } from '../../../feature-access/feature.access.service';
import { Action } from '../../../feature-access/feature.access.action';

@UntilDestroy()
@Component({
  selector: 'lib-activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.sass'],
  providers: [DatePipe],
})

export class ActivityTableComponent implements OnInit, OnDestroy, AfterViewInit {
  enableUIADHActivityListing = false
  formGroup: UntypedFormGroup;
  userDetails: User;
  filteredCampaignExtracts: CampaignExtract[] = [];
  filteredCampaignExtracts$: Observable<CampaignExtract[]>;
  hasMore = true;
  modifiedDetails: string;
  sorted$ = new Subject<void>();
  contextId: string;
  absoluteUrl: string;
  scrollSortOption = 'displayName';
  scrollSortDirection: 'ASC' | 'DESC' = 'ASC';
  productType: string;
  tenantId = '';
  searchPayload: {
    dataUniverseId?: string;
    baseEntityTypes?: string[];
    hideSendToTargetTypes?: string[];
    sendToTargetType: string;
    displayName?: string;
    fromDateTime?: string;
    limit?: number;
    offset?: number;
    sort?: string;
    sortBy?: string[];
    statuses?: string[];
    toDateTime?: string;
  } = {
    limit: 25,
    offset: 0,
    sortBy: [this.scrollSortOption],
    sort: this.scrollSortDirection,
    hideSendToTargetTypes: ['AMS_FORECAST_REPORT'],
    sendToTargetType:"AMS"
  };
  campaignExtracts$ = this.store.select(selectCampaignExtracts).pipe(
    map(values),
    map((audiences) => audiences));
    campaignExtractsHasMore$ = this.store.select(selectHasMore).subscribe((value) => {
      this.hasMore = value;
    });

  subscriptions = [];
  isLoader = false;
  initialLoad = true;
  tableType = 'paid';
  connections: any = {};
  hasAccess = false;

  constructor(private store: Store<AppState>,
              private router: Router,
              private route: ActivatedRoute,
              private tableFilterService: TableFilterService,
              protected loadingService: LoadingService,
              public campaignExtractService: CampaignExtractService,
              private datePipe: DatePipe,
              private elementRef: ElementRef,
              private utilsService: UtilsService,
              private featureAccessService: FeatureAccessService) {

    this.filteredCampaignExtracts = null;
    this.formGroup = tableFilterService.formGroup;
    this.contextId = this.route.snapshot.paramMap.get('contextId');

    this.store
      .select(selectUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => (this.userDetails = user));
  }

  ngOnInit(): void {
    this.filteredCampaignExtracts = null;
    this.absoluteUrl = '/' + this.utilsService.getProductBaseUrl(this.router, this.route) + '/' + this.contextId + '/';
    this.store.select(selectTenantContext).pipe(untilDestroyed(this)).subscribe(contextId => {
      if(contextId && contextId !== this.contextId) {
        this.contextId = contextId;
      }
    });

    this.filteredCampaignExtracts$ = observableCombineLatest([
      this.campaignExtracts$
    ]).pipe(untilDestroyed(this), map(([audiences]: [CampaignExtract[]]) => {
      return audiences;
      }));

    this.isLoader = true;
    this.store.select(selectContext).pipe(untilDestroyed(this)).subscribe((context: Context) => {
      this.enableUIADHActivityListing = context?.enableUIADHActivityListing
      this.tenantId = context?.tenantId;
    });
    this.store.select(selectCampaignExtractsInitialState).pipe(untilDestroyed(this)).subscribe((initialLoad: boolean) => {
      this.initialLoad = initialLoad;
    });

    this.filteredCampaignExtracts$
      .pipe(
        tap(this.tableFilterService.resetFormRows.bind(this)),
        untilDestroyed(this)
      )
      .subscribe((audiences) => {
        // eslint-disable-next-line guard-for-in
         for (const audiencesList in audiences){
          audiences[audiencesList].createdDate = this.datePipe.transform(audiences[audiencesList]?.createdDate, 'M/d/YY h:mm a', 'UTC') + ' UTC';
          audiences[audiencesList].lastModifiedDate = this.datePipe.transform(audiences[audiencesList]?.lastModifiedDate, 'M/d/YY h:mm a', 'UTC') + ' UTC';
          this.filteredCampaignExtracts?.push(audiences[audiencesList]);
        }
        if(this.filteredCampaignExtracts ){
          this.isLoader = false;
        }
      });

    this.tableFilterService.statuses$.pipe(untilDestroyed(this)).subscribe((campaignExtractStatuses: CampaignExtractStatus[]) => {
      const campaignExtractStatusBE = [];
      campaignExtractStatuses.forEach(status => {
        if (status === UI_STATUS_COMPLETE) {
          campaignExtractStatusBE.push(BE_STATUS_COMPLETE);
          campaignExtractStatusBE.push(BE_STATUS_SUCCESS);
        } else if (status === UI_STATUS_ERROR) {
          campaignExtractStatusBE.push(BE_STATUS_ERROR);
        } else {
          campaignExtractStatusBE.push(BE_STATUS_MONITOR_PROCESSING);
        }
      });
      this.resetSearchPayload();
      this.searchPayload.statuses = campaignExtractStatusBE;
      if (!this.initialLoad) {
        this.isLoading();
      }
    });

    this.tableFilterService.jobTypes$.pipe(untilDestroyed(this)).subscribe((jobTypes: any[]) => {
      const jobTypesBE = [];
      jobTypes.forEach(jobType => {
        jobTypesBE.push(jobType.value);
      });
      this.resetSearchPayload();
      this.searchPayload.baseEntityTypes = jobTypesBE;
      if (!this.initialLoad) {
        this.isLoading();
      }
    });

    this.tableFilterService.sendToTargetType$.pipe(untilDestroyed(this)).subscribe((type: string) => {
      if(type === 'owned' && this.enableUIADHActivityListing ){
        return ;
      }
      let targetType = "AMS";
      if(type === 'owned') {
        targetType = "DATAHUB_FEED"
      }
      this.resetSearchPayload();
      this.searchPayload.sendToTargetType = targetType;
      if (!this.initialLoad) {
        this.isLoading();
      }
    });

    this.tableFilterService.dateTypes$.pipe(untilDestroyed(this)).subscribe((dateType: any) => {
      const dateObj = {
        today : 0,
        yesterday: 1,
        recent3Days : 2,
        recent7Days : 6,
        recent30Days : 29
      };
      if (dateType?.length > 0 && dateObj.hasOwnProperty(dateType)) {
        const filterDate: number = dateObj[dateType];
        const format = 'YYYY-MM-DDTHH:mm:ss';
        this.searchPayload.fromDateTime = moment.utc(moment().subtract(filterDate, 'days').hours(0).minutes(0).seconds(0).milliseconds(0)).format(format);
        if (dateType === 'yesterday'){
          this.searchPayload.toDateTime = moment.utc(moment().subtract(filterDate, 'days').hours(23).minutes(59).seconds(59).milliseconds(999)).format(format);
        }else{
          this.searchPayload.toDateTime = moment.utc(moment().hours(23).minutes(59).seconds(59).milliseconds(999)).format(format);
        }
      } else {
        delete this.searchPayload.fromDateTime;
        delete this.searchPayload.toDateTime;
      }
      this.resetSearchPayload();

      if (!this.initialLoad) {
        this.isLoading();
      }
    });

    this.formGroup.get('search').valueChanges.pipe(
      auditTime(250),
      startWith(''),
      pairwise(),
      untilDestroyed(this)).subscribe(([prevSearchText,searchText]) => {
        if(prevSearchText === searchText){
          return;
        }
        this.searchPayload.displayName = searchText;
        this.resetSearchPayload();
        if (!this.initialLoad) {
          this.isLoading();
        }
    });

    this.tableFilterService.sendToTargetType$.subscribe((tableType) => {
      this.tableType = tableType;
    });
  }

  ngAfterViewInit() {
    const sortComp: TableHeaderColumnComponent = new TableHeaderColumnComponent(this.elementRef);
    sortComp.sortDirection = 'DESC';
    this.sort(sortComp, 'createdDate');
  }

  public routeToEditHandler(job){
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      job.dataUniverseId,
      MONITOR_TXT,
      'view',
      job.id,
    ]);
  }

  ngOnDestroy() {
    this.store.dispatch(new DestroyCampaignExtracts());
  }

  resetSearchPayload(){
    this.searchPayload.limit = 25;
    this.searchPayload.offset = 0;
    this.filteredCampaignExtracts = [];
  }

  public sort(sortComponent: TableHeaderColumnComponent, sortOption) {
    this.scrollSortOption = sortOption;
    this.scrollSortDirection = sortComponent.sortDirection;
    this.searchPayload.sortBy = [sortOption];
    this.searchPayload.sort = this.scrollSortDirection;
    this.resetSearchPayload();
    this.isLoading();
  }

  public search() {
    this.hasAccess = this.featureAccessService.hasAccess(Action.ACTIVITY_VIEW);
    if(this.hasAccess){
      this.store.dispatch(new SearchCampaignExtracts(this.contextId, this.searchPayload));
    } else {
      this.isLoader = false
    }
  }

  public limitReached(){
    if(this.hasMore){
      this.searchPayload.offset +=  25;
      this.search();
    }
  }

  public isLoading(){
    this.isLoader = true;
    this.search();
    if(this.filteredCampaignExtracts.length ){
      this.isLoader = false;
    }
  }

  public checkForSendToTargetType(item) {
    return checkForSendToTargetType(item);
  }

  public getConnectorRoute(item){
    return getConnectorRoute(item, this.tenantId);
  }

  fetchConnectorLogos(connectionName: string): string | void {
    if (connectionName) {
      connectionName = connectionName?.replace(/\s/g, '').toLowerCase();
      for (const connector of SendToComponent.logos) {
        if (connectionName.includes(connector.name)) {
          return SendToComponent.partnerLogosPath + connector.logo;
        }
      }
      return SendToComponent.partnerLogosPath + SendToComponent.defaultLogo;
    }
  }

  onRowPlusClick(iActive, item) {
    const id = item?.sendToTargetInfo?.amsActivationId;
    if (id && !this.connections[id]?.data) {
      this.connections[id] = {
        isLoading: true,
        data: null
      }
      this.campaignExtractService
        .getAmsConnectors(
          item?.sendToTargetInfo?.amsActivationId
        )
        .subscribe((res) => {
          this.connections[id] = {
            isLoading: false,
            data: res.activationDestinations
          }
        });
    }
  }

  public sortAscDesc(option: string | string[]): string | undefined {
    return this.scrollSortOption === option || option.includes(this.scrollSortOption)
        ? this.scrollSortDirection === 'ASC'
            ? 'ascending'
            : 'descending'
        : undefined;
}
}
