<h1 class="header">Epsilon Peoplecloud</h1>
<div>
  <form class="Core-Form" [formGroup]="cabContexts">
    <coreui-form-field label="Select Product Type">
      <coreui-select formControlName="productType" placeholder="Select" (valueChange)="fetchContextsOfProductType($event)">
        <coreui-option *ngFor="let productType of productTypes" [value]="productType">
          {{productType}}
        </coreui-option>
      </coreui-select>
    </coreui-form-field>
  </form>
</div>
<div [formGroup]="tableFormGroup">
  <coreui-table class="tableScroll" #scrollTable coreUIInfiniteScroll [scrollTarget]="scrollTable.scrollContainer"
                [fixedHeader]="true">
    <thead coreUITableSort>
    <tr coreUITableHeaderRow>
      <th scope="col">
        <coreui-table-header-column [disableSort]="true">
          Name
        </coreui-table-header-column>
      </th>

      <th scope="col">
        <coreui-table-header-column [disableSort]="true">
          Tenant Id
        </coreui-table-header-column>
      </th>

      <th scope="col">
        <coreui-table-header-column [disableSort]="true">
          Product Type
        </coreui-table-header-column>
      </th>

      <th scope="col">
        <coreui-table-header-column [disableSort]="true">
          Datahub Tenant Code
        </coreui-table-header-column>
      </th>

      <th scope="col">
        <coreui-table-header-column [disableSort]="true">
        </coreui-table-header-column>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of productTypeContexts" coreUITableRow>
      <td class="selectedTenant">
        <a class="text-style-link-2" (click)="navigateTo(item)">{{ item.displayName }}</a>
      </td>
      <td>{{ item.tenantId ? item.tenantId : '—' }}</td>
      <td>{{ item.productType }}</td>
      <td>{{ item.datahubTenantCode ? item.datahubTenantCode : '—' }}</td>
      <td>
        <i coreUIIcon="information" class="info" (click)="showMetadata(item)"></i>
      </td>
    </tr>
    <tr coreUITableRow *ngIf="(loadingService.isLoading$ | async)">
      <td><coreui-loading-table-column></coreui-loading-table-column></td>
      <td><coreui-loading-table-column></coreui-loading-table-column></td>
      <td><coreui-loading-table-column></coreui-loading-table-column></td>
      <td><coreui-loading-table-column></coreui-loading-table-column></td>
      <td><coreui-loading-table-column></coreui-loading-table-column></td>
      <td><coreui-loading-table-column></coreui-loading-table-column></td>
      <td><coreui-loading-table-column></coreui-loading-table-column></td>
      <td><coreui-loading-table-column></coreui-loading-table-column></td>
    </tr>
    <tr *ngIf="((loadingService.isLoading$ | async) === null || (loadingService.isLoading$ | async) === undefined || (loadingService.isLoading$ | async) === false) && !productTypeContexts?.length" coreUITableRow [disableHover]="true">
      <td colspan="9" class="empty-list">
        <coreui-graphic-message label="No Results Found" graphic="noResults">
          Sorry, we did not find any results.
        </coreui-graphic-message>
      </td>
    </tr>
    </tbody>
  </coreui-table>
</div>

<coreui-modal modalWidth="40" modalHeight="40" #metadataModal>
  <ng-template #titleTemplate>
    {{tenantName}} Metadata
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="closeMetadataModal()" coreUIButton="icon-large"><i coreUIIcon="close"></i></button>
  </ng-template>
  <ng-template #contentTemplate>
    <coreui-table class="tableScroll" #scrollTable coreUIInfiniteScroll [scrollTarget]="scrollTable.scrollContainer" [fixedHeader]="true">
      <thead>
        <tr coreUITableHeaderRow>
          <th scope="col">
            <coreui-table-header-column [disableSort]="true">
              Name
            </coreui-table-header-column>
          </th>
          <th scope="col">
            <coreui-table-header-column [disableSort]="true">
              Value
            </coreui-table-header-column>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr coreUITableRow *ngFor="let md of tenantMetadata | keyvalue">
          <td>{{ md.key }}</td>
          <td>{{ md.value ? md.value : '—' }}</td>
        </tr>
      </tbody>
    </coreui-table>
  </ng-template>
</coreui-modal>
