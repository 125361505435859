import { Action } from '@ngrx/store';
import { DataUniverse } from './data-universe.models';

export class FetchDataUniverses implements Action {
  static readonly type = 'DATA_UNIVERSE:FETCH';
  readonly type = FetchDataUniverses.type;

  constructor(public contextId: string) {}
}

export class LoadDataUniverses implements Action {
  static readonly type = 'DATA_UNIVERSE:LOAD';
  readonly type = LoadDataUniverses.type;

  constructor(public dataUniverses: DataUniverse[]) {}
}

export class ResetDataUniverses implements Action {
  static readonly type = 'DATA_UNIVERSE:RESET';
  readonly type = ResetDataUniverses.type;
}

export class SetActiveDataUniverse implements Action {
  static readonly type = 'DATA_UNIVERSE:SET_ACTIVE';
  readonly type = SetActiveDataUniverse.type;

  constructor(public dataUniverse: DataUniverse) {}
}

export type ReducerActions =
  | FetchDataUniverses
  | LoadDataUniverses
  | ResetDataUniverses
  | SetActiveDataUniverse;
