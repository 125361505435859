import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DiscardTypes } from '../enums/discard-types';

@UntilDestroy()
@Component({
  selector: 'lib-discard-changes',
  templateUrl: './discard-changes.component.html',
  styleUrls: ['./discard-changes.component.sass'],
})
export class DiscardChangesComponent implements OnChanges {
  @ViewChild('definitionTabChangeModal') public definitionTabChangeModal;
  @Input() show: boolean;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onEditOrDiscardEvent = new EventEmitter<DiscardTypes>();

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.definitionTabChangeModal && event.key === 'Escape') {
      this.backToEditing();
    }
  }
  
  constructor() {
    // DiscardChangesComponent constructor
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.definitionTabChangeModal && changes['show'].previousValue !== changes['show'].currentValue) {
      if (changes['show'].currentValue) {
        this.definitionTabChangeModal.show();
      } else {
        this.definitionTabChangeModal.hide();
      }
    }
  }

  discardAndContinue() {
    this.onEditOrDiscardEvent.emit(DiscardTypes.DISCARD_AND_CONTINUE);
  }
  backToEditing() {
    this.onEditOrDiscardEvent.emit(DiscardTypes.BACK_TO_EDITING);
  }
}

