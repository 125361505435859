<coreui-modal #generateCriteriaModal [autoFocus]="true" panelCssClass="generateCriteriaModal" modalWidth="40" >
  <ng-template #titleTemplate> Summarize Your Audience Criteria </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
        (click)="closeModal()"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <div class="iconHeader">
      <lib-type-icon [type]=type></lib-type-icon>
      <span class="text-style-3">In your own words, tell us who you would like to {{type === 'Include' ? 'include in' : 'exclude from'}} your audience.</span>
    </div>
    <form class="Core-Form modalForm" [formGroup]="modalGenerateCriteriaForm">
      <coreui-form-field>
        <textarea rows="5" coreUIInput class="text-area" formControlName="textPrompt" [placeholder]="placeHolderText" maxlength="500"></textarea>
      </coreui-form-field>
    <lib-multi-select-all *ngIf="datasetCategoriesMultiSelectList?.length" [fieldLabel]="'Categories'" [items]="datasetCategoriesMultiSelectList" [defaultSelectItems]="modalGenerateCriteriaForm.get('datasetCategory').value" (selectedValues)="datasetCategorySelect($event)"></lib-multi-select-all>
    </form>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal()">
      Cancel
    </button>
    <button coreUIButton [class.buttonLeft]="generateAIQueryFromTextInProgress" [disabled]="!modalGenerateCriteriaForm.get('textPrompt').value || generateAIQueryFromTextInProgress" (click)="generateQueryFromText()">
      <coreui-load-mask *ngIf="generateAIQueryFromTextInProgress" class="inlineSpinner" [active]="generateAIQueryFromTextInProgress"></coreui-load-mask>
      {{generateAIQueryFromTextInProgress ? 'Generating Criteria' : 'Generate Criteria'}}
    </button>
  </ng-template>
</coreui-modal>

<coreui-notification notificationStyle="error" #errorGeneratingQueryFromText>
  <b>{{ errorMessage }}</b>
  <br/>
</coreui-notification>

<coreui-modal #confirmGeneratedCriteriaModal [autoFocus]="true" modalWidth="40" >
  <ng-template #titleTemplate> Confirm to use AI generated Audience Criteria </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeConfirmModal()"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <p>
      Are you sure you want to make this change?
    </p>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeConfirmModal()">
      Cancel
    </button>
    <button coreUIButton (click)="acceptAICriteria()">
      Continue
    </button>
  </ng-template>
</coreui-modal>
