import { throwError as observableThrowError } from 'rxjs';
import { logout } from './auth-utils';
import { LocalStorageService } from '../local-storage.service';

export function handleError(error: any) {
  const body = error || '';
  console.log(body);
  const err = body.error || JSON.stringify(body);
  const errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
  //if ((error.statusText || '').toLowerCase() === 'unauthorized') {
  if (error.status === 401) {
    console.log('logout user');
    logout(new LocalStorageService());
  }
  console.error(errMsg);
  return observableThrowError(errMsg);
}
