<div *ngIf="audienceDefinitionId">
  <div *ngIf="isAudienceWizardFlow">
    <lib-audience-definition-tab-flow #definitionTab [audienceForm]="audienceForm"  (audienceDefSaved)="save(false)" (discardChanges)="onDiscardChanges($event)"></lib-audience-definition-tab-flow>
  </div>
  <div *ngIf="!isAudienceWizardFlow">
    <lib-audience-builder></lib-audience-builder>
  </div>
</div>
<div *ngIf="!audienceDefinitionId">
  <div *ngIf="isAudienceWizardFlow">
    <lib-audience-builder-wizard [audienceForm]="audienceForm" (audienceDefSaved)="save(false)"></lib-audience-builder-wizard>
  </div>
  <div *ngIf="!isAudienceWizardFlow">
    <lib-audience-builder></lib-audience-builder>
  </div>
</div>
<coreui-notification notificationStyle="error" [isPersistent]="false" #errorNotification>
  {{ errorMessage }}
</coreui-notification>
<coreui-notification notificationStyle="success" [isPersistent]="false" #successNotification>
  Your changes have been saved.
</coreui-notification>
<lib-details-modal></lib-details-modal>
