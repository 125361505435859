import { RouterModule, Routes } from '@angular/router';
import { ContextsComponent } from './contexts/contexts.component';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { NotAuthorizeComponent } from './not-authorize/not-authorize.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PageNotFoundComponent } from '@epsilon-cdp/cab-lib';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'contexts',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'contexts',
    component: ContextsComponent,
  },
  {
    path: ':businessUnitId/cab-wrapper',
    data: { titlePrefix: 'Audiences'},
    loadChildren: () => import('./audiences/shell/audiences-shell.module').then(m => m.AudiencesShellModule)
  },
  {
    path: 'cab-wrapper',
    data: { titlePrefix: 'Audiences'},
    loadChildren: () => import('./audiences/shell/audiences-shell.module').then(m => m.AudiencesShellModule)
  },
  {
    path: 'access-denied',
    component: NotAuthorizeComponent
  },
  {
    path: 'error/:error',
    component: ErrorPageComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
