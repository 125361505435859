import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  storage: Storage;
  changes$ = new Subject();

  constructor() {
    this.storage = localStorage;
  }

  get(key: string): any {
    if (this.isLocalStorageSupported) {
      const value = this.storage.getItem(key);
      try {
        return JSON.parse(value);
      } catch (err) {
        console.log('token cannot be parsed');
      }
      return '';
    }
    return null;
  }

  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      this.storage.setItem(key, JSON.stringify(value));
      this.changes$.next({
        type: 'set',
        key,
        value,
      });
      return true;
    }
    return false;
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.storage.removeItem(key);
      this.changes$.next({
        type: 'remove',
        key,
      });
      return true;
    }
    return false;
  }

  get isLocalStorageSupported(): boolean {
    return !!this.storage;
  }
}
