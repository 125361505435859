import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CabHttpService, ContextType } from '../services/cab-http.service';
import { fetchResource } from '../utils/fetch-state';
import * as actions from './user.actions';
import { User } from './user.models';
import { handleError } from '../utils/errors';
import { CabConstants } from '../cab.constants';
import { UtilsService } from '../utils/utilservice';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  fetchUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FetchUserDetails.type),
      fetchResource(() =>
        this.fetchUserDetails().pipe(
          map((userDetails) => new actions.LoadUserDetails(userDetails))
        )
      )
    )
  );

  fetchUserPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FetchUserPermissions.type),
      fetchResource((action) =>
        this.fetchUserPermissions(action.contextId).pipe(
          map((userPermissions) => new actions.LoadUserPermissions(userPermissions))
        )
      )
    )
  );

  constructor(
    private http: HttpClient,
    private httpService: CabHttpService,
    private actions$: Actions,
    private utilsService: UtilsService
  ) {}

  fetchUserDetails(): Observable<User> {
    return this.http.get(this.userUrl()).pipe(map(res => res as User), catchError(handleError));
  }

  fetchUserPermissions(contextId: string): Observable<string[]> {

    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;

    const userPermissionsUrl = this.httpService.apiUrl(
      ContextType.CAB,
      '/cab/v1/user/user-permissions'
    );
    return this.http.get(userPermissionsUrl, { headers }).pipe(
      map((res: any) => {
        return res.dataset.allowed;
      }), catchError(handleError)
    );
  }

  private userUrl() {
    if (this.utilsService.isMocked()) {
      return this.httpService.apiUrl(
        ContextType.DISCOVERY,
        '/id_analytics_api/cab/v1/user/authenticated-user-details'
      );
    }
    return this.httpService.apiUrl(
      ContextType.CAB,
      '/cab/v1/user/authenticated-user-details'
    );
  }
}
