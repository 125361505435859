import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FetchTablesByDataUniverse, ResetDatsets } from '../../admin.actions';
import { AppState } from '../../../reducers';
import { DataSet, DataSetAttribute, FetchTablesBySchemaSearchPayload, Relations } from '../../admin.models';
import { AdminService } from '../../admin.service';

@UntilDestroy()
@Component({
  selector: 'lib-manage-joins-modal',
  templateUrl: './manage-joins-modal.component.html',
  styleUrls: ['./manage-joins-modal.component.sass']
})
export class ManageJoinsModalComponent implements AfterViewInit, OnInit {
  @ViewChild('manageJoins') public manageJoinsModal;
  @ViewChild('failedSavingJoins', { static: true }) public failedSavingJoins;
  @ViewChild('successSavingJoins', { static: true }) public successSavingJoins;
  @Input() dataset: DataSet = { id: '', name: '', displayName: '', cabContextId: '', dataUniverseId: '', productDataSetId: '', version: 0 };
  @Output() closeManageJoinsModal = new EventEmitter<string>();
  @Output() resetTableData = new EventEmitter<string>();
  targetDatasetAttributes: any[] = [];
  targetDatasetAttributesOriginal: any[] = [];
  sourceDatasetAttributes: DataSetAttribute[];
  sourceDatasetAttributesOriginal: DataSetAttribute[];
  addJoin: UntypedFormGroup;
  relations: Relations[] = [];
  dataSetArray: DataSet[] = [];
  fetchTablesBySchemaSearchPayload: FetchTablesBySchemaSearchPayload = {
    cabContextId: '',
    dataUniverseId: '',
    schema: '',
    ignoreExistingDataSets: false,
    limit: 200,
    offset: 0,
    displayName: ''
  };
  showSearchLimit = false;
  errorMessage = '';

  constructor(
    public adminService: AdminService,
    public formBuilder: UntypedFormBuilder,
    public store: Store<AppState>
  ) {
    this.addJoin = this.formBuilder.group({
      selectTableDisabled: new UntypedFormControl()
    });
  }

  ngOnInit() {
    const cabContextId = this.dataset.cabContextId;
    const dataUniverseId = this.dataset.dataUniverseId;
    const fetchTablesByDataUniversePayload = {
      cabContextId,
      dataUniverseId,
      displayName: '',
      limit: 200,
      offset: 0,
      sort: 'ASC',
      sortBy: ['displayName']
    };
    this.adminService.fetchTablesByDataUniverse(fetchTablesByDataUniversePayload).subscribe((result) => {
      this.dataSetArray = result.dataSetArray;
    });
    this.adminService.fetchDatasetAttributesByDatasetId(cabContextId, this.dataset.id).subscribe((result) => {
      this.sourceDatasetAttributes = result;
      this.sourceDatasetAttributesOriginal = result;
    });
    this.adminService.fetchRelations(cabContextId, this.dataset.dataUniverseId, this.dataset.id).subscribe((result: any) => {
      result.forEach((relation, idx) => {
        this.addJoin.addControl('joinTargetSelect'+idx, new UntypedFormControl(relation.targetDataSetId));
        relation.attributeRelationsDetails.forEach((attributeRelation, attributeRelationIdx) => {
          this.addJoin.addControl('attributeSourceJoinSelect'+relation.targetDataSetId+attributeRelationIdx+idx, new UntypedFormControl(attributeRelation.sourceAttributeId));
          this.addJoin.addControl('attributeTargetJoinSelect'+relation.targetDataSetId+attributeRelationIdx+idx, new UntypedFormControl(attributeRelation.targetAttributeId));
        });
        this.populateTargetDataset(idx, relation.targetDataSetId);
      });
      this.relations = result;
    });
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.addJoin.get('selectTableDisabled').setValue(this.dataset.displayName);
      this.addJoin.get('selectTableDisabled').disable();
      this.manageJoinsModal.show();
    });
  }

  populateTargetDataset(joinIdx, targetDatasetId) {
    this.adminService.fetchDatasetAttributesByDatasetId(this.dataset.cabContextId, targetDatasetId).subscribe((result) => {
      this.targetDatasetAttributes[joinIdx] = result;
      this.targetDatasetAttributesOriginal[joinIdx] = result;
    });
  }

  onJoinTrigger(joinIdx: number) {
    this.addJoin.addControl('joinTargetSelect'+joinIdx, new UntypedFormControl(''));
    this.relations[joinIdx] = {
      targetDataSetId: '',
      attributeRelationsDetails: []
    };
  }

  onAttributeJoinTrigger(joinIdx: number, attributeJoinIdx: any, targetDataSetId) {
    this.addJoin.addControl('attributeSourceJoinSelect'+targetDataSetId+attributeJoinIdx+joinIdx, new UntypedFormControl(''));
    this.addJoin.addControl('attributeTargetJoinSelect'+targetDataSetId+attributeJoinIdx+joinIdx, new UntypedFormControl(''));
    this.relations[joinIdx].attributeRelationsDetails[attributeJoinIdx] =  {
      sourceAttributeId: '',
      targetAttributeId: ''
    };
  }

  onTargetDatasetChange(dataSetId, joinIdx) {
    if (this.relations[joinIdx].targetDataSetId !== dataSetId) {
      const cabContextId = this.dataset.cabContextId;
      this.adminService.fetchDatasetAttributesByDatasetId(cabContextId, dataSetId).subscribe((result) => {
        const previousTargetDatasetId = this.relations[joinIdx].targetDataSetId;
        this.relations[joinIdx].targetDataSetId = dataSetId;
        this.relations[joinIdx].attributeRelationsDetails.forEach((attribute, idx) => this.removeJoinAttribute(joinIdx, idx, previousTargetDatasetId));
        this.relations[joinIdx].attributeRelationsDetails = [];
        this.targetDatasetAttributes[joinIdx] = result;
        this.targetDatasetAttributesOriginal[joinIdx] = result;
      });
    }
  }

  removeJoin(joinIdx: number, targetDataSetId: any) {
    const joinLength = this.relations.length;
    const joinAttributesLength = this.relations[joinIdx].attributeRelationsDetails.length;
    this.relations.splice(joinIdx, 1);
    for (let i=joinIdx; i<joinLength; i++) {
      this.addJoin.removeControl('joinTargetSelect'+joinIdx);
      if (i < this.relations.length) {
        this.addJoin.addControl('joinTargetSelect'+joinIdx, new UntypedFormControl(this.relations[joinIdx].targetDataSetId));
      }
    }
    for (let i=0; i<joinAttributesLength; i++) {
      this.addJoin.removeControl('attributeSourceJoinSelect'+targetDataSetId+i+joinIdx);
      this.addJoin.removeControl('attributeTargetJoinSelect'+targetDataSetId+i+joinIdx);
    }
  }

  sourceAttributeValueChange(id: any, joinIdx: number, attributeJoinIdx: number) {
    this.relations[joinIdx].attributeRelationsDetails[attributeJoinIdx].sourceAttributeId = id;
  }

  targetAttributeValueChange(id: any, joinIdx: number, attributeJoinIdx: number) {
    this.relations[joinIdx].attributeRelationsDetails[attributeJoinIdx].targetAttributeId = id;
  }

  removeJoinAttribute(joinIdx: number, attributeJoinIdx: any, targetDataSetId: any) {
    const attributeJoinLength = this.relations[joinIdx].attributeRelationsDetails.length;
    this.relations[joinIdx].attributeRelationsDetails.splice(attributeJoinIdx, 1);
    for (let i=attributeJoinIdx; i<attributeJoinLength; i++) {
      this.addJoin.removeControl('attributeSourceJoinSelect'+targetDataSetId+attributeJoinIdx+joinIdx);
      this.addJoin.removeControl('attributeTargetJoinSelect'+targetDataSetId+attributeJoinIdx+joinIdx);
      if (i < this.relations[joinIdx].attributeRelationsDetails.length) {
        this.addJoin.addControl('attributeSourceJoinSelect'+targetDataSetId+attributeJoinIdx+joinIdx, new UntypedFormControl(this.relations[joinIdx].attributeRelationsDetails[i].sourceAttributeId));
        this.addJoin.addControl('attributeTargetJoinSelect'+targetDataSetId+attributeJoinIdx+joinIdx, new UntypedFormControl(this.relations[joinIdx].attributeRelationsDetails[i].targetAttributeId));
      }
    }
  }

  searchTargetTables(searchText: string) {
    if (searchText.trim().length > 0 && searchText.trim().length < 3) {
      this.showSearchLimit = true;
      this.dataSetArray = [];
      return;
    }
    this.showSearchLimit = false;
    this.fetchTablesBySchemaSearchPayload.cabContextId = this.dataset.cabContextId;
    this.fetchTablesBySchemaSearchPayload.dataUniverseId = this.dataset.dataUniverseId;
    this.fetchTablesBySchemaSearchPayload.displayName = searchText;
    this.adminService.fetchTablesByDataUniverse(this.fetchTablesBySchemaSearchPayload).subscribe((result) => {
      this.dataSetArray = result.dataSetArray;
    });
  }

  searchTextSourceAttributes(searchText: string) {
    if (searchText.trim().length > 0 && searchText.trim().length < 3) {
      this.showSearchLimit = true;
      this.sourceDatasetAttributes = [];
      return;
    }
    this.showSearchLimit = false;
    this.sourceDatasetAttributes = this.sourceDatasetAttributesOriginal.filter(
      attribute => attribute.displayName.toLowerCase().includes(searchText.toLowerCase()));
  }

  searchTextTargetAttributes(searchText: string, joinIdx: number) {
    if (searchText.trim().length > 0 && searchText.trim().length < 3) {
      this.showSearchLimit = true;
      this.targetDatasetAttributes[joinIdx] = [];
      return;
    }
    this.showSearchLimit = false;
    this.targetDatasetAttributes[joinIdx] = this.targetDatasetAttributesOriginal[joinIdx].filter(
      attribute => attribute.displayName.toLowerCase().includes(searchText.toLowerCase()));
  }

  saveJoins() {
    const fetchTablesByDataUniversePayload = {
      cabContextId: this.dataset.cabContextId,
      dataUniverseId: this.dataset.dataUniverseId,
      displayName: '',
      limit: 200,
      offset: 0,
      sort: 'DESC',
      sortBy: ['lastModifiedDate']
    };
    this.relations.forEach((relation) => relation.attributeRelations = relation.attributeRelationsDetails);
    this.adminService.updateRelations(this.dataset.cabContextId, this.dataset.dataUniverseId, this.dataset.id, this.relations)
    .subscribe({
      next: ()=> {
        this.resetTableData.emit();
        this.store.dispatch(new ResetDatsets());
        this.store.dispatch(new FetchTablesByDataUniverse(fetchTablesByDataUniversePayload));
        this.closeModal('SUCCESS');
      },
      error: (errorResponse) => {
        if (errorResponse.error?.errorDetails) {
          this.errorMessage = errorResponse.error.errorDetails[0].errorMessage;
          this.failedSavingJoins.show();
        }
      }
    });
  }

  closeModal(manageJoinStatus?: string) {
    this.manageJoinsModal.hide();
    this.closeManageJoinsModal.emit(manageJoinStatus);
  }
}
