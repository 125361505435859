<coreui-modal #manageAttributes modalWidth="40">
  <ng-template #titleTemplate> Manage Attributes </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeModal()"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <form class="Core-Form" [formGroup]="addAttributesForm">
      <coreui-form-field class="selectDataUniverse" label="Select a DataUniverse">
        <coreui-select formControlName="selectDataUniverse" (valueChange)="onDataUniverseSelect($event)">
          <coreui-option *ngFor="let dataUniverse of dataUniverses" [value]="dataUniverse">
            {{dataUniverse.displayName}}
          </coreui-option>
        </coreui-select>
      </coreui-form-field>
      <coreui-form-field label="Select attributes to add  this enumeration">
        <span>
        <coreui-select formControlName="selectAttribute" placeholder="Select" [multiple]="true" [searchable]="true" (searchValueChange)="searchAttributes($event)">
            <ng-template #multipleSelectedTpl let-selectedOptions="selectedOptionsCount" let-value="value">
                <coreui-select-tag [value]="selectedOptions" [attr.aria-label]="selectedOptions + ' options selected'"></coreui-select-tag>
                <div>{{getSelectedDataSetAttributes}}</div>
            </ng-template>
          <coreui-option-group *ngFor="let dataset of dataSetArray; let i = index" (click)="toggleOn(i, dataset.id)" style="cursor: pointer">
            {{dataset.displayName}}
            <coreui-option *ngFor="let attributes of dataset.attributes" [hidden]="toggle[i] && associationsLoadCompleted" (click)="$event.stopPropagation()" [value]="dataset.id + ':' + attributes.id" (select)="attributeSelect(dataset, attributes);" (deselect)="attributeDeselect(dataset, attributes);">
              <lib-type-icon [type]="attributes?.dataType.toLowerCase()" [scale]=1.2 class="attributeIconType"></lib-type-icon>
              {{attributes.displayName}}
            </coreui-option>
          </coreui-option-group>
          <span *ngIf="showSearchLimit" style="color:red">Input at least 3 characters to search.</span>
          <span *ngIf="dataSetArray?.length === 0 && !showSearchLimit" class="no-results">No results found...</span>
        </coreui-select>
        <button coreUIButton="secondary" (click)="addAttribute()" class="addAttribute">Add</button>
        </span>
        <span class="u-fontWeightBold">Associated Attributes</span>
        <coreui-selected-items *ngIf="associationsLoadCompleted">
          <ng-template #header>
            <div class="Core-SelectedItems-info" style="padding-left: 0px">
              <span class="Core-SelectedItems-label">{{attributeItemsText}}</span>
              <span *ngIf="enumAttributeArrayMap?.size >= 2"> &#183;
                <a (click)="onRemoveAll()" class="Core-SelectedItems-removeAll">Remove all</a>
              </span>
            </div>
          </ng-template>
          <coreui-selected-item *ngFor="let item of getKeys" (removed)="onRemoveItem(item)">
            {{displayDataUniverses?.get(enumAttributeArrayMap?.get(item).dataUniverseId) +'/ '+enumAttributeArrayMap?.get(item).dataSetDisplayName+'/ '+enumAttributeArrayMap?.get(item).attributeDisplayName}}
          </coreui-selected-item>
        </coreui-selected-items>
      </coreui-form-field>
    </form>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal()">
      Cancel
    </button>
    <button coreUIButton (click)="saveAttributes()">
      Save
    </button>
  </ng-template>
</coreui-modal>
<coreui-notification notificationStyle="error" #failedSavingAssociatedAttributes>
  <b>{{ errorMessage }}</b>
  <br/>
</coreui-notification>
