import {
  Component,
  HostBinding,
  Input,
  Optional,
  SkipSelf,
} from '@angular/core';
import { capitalize, find, get, isEmpty } from 'lodash-es';

import {
  AttributeDetail,
  AttributeDetails,
  AudienceExpression,
  AudienceRuleGroup,
} from '../../../audience-builder/audience-builder.models';
import { DataTypeOperator } from '../../../models/data-type-operator';
import {
  expressionDataType,
  formatDate,
  isDateNumeric,
  isRelativeDate,
  displayRelativeDateUnits,
} from '../../../utils/utils';
import dayjs from 'dayjs';
import { DataType } from '../../../enums/data-types';
import * as audienceReducer from '../../../audience/audience.reducer';
import { DetailsModalService } from '../../../services/details-modal.service';
import {
  cabAttributeTooltip,
  getAttributeDetail,
  getCabAttributeDetail,
  getFullSegmentPath,
} from '../../../audience/audience.utils';
import { selectDisplayFullSegmentPath } from '../../../audience-builder/audience-builder.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';

@Component({
  selector: 'lib-detail-node',
  templateUrl: './detail-node.component.html',
  styleUrls: ['./detail-node.component.sass'],
})
export class DetailNodeComponent {
  @Input() cabAttributes: audienceReducer.State;
  @Input() nodes: AudienceRuleGroup[];
  @Input() dataTypeOperators: {dataType: string; operators: DataTypeOperator[]}[];
  @Input() depth = 0;
  @Input() firstLogicalOperator?: DataTypeOperator;
  @Input() attributeDetails: AttributeDetails;
  @Input() aggregationBlock = false;
  @HostBinding('class.nested-expression') nestedExpression = false;

  expressionDataType = expressionDataType;
  displayFullSegmentPath$ = this.store.select(selectDisplayFullSegmentPath);

  constructor(
    @Optional() @SkipSelf() public readonly parent: DetailNodeComponent,
    public detailsModal: DetailsModalService,
    private store: Store<AppState>
  ) {
    this.nestedExpression = !!parent?.parent;
  }

  isCabAttribute(expression): boolean {
    return !!expression.operand;
  }

  cabAttributeTooltip(expression): string {
    const dataType = expression?.operand?.valueType?.toLowerCase();
    return cabAttributeTooltip(dataType);
  }

  isNextNodeAGroup(index: number): boolean {
    return !!get(this.nodes, [index + 1, 'group'], false);
  }

  getDedupeType(expression: AudienceExpression): string | void {
    if (this.isCabAttribute(expression)) {
      const isAudience =
        get(expression, 'operand.valueType')?.toLowerCase() ===
        DataType.AUDIENCE_LIST;
      const key = isAudience ? 'audienceListId' : 'audienceDefinitionId';
      const id = get(expression, ['operand', key]);
      const cabAttributes = isAudience
        ? this.cabAttributes.audiences
        : this.cabAttributes.audienceDefinitions;
      return get(find(cabAttributes, { id }), 'dedupeIdentityType');
    }
  }

  expressionHelper(expression: AudienceExpression) {
    expression = this.setSecondOperand(expression);
    const { comparisonOperator, firstOperand, secondOperand } =
      expression || {};
    const { name } = firstOperand || {};
    const { dataType, values } = secondOperand || {};
    const comparedAttribute = this.comparedAttribute(expression);
    const dataTypeOperator = this.getDataTypeOperator(dataType);
    const operator = this.getOperator(dataTypeOperator, comparisonOperator);
    const aggregationOperator = this.getAggregationOperator(
      dataTypeOperator,
      firstOperand?.function
    );
    const relativeDate = isRelativeDate(expression) ? values : null;
    const result = [`${name}`, operator?.displayName];

    if (!isEmpty(relativeDate)) {
      const relativeDateValue = this.convertTypeDisplay(
        dataType,
        relativeDate[0],
        operator,
        expression
      );
      result.push(`${relativeDateValue}`);
      if (operator?.dualValue) {
        result.push(
          operator.comments,
          `${this.convertTypeDisplay(
            dataType,
            values[1],
            operator,
            expression
          )}`
        );
      }
      return result.join(' ');
    }

    if (aggregationOperator) {
      result.splice(0, 0, `${aggregationOperator.displayText}`);
    }

    if (comparedAttribute) {
      const endComments = `${comparedAttribute?.displayName}`;
      result.push(endComments);
      return result.join(' ');
    }

    if (operator?.multiValue) {
      const endComments = `${
        operator.comments && !operator?.dualValue ? ' ' + operator.comments : ''
      }`;
      result.push(
        `${this.convertTypeDisplay(
          dataType,
          values.join(', '),
          operator
        )}${endComments}`
      );
    }

    if (operator?.singleOperand && !operator?.multiValue) {
      const endComments = `${
        operator.comments && !operator?.dualValue ? ' ' + operator.comments : ''
      }`;
      result.push(
        `${this.convertTypeDisplay(
          dataType,
          values[0],
          operator
        )}${endComments}`
      );
    }
    if (operator?.dualValue) {
      result.push(
        operator.comments,
        `${this.convertTypeDisplay(
          dataType,
          values[1],
          operator
        )}`
      );
    }
    return result.join(' ');
  }

  cabAttributeExpressionHelper(expression: AudienceExpression) {
    const { comparisonOperator, operand } = expression || {};
    const { valueType } = operand || {};
    const dataTypeOperator = this.getDataTypeOperator(valueType);
    const operator = this.getOperator(dataTypeOperator, comparisonOperator);
    return operator?.displayName;
  }

  comparedAttribute(expression: AudienceExpression): AttributeDetail {
    return get(this.attributeDetails, [
      get(expression, 'secondOperand.comparedAttribute.cabId'),
    ]);
  }

  getCabAttributeDisplayName(expressionGroup): string {
    const id = get(expressionGroup, 'expression.operand.cabId');
    return get(this.attributeDetails, [id, 'displayName'], '');
  }

  private getDataTypeOperator(dataType: string) {
    return find(
      this.dataTypeOperators,
      (o) => o.dataType?.toLowerCase() === dataType?.toLowerCase()
    );
  }

  // dataTypeOperator.operators
  private getOperator(dataTypeOperator, comparisonOperator: string) {
    return find(
      dataTypeOperator?.operators,
      (o) => o.operator === comparisonOperator
    );
  }

  // dataTypeOperator.functions
  private getAggregationOperator(dataTypeOperator, comparisonFunction) {
    return find(
      dataTypeOperator?.functions,
      (f) => f.functionType?.toLowerCase() === comparisonFunction?.toLowerCase()
    );
  }

  private convertTypeDisplay(
    dataType: string,
    value: string,
    operator,
    expression?
  ): string {
    if (isRelativeDate(expression)) {
      const [relativeDateNumber, relativeDateOperator] = value?.split(':');
      const relativeDateNumberParsed = parseInt(relativeDateNumber, 10);
      const dateNum = parseInt(relativeDateNumber.replace('-', ''), 10);
      const relativeDateUnits = relativeDateNumber.includes('-')
        ? 'ago'
        : 'from now';
      const relativeDateValue =
        relativeDateNumberParsed === 0
          ? 'Today'
          : `${dateNum} ${capitalize(
              displayRelativeDateUnits(relativeDateOperator)
            )} ${relativeDateUnits}`;
      return relativeDateValue;
    } else if (
      dataType &&
      dataType.toLowerCase() === DataType.DATE &&
      !isDateNumeric(operator)
    ) {
      const date = new Date(formatDate(value, '/'));
      return dayjs(date).format('MMMM D, YYYY');
    }
    return value;
  }

  setSecondOperand(expression: AudienceExpression) {
    const attributeDetail = getAttributeDetail(
      this.attributeDetails,
      expression
    );
    if (attributeDetail) {
      expression.secondOperand = {
        ...expression.secondOperand,
        dataType: attributeDetail.dataType,
      };
    }
    return expression;
  }

  getPathFromExpression(expression, comparedAttribute = false) {
    const attribute = this.isCabAttribute(expression)
      ? getCabAttributeDetail(this.attributeDetails, expression)
      : comparedAttribute
      ? this.comparedAttribute(expression)
      : getAttributeDetail(this.attributeDetails, expression);
    return getFullSegmentPath(attribute);
  }
}
