export class Context {
  createdBy: string;
  createdDate: string;
  defaultDedupeBy: string;
  id: string;
  name: string;
  displayName: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  pluginApiUrl: string;
  productType: string;
  productUrl: string;
  tenantId: string;
  datahubTenantCode: string;
  version: number;
  exportAudience: boolean;
  latestMetadataSyncInfo?: LatestMetadataSyncInfo;
  enableADHListSendTo?: boolean;
  enableUIADHActivityListing?: boolean;
  enableFoundationRolePermission?: boolean;
  metadata?: Metadata;

  constructor(data) {
    this.createdBy = data.createdBy;
    this.createdDate = data.createdDate;
    this.defaultDedupeBy = data.defaultDedupeBy;
    this.id = data.id;
    this.name = data.name;
    this.displayName = data.displayName;
    this.lastModifiedBy = data.lastModifiedBy;
    this.lastModifiedDate = data.lastModifiedDate;
    this.pluginApiUrl = data.pluginApiUrl;
    this.productType = data.productType;
    this.productUrl = data.productUrl;
    this.tenantId = data.tenantId;
    this.version = data.version;
    this.latestMetadataSyncInfo = data.latestMetadataSyncInfo;
    this.exportAudience = data.metadata.exportAudience;
    this.enableADHListSendTo = data.metadata['ui.use.adh.for.list.send.to'];
    this.enableUIADHActivityListing = data.metadata['ui.use.tams.for.activity.listing'];
    this.enableFoundationRolePermission = data.metadata['foundations.permissions.check.enabled'];
    this.metadata = data.metadata;
    this.datahubTenantCode = data.datahubTenantCode;
  }
}

export class LatestMetadataSyncInfo {
  requestId?: string;
  status?: string;
  errorMessage?: string;
  requestedAt?: string;
  requestedBy?: string;
  completedAt?: string;
}

export class Metadata {
  amsCompanyId?: number;
  enablePermissionService: boolean;
}
