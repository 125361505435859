<coreui-form-field>
  <coreui-menu
    [formGroup]="attributeForm"
    #relativeDateMenu
    [autoHide]="false"
    menuButtonStyle="link"
    menuButtonCssClass="date-relative-button"
    icon="stepper"
    [buttonLabel]="label$ | async"
    [menuAlignment]="isMulti ? 'right' : 'left'"
    class="relative-menu"
  >
    <div class="relative-date-menu-panel-container">
      <coreui-radio-group orientation="vertical" >
        <div class="custom-relative-date-container">
          <div *ngIf="relativeDateRadioIs('custom')" class="text-style-3 label">Relative Date</div>
          <div class="relative-date-container">
            <coreui-form-field
              [label]="relativeDateRadioIs('custom') ? '' : 'Relative Date'"
              (click)="setRadio('custom')"
            >
              <input
                type="radio"
                [formControlName]="setFormFieldName('relativeDateRadio')"
                coreUIRadio
                value="custom"
              />
            </coreui-form-field>
            <div
              *ngIf="relativeDateRadioIs('custom')"
              class="relative-value-form"
            >
              <div class="relative-date-form-fields-container">
                <coreui-form-field class="relative-value-form-container shortValue">
                  <input
                    class="relative-value-field"
                    type="number"
                    min=0
                    [formControlName]="setFormFieldName('relativeDateNum')"
                    coreUIInput
                    coreUIIntegerMask
                  />
                </coreui-form-field>
                <coreui-form-field class="relative-value-form-container">
                  <coreui-select
                    class="relative-value-field"
                    [formControlName]="setFormFieldName('relativeDateUnits')"
                    [value]="relativeDateUnits.value"
                    #relativeDateUnits
                  >
                    <coreui-option
                      *ngFor="let timeIncrement of relativeValueTimeOptions"
                      [value]="timeIncrement"
                    >
                      {{ displayRelativeDateUnits(timeIncrement).toLowerCase()}}
                    </coreui-option></coreui-select
                  ></coreui-form-field
                >
                <coreui-form-field class="relative-value-form-container">
                  <coreui-select
                    class="relative-value-field"
                    [formControlName]="setFormFieldName('relativeDateOperator')"
                    [value]="relativeDateOperator.value"
                    #relativeDateOperator
                  >
                    <coreui-option
                      *ngFor="let timeIncrement of relativeValueOptions"
                      [value]="timeIncrement"
                    >
                      {{ timeIncrement }}
                    </coreui-option></coreui-select
                  >
                </coreui-form-field>
              </div>
              <div
                *ngIf="
                  hasCustomRelativeDateValue() && relativeDateRadioIs('custom')
                "
                class="relative-date-display text-style-muted-4"
              >
              {{ relativeDate$ | async }}
                <!--span class="relative-current-date"
                  >(relative to current date {{ today$ | async }})</span
                -->
              </div>
            </div>
          </div>
        </div>
        <div class="today-relative-date-container">
          <div class="today-relative-date-container-column">
            <coreui-form-field [label]="Today" (click)="setRadio('today')">
              <ng-template #Today>Today <span class="text-style-muted-4">{{ today$ | async }}</span></ng-template>
              <input
                type="radio"
                [formControlName]="setFormFieldName('relativeDateRadio')"
                coreUIRadio
                value="today"
                #radioTwo
              />
            </coreui-form-field>
          </div>
        </div>
      </coreui-radio-group>
      <div coreUIButtonGroup class="relative-date-buttons">
        <button coreUIButton="secondary" (click)="closeRelativeDateMenu()">
          Cancel
        </button>
        <button
          coreUIButton
          (click)="closeRelativeDateMenu('save')"
          [disabled]="
            !hasCustomRelativeDateValue() && relativeDateRadioIs('custom')
          "
        >
          Apply
        </button>
      </div>
    </div>
  </coreui-menu>
</coreui-form-field>
