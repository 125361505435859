import { DataType } from './../enums/data-types';

export const typeToCuikIconRef = new Map<string, any>([
  [DataType.STRING, { icon: 'string', tooltip: 'String', color:'Plum' }],
  [DataType.NUMBER, { icon: 'integer', tooltip: 'Number' , color: 'Pineapple' }],
  [DataType.BOOLEAN, { icon: 'boolean', tooltip: 'Boolean', color: 'Raspberry' }],
  [DataType.DATE, { icon: 'date', tooltip: 'Date', color:'Tangerine' }],
  [DataType.AUDIENCE_LIST, { icon: 'id', tooltip: 'Audience List', color: 'Watermelon' }],
  [DataType.INCLUDE_ICON, { icon: 'userProfile', customClass:'includeIconDefinition' }],
  [DataType.EXCLUDE_ICON, { icon: 'userProfile', customClass:'excludeIconDefinition' }],
  [
    DataType.AUDIENCE_DEFINITION,
    { icon: 'users', tooltip: 'Audience Definition', color:'Coconut' },
  ],
  ['default', { icon: 'xFourCornerBorder', tooltip: 'Not Found' }],
]);
