<coreui-standalone-accordion [label]="screen ? audienceDefinitionName : 'Details'" [active]="false">
  <div class="standalone">
    <div class="standalone__content">
      <div class="label text-style-4">ID:</div>
      <div class="value text-style-5">{{audienceDefinitionId}}</div>
    </div>
    <div class="standalone__content">
      <div class="label text-style-4">Created:</div>
      <div class="value text-style-5">{{createdDetails}}</div>
    </div>
    <div class="standalone__content">
      <div class="label text-style-4">Updated:</div>
      <div class="value text-style-5">{{modifiedDetails}}</div>
    </div>
    <div *ngIf="!isProductTypeDcdp" class="standalone__content">
      <div class="label text-style-4">Data Universe:</div>
      <div class="value text-style-5">{{dataUniverseName}}</div>
    </div>
    <div *ngIf="isProductTypeDcdp" class="standalone__content">
      <div class="label text-style-4">Channel:</div>
      <div class="value text-style-5">{{channelType | titlecase}}</div>
    </div>
    <div class="standalone__content">
      <div class="label text-style-4">{{isProductTypeDcdp ? 'Audience:' : 'Dedupe:'}}</div>
      <div class="value text-style-5">{{builderService.getAudienceDisplayName(identifier, channelType)}}</div>
    </div>
    <div class="standalone__content">
      <div class="label text-style-4">Count:</div>
      <div class="value text-style-5">{{count ? (count | number) : count ?? '—'}}</div>
    </div>
    <div *ngIf="dependentDefinitions && dependentDefinitions.length > 0" class="standalone__content">
      <div class="label text-style-4">Where this being used?</div>
      <div class="value text-style-5">
        <span class="def-desc" *ngFor="let dependentDefinition of dependentDefinitions;"><i coreUIIcon="users" class="icn-clr"></i>
          <a class="def-name" (click)="showDependentDefinitionClickModal(dependentDefinition)">{{dependentDefinition.displayName}}</a>
        </span>
      </div>
    </div>
  </div>
</coreui-standalone-accordion>
<coreui-modal modalWidth="60" #dependentDefinitionModal>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon"></span> You’re leaving the current page with unsaved changes!
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="dependentDefinitionModal.hide()" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <p>What would you like to do?</p>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="primary" (click)="discardAudienceDefinitionChangesAndRoute()">
      Discard Changes
    </button>
    <button coreUIButton="primary" (click)="saveCurrentDefinitionChanges()">
      Save Changes
    </button>
  </ng-template>
</coreui-modal>
