<div class="aggregate-container">
  <div class="aggregate-data-container">

    <ng-container *ngFor="let item of attribute.aggregationConditions; index as idx; trackBy:identity">
      <div class="drop-list Core-Drop-List">
        <div class="drop-list-actions">
          <lib-builder-attribute [dedupeType]="dedupeType" [hideTrashIcon]="attribute.aggregationConditions.length ===1" [fromAggregationGroup] = "true" [attributeIndex]="idx"  [attribute]="item" [group]="attribute.aggregationConditions" [isAggregator]="true"></lib-builder-attribute>
          <div *ngIf="idx ===0" class="actions" libDisableDrag>
            <coreui-menu menuButtonStyle="link" icon="more" verticalPosition="bottom" #actionsMenu>
              <ng-container>
                <coreui-menu-section>
                  <button type="button" coreUIMenuItem (click)="toggleAggregatorButtonClick()">
                    Add Another Aggregation
                  </button>
                  <button type="button" coreUIMenuItem (click)="toggleAggregatorFilters()">
                    {{enableAggregateFilter ? 'Remove Aggregation Filters': 'Add Aggregation Filters'}}
                  </button>
                  <coreui-menu-item-divider></coreui-menu-item-divider>
                  <button type="button" coreUIMenuItem (click)="removeAggregationGroup()">
                    Remove Whole Group
                  </button>
                </coreui-menu-section>
              </ng-container>
            </coreui-menu>
          </div>
          <div class="splitter-aggregate" *ngIf="attribute.aggregationConditions.length !== idx + 1">
            <coreui-menu
              menuAlignment="left"
              [buttonLabel]="getOperatorLabel(getLogicalOperator(idx)?.['logicalOperator']).toLowerCase()"
              menuButtonStyle="link"
              class="boolean-menu"
              verticalPosition="bottom"
              iconAlignment="right"
            >
              <coreui-menu-section>
                <button
                  type="button"
                  coreUIMenuItem
                  [class.boolean-selected]="isBooleanSelected(getLogicalOperator(idx)?.['logicalOperator'], operator) "
                  (click)="updateGroupLogicalOperator(operator, idx)"
                  *ngFor="let operator of booleanOperators"
                >
                  {{ getOperatorLabel(operator).toLowerCase() }}
                </button>
              </coreui-menu-section>
            </coreui-menu>
          </div>
        </div>
      </div>
      <div
        [class.hidden]="!aggregateDropBoxIndex.get(idx)" class ="for-cancel">
        <div coreUIDropList
             class="drop-list-aggregation drop-list-aggregation-condition text-align-center"
             [connectedTo]="dragDropService.dropLists$ | async"
             (dropped)="onDropped($event, idx, true)"
             (entered)="isDropAllowed($event)"
             [class.drop-disabled]="dropDisabled$ | async"
             [data]="attribute.aggregationConditions"
             (exited)="enableDrop()">
        </div>
        <button coreUIButton="text-primary" class="u-floatRight cancel-agg" (click)="removeAggregation()">Cancel</button>
      </div>
    </ng-container>
  </div>

  <div class="attribute-data-filter-container" *ngIf="enableAggregateFilter">
    <div class="for-whose">
      <span class="text-style-4">Whose</span>
    </div>
    <div class ="multi-splitter-Filter">
      <div
      class="drop-list-aggregation drop-list-aggregation-filter text-align-center nest-level-1 expression-group-idx-1"
      [class.remove-padding]="attribute.group.length > 0"
      coreUIDropList
      (dropped)="onFilterDropped($event)"
      (entered)="isFilterDropAllowed($event)"
      (exited)="enableDrop()"
      [data]="attribute.group"
      [connectedTo]="dragDropService.dropLists$ | async">
        <ng-container *ngFor="let item of attribute.group; index as idx; trackBy:identity">
          <div coreUIDrag class="drag-element" (started)="toggleDrag()" (ended)="toggleDrag()" *ngIf="item['expression']">
            <ng-template coreUIDragPreview [matchSize]="true" >
              <lib-builder-attribute [dedupeType]="dedupeType" [fromAggregationGroup] = "true" [attributeIndex]="idx" [attribute]="item" [group]="attribute.group" [isPreview]="true"></lib-builder-attribute>
            </ng-template>
            <lib-builder-attribute [dedupeType]="dedupeType" [fromAggregationGroup] = "true" [attributeIndex]="idx"  [attribute]="item" [group]="attribute.group"></lib-builder-attribute>
            <div class="splitter-aggregate" *ngIf="attribute.group[idx +1]?.['expression'] && attribute.group.length !== idx + 1 && (isDragging$ | async) === false">
              <coreui-menu
                menuAlignment="left"
                [buttonLabel]="getOperatorLabel(getFilterLogicalOperator(idx)?.['logicalOperator'])"
                menuButtonStyle="link"
                class="boolean-menu"
                verticalPosition="bottom"
                iconAlignment="right"
              >
                <coreui-menu-section>
                  <button
                    type="button"
                    coreUIMenuItem
                    [class.boolean-selected]="
                          isBooleanSelected(
                            getFilterLogicalOperator(idx),
                            operator
                          )
                        "
                    (click)="updateFilterLogicalOperator(operator, idx)"
                    *ngFor="let operator of booleanOperators"
                  >
                    {{ getOperatorLabel(operator).toLowerCase() }}
                  </button>
                </coreui-menu-section>
              </coreui-menu>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
