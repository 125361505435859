import { Action } from '@ngrx/store';
import {AudienceDefinition, Audience, AudienceSearchData, AudienceDefinitionSearchData} from './audience.models';

// AUDIENCE DEFINITIONS

export class DestroyAudienceDefinition implements Action {
  static readonly type = 'AUDIENCE:DESTROY_DEFINITION';
  readonly type = DestroyAudienceDefinition.type;

  constructor(public audienceDefinition: AudienceDefinition) {}
}

export class DestroyAudienceDefinitions implements Action {
  static readonly type = 'AUDIENCE:DESTROY_DEFINITIONS';
  readonly type = DestroyAudienceDefinitions.type;

  constructor(public audienceDefinitions: AudienceDefinition[]) {}
}

export class FetchAudienceDefinitions implements Action {
  static readonly type = 'AUDIENCE:FETCH_DEFINITIONS';
  readonly type = FetchAudienceDefinitions.type;

  constructor(public contextId: string) {}
}


export class SearchAudienceDefinition implements Action {
  static readonly type = 'AUDIENCE:SEARCH_AUDIENCE_DEFINITION';
  readonly type = SearchAudienceDefinition.type;

  constructor(public contextId: string, public searchPayload: any) {}
}

export class LoadAudienceForCreateList implements Action {
  static readonly type = 'AUDIENCE:LOAD_AUDIENCE_FOR_CREATE_LIST';
  readonly type = LoadAudienceForCreateList.type;

  constructor(public audienceDefinition: AudienceDefinition) {}
}

export class LoadAudienceDefinition implements Action {
  static readonly type = 'AUDIENCE:LOAD_DEFINITION';
  readonly type = LoadAudienceDefinition.type;

  constructor(public audienceDefinition: AudienceDefinition) {}
}

export class LoadAudienceDefinitions implements Action {
  static readonly type = 'AUDIENCE:LOAD_DEFINTIONS';
  readonly type = LoadAudienceDefinitions.type;

  constructor(public audienceDefinitions: AudienceDefinition[]) {}
}
export class LoadAudienceDefinitionsSearch implements Action {
  static readonly type = 'AUDIENCE:LOAD_AUDIENCES_DEFINITION_SEARCH';
  readonly type = LoadAudienceDefinitionsSearch.type;
  constructor(public audiencesSearch: AudienceDefinitionSearchData) {}
}


export class SaveAudienceDefinition implements Action {
  static readonly type = 'AUDIENCE:SAVE_DEFINITION';
  readonly type = SaveAudienceDefinition.type;

  constructor(public audienceDefinition: AudienceDefinition) {}
}

export class RemoveAudienceDefinition implements Action {
  static readonly type = 'AUDIENCE:REMOVE_DEFINITION';
  readonly type = RemoveAudienceDefinition.type;

  constructor(public audienceDefinition: AudienceDefinition) {}
}

export class RemoveAudienceDefinitions implements Action {
  static readonly type = 'AUDIENCE:REMOVE_DEFINITIONS';
  readonly type = RemoveAudienceDefinitions.type;

  constructor(public audienceDefinitions: AudienceDefinition[]) {}
}

export class ResetAudienceDefinitions implements Action {
  static readonly type = 'AUDIENCE:RESET_DEFINITIONS';
  readonly type = ResetAudienceDefinitions.type;

  constructor() {
    // ResetAudienceDefinitions constructor
  }
}

// AUDIENCES (AKA AUDIENCE LISTS)

export class ResetAudiences implements Action {
  static readonly type = 'AUDIENCE:RESET_AUDIENCES';
  readonly type = ResetAudiences.type;

  constructor() {
    // ResetAudiences constructor
  }
}

export class DestroyAudience implements Action {
  static readonly type = 'AUDIENCE:DESTROY_AUDIENCE';
  readonly type = DestroyAudience.type;

  constructor(public audience: Audience) {}
}

export class DestroyAudiences implements Action {
  static readonly type = 'AUDIENCE:DESTROY_AUDIENCES';
  readonly type = DestroyAudiences.type;

  constructor(public audiences: Audience[]) {}
}

export class FetchAudiences implements Action {
  static readonly type = 'AUDIENCE:FETCH_AUDIENCES';
  readonly type = FetchAudiences.type;

  constructor(public contextId: string) {}
}

export class SearchAudiences implements Action {
  static readonly type = 'AUDIENCE:SEARCH_AUDIENCES';
  readonly type = SearchAudiences.type;

  constructor(public contextId: string, public searchPayload: any) {}
}

export class LoadAudience implements Action {
  static readonly type = 'AUDIENCE:LOAD_AUDIENCE';
  readonly type = LoadAudience.type;

  constructor(public audience: Audience) {}
}

export class LoadAudiences implements Action {
  static readonly type = 'AUDIENCE:LOAD_AUDIENCES';
  readonly type = LoadAudiences.type;

  constructor(public audiences: Audience[]) {}
}

export class LoadAudiencesSearch implements Action {
  static readonly type = 'AUDIENCE:LOAD_AUDIENCES_SEARCH';
  readonly type = LoadAudiencesSearch.type;
  constructor(public audiencesSearch: AudienceSearchData) {}
}

export class RemoveAudience implements Action {
  static readonly type = 'AUDIENCE:REMOVE_AUDIENCE';
  readonly type = RemoveAudience.type;

  constructor(public audience: Audience) {}
}

export class RemoveAudiences implements Action {
  static readonly type = 'AUDIENCE:REMOVE_AUDIENCES';
  readonly type = RemoveAudiences.type;

  constructor(public audiences: Audience[]) {}
}

export class SaveAudience implements Action {
  static readonly type = 'AUDIENCE:SAVE_AUDIENCE';
  readonly type = SaveAudience.type;

  constructor(public audience: Audience) {}
}

export type ReducerActions =
  | // AUDIENCE DEFINITIONS
  DestroyAudienceDefinition
  | DestroyAudienceDefinitions
  | FetchAudienceDefinitions
  | SearchAudienceDefinition
  | LoadAudienceDefinition
  | LoadAudienceDefinitions
  | LoadAudienceDefinitionsSearch
  | SaveAudienceDefinition
  | RemoveAudienceDefinition
  | RemoveAudienceDefinitions
  | ResetAudienceDefinitions
  // AUDIENCES
  | DestroyAudience
  | DestroyAudiences
  | FetchAudiences
  | SearchAudiences
  | LoadAudience
  | LoadAudiences
  | LoadAudiencesSearch
  | RemoveAudience
  | RemoveAudiences
  | SaveAudience
  | LoadAudienceForCreateList
  | ResetAudiences;
