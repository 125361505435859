import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent, TableHeaderColumnComponent } from '@epsilon/core-ui';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { EnumData, EnumerationData, EnumSet } from '../../admin.models';
import { AdminService } from '../../admin.service';
import { UtilsService } from '../../../utils/utilservice';

@UntilDestroy()
@Component({
  selector: 'lib-enumeration-table',
  templateUrl: './enumeration-table.component.html',
  styleUrls: ['./enumeration-table.component.sass'],
})
export class EnumerationTableComponent implements OnInit {
  @Input() contextId: string;
  @ViewChild('removeEnumerationModal') public removeEnumerationModal;
  @ViewChild('errorRemoveEnumerationModal') public errorRemoveEnumerationModal;
  @ViewChild('successDeleteEnumeration', { static: true }) public successDeleteEnumeration;
  @ViewChild('errorDeleteEnumeration', { static: true }) public errorDeleteEnumeration;
  @ViewChild('successSavingAssociatedAttributes', { static: true }) public successSavingAssociatedAttributes;
  enumData: EnumData;
  public scrollItems: any[];
  public scrollSortOption = 'displayName';
  public scrollSortDirection = 'ASC';
  hasMore = false;
  updatedEnumDisplayName? = '';
  enumPayload: {
    cabContextId?: string;
    displayName?: string;
    limit: number;
    offset: number;
    sort?: string;
    sortBy?: string[];
  } = {
      displayName: '', limit: 25, offset: 0, sort: 'ASC', sortBy: ['displayName'],
    };

  actionInProgress$ = new BehaviorSubject<{
    type: string;
    enumSet: EnumSet;
  } | null>(null);
  dropdownListActions = [
    {
      display: 'Edit Enumeration',
      onClick: (enumSet) => {
        this.router.navigate([this.utilsService.getProductBaseUrl(this.router, this.route), this.contextId, 'admin', 'enumeration', `${enumSet.id}`, 'edit']);
      }
    },
    {
      display: 'Delete',
      onClick: (enumSet) => {
        this.actionInProgress$.next({
          type: 'Delete',
          enumSet,
        });
        this.getEnumerationAttributeDetails(enumSet);
      }
    },
    {
      display: 'Manage Attributes',
      onClick: (enumSet) => {
        this.actionInProgress$.next({
          type: 'manageAttributes',
          enumSet,
        });
      }
    },
  ];

  searchFormGroup = new UntypedFormGroup({
    search: new UntypedFormControl()
  });

  deleteEnumerationAttributeDetails: EnumerationData;
  isLoader = false;

  constructor(
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.enumPayload.cabContextId = this.contextId;
    this.searchEnumSet();
    this.searchFormGroup.get('search').valueChanges.subscribe(searchText => {
      this.resetEnumPayload();
      this.enumPayload.displayName = searchText;
      this.searchEnumSet();
    });
  }

  limitReached() {
    if (this.enumData.hasMore) {
      this.enumPayload.offset += 25;
      this.searchEnumSet();
    }
  }

  resetEnumPayload() {
    this.enumPayload.displayName = '';
    this.enumPayload.limit = 25;
    this.enumPayload.offset = 0;
    this.enumData.hasMore = false;
    this.enumData.enumSets = [];
  }

  searchEnumSet() {
    this.isLoader = true;
    this.adminService.fetchListOfEnums(this.contextId, this.enumPayload).subscribe((res: EnumData) => {
      this.enumData = res;
      this.isLoader = false;
    });
  }

  scrollSort(sortComponent: TableHeaderColumnComponent, sortOption) {
    this.scrollSortOption = sortOption;
    this.scrollSortDirection = sortComponent.sortDirection;
  }

  sortAscDesc(sortType) {
    return sortType === 'ASC' ? 'ascending' : 'descending';
  }

  sort(sortComponent: TableHeaderColumnComponent, sortOption) {
    this.resetEnumPayload();
    this.scrollSortOption = sortOption;
    this.scrollSortDirection = sortComponent.sortDirection;
    this.enumPayload.sort = this.scrollSortDirection;
    this.enumPayload.sortBy = [this.scrollSortOption];
    this.enumPayload.cabContextId = this.contextId;
    this.searchEnumSet();
  }

  routeToCreateEnum() {
    this.router.navigate([this.utilsService.getProductBaseUrl(this.router, this.route), this.contextId, 'admin', 'enumeration', 'create']);
  }

  closeManageAttributes(response?: any) {
    this.resetActionInProgress();
    if (response.status === 'SUCCESS') {
      this.updatedEnumDisplayName = response.displayName;
      this.successSavingAssociatedAttributes.show();
    }
  }

  resetActionInProgress() {
    this.actionInProgress$.next(null);
  }

  getEnumerationAttributeDetails(enumSet: EnumSet) {
    this.adminService.getEnumAttributeDetails(this.contextId, enumSet.id).subscribe({
      next: (res: EnumerationData) => {
        if (res.dataSetAssociations?.length > 0) {
          this.deleteEnumerationAttributeDetails = res;
          this.errorRemoveEnumerationModal.show();
        } else {
          this.removeEnumerationModal.show();
        }
      },
      error: () => {
        this.errorDeleteEnumeration.show();
      }
    });
  }

  closeDeleteEnumerationModal(modal: ModalComponent) {
    this.resetActionInProgress();
    modal.hide();
  }

  removeEnumeration(modal: ModalComponent) {
    const enumSet: EnumSet = this.actionInProgress$.getValue().enumSet;
    this.adminService.removeEntityEnum(this.contextId, enumSet.id).subscribe({
      next: (res) => {
        if (res.resultStatus === 'FAILURE') {
          this.errorDeleteEnumeration.show();
        } else {
          this.successDeleteEnumeration.show();
          this.searchEnumSet();
          this.resetActionInProgress();
          modal.hide();
        }
      },
      error: () => {
        this.errorDeleteEnumeration.show();
      }
    });
  }
}
