import { map as _map, orderBy } from 'lodash-es';
import {
  BE_STATUS_ERROR,
  completeStatuses,
  UI_STATUS_COMPLETE,
  UI_STATUS_ERROR,
  UI_STATUS_PROCESSING,
} from '../utils/utils';
import { Mapping } from '../audience-overview/overview-table/send-to/send-to.models';

export type CampaignExtractStatus =
  | typeof UI_STATUS_PROCESSING
  | typeof UI_STATUS_COMPLETE
  | typeof UI_STATUS_ERROR;

export class CampaignData{
  hasMore?: boolean;
  campaignExtracts: CampaignExtract[];
}

export class CampaignExtract {
  id?: string;
  displayName?: string;
  status?: string;
  count?: number;
  cabContextId: string;
  dataUniverseId: string;
  audienceListId?: string;
  audienceDefinitionId?: string;
  createdBy: string;
  createdDate?: string;
  lastModifiedBy: string;
  lastModifiedDate?: string;
  version?: number;
  sendToTargetType: string;
  runId?: string;
  productExtractInfo: {
    templateId?: string;
    taxonomyCode?: string;
    type?: string;
  };
  dataType?: string = 'CampaignExtract';
  description?: string;
  countStatus?: string;
  countErrorMessage?: string;
  sendToTargetInfo?: {
    mapping?: Mapping;
    useCaseId?: string;
    useCaseDisplayName?: string;
    targetConnector?: string;
    connectionId?: string;
    connectionDisplayName?: string;
  };
  queryCaseInsensitive?: boolean;

  constructor(data: any) {
    this.displayName = data.displayName;
    this.cabContextId = data.cabContextId || data.contextId;
    this.dataUniverseId = data.dataUniverseId;
    this.count = data.count;
    this.countStatus = data.count?.status;
    this.countErrorMessage = data.count?.errorMessage;
    this.createdBy = data.created_by || data.createdBy;
    this.lastModifiedBy = data.updated_by || data.lastModifiedBy;
    this.createdDate = data.createdDate;
    this.lastModifiedDate = data.lastModifiedDate;
    this.id = data.id;
    this.audienceListId = data.audienceListId;
    this.audienceDefinitionId = data.audienceDefinitionId;
    this.cabContextId = data.cabContextId || data.contextId;
    this.dataUniverseId = data.dataUniverseId;
    this.createdBy = data.createdBy;
    this.lastModifiedBy = data.lastModifiedBy;
    this.createdDate = data.createdDate;
    this.lastModifiedDate = data.lastModifiedDate;
    this.status = this.transformStatus(data.status || data.audienceListStatus);
    this.version = data.version || 0;
    this.runId = data.runId;
    this.productExtractInfo = data.productExtractInfo;
    this.description = data.description;
    this.sendToTargetInfo = data.sendToTargetInfo;
    this.sendToTargetType = data.sendToTargetType;
    this.queryCaseInsensitive = !data.query?.queryFlags?.caseInsensitive;
  }

  private transformStatus(status: string): CampaignExtractStatus {
    if (completeStatuses.includes(status)) {
      return UI_STATUS_COMPLETE;
    } else if (status === BE_STATUS_ERROR) {
      return UI_STATUS_ERROR;
    }
    return UI_STATUS_PROCESSING;
  }
}

export class CampaignTemplate {
  id?: string;
  displayName?: string;

  constructor(data: any) {
    this.id = data.id;
    this.displayName = data.displayName;
  }
}

export function transformCampaignExtracts(campaignExtracts): CampaignExtract[] {
  return _map(
      campaignExtracts,
      (campaignExtract) => new CampaignExtract(campaignExtract)
    );
}

export function transformCampaignTemplates(
  campaignTemplates
): CampaignTemplate[] {
  return orderBy(
    _map(
      campaignTemplates,
      (campaignTemplate) => new CampaignTemplate(campaignTemplate)
    ),
    'displayName'
  );
}

