export class Schema {
  displayName: string;
  id: string;

  constructor(data) {
    this.displayName = data.displayName;
    this.id = data.id;
  }
}

export class SchemaData {
  hasMore?: boolean;
  schemas?: Schema[];
}

export class DataSet{
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  id: string;
  name: string;
  displayName: string;
  dataUniverseId: string;
  productDataSetId: string;
  relations?: any[];
  attributes?: DataSetAttribute[];
  cabContextId: string;
  productDataSetInfo?: ProductDataSetInfo;
  version: number;

  constructor(data) {
    this.displayName = data.displayName;
    this.id = data.id;
    this.createdBy = data.createdBy;
    this.name = data.name;
    this.productDataSetId = data.productDataSetId;
    this.createdDate = data.createdDate;
    this.dataUniverseId = data.dataUniverseId;
    this.lastModifiedBy = data.lastModifiedBy;
    this.lastModifiedDate = data.lastModifiedDate;
    this.cabContextId = data.cabContextId;
    this.relations = data.relations;
    this.attributes = data.attributes? data.attributes : [];
    this.productDataSetInfo = data.productDataSetInfo;
    this.version = data.version;
  }
}

export class ProductDataSetInfo{
  type: string;
  schema: string;
  tableName: string;

}

export class DataSets{
  dataSetArray: DataSet[];
  offset?: number;
  totalCount?: number;
  hasMore: boolean;
}

export class DataSetAttribute{
  id: string;
  name: string;
  displayName: string;
  dataType: string;
  entityEnumId: string;
  productAttributeInfo: ProductAttributeInfo;
  hidden: boolean;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.displayName = data.displayName;
    this.dataType = data.dataType;
    this.entityEnumId = data.entityEnumId;
    this.productAttributeInfo = data.ProductAttributeInfo;
    this.hidden = data.hidden;
  }
}

export class ProductAttributeInfo{
  type: string;
  columnName: string;
}

export class DataSetAttributes{
  dataSetAttributeArray: DataSetAttribute[];
}

export class FetchTablesBySchemaSearchPayload {
  cabContextId: string;
  dataUniverseId: string;
  displayName?: string;
  ignoreExistingDataSets: boolean;
  limit: number;
  offset: number;
  schema: string;
}

export class FetchAttributesPayload {
  dataSetId?: string;
  productDataSetId: string;
  dataUniverseId: string;
  name: string;
  displayName: string;
}

export class DataSetResults {
  shouldCloseTheDrawer?: boolean;
  failedProductDataSetIds?: string[];
  successProductDataSetIds?: string[];
}

export class EnumSet {
  id: string;
  cabContextId?: string;
  name?: string;
  displayName?: string;
  dataType?: string;
  description?: string;
  values?: EnumSetValues[];
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  version?: number;

  constructor(data) {
    this.displayName = data.displayName;
    this.cabContextId = data.cabContextId;
    this.description = data.description;
    this.dataType = data.dataType;
    this.id = data.id;
    this.name = data.name;
    this.values = data.values;
    this.lastModifiedBy = data.lastModifiedBy;
    this.lastModifiedDate = data.lastModifiedDate;
    this.createdBy = data.createdBy;
    this.createdDate = data.createdDate;
  }
}

  export class EnumSetValues{
    id: string;
    displayName: string;

    constructor(data){
      this.displayName = data.displayName;
      this.id = data.id;
    }
  }

  export class EnumData {
    hasMore: boolean;
    totalCount: number;
    enumSets: EnumSet[];

    constructor(data){
      this.hasMore = data.hasMore;
      this.totalCount = data.totalCount;
      this.enumSets = data.enumSets;
    }
  }

export class Relations {
  attributeRelations?: any[];
  attributeRelationsDetails?: any[];
  targetDataSetDescription?: string;
  targetDataSetDisplayName?: string;
  targetDataSetId: string;

  constructor(data) {
    this.attributeRelations = data.attributeRelations;
    this.attributeRelationsDetails = data.attributeRelationsDetails;
    this.targetDataSetDescription = data.targetDataSetDescription;
    this.targetDataSetDisplayName = data.targetDataSetDisplayName;
    this.targetDataSetId = data.targetDataSetId;
  }
}

export class EnumerationData {
  cabContextId: string;
  dataSetAssociations?: EnumAssociatedAttributes[];
}

export class EnumAssociatedAttributes {
  dataSetId: string;
  attributeId: string;
  dataUniverseId: string;
  dataSetDisplayName: string;
  attributeDisplayName: string;

  constructor(data) {
    this.dataSetId = data.dataSetId;
    this.attributeId = data.attributeId;
    this.dataUniverseId = data.dataUniverseId;
    this.dataSetDisplayName = data.dataSetDisplayName;
    this.attributeDisplayName = data.attributeDisplayName;
  }
}
