import { keyBy, omit, map as _map } from 'lodash-es';
import { CampaignExtract} from './campaign-extract.model';
import * as actions from './campaign-extract.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export interface State {
  campaignExtracts: { [id: string]: CampaignExtract};
  hasMore: boolean;
  initialState: boolean;
}

const defaultState = {
  campaignExtracts: {},
  hasMore : false,
  initialState: true
};

export function reducer(
  state: State = defaultState,
  action: actions.ReducerActions
): State {
  switch (action.type) {
    case actions.LoadCampaignExtract.type:
      return {
        ...state,
        campaignExtracts: {
          ...state.campaignExtracts,
          [action.campaignExtract.id]: action.campaignExtract,
        },
        initialState: false
      };
    case actions.LoadCampaignExtracts.type:
      return {
        ...state,
        campaignExtracts: keyBy(action.campaignData.campaignExtracts, 'id'),
        hasMore: action.campaignData.hasMore,
        initialState: false
      };
    case actions.RemoveCampaignExtract.type:
      return {
        ...state,
        campaignExtracts: omit(
          state.campaignExtracts,
          action.campaignExtract.id
        ),
      };
    case actions.RemoveCampaignExtracts.type:
      const campaignExtracts = omit(
        state.campaignExtracts,
        _map(action.campaignExtracts, 'id')
      );
      return { ...state, campaignExtracts };
    case actions.DestroyCampaignExtracts.type:
      return { ...state, campaignExtracts: {}, hasMore: false, initialState: true };
    default:
      return state;
  }
}

const cabFeatureSelector = createFeatureSelector<AppState>('cab');
export const campaignExtractsFeatureSelector = createSelector(cabFeatureSelector, (state: AppState) => state.campaignExtracts);
export const selectCampaignExtracts = createSelector(campaignExtractsFeatureSelector, (state: State) => state.campaignExtracts);
export const selectHasMore = createSelector(campaignExtractsFeatureSelector, (state: State) => state.hasMore);
export const selectCampaignExtractsInitialState = createSelector(campaignExtractsFeatureSelector, (state: State) => state.initialState);
