import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { first, sortBy } from 'lodash-es';
import { CabHttpService, ContextType } from '../services/cab-http.service';
import { fetchResource } from '../utils/fetch-state';
import { DataUniverse, getAllDataUniverse } from './data-universe.models';
import * as actions from './data-universe.actions';
import { handleError } from '../utils/errors';
import { CabConstants } from '../cab.constants';
import { UtilsService } from '../utils/utilservice';

@Injectable({
  providedIn: 'root',
})
export class DataUniverseService {
  fetchDataUniverses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FetchDataUniverses.type),
      fetchResource((action) =>
        this.fetchUniverses(action.contextId).pipe(
          map((dataUniverses) => sortBy(dataUniverses, 'displayName')),
          mergeMap((dataUniverses) => [
            new actions.LoadDataUniverses([
              getAllDataUniverse(action.contextId),
              ...dataUniverses,
            ]),
            new actions.SetActiveDataUniverse(
              dataUniverses.length === 1
                ? first(dataUniverses)
                : getAllDataUniverse(action.contextId)
            ),
          ])
        )
      )
    )
  );

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private httpService: CabHttpService,
    private utilsService: UtilsService
  ) {}

  fetchUniverses(contextId: string): Observable<DataUniverse[]> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .get<DataUniverse[]>(
        `${this.getUniversesUrl()}?cabContextId=${contextId}`,
        { headers }
      )
      .pipe(
        map((data) => data['results'].map((data) => new DataUniverse(data))),
        catchError(handleError)
      );
  }

  getUniversesUrl(): string {
    return `${this.getBaseUrl()}/data-universe`;
  }

  private getBaseUrl(): string {
    if (this.utilsService.isMocked()) {
      return this.httpService.apiUrl(
        ContextType.DISCOVERY,
        '/id_analytics_api/cab/v1'
      );
    }
    return this.httpService.apiUrl(ContextType.CAB, '/cab/v1');
  }
}
