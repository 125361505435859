import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CabConfig, CAB_CONFIG } from './cab.config';

@Injectable({
  providedIn: 'root',
})
export class CabService {
  cdpContext: any | null = null;

  constructor(private router: Router, @Inject(CAB_CONFIG) cabConfig: CabConfig) {
    cabConfig.cdpContext$?.subscribe( (ctx: any) => {
      console.log('Context change detected in CAB', ctx, router.url);
      this.cdpContext = ctx;
    });
  }
}
