import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor() {
    // FeatureFlagService constructor
  }
  isFeatureEnabled(flagName = ''): Observable<boolean> {
    console.log(flagName);
    return of(true);
  }
}
