import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ErrorStateType } from "@epsilon/core-ui";

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  header = "";
  content = "";
  icon: ErrorStateType = "somethingWentWrong";
  subhead = "";
  text = "";
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.setErrorBasedOnResponse(this.route.snapshot.params['error']);
  }

  setErrorBasedOnResponse(error: string) {
    switch (error) {
      case '401':
        this.header = 'Blocked';
        this.subhead = 'Access Denied';
        this.content = `You don't have permissions to view this page.`;
        this.icon = 'accessDenied';
        break;
      case 'under-construction':
        this.header = 'Apologies';
        this.subhead = 'Under Construction';
        this.content = `We are currently working to improve the service. Please try again later.`;
        this.icon = 'underConstruction';
        break;
      case 'no-tenants':
        this.header = 'Apologies';
        this.subhead = 'No Tenants Found';
        this.content = 'There are currently no tenants assigned to the application. Please reach out the administrator.';
        this.icon = 'somethingWentWrong';
        break;
      case '404':
        this.header = '404';
        this.subhead = 'Page Not Found';
        this.content = 'Please check the URL.';
        this.icon = 'somethingMissing';
        break;
      default:
        this.header = 'Uh-Oh';
        this.subhead = 'Something Went Wrong';
        this.content = 'The page you requested was not found.';
        this.icon = 'somethingWentWrong';
        break;
    }
  }

}
