import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FetchTablesByDataUniverse, FetchTablesBySchema, GetSchemas, ResetDatsets } from '../../admin.actions';
import { AppState } from '../../../reducers';
import { selectSchemas, selectTablesBySchema } from '../../admin.reducer';
import { DataSet, DataSetAttribute, DataSets, FetchTablesBySchemaSearchPayload, Schema, SchemaData, DataSetResults } from '../../admin.models';
import { AdminService } from '../../admin.service';

@UntilDestroy()
@Component({
  selector: 'lib-add-table-modal',
  templateUrl: './add-table.component.html',
  styleUrls: ['./add-table.component.sass'],
})
export class AddTableComponent implements OnInit, OnDestroy{
  schemas: Schema[];
  activeSchema: Schema;
  addResults: DataSetResults = {};
  @Input() contextId: string;
  @Input() dataUniverseId: string;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCloseDrawer: EventEmitter<DataSetResults> = new EventEmitter<DataSetResults>();
  dataAttributes: DataSetAttribute[];
  selectedProductDataSetIdSet = new Map();
  fetchTablesBySchemaSearchPayload: FetchTablesBySchemaSearchPayload = {
    cabContextId: '',
    dataUniverseId: '',
    schema: '',
    ignoreExistingDataSets: false,
    limit: 200,
    offset: 0,
    displayName: ''
  };

  public tableGroup = new UntypedFormGroup({
    fetchSchemas: new UntypedFormControl(),
    searchTableName: new UntypedFormControl()
  });
  showSearchLimit = false;
  currentOffset = 0;
  numOfDataSetsAllowedPerReq = 11;
  dataSetArray: DataSet[] = [];
  hasMoreRawDataSets = true;
  productDataSetIdArray: string[];
  failedDataSets: string[] = [];

  constructor(
    public store: Store<AppState>,
    public adminService: AdminService,
    public route: ActivatedRoute
  ) {}

  ngOnDestroy() {
    this.activeSchema = null;
  }

  ngOnInit() {
    this.store.dispatch(new ResetDatsets());
    this.store.dispatch(new GetSchemas(this.contextId, this.dataUniverseId));
    this.store.select(selectSchemas).subscribe((schemaData: SchemaData) => {
      this.schemas = schemaData.schemas ? schemaData.schemas : [];
      this.activeSchema = this.schemas.length > 0 ? this.schemas[0] : null;
      if (this.activeSchema) {
        this.fetchTablesBySchemaSearchPayload.cabContextId = this.contextId;
        this.fetchTablesBySchemaSearchPayload.dataUniverseId = this.dataUniverseId;
        this.fetchTablesBySchemaSearchPayload.schema = this.activeSchema.id;
        this.store.dispatch(new FetchTablesBySchema(this.fetchTablesBySchemaSearchPayload));
        this.tableGroup.get('fetchSchemas').setValue(this.activeSchema);
      }
    });
    this.store.select(selectTablesBySchema).subscribe((dataSetsBySchema: DataSets) => {
      this.dataSetArray = dataSetsBySchema.dataSetArray;
      this.hasMoreRawDataSets = dataSetsBySchema.hasMore;
    });
  }

  onHideTableHanlder($event){
    this.resetSearchPayload();
    this.fetchTablesBySchemaSearchPayload.schema = this.activeSchema.id;
    this.fetchTablesBySchemaSearchPayload.ignoreExistingDataSets = $event.target.checked;
    this.store.dispatch(new FetchTablesBySchema(this.fetchTablesBySchemaSearchPayload));
  }

  public onSchemaSelect(schema: Schema) {
    if(schema && schema.id !== this.activeSchema.id) {
      this.resetSearchPayload();
      this.selectedProductDataSetIdSet = new Map();
      this.fetchTablesBySchemaSearchPayload.schema = schema.id;
      this.activeSchema = schema;
      this.store.dispatch(new FetchTablesBySchema(this.fetchTablesBySchemaSearchPayload));
    }
  }

  public displayWith(item: DataSet): string {
    return item.displayName;
  }

  public onSearchChange(searchText: string) {
    if (searchText.trim().length !== 0 && searchText.trim().length < 3) {
      this.showSearchLimit = true;
      this.fetchTablesBySchemaSearchPayload.displayName = null;
    } else {
      this.showSearchLimit = false;
      this.fetchTablesBySchemaSearchPayload.displayName = searchText;
    }
    this.showSearchLimit = false;
    if (this.fetchTablesBySchemaSearchPayload.displayName !== null) {
      this.store.dispatch(new FetchTablesBySchema(this.fetchTablesBySchemaSearchPayload));
    }
  }

  public showOrHideAttributes(event: any) {
    if (event.pane.active && this.dataSetArray[event.index].attributes?.length === 0) {
      const fetchAttributesPayload = {
        dataSetId: '',
        productDataSetId: this.dataSetArray[event.index].productDataSetId,
        dataUniverseId: this.dataUniverseId,
        name: this.dataSetArray[event.index].name,
        displayName: this.dataSetArray[event.index].displayName
      };
      this.adminService.fetchTableAttributes(this.contextId, fetchAttributesPayload).subscribe((result) => {
        this.dataSetArray[event.index].attributes = result;
      });
    }
  }

  public isNextDataSetLinkActive() {
    return this.hasMoreRawDataSets;
  }

  public isPreviousDataSetLinkActive() {
    return this.fetchTablesBySchemaSearchPayload.offset !== 0;
  }

  public nextDataSetBySchema() {
    this.fetchTablesBySchemaSearchPayload.offset = this.fetchTablesBySchemaSearchPayload.offset + 200;
    this.store.dispatch(new FetchTablesBySchema(this.fetchTablesBySchemaSearchPayload));
  }

  public previousDataSetBySchema() {
    this.fetchTablesBySchemaSearchPayload.offset = this.fetchTablesBySchemaSearchPayload.offset - 200;
    this.store.dispatch(new FetchTablesBySchema(this.fetchTablesBySchemaSearchPayload));
  }

  public addDataSetToDataUniverse(closePane = true) {
    this.adminService.addDataSetToDataUniverse(this.contextId, this.dataUniverseId, Array.from(this.selectedProductDataSetIdSet.keys())).subscribe(res => {
      this.resetSearchPayload();
      const fetchTablesByDataUniversePayload = {
        cabContextId: this.contextId,
        dataUniverseId: this.dataUniverseId,
        displayName: '',
        limit: 25,
        offset: 0,
        sort: 'DESC',
        sortBy: ['lastModifiedDate']
      };
      this.addResults = res.entity;
      this.failedDataSets.length = 0;
      res.entity.failedProductDataSetIds.map(id => {
          this.failedDataSets.push(this.selectedProductDataSetIdSet.get(id));
      });

      this.store.dispatch(new FetchTablesByDataUniverse(fetchTablesByDataUniversePayload));
      if(!closePane) {
        this.addResults.shouldCloseTheDrawer = false;
        this.selectedProductDataSetIdSet = new Map();
        this.store.dispatch(new FetchTablesBySchema(this.fetchTablesBySchemaSearchPayload));
      } else {
        this.addResults.shouldCloseTheDrawer = true;
      }
      this.onCloseDrawer.emit(this.addResults);
    });
  }

  private resetSearchPayload() {
    this.fetchTablesBySchemaSearchPayload = {
      cabContextId: this.contextId,
      dataUniverseId: this.dataUniverseId,
      schema: this.activeSchema.id,
      ignoreExistingDataSets: false,
      limit: 200,
      offset: 0,
      displayName: ''
    };
    this.currentOffset = 0;
    this.hasMoreRawDataSets = true;
    this.failedDataSets = [];
  }

  public onSelectionOfDataSet(event, dataSet){
    if(event.target.checked){
      this.selectedProductDataSetIdSet.set(dataSet.productDataSetId, dataSet.displayName);
    } else {
      this.selectedProductDataSetIdSet.delete(dataSet.productDataSetId);
    }
  }

  public disableCheckBox(dataSet): boolean {
      return dataSet.id ? true: false;
  }
}
