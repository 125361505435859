<coreui-modal #exportActionModal>
  <ng-template #titleTemplate>
    <h1 *ngIf="!scheduleDetails">Export Audience {{ displayType | titlecase }}</h1>
    <h1 *ngIf="scheduleDetails">Edit Schedule</h1>
  </ng-template>

  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="closeModal()" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>

  <ng-template #contentTemplate>
    <ng-container>
      <form class="Core-Form modalForm" [formGroup]="modalExportForm">
        <coreui-form-field [label]='label'>
          <b> {{audience?.displayName}}</b>
        </coreui-form-field>
        <coreui-form-field label="Name This Export">
          <input  [readonly]="scheduleDetails" [ngClass] = "scheduleDetails ? 'disabled' : ''" coreUIInput formControlName="exportName" />
          <coreui-form-error>{{
            nameFieldErrorMessage("exportName")
          }}</coreui-form-error>
        </coreui-form-field>
        <coreui-form-field label="Select Destination/Template">
          <coreui-select
            formControlName="destinationTemplate"
            placeholder="Select an option"
            [searchable]="true"
            (searchValueChange)="searchTemplates($event)"
          >
            <coreui-option [disabled] = "scheduleDetails !== undefined"
              *ngFor="let destinationTemplate of destinationsTemplates"
              [value]="destinationTemplate"
            >
              {{ destinationTemplate.displayName }}
            </coreui-option>
            <span *ngIf="showSearchLimit" style="color:red">Input at least 3 characters to search.</span>
            <span *ngIf="destinationsTemplates?.length === 0 && !showSearchLimit" class="no-results">No results found...</span>
          </coreui-select>
          <coreui-form-error
            *ngIf="
              modalExportForm.get('destinationTemplate').hasError('required')
            "
            >Required
          </coreui-form-error>
        </coreui-form-field>
        <coreui-form-field label="Marketing Code">
          <input  [readonly]="scheduleDetails" [ngClass] = "scheduleDetails ? 'disabled' : ''" coreUIInput formControlName="marketingCode" />
          <coreui-form-error>{{
            nameFieldErrorMessage("marketingCode")
          }}</coreui-form-error>
        </coreui-form-field>
        <lib-schedule-audience-modal #libScheduleAudienceModal *ngIf="schedulerEnabled && !audience.status" dataType="export" [scheduleDetails]="scheduleDetails" [isEditScheduler]="scheduleDetails ? true : false"></lib-schedule-audience-modal>
      </form>
    </ng-container>
  </ng-template>

  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal()">Cancel</button>
    <button coreUIButton (click)="save()" [disabled]="!modalExportForm.valid">
      {{saveButtonName}}
    </button>
  </ng-template>
</coreui-modal>
