import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'lib-details-toggle-dropdown',
  templateUrl: './details-toggle-dropdown.component.html',
  styleUrls: ['./details-toggle-dropdown.component.sass'],
})
export class DetailsToggleDropdownComponent implements OnInit {
    @Input() caseInsensitive = true;
    @Input() caseInsensitiveDisabled = false;
    @Input() caseInsensitiveControl: UntypedFormControl = null;

  constructor() {
    // DetailsToggleDropdownComponent constructor
  }

  ngOnInit(): void {
    if (this.caseInsensitiveControl) {
      this.caseInsensitive = this.caseInsensitiveControl.value;
      this.caseInsensitiveControl.valueChanges.subscribe(val => this.caseInsensitive = val);
    }
  }

  toggleCaseInsensitive(checked) {
    if (!this.caseInsensitiveControl) return;
    this.caseInsensitiveControl.setValue(checked);
  }
}
