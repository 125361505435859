import { UntilDestroy } from '@ngneat/until-destroy';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { DataType } from '../enums/data-types';
import { ACTIVITY_TXT, MONITOR_TXT } from '../utils/utils';
import { UtilsService } from '../utils/utilservice';
import { selectDataTypeOperators } from '../audience-builder/audience-builder.reducer';
import { FetchDataTypeOperators } from '../audience-builder/audience-builder.actions';
import { Store } from '@ngrx/store';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
class AudienceResolverService {

  constructor(public builderService: AudienceBuilderService, private store: Store, private router: Router, public route: ActivatedRoute, private utilsService: UtilsService) {}

  resolve(route: ActivatedRouteSnapshot): any {
    const isDCDP = route.queryParams['isDCDP'] === 'true';
    this.store.select(selectDataTypeOperators).subscribe(dataTypeOperators => {
      if (!dataTypeOperators || !dataTypeOperators.length) {
        this.store.dispatch(new FetchDataTypeOperators(route.params['contextId'], route.params['dataUniverseId']))
      }
    });

    return this.builderService.fetchCabAttributeDetails(
      DataType.AUDIENCE_LIST,
      route.params['contextId'],
      route.params['dataUniverseId'],
      route.params['audienceId']
    ).pipe(catchError(error => {
      if(error.status === 404 || error.status >= 500) {
        this.router.navigate([
          this.utilsService.getProductBaseUrl(this.router, route),
          route.params['contextId'],
          isDCDP ? ACTIVITY_TXT : MONITOR_TXT,
        ], { state: {errorMessage: error.error?.errorDetails[0]?.errorMessage}});
      }
    return of({error});
    }))
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AudienceResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot): Observable<any> => {
  return inject(AudienceResolverService).resolve(route);
}
