import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {Actions} from '@ngrx/effects';
import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {take} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';
import {fetchOutcome} from '../../../utils/fetch-state';
import {CampaignExtractService} from '../../../campaign-extract/campaign-extract.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CampaignExtract, CampaignTemplate,} from '../../../campaign-extract/campaign-extract.model';
import {SaveCampaignExtract, SaveCampaignExtractSchedule} from '../../../campaign-extract/campaign-extract.actions';
import {MAX_NAME_LENGTH, nameValidators,} from '../../../audience/audience.utils';
import {get, startCase} from 'lodash-es';
import {FEATURE_SCHEDULING} from '../../../utils/feature-utils';
import {ScheduleDetail, ScheduleType} from '../../../audience-overview/details/schedules/schedules.models';
import { FeatureService } from '../../../utils/feature-service';
import { selectContext } from '../../../context/context.reducer';

@UntilDestroy()
@Component({
  selector: 'lib-export-audience-modal',
  templateUrl: './export-audience-modal.component.html',
  styleUrls: ['./export-audience-modal.component.sass'],
})
export class ExportAudienceModalComponent
  implements AfterViewInit, OnInit
{
  modalExportForm: UntypedFormGroup = this.formBuilder.group({
    exportName: new UntypedFormControl('', {
      validators: [...nameValidators],
      updateOn: 'change',
    }),
    destinationTemplate: new UntypedFormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
    marketingCode: new UntypedFormControl('', {
      validators: [...nameValidators],
      updateOn: 'change',
    }),
  });
  schedulerEnabled: boolean;
  @ViewChild('exportActionModal') public exportActionModal;
  @ViewChild('libScheduleAudienceModal') libScheduleAudienceModal;
  destinationsTemplates: CampaignTemplate[];
  modalCreateForm: UntypedFormGroup;
  saveButtonName: string;
  @Input() audience;
  @Input() scheduleDetails: ScheduleDetail;
  @Output() exportModalClosed = new EventEmitter<string>();
  label ='Audience Definition Name';
  showSearchLimit = false;
  tempDestinationTemplates: CampaignTemplate[];

  constructor(
    public store: Store<AppState>,
    public formBuilder: UntypedFormBuilder,
    private actions$: Actions,
    private campaignExtractService: CampaignExtractService,
    public featureService: FeatureService
  ) {}

  ngOnInit(): void {
    const dataUniverseId = this.audience.dataUniverseId;
    const cabContextId = this.audience.cabContextId;
    const isAudience = this.audience.dataType === 'Audience';
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.schedulerEnabled = this.featureService.isFeatureEnabled(FEATURE_SCHEDULING);
      });
    if(isAudience) {
      this.label = 'Audience List Name';
    }
    this.modalExportForm.patchValue({
      exportName: this.audience.displayName + '_export',
    });
    this.saveButtonName = this.scheduleDetails ? 'Save' : 'Export';
    this.campaignExtractService
      .fetchCampaignTemplates(cabContextId, dataUniverseId)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.destinationsTemplates = res;
        this.tempDestinationTemplates = this.destinationsTemplates;
        if(this.scheduleDetails) {
            this.modalExportForm.patchValue(
            {
              exportName: this.scheduleDetails.scheduleParams.name,
              destinationTemplate: this.destinationsTemplates.find(template => template.displayName === this.scheduleDetails.scheduleParams.productExtractInfo.templateId),
              marketingCode: this.scheduleDetails.scheduleParams.productExtractInfo.taxonomyCode
            }
          );
        }
      });
  }

  ngAfterViewInit() {
    this.exportActionModal.show();
  }

  save() {
    const displayName = this.modalExportForm.get('exportName').value.trim();
    const destinationTemplate = this.modalExportForm.get(
      'destinationTemplate'
    ).value;
    const isAudience = this.audience.dataType === 'Audience';
    const taxonomyCode = this.modalExportForm.get('marketingCode').value.trim();
    const { id, cabContextId, idCount, dataUniverseId } = this.audience;

    const campaignExtract = {
      audienceListId: isAudience ? id : null,
      audienceDefinitionId: isAudience ? null : id,
      cabContextId,
      count: idCount,
      dataUniverseId,
      productExtractInfo: {
        templateId: destinationTemplate.id,
        taxonomyCode,
      },
      displayName,
    } as CampaignExtract;

    if(this.libScheduleAudienceModal?.isFutureSchedule) {
      const scheduleDetail = new ScheduleDetail();
      if(this.scheduleDetails) {
        scheduleDetail.scheduleId = this.scheduleDetails.scheduleId;
      }
      scheduleDetail.name = this.libScheduleAudienceModal.getName();
      scheduleDetail.displayName = this.libScheduleAudienceModal.getName();
      scheduleDetail.cabContextId = cabContextId;
      scheduleDetail.scheduleEntityId = id;
      scheduleDetail.scheduleType = ScheduleType.EXTRACT_FROM_DEFINITION;
      scheduleDetail.scheduleParams = {
        name: displayName,
        displayName,
        productExtractInfo: {
          templateId: destinationTemplate.id,
          taxonomyCode
        }
      };
      scheduleDetail.scheduleIntervalInfo = this.libScheduleAudienceModal.getScheduleInfo();
      this.store.dispatch(new SaveCampaignExtractSchedule(scheduleDetail));
      this.actions$
        .pipe(fetchOutcome(SaveCampaignExtractSchedule.type), take(1))
        .subscribe(
          () => this.closeModal('success'),
          () => this.closeModal('error')
        );
    } else {
      this.store.dispatch(new SaveCampaignExtract(campaignExtract));
      this.actions$
        .pipe(fetchOutcome(SaveCampaignExtract.type), take(1))
        .subscribe(
          () => this.closeModal('success'),
          () => this.closeModal('error')
        );

    }
  }

  public closeModal(exportStatus?: string) {
    this.exportActionModal.hide();
    this.exportModalClosed.emit(exportStatus);
  }

  nameFieldErrorMessage(formField: string): string | void {
    const errors = get(this.modalExportForm.controls, formField).errors;
    if (!errors) {
      return;
    }
    const { required, maxlength, whitespace } = errors;
    if (required || whitespace) {
      return 'Required';
    }
    if (maxlength?.actualLength > maxlength?.requiredLength) {
      return `${startCase(
        formField
      )} cannot be longer than ${MAX_NAME_LENGTH} characters.`;
    }
    console.error(errors);
    return 'An unknown error occurred.';
  }

  searchTemplates(searchText: string) {
    if (searchText?.trim().length > 0 && searchText?.trim().length < 3) {
      this.showSearchLimit = true;
      this.destinationsTemplates = [];
      return;
    }
    this.showSearchLimit = false;
    if (searchText.length === 0) {
      this.destinationsTemplates = this.tempDestinationTemplates;
    } else {
      this.destinationsTemplates = this.tempDestinationTemplates?.filter(template => template.displayName.toLowerCase().includes(searchText.toLowerCase()));
    }
  }
}
