import { UntilDestroy } from '@ngneat/until-destroy';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { DataType } from '../enums/data-types';
import { Store } from '@ngrx/store';
import { FetchDataTypeOperators } from '../audience-builder/audience-builder.actions';
import { selectDataTypeOperators } from '../audience-builder/audience-builder.reducer';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
class MonitorResolverService {

  constructor(public builderService: AudienceBuilderService, private router: Router, private store: Store) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    this.store.select(selectDataTypeOperators).subscribe(dataTypeOperators => {
      if (!dataTypeOperators || !dataTypeOperators.length) {
        this.store.dispatch(new FetchDataTypeOperators(route.params['contextId'], route.params['dataUniverseId']))
      }
    });
    return this.builderService.fetchCabAttributeDetails(
      DataType.MONITOR,
      route.params['contextId'],
      route.params['dataUniverseId'],
      route.params['campaignExtractId']
      ).pipe(catchError((error)=>{
        return of({error});}));
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MonitorResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot): Observable<any> => {
  return inject(MonitorResolverService).resolve(route);
}
