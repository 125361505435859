import {
  combineLatest as observableCombineLatest,
  Subject,
  BehaviorSubject,
} from 'rxjs';

import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import {
  AfterViewChecked,
  Directive,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

/*
Lets a user obtain information on whether or not the targeted element overflows its container.
Can be used like so (similar to *ngFor syntax):

<label *ppcCheckOverflow="let hasXOverflow=hasXOverflow; let hasYOverflow=hasYOverflow;">
  {{hasXOverflow ? 'Too Skinny!' : 'Lorem ipsum'}}
  {{hasYOverflow ? 'Too Short!' : 'Dolor sit amet'}}
</label>

Note that if you make use of hasXOverflow or hasYOverflow to change whether or not the component overflows
you risk creating an infinite loop. For that reason it is recommended you do not change the overall size of the element

A programmatic way to see if there is ellipsis or it is ellipsized.
 */
@Directive({
  selector: '[libCheckOverflow]',
})
export class CheckOverflowDirective implements OnDestroy, AfterViewChecked {
  ngUnsubscribe$ = new Subject<void>();
  hasXOverflow$ = new BehaviorSubject<boolean>(false);
  hasYOverflow$ = new BehaviorSubject<boolean>(false);

  constructor(
    private templateRef: TemplateRef<{
      hasXOverflow: boolean;
      hasYOverflow: boolean;
    }>,
    private viewContainer: ViewContainerRef
  ) {
    const view = this.viewContainer.createEmbeddedView(this.templateRef, {
      hasXOverflow: false,
      hasYOverflow: false,
    });

    observableCombineLatest([
      this.hasXOverflow$.pipe(distinctUntilChanged()),
      this.hasYOverflow$.pipe(distinctUntilChanged()),
    ])
      .pipe(debounceTime(10), takeUntil(this.ngUnsubscribe$))
      .subscribe(([hasXOverflow, hasYOverflow]) => {
        view.context.hasXOverflow = hasXOverflow;
        view.context.hasYOverflow = hasYOverflow;
        view.detectChanges();
      });
  }

  ngAfterViewChecked() {
    const el = this.viewContainer.get(0)['rootNodes'][0] as HTMLElement;
    this.hasXOverflow$.next(el.clientWidth < el.scrollWidth);
    this.hasYOverflow$.next(el.clientHeight < el.scrollHeight);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
