import { Injectable } from '@angular/core';

import { LocalStorageService } from '../../local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class MockLocalStorageService extends LocalStorageService {
  store = {};

  getRawValue(key: string): string {
    return this.store[key];
  }

  setRawValue(key: string, value: string): string {
    this.store[key] = value;
    return value;
  }

  removeRawValue(key: string): string {
    const value = this.store[key];
    delete this.store[key];
    return value;
  }
}
