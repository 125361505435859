import { Injectable } from '@angular/core';

type ResourceOp = "create" | "update" | "write" | "read" | "view" | "trigger" | "run" | "delete";
@Injectable({ providedIn: 'root' })
export class UserPermissionsService {
  userPermissions = [];
  /**
   * Determines if the current user has permission to the specified resource.
   */
  public userHasResourcePermission(
    resourceName: string,
    resourceOp: ResourceOp
  ): boolean {
    console.log(resourceName);
    console.log(resourceOp);
    return true;
  }
}