<main>
  <div class="notification-nested">
    <coreui-notification ariaCloseButtonLabel="Dismiss" notificationStyle="error" [isPersistent]="true"
                         [dismissible]="true" #showNestedDefinitionError>
      <b>Number of nested definitions limit exceeded</b>
      <p class="notification-text">
        {{ maxNestedDefsReachedErrorMsg }}
      </p>
      <p class="notification-text">Note: A definition may also have multiple
        definitions nested in it. Nested
        definitions at all nesting levels will be counted.</p>
      <a href="javascript:void(0);" class="text-style-action" (click)="showNestedLimitModal()"> Learn More </a>
    </coreui-notification>
    <coreui-notification ariaCloseButtonLabel="Dismiss" notificationStyle="error" [isPersistent]="true"
                         [dismissible]="true" #showNestingLimitError>
      <b>Nesting Depth limit exceeded</b>
      <p class="notification-text">
        {{ maxNestingLimitDepthReachedErrorMsg }}
      </p>
      <a href="javascript:void(0);" class="text-style-action" (click)="showNestedLimitModal()"> Learn More </a>
    </coreui-notification>
  </div>
  <div class="header-container">
    <div class="header">
      <h1>Audience Definition Builder</h1>
    </div>
    <div class="actions">
      <form class="Core-Form" [formGroup]="audienceForm">
        <coreui-form-field>
          <input [(ngModel)]="builderService.audience.displayName" coreUIInput formControlName="displayName"
                 placeholder="New Audience Definition" autocomplete="off"/>
          <coreui-form-error>{{ nameFieldErrorMessage() }}</coreui-form-error>
        </coreui-form-field>
      </form>
      <div
        class="dedupe-select"
        *ngIf="dedupeTypes?.length"
        [formGroup]="audienceForm"
      >
        <coreui-form-field>
          <coreui-select [disabled]="isEdit"
                         (valueChange)="onDedupeTypeSelectChange($event)"
                         formControlName="dedupeType"
          >
            <ng-container *ngFor="let dedupeType of dedupeTypes">
              <coreui-option
                [value]="dedupeType.identityType"
                class="text-style-5"
                [displayValue]="'Dedupe By ' + dedupeType.name"
              >
                {{ dedupeType.name }}
              </coreui-option>
            </ng-container>
          </coreui-select>
        </coreui-form-field>
      </div>
      <div class="action-button-container">
        <button *ngIf="!builderService.audienceBuilderUpdatedManually$.value && !audienceForm.dirty" coreUIButton="secondary" (click)="cancel()">Exit</button>
        <button *ngIf="builderService.audienceBuilderUpdatedManually$.value || audienceForm.dirty" coreUIButton="secondary" (click)="saveDiscardDefinitionModal.show()">Cancel</button>
        <ng-template #saveTooltip>
          <ng-container *ngFor="let message of saveButtonErrorTooltip()">
            {{ message }}<br/>
          </ng-container>
        </ng-template>
        <button coreUIButton (click)="save(false)" [disabled]="isSaveButtonDisabled() || (countsService.saveDisable$ | async)">
          <!-- TODO: need a way to refactor this workaround --> <!-- //NOSONAR -->
          <span *ngIf="
              isSaveButtonDisabled() && (errorOnLoadState$ | async) === false
            " [coreUITooltip]="saveTooltip">Save</span>
          <span *ngIf="isSaveButtonDisabled() && (errorOnLoadState$ | async)">Save</span>
          <span *ngIf="!isSaveButtonDisabled()">Save</span>
        </button>
      </div>
    </div>
    <span *ngIf="isEdit">
      <lib-audience-builder-details (saveDefinition)="onSaveDefinition($event)"></lib-audience-builder-details>
  </span>
  </div>
  <div class="audienceSelection">
    <lib-audience-selection [audienceForm]="audienceForm"></lib-audience-selection>
  </div>

</main>

<coreui-modal modalWidth="40" #saveDefinitionOnVersionChange>
  <!--
    This modal is used when another user has already updated the definition
    and the current user has made their own changes to the definition.
  -->
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon"></span> A new version of this definition has been saved
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="saveDefinitionOnVersionChange.hide()" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <p>
      In order to update the count, please save your edits under a new name or load the most updated definition and update count again.
    </p>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="discardAudienceDefinitionChanges(true)">
      Discard & Load new version
    </button>
    <button coreUIButton="primary" (click)="saveAsNewAudienceDefinition()">
      Save as new
    </button>
  </ng-template>
</coreui-modal>

<coreui-modal modalWidth="40" #saveAsNewDefinitionOnVersionChange>
  <!--
    This modal is used when another user has already updated the definition
    and the current user has made their own changes to the definition.
  -->
  <ng-template #titleTemplate>
    New Audience Definition
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="saveAsNewDefinitionOnVersionChange.hide()" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <input [(ngModel)]="builderService.audience.displayName" coreUIInput placeholder="New Audience Definition"
           autocomplete="off"/>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="text-primary" (click)="save(true)">
      Save
    </button>
  </ng-template>
</coreui-modal>

<coreui-modal modalWidth="40" #updateDedupeValueWarning>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon"></span> Are you sure?
  </ng-template>

  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="cancelDedupeChanges()" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>

  <ng-template #contentTemplate>
    <p>
      Changing the Dedupe type will remove all Include and Exclude conditions. Would you like to proceed?
    </p>
  </ng-template>

  <ng-template #buttonsTemplate>
    <button coreUIButton="text-primary" (click)="saveDedupeWarningChanges()">
      Continue
    </button>
    <button coreUIButton="secondary" (click)="cancelDedupeChanges()">
      Cancel
    </button>
  </ng-template>
</coreui-modal>

<coreui-notification notificationStyle="success" [isPersistent]="false" #successNotification>
  Saved Audience Definition {{ builderService.audience?.displayName }}!
</coreui-notification>

<coreui-notification notificationStyle="error" [isPersistent]="false" #errorNotification>
  {{ errorMessage }}
</coreui-notification>
<coreui-modal modalWidth="60" #nestedLimitModal>
  <ng-template #titleTemplate>
    Nesting Limit
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="nestedLimitModal.hide()" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <div class="nested-container">
      <div>
        <coreui-badge color="Plum">Maximum number of nesting definitions is 10</coreui-badge>
        <p class="notification-text">Nested definitions at all nesting levels will be counted.</p>
        <p class="notification-text">For example, definition A is nested in definition B. When you nest definition B in
          definition C, there’re two
          nested definitions counted in Definition C, which are A and B.</p>
        <coreui-badge color="Plum">Maximum nesting depth is 5</coreui-badge>
        <p class="notification-text">Maximum depth of nesting is 5. Level N can be 5 levels at the maximum.</p>
      </div>
      <div>
        <svg width="473px" height="286px" viewBox="0 0 473 286" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Group 25</title>
          <g id="UX-1273---CAB-UI-enhancement" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group-24">
              <rect id="Rectangle" stroke="#C7D6EB" fill="#F4F6FA" x="0.5" y="0.5" width="472" height="285" rx="4">
              </rect>
              <rect id="Rectangle" stroke="#C7D6EB" fill="#F4F6FA" x="20.5" y="47.5" width="432" height="222" rx="4">
              </rect>
              <rect id="Rectangle" stroke="#C7D6EB" fill="#F4F6FA" x="40.5" y="92.5" width="392" height="161" rx="4">
              </rect>
              <rect id="Rectangle" stroke="#C7D6EB" fill="#F4F6FA" x="60.5" y="139.5" width="352" height="98" rx="4">
              </rect>
              <text id="Definition-1" font-family="SourceSansPro-Regular, Source Sans Pro" font-size="12"
                    font-weight="normal" fill="#1A1A1A">
                <tspan x="73.128" y="165">Definition 1</tspan>
              </text>
              <text id="Definition-2" font-family="SourceSansPro-Regular, Source Sans Pro" font-size="12"
                    font-weight="normal" fill="#1A1A1A">
                <tspan x="61.128" y="118">Definition 2</tspan>
              </text>
              <text id="Definition-3" font-family="SourceSansPro-Regular, Source Sans Pro" font-size="12"
                    font-weight="normal" fill="#1A1A1A">
                <tspan x="41.128" y="73">Definition 3</tspan>
              </text>
              <text id="Definition-N" font-family="SourceSansPro-Regular, Source Sans Pro" font-size="12"
                    font-weight="normal" fill="#1A1A1A">
                <tspan x="20.228" y="26">Definition N</tspan>
              </text>
              <text id="Level-1" font-family="SourceSansPro-SemiBold, Source Sans Pro" font-size="12" font-weight="500"
                    fill="#1A1A1A">
                <tspan x="364.03" y="165">Level 1</tspan>
              </text>
              <text id="Level-2" font-family="SourceSansPro-SemiBold, Source Sans Pro" font-size="12" font-weight="500"
                    fill="#1A1A1A">
                <tspan x="378.03" y="118">Level 2</tspan>
              </text>
              <text id="Level-3" font-family="SourceSansPro-SemiBold, Source Sans Pro" font-size="12" font-weight="500"
                    fill="#1A1A1A">
                <tspan x="398.03" y="73">Level 3</tspan>
              </text>
              <text id="Level-N" font-family="SourceSansPro-SemiBold, Source Sans Pro" font-size="12" font-weight="500"
                    fill="#1A1A1A">
                <tspan x="416.172" y="25">Level N</tspan>
              </text>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="primary" (click)="nestedLimitModal.hide()">
      Got it
    </button>
  </ng-template>
</coreui-modal>
<coreui-modal modalWidth="60" #saveDiscardDefinitionModal>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon"></span> You’re leaving the current page with unsaved changes!
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="saveDiscardDefinitionModal.hide()" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <p>What would you like to do?</p>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="primary" (click)="cancel()">
      Discard & Exit
    </button>
    <button coreUIButton="primary" (click)="saveDiscardDefinitionModal.hide()">
      Return to Editing
    </button>
  </ng-template>
</coreui-modal>
