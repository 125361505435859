import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first, map as _map } from 'lodash-es';
import { fetchResource } from '../utils/fetch-state';
import * as actions from '../audience/audience.actions';
import {AudienceDefinition, Audience, AudienceSearchData, AudienceDefinitionSearchData} from './audience.models';
import { CabHttpService, ContextType } from '../services/cab-http.service';
import { httpAbortLoadIndicator } from '../utils/utils';
import { AttributeModel } from './attribute.model';
import { CabConstants } from '../cab.constants';
import { EnumSet } from '../admin/admin.models';
import { UtilsService } from '../utils/utilservice';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AudienceService {
  errorMessages: string[]= [];
  successFullyDeletedIds = [];
  destroyAudienceDefinition$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.DestroyAudienceDefinition.type),
      fetchResource((action) =>
        this.destroyAudienceDefinition(action.audienceDefinition).pipe(
          map(
            () =>
              new actions.RemoveAudienceDefinition(action.audienceDefinition)
          )
        )
      )
    );
  });

  destroyAudience$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.DestroyAudience.type),
      fetchResource((action) =>
        this.destroyAudience(action.audience).pipe(
          map(() => new actions.RemoveAudience(action.audience))
        )
      )
    )
  );

  // TODO: if we want multi-delete, backend must provide route
  destroyAudienceDefinitions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.DestroyAudienceDefinitions.type),
      fetchResource((action) =>
        this.destroyAudienceDefinitions(action.audienceDefinitions).pipe(
          map(
            () => {
              try {
                if(this.successFullyDeletedIds.length > 0)
                {
                  new actions.RemoveAudienceDefinitions(action.audienceDefinitions);
                }
                throw new Error(JSON.stringify(this.errorMessages));
              } catch (error) {
                new actions.RemoveAudienceDefinitions(action.audienceDefinitions);
                throw new Error(JSON.stringify(this.errorMessages));
              }
            }
          )
        )
      )
    );
  });

  // TODO: if we want multi-delete, backend must provide route
  destroyAudiences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.DestroyAudiences.type),
      fetchResource((action) =>
        this.destroyAudiences(action.audiences)
          .pipe(
            map(
              () => {
                try {
                  if(this.successFullyDeletedIds.length > 0)
                  {
                    new actions.RemoveAudiences(action.audiences);
                  }
                  throw new Error(JSON.stringify(this.errorMessages));
                } catch (error) {
                  new actions.RemoveAudiences(action.audiences);
                  throw new Error(JSON.stringify(this.errorMessages));
                }
              }
            )
        )
      )
    )
  );

  fetchAudienceDefinitions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FetchAudienceDefinitions.type),
      fetchResource((action) =>
        this.fetchAudienceDefinitions(action.contextId).pipe(
          map((definitions) => new actions.LoadAudienceDefinitions(definitions))
        )
      )
    )
  );

  searchAudienceDefinition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SearchAudienceDefinition.type),
      fetchResource((action) =>
        this.searchAudienceDefinitions(action.contextId, action.searchPayload).pipe(
          map((audienceDefinitionSearchData) => new actions.LoadAudienceDefinitionsSearch(audienceDefinitionSearchData))
        )
      )
    )
  );

  fetchAudiences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FetchAudiences.type),
      fetchResource((action) =>
        this.fetchAudiences(action.contextId).pipe(
          map((audiences) => new actions.LoadAudiences(audiences))
        )
      )
    )
  );

  searchAudiences$ = createEffect( () =>
    this.actions$.pipe(
      ofType(actions.SearchAudiences.type),
      fetchResource((action) =>
        this.searchAudiences(action.contextId, action.searchPayload).pipe(
          map((audienceSearchData) => new actions.LoadAudiencesSearch(audienceSearchData))
        )
      )
    ));

  saveAudienceDefinition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SaveAudienceDefinition.type),
      fetchResource((action) =>
        this.saveAudienceDefinition(action.audienceDefinition).pipe(
          map((definition) => new actions.LoadAudienceDefinition(definition))
        )
      )
    )
  );

  saveAudience$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SaveAudience.type),
      fetchResource((action) =>
        this.saveAudience(action.audience).pipe(
          map((audience) => new actions.LoadAudience(audience))
        )
      )
    )
  );

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private httpService: CabHttpService,
    private utilsService: UtilsService
  ) {}

  destroyAudienceDefinition(
    audienceDefinition: AudienceDefinition
  ): Observable<null> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = audienceDefinition.cabContextId;
    const options = { headers, responseType: 'text' as 'json',
      params: {
        fullPageLoader: true
      } };
    return this.http.delete(
      this.getAudienceDefinitionsUrl(audienceDefinition.id),
      options
    ) as Observable<null>;
  }

  destroyAudience(audience: Audience): Observable<null> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = audience?.cabContextId;
    const options = { headers, responseType: 'text' as 'json',
      params: {
        fullPageLoader: true
      } };
    return this.http.delete(
      this.getAudiencesUrl(audience.id),
      options
    ) as Observable<null>;
  }

  destroyAudienceDefinitions(
    audienceDefinitions: AudienceDefinition[]
  ): Observable<null> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = audienceDefinitions[0].cabContextId;
    const options = { headers, responseType: 'text' as 'json',
      params: {
        fullPageLoader: true
      } };
    this.errorMessages = [];
    // TODO: if we want multi-delete, backend must provide route
    return forkJoin(
      _map(audienceDefinitions, (audienceDefinition) =>
        this.http.delete(
          this.getAudienceDefinitionsUrl(audienceDefinition.id),
          options
        ).pipe(
          map(() => {
            this.successFullyDeletedIds.push(audienceDefinition.id);
          }),
          catchError(error => {
            if(error.error){
              const jsonArr = JSON.parse(error.error);
              this.errorMessages.push(jsonArr.errorDetails[0].errorMessage.split('is referenced')[0].trim());
            }
            return of(this.errorMessages);
            }))
      )
    ) as Observable<null>;
  }

  destroyAudiences(audiences: Audience[]): Observable<null> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = audiences[0].cabContextId;
    const options = { headers, responseType: 'text' as 'json',
      params: {
        fullPageLoader: true
      } };
    this.errorMessages = [];
    // TODO: if we want multi-delete, backend must provide route
    return forkJoin(
      _map(audiences, (audience) =>
        this.http.delete(this.getAudiencesUrl(audience.id), options).pipe(
        map(() => {
          this.successFullyDeletedIds.push(audience.id);
        }),
        catchError(error => {
          if(error.error){
            const jsonArr = JSON.parse(error.error);
            this.errorMessages.push(jsonArr.errorDetails[0].errorMessage.split('is referenced')[0].trim());
          }
          return of(this.errorMessages);
        }))
    )
    ) as Observable<null>;
  }

  fetchAudienceTableBasedonType(isFromAudienceListTable, id: string,
    contextId: string,
    abortLoadIndicator = false) {
    if(!isFromAudienceListTable) {
     return this.fetchAudienceDefinition(id, contextId, abortLoadIndicator)
    } else {
     return  this.fetchAudienceList(id, contextId, abortLoadIndicator)
    }
  }

  fetchAudienceDefinition(
    audienceDefinitionId: string,
    contextId: string,
    abortLoadIndicator = false
  ): Observable<AudienceDefinition> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const params = abortLoadIndicator ? httpAbortLoadIndicator({}) : {};
    return this.http
      .get(this.getAudienceDefinitionsUrl(audienceDefinitionId), {
        headers,
        params,
      })
      .pipe(
        map((res) => new AudienceDefinition(res))
      ) as Observable<AudienceDefinition>;
  }

  fetchAudienceList(
    audienceListId: string,
    contextId: string,
    abortLoadIndicator = false
  ): Observable<AudienceDefinition> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const params = abortLoadIndicator ? httpAbortLoadIndicator({}) : {};
    return this.http
      .get(this.getAudiencesUrl(audienceListId), {
        headers,
        params,
      })
      .pipe(
        map((res) => new AudienceDefinition(res))
      ) as Observable<AudienceDefinition>;
  }

  fetchAudienceDefinitions(
    contextId: string
  ): Observable<AudienceDefinition[]> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .get(this.getAudienceDefinitionsUrl(null, 1000), { headers })
      .pipe(
        map((res: any) =>
          _map(res.results, (result) => new AudienceDefinition(result))
        )
      );
  }

  searchAudienceDefinitions(
    contextId: string,
    searchPayload: any
  ): Observable<AudienceDefinitionSearchData> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .post(`${this.baseUrl()}/audience-definition/search`, searchPayload, { headers })
      .pipe(map((res: any) => {
        return {audienceDefinitions: _map(res.results, (result) => new AudienceDefinition(result)), hasMore: res.hasMore} as AudienceDefinitionSearchData;
      }));
  }

  fetchAudiences(contextId: string): Observable<Audience[]> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .get(this.getAudiencesUrl(null, 1000), { headers })
      .pipe(
        map((res: any) => _map(res.results, (result) => new Audience(result)))
      );
  }

  searchAudiences(contextId: string, searchPayload: any): Observable<AudienceSearchData> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .post(`${this.baseUrl()}/audience-list/search`, searchPayload, { headers })
      .pipe(
        map((res: any) => {
          return {audiences: _map(res.results, (result) => new Audience(result)), hasMore: res.hasMore} as AudienceSearchData;
        })
      );
  }

  saveAudienceDefinition(
    audience: AudienceDefinition
  ): Observable<AudienceDefinition> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = audience.cabContextId;
    const verb = audience.id ? 'put' : 'post';
    const transformedAudience = new AudienceDefinition(
      audience
    ).transformToBECompatibleObject();
    return this.http[verb](
      this.saveAudienceDefinitionUrl(verb, audience.id),
      transformedAudience,
      { headers,
        params: {
          fullPageLoader: true
        } }
    ).pipe(
      map((res: any) => {
        return this.utilsService.isMocked()
          ? new AudienceDefinition(first(res?.entity))
          : new AudienceDefinition(res?.entity);
      })
    ) as Observable<AudienceDefinition>;
  }

  saveAudience(audience: Audience): Observable<Audience> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = audience.cabContextId;
    const verb = audience.id ? 'put' : 'post';
    let transformedAudience;
    if(audience.id) {
      transformedAudience = new Audience(
        audience
      ).transformToBECompatibleObjectForUpdate();
    }else {
      transformedAudience = new Audience(
        audience
      ).transformToBECompatibleObject(this.utilsService.isMocked());
    }
    return this.http[verb](
      this.saveAudienceUrl(verb, audience.id),
      transformedAudience,
      { headers,
        params: {
          fullPageLoader: true
        } }
    ).pipe(
      map((res: any) => {
        return this.utilsService.isMocked()
          ? new Audience(first(res?.entity))
          : new Audience(res?.entity);
      })
    ) as Observable<Audience>;
  }

  getAudienceDefinitionsUrl(id: string = null, limit: number = null): string {
    const idPath = id ? `/${id}` : '';
    const query = limit ? `?limit=${limit}` : '';
    return `${this.baseUrl()}/audience-definition${idPath}${query}`;
  }

  getAudiencesUrl(id: string = null, limit: number = null): string {
    const idPath = id ? `/${id}` : '';
    const query = limit ? `?limit=${limit}` : '';
    return `${this.baseUrl()}/audience-list${idPath}${query}`;
  }

  saveAudienceDefinitionUrl(verb: string, audienceId?: string): string {
    const childPath = verb === 'put' ? `/${audienceId}` : '';
    return `${this.getAudienceDefinitionsUrl()}${childPath}`;
  }

  saveAudienceUrl(verb: string, audienceId: string): string {
    const childPath = verb === 'put' ? `/${audienceId}` : '';
    return `${this.getAudiencesUrl()}${childPath}`;
  }

  public baseUrl(): string {
    if (this.utilsService.isMocked()) {
      return this.httpService.apiUrl(
        ContextType.DISCOVERY,
        '/id_analytics_api/cab/v1'
      );
    }
    return this.httpService.apiUrl(ContextType.CAB, '/cab/v1');
  }

  getAudienceDefinitionsPaginationUrl(paginationPayload: any): string {
    let query: string = paginationPayload.limit ? `?limit=${paginationPayload.limit}` : '';
    query = query.concat(paginationPayload.offset ? `&offset=${paginationPayload.offset}` : '');
    query = query.concat(paginationPayload.sort ? `&sort=${paginationPayload.sort}` : '');
    query = query.concat(paginationPayload.search ? `&name=${paginationPayload.search}` : '');
    return `${this.baseUrl()}/audience-definition${query}`;
  }

  getAttributesForDefinition(audienceDefinitionId: string, contextId: string): Observable<AttributeModel[]> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .get(`${this.baseUrl()}/audience-definition/${audienceDefinitionId}/datasets-for-extract`, { headers })
      .pipe(
        map((res: any) => res)
      );
  }

  retrieveAttributesForDefinition(id: string, contextId: string, dataUniverseId: string, isFromAudienceTable = false): Observable<AttributeModel[]> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .post(`${this.baseUrl()}/audience-builder/datasets-for-extract`,{
        ...isFromAudienceTable ? {audienceListId: id} : {audienceDefinitionId : id},
        cabContextId: contextId,
        dataUniverseId
      } ,{ headers })
      .pipe(
        map((res: any) => res)
      );
  }

    /*
  This API will fetch only the enum values that are associated to the particular entity.
  Since this particular flow only deals with values so written seperate method to fetch those
  */
  getEnumValues(contextId: string, entityId: string): Observable<EnumSet> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/audience-builder/enum-values/${entityId}`);
      return this.http.get(url, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
