<div class="header-right">
    <button
      coreUIButton
      withIcon="left"
      (click)="routeToCreateEnum()"
    >
    <span>
        <i coreUIIcon="add"></i>New Enumeration
      </span>
    </button>
    <form [formGroup]="searchFormGroup">
    <coreui-table-search
    class="tableSearchBox"
    placeholder="Search by Enumeration name"
    formControlName="search"
    >
    </coreui-table-search>
    </form>
</div>


<div class="enumeration-table">
    <coreui-table  [fixedHeader]="true" [class.sticky-table]="scrollItems" class="table tableScroll" coreUIInfiniteScroll #scrollTable [scrollTarget]="scrollTable.scrollContainer">
        <thead coreUITableSort>
            <tr coreUITableHeaderRow>
                <th scope="col" class="table-header">
                    <coreui-table-header-column (sort)="scrollSort($event,'EnumerationName')">
                        Enumeration Name
                    </coreui-table-header-column>
                </th>
                <th scope="col" class="table-header">
                    <coreui-table-header-column (sort)="scrollSort($event,'Description')">
                        Description
                    </coreui-table-header-column>
                </th>
                <th scope="col" class="table-header">
                    <coreui-table-multiple-header-column label="Created">
                      <coreui-table-header-column
                        coreUIMenuItem
                        (sort)="scrollSort($event, 'createdDate')"
                      >
                        Date
                      </coreui-table-header-column>
                      <coreui-table-header-column
                        coreUIMenuItem
                        (sort)="scrollSort($event, 'createdBy')"
                      >
                        User
                      </coreui-table-header-column>
                    </coreui-table-multiple-header-column>
                </th>
                <th scope="col" class="table-header">
                    <coreui-table-multiple-header-column label="Last Updated">
                      <coreui-table-header-column
                        coreUIMenuItem
                        (sort)="scrollSort($event, 'lastModifiedDate')"
                      >
                        Date
                      </coreui-table-header-column>
                      <coreui-table-header-column
                        coreUIMenuItem
                        (sort)="scrollSort($event, 'lastModifiedBy')"
                      >
                        User
                      </coreui-table-header-column>
                    </coreui-table-multiple-header-column>
                </th>
                <th scope="col" class="table-header">
                    <coreui-table-header-column [disableSort]="true">
                    </coreui-table-header-column>
                </th>
            </tr>
        </thead>
        <tbody
        >
            <tr *ngFor="let enumSet of enumData?.enumSets; let idx=index" coreUITableRow>
                <td>{{enumSet.displayName}}</td>
                <td>{{enumSet.description}}</td>
                <td>
                    {{ enumSet.createdDate | date: "MM/dd/yyyy hh:mm a" }}
                    <div class="text-style-muted-3">{{ enumSet.createdBy }}</div>
                  </td>
                  <td>
                    {{ enumSet.lastModifiedDate | date: "MM/dd/yyyy hh:mm a" }}
                    <div class="text-style-muted-3">{{ enumSet.lastModifiedBy }}</div>
                  </td>
                <td>
                    <coreui-menu menuButtonStyle="link" icon="more">
                        <coreui-menu-section>
                          <button
                            type="button"
                            *ngFor="let action of dropdownListActions"
                            (click)="action.onClick(enumSet)"
                            coreUIMenuItem
                          >
                            {{ action.display }}
                          </button>
                        </coreui-menu-section>
                      </coreui-menu>
                </td>
            </tr>
            <tr coreUITableRow *ngIf="isLoader">
              <td colspan="2"><coreui-loading-table-column></coreui-loading-table-column></td>
              <td><coreui-loading-table-column></coreui-loading-table-column></td>
              <td><coreui-loading-table-column></coreui-loading-table-column></td>
              <td><coreui-loading-table-column></coreui-loading-table-column></td>
              <td><coreui-loading-table-column></coreui-loading-table-column></td>
            </tr>
            <tr *ngIf="enumData?.enumSets.length === 0 && !isLoader" coreUITableRow [disableHover]="true"> 
              <td colspan="9" class="empty-list">
                <coreui-graphic-message label="No Results Found" graphic="noResults">
                  Sorry, we did not find any results for your search.
                </coreui-graphic-message>
              </td>
            </tr>
        </tbody>
    </coreui-table>
</div>

<lib-manage-enum-attributes
  *ngIf="(actionInProgress$ | async)?.type === 'manageAttributes'"
  (closeManageAttributes)="closeManageAttributes($event)"
  [enumSet]="(actionInProgress$ | async)?.enumSet"
></lib-manage-enum-attributes>

<coreui-modal #errorRemoveEnumerationModal>
  <ng-template #titleTemplate>
    <i coreUIIcon="warning" class="errorActionWarning"></i>
    Cannot delete enumeration.
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeDeleteEnumerationModal(errorRemoveEnumerationModal)"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <span>Please remove the enumeration from all attributes listed below before deleting the enumeration <b>{{(actionInProgress$ | async)?.enumSet?.displayName}} </b></span>
    <br/>
    <coreui-selected-items>
      <coreui-selected-item *ngFor="let dataSetAssociation of deleteEnumerationAttributeDetails?.dataSetAssociations;" [removable]="false">{{dataSetAssociation?.dataSetDisplayName + '/ ' + dataSetAssociation?.attributeDisplayName}}</coreui-selected-item>
    </coreui-selected-items>

  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeDeleteEnumerationModal(errorRemoveEnumerationModal)">
      Close
    </button>
  </ng-template>
</coreui-modal>

<coreui-modal #removeEnumerationModal>
  <ng-template #titleTemplate>
    <i coreUIIcon="warning" class="removeActionWarning"></i>
    Are you sure to remove this enumeration?
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeDeleteEnumerationModal(removeEnumerationModal)"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <span>Are you sure you want to remove this enumeration <b>{{(actionInProgress$ | async)?.enumSet?.displayName}} </b> from the builder context?</span>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeDeleteEnumerationModal(removeEnumerationModal)">
      Cancel
    </button>
    <button coreUIButton (click)="removeEnumeration(removeEnumerationModal)">
      Remove
    </button>
  </ng-template>
</coreui-modal>

<coreui-notification notificationStyle="success" #successDeleteEnumeration>
  <b>Enumeration deleted successfully</b>
  <br/>
</coreui-notification>

<coreui-notification notificationStyle="error" #errorDeleteEnumeration>
  There was a problem deleting enumeration. Please try again.
</coreui-notification>
<coreui-notification notificationStyle="success" #successSavingAssociatedAttributes>
  <b>The selected attributes are successfully associated with {{updatedEnumDisplayName}}</b>
  <br/>
</coreui-notification>

