import { Action } from '@ngrx/store';
import { AttributeDetails, BuilderAudience } from './audience-builder.models';
import { DataTypeOperator } from '../models/data-type-operator';
import { DataType } from '../enums/data-types';
import { AudienceDefinition } from '../audience/audience.models';

export class FetchDataTypeOperators implements Action {
  static readonly type = 'AUDIENCE_BUILDER:FETCH_DATA_TYPE_OPERATORS';
  readonly type = FetchDataTypeOperators.type;

  constructor(public contextId: string, public dataUniverseId: string) {}
}

export class LoadDependentDefinitions implements Action {
  static readonly type = 'AUDIENCE_BUILDER:LOAD_DEPENDENT_DEFINITIONS';
  readonly type = LoadDependentDefinitions.type;

  constructor(public dependentDefinitions: AudienceDefinition[]) {}
}

export class LoadDataTypeOperators implements Action {
  static readonly type = 'AUDIENCE_BUILDER:LOAD_DATA_TYPE_OPERATORS';
  readonly type = LoadDataTypeOperators.type;

  constructor(public operators: DataTypeOperator[]) {}
}

export class ClearDataTypeOperators implements Action {
  static readonly type = 'AUDIENCE_BUILDER:CLEAR_DATA_TYPE_OPERATORS';
  readonly type = ClearDataTypeOperators.type;

  constructor() {
    // ClearDataTypeOperators constructor
  }
}

export class LoadPrebuiltAudience implements Action {
  static readonly type = 'AUDIENCE_BUILDER:LOAD_PREBUILT_AUDIENCE';
  readonly type = LoadPrebuiltAudience.type;

  constructor(public audience: BuilderAudience) {}
}

export class SetPrebuiltAudience implements Action {
  static readonly type = 'AUDIENCE_BUILDER:SET_PREBUILT_AUDIENCE';
  readonly type = SetPrebuiltAudience.type;

  constructor(
    public audience: BuilderAudience,
    public contextId?: string,
    public dataType?: DataType.AUDIENCE_LIST | DataType.AUDIENCE_DEFINITION,
    public shouldFetchEditDetails = false
  ) {}
}

export class FetchAttributeDetails implements Action {
  static readonly type = 'AUDIENCE_BUILDER:FETCH_ATTRIBUTE_DETAILS';
  readonly type = FetchAttributeDetails.type;

  constructor(
    public audience: BuilderAudience,
    public contextId: string,
    public dataType: DataType.AUDIENCE_LIST | DataType.AUDIENCE_DEFINITION
  ) {}
}

export class LoadAttributeDetails implements Action {
  static readonly type = 'AUDIENCE_BUILDER:LOAD_ATTRIBUTE_DETAILS';
  readonly type = LoadAttributeDetails.type;

  constructor(public details: AttributeDetails) {}
}

export class ToggleFullSegmentPath implements Action {
  static readonly type = 'AUDIENCE_BUILDER:TOGGLE_FULL_SEGMENT_PATH';
  readonly type = ToggleFullSegmentPath.type;

  constructor(public displayPath: boolean) {}
}

export class UpdateCountDetails implements Action {
  static readonly type = 'AUDIENCE_BUILDER:UPDATE_COUNT_DETAILS';
  readonly type = UpdateCountDetails.type;

  constructor(public countDetails: any) {}
}

export type ReducerActions =
  | FetchDataTypeOperators
  | LoadDependentDefinitions
  | LoadDataTypeOperators
  | ClearDataTypeOperators
  | LoadPrebuiltAudience
  | SetPrebuiltAudience
  | FetchAttributeDetails
  | LoadAttributeDetails
  | ToggleFullSegmentPath
  | UpdateCountDetails;
