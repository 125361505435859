import { Asset } from './feed-data-list.model';
import * as actions from './feed-data-list.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export interface State {
  feedData: Asset[];
  hasMore: boolean;
  initialState: boolean;
}

const defaultState = {
  feedData: [],
  hasMore: false,
  initialState: true,
};

export function reducer(
  state: State = defaultState,
  action: actions.ReducerActions
): State {
  switch (action.type) {
    case actions.LoadFeedDataList.type:
      return {
        ...state,
        feedData: action.feedData.feedData,
        hasMore: action.feedData.hasMore,
        initialState: false,
      };
    case actions.DestroyFeedDataList.type:
      return {
        ...state,
        feedData: [],
        hasMore: false,
        initialState: true,
      };
    default:
      return state;
  }
}

const cabFeatureSelector = createFeatureSelector<AppState>('cab');
export const feedDataListFeatureSelector = createSelector(
  cabFeatureSelector,
  (state: AppState) => state.feedData
);
export const selectFeedDataList = createSelector(
  feedDataListFeatureSelector,
  (state: State) => state.feedData
);
export const selectHasMore = createSelector(
  feedDataListFeatureSelector,
  (state: State) => state.hasMore
);
export const selectFeedDataListInitialState = createSelector(
  feedDataListFeatureSelector,
  (state: State) => state.initialState
);
