import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Context } from './context.models';
import * as actions from './context.actions';
import { AppState } from '../reducers';

export interface State {
  context: Context;
}

const defaultState = {
  context: null,
};

export function reducer(
  state: State = defaultState,
  action: actions.ReducerActions
): State {
  switch (action.type) {
    case actions.LoadContextDetails.type:
      return { ...state, context: action.contextDetails };
    case actions.ResetContextDetails.type:
      return { ...state, context: null };
    default:
      return state;
  }
}

const cabFeatureSelector = createFeatureSelector<AppState>('cab');

const contextFeatureSelector = createSelector(cabFeatureSelector, (state: AppState) => state?.context);
export const selectContext = createSelector(contextFeatureSelector, (state: State) => state?.context);
export const selectContextId = createSelector(selectContext, (context) => context?.id);
