<coreui-notification notificationStyle="success" [isPersistent]="false" #successNotification>
  Your changes have been saved.
</coreui-notification>

<coreui-notification notificationStyle="error" [isPersistent]="false" #errorNotification>
  {{ errorNotificationMessage }}
</coreui-notification>

<div class="header">
  <div class="header-content">
    <h1 *ngIf="productType === 'DCDP' && routeName === 'deliveries'">Deliveries</h1>
    <h1 *ngIf="productType === 'DCDP' && routeName === 'audience'">Audience Lists</h1>
    <h1 *ngIf="productType === 'DCDP' && routeName === 'definition'">Audience Definitions</h1>

    <div class="header-right">
      <div
        *ngIf="(disableGoToBuilder$ | async) === false && productType !== 'DCDP' && featureAccessService.hasAccess(definitionWriteAction)">
        <button coreUIButton withIcon="left" (click)="goToBuilder()" [disabled]="disableGoToBuilder$ | async">
          <i coreUIIcon="add"></i>New Audience Definition
        </button>
      </div>
      <div
        *ngIf="(disableGoToBuilder$ | async) === false && productType === 'DCDP' && featureAccessService.hasAccess(definitionWriteAction) && routeName === 'definition'"
        class="button-right">
        <button coreUIButton withIcon="left" (click)="showNewDefinitionModal()"
          [disabled]="disableGoToBuilder$ | async">
          <i coreUIIcon="add"></i>New Definition
        </button>
      </div>
      <div *ngIf="(disableGoToBuilder$ | async) === true && featureAccessService.hasAccess(definitionWriteAction)"
        coreUITooltip="Select a data universe to build an audience definition">
        <button coreUIButton withIcon="left">
          <i coreUIIcon="add"></i>New Audience Definition
        </button>
      </div>
      <coreui-select *ngIf="dataUniverses?.length && productType !== 'DCDP'"
        (valueChange)="onDataUniverseSelect($event)" [value]="activeDataUniverse">
        <coreui-option *ngFor="let dataUniverse of dataUniverses" [value]="dataUniverse">
          {{ dataUniverse.displayName }}
        </coreui-option>
      </coreui-select>
    </div>
  </div>

  <div *ngIf="productType" class="tabs" [style.display]="productType !== 'DCDP' ? 'block' : 'none' "  [ngClass]="{'disableAction': isTabsDataLoading}">
    <coreui-tab-group (tabChange)="tabChange($event)">
      <coreui-tab label="Audience Definition" *ngIf="productType !== 'DCDP' && productType !== 'EPCP'"
        [active]="tableFilterService.tableType$.value === 'definition'"></coreui-tab>
      <coreui-tab label="Audience List" *ngIf="productType !== 'DCDP' && productType !== 'EPCP'"
        [active]="tableFilterService.tableType$.value === 'audience'"></coreui-tab>
      <coreui-tab *ngIf="productType !== 'DCDP' && productType !== 'EPCP'" label="Monitor"
        [active]="tableFilterService.tableType$.value === 'monitor'"></coreui-tab>
    </coreui-tab-group>
  </div>
</div>

<lib-overview-table></lib-overview-table>

<coreui-modal #newDefinitionModal>
  <ng-template #titleTemplate>
    <span></span>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="closeModal(newDefinitionModal)" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <div class="container">
      <h2 class="heading">How would you like to build your definition?</h2>
      <div class="card-row">
        <coreui-card-container>
          <coreui-card role="button" class="Card-small" (click)="goToBuilder()">
            <div class="card-content-header card-content-header-visual-builder">
              <div class="selector-icon"><i coreUIIcon="browser"></i></div>
            </div>
            <h4 class="card-title">Visual Builder</h4>
            <span class="text-style-muted-3">
              Easily create your perfect audience with our visual drag-and-drop builder. Start crafting precise, effective audiences in just a few clicks.
            </span>
          </coreui-card>
        </coreui-card-container>
        <coreui-card-container>
          <coreui-card role="button" class="Card-small" (click)="goToAudienceAIChatDailog()">
            <div class="beta-tag">BETA</div>
            <div class="card-content-header">
              <div class="selector-icon">
                <i coreUIIcon="wand"></i>
              </div>
            </div>
            <h4 class="card-title">Audience AI</h4>
            <span class="text-style-muted-3">
              Unlock the power of AI to discover and target your ideal audience with precision. Start building smarter, more effective audiences today.
            </span>
          </coreui-card>
        </coreui-card-container>
      </div>
    </div>
  </ng-template>
</coreui-modal>