import { Pipe, PipeTransform } from '@angular/core';
import markdownit from 'markdown-it';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-sql';

const md = markdownit({
  html: true,
  linkify: true,
  typographer: true,
  highlight: function (str: string, lang: string): string {
    let hl;

    try {
      hl = Prism.highlight(str, Prism.languages[lang], lang);
    } catch (error) {
      hl = md.utils.escapeHtml(str);
    }

    return `<pre class="language-${lang}">${hl}</pre>`;
  },
});

@Pipe({
  name: 'highlightCode',
})
export class HighlightCodePipe implements PipeTransform {
  transform(htmlMarkup: string): string {
    const preCodeRegex = /<pre><code>([\s\S]*?)<\/code><\/pre>/g;
    return htmlMarkup.replace(preCodeRegex, function (_match, p1) {
      let codeBlockHighlighted;
      try {
        codeBlockHighlighted = Prism.highlight(
          p1,
          Prism.languages['sql'],
          'sql'
        );
      } catch (error) {
        console.error(error);
        codeBlockHighlighted = md.utils.escapeHtml(p1);
      }
      return `<pre class="language-sql">${codeBlockHighlighted}</pre>`;
    });
  }
}

@Pipe({
  name: 'markdownToHtml',
})
export class MarkdownToHtmlPipe implements PipeTransform {
  transform(markdown: string): string {
    if (!markdown) return '';
    console.log(">>>>> MARKDOWN: ", markdown);
    return md.render(markdown);
  }
}
