<div class ="selectSchema">
    <form class="Core-Form" [formGroup]="tableGroup">
      <coreui-form-field label="Select a schema">
        <coreui-select
          formControlName="fetchSchemas"
          *ngIf="schemas?.length"
          (valueChange)="onSchemaSelect($event)"
          [value]="activeSchema"
        >
          <coreui-option
            *ngFor="let schema of schemas"
            [value]="schema"
          >
            {{ schema.displayName }}
          </coreui-option>
        </coreui-select>
      </coreui-form-field>
      <coreui-form-field>
        <coreui-autocomplete #autocomplete name="autocomplete" placeholder="Search by table name" [displayWith]="displayWith"
            formControlName="searchTableName" (displayValueChange)="onSearchChange($event)" minSearchLength="3">
            <coreui-option *ngFor="let item of dataSetArray" [value]="item">
              {{ item.displayName }}
            </coreui-option>
          <ng-template #noResults>
            No results found...
          </ng-template>
        </coreui-autocomplete>
      </coreui-form-field>
      <span *ngIf="showSearchLimit" style="color:red">Input at least 3 characters to search.</span>
    </form>
    <div>
      <coreui-badge *ngIf="failedDataSets.length > 0" color="Apple" [filled]="true" [subMessage]="failedTpl"  [coreUITooltip]="failedresults">Failed {{failedDataSets.length}} {{failedDataSets.length === 1 ? 'table was not added' : 'tables were not added'}}</coreui-badge>
      <ng-template #failedTpl> Details</ng-template>
      <ng-template #failedresults><div *ngFor="let dataSet of failedDataSets">{{dataSet}}</div></ng-template>
      <br/>
      <span *ngIf="numOfDataSetsAllowedPerReq <= selectedProductDataSetIdSet.size" class="alertTxt">
          You can only add ten tables at a time from this
          dialog. Add these and then return to the dialog to add more.
        <br/>
      </span>
        <span>{{selectedProductDataSetIdSet.size}} table(s) selected
              </span>
        <span class="hideTable">
            <input type="checkbox" (click)="onHideTableHanlder($event)"/> Hide added tables
        </span>
    </div>
    <div class= "dataSetScroll">
        <coreui-accordion (paneChange)="showOrHideAttributes($event)" *ngIf="dataSetArray && dataSetArray.length > 0">
            <ng-container *ngFor="let dataSet of dataSetArray">
              <ng-template #selectedTableTooltip>
                {{ dataSet.id ? 'This table is already added.' : ''}}
              </ng-template>
                <ng-container>
                    <span class="dataSetArray">
                        <input type="checkbox" [checked]="selectedProductDataSetIdSet.has(dataSet.productDataSetId)"
                            (click)="onSelectionOfDataSet($event, dataSet)" [disabled]="disableCheckBox(dataSet)" />
                    </span>
                      <coreui-accordion-pane label="{{dataSet.displayName}}" [coreUITooltip]="selectedTableTooltip" [tooltipEnabled]=" dataSet.id ? true: false">
                        <div *ngFor="let content of dataSet.attributes" class="dataAttributes">
                            <div>
                                <lib-type-icon [type]="content?.dataType.toLowerCase()" [scale]=1.2></lib-type-icon>
                                <span>{{content.displayName}}</span>
                            </div>
                        </div>
                    </coreui-accordion-pane>
                </ng-container>
            </ng-container>
        </coreui-accordion>
    </div>
    <div class="pagination">
      <div class="previousIcon" (click)="previousDataSetBySchema()" [ngClass]="{'disabledPagination': !isPreviousDataSetLinkActive()}">
        <button coreUIButton="icon" class="Core-PaginationLeft Core-Button">
          <i coreUIIcon="chevronLeft" class="previousIcon" scale="2"></i>
        </button>
        <span class="iconText">Previous</span>
      </div>
      <div class="nextIcon" (click)="nextDataSetBySchema()" [ngClass]="{'disabledPagination':!isNextDataSetLinkActive()}">
        <span class="iconText">Next</span>
        <button coreUIButton="icon" class="Core-PaginationRight Core-Button">
          <i coreUIIcon="chevronRight" class="nextIcon" scale="2"></i>
        </button>
      </div>
    </div>
    <div class="footerBtns" [ngClass]="{'disabledPagination': numOfDataSetsAllowedPerReq <= selectedProductDataSetIdSet.size}">
      <button coreUIButton="micro-secondary" (click)="addDataSetToDataUniverse()" class="addAndcloseBtn">Add and
        Close</button>
      <button coreUIButton="micro-secondary" (click)="addDataSetToDataUniverse(false)" class="addAndcontinueBtn">Add and
        Continue</button>
    </div>
</div>

