import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorPageHandlerService } from './error-page-handler.service';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService extends HttpClient {
  constructor(errorPageHandler: ErrorPageHandlerService) {
    super(errorPageHandler);
  }
}
