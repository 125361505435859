import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { auditTime, filter, startWith } from 'rxjs/operators';

import { TableHeaderColumnComponent } from '@epsilon/core-ui';

import { AppState } from '../../../reducers';
import { selectActiveDataUniverse, selectDataUniverses } from '../../../data-universe/data-universe.reducer';
import { DataUniverse } from '../../../data-universe/data-universe.models';
import { ScheduleDetail, ScheduleDetailSearch, ScheduleType } from '../../details/schedules/schedules.models';
import {
  AUDIENCE_TXT,
  DEFINITION_TXT,
  isDefined
} from '../../../utils/utils';
import { SchedulesService } from '../../details/schedules/schedules.service';
import { TableFilterService } from '../table-filter.service';
import {selectCampaignExtractsInitialState} from '../../../campaign-extract/campaign-extract.reducer';
import {DestroyCampaignExtracts} from '../../../campaign-extract/campaign-extract.actions';
import { UtilsService } from '../../../utils/utilservice';

@UntilDestroy()
@Component({
  selector: 'lib-schedule-table',
  templateUrl: './schedule-table.component.html',
  styleUrls: ['./schedule-table.component.sass']
})
export class ScheduleTableComponent implements OnInit, OnDestroy {

  formGroup: UntypedFormGroup;
  contextId: string;
  dataUniverses: DataUniverse[];
  hasMore = false;
  public sortOption = 'name';
  public sortDirection = 'asc';
  scheduleDetailSearch: ScheduleDetailSearch = {scheduleDetails: []};
  searchPayload: {
    dataUniverseId?: string;
    displayName?: string;
    limit: number;
    offset: number;
    scheduleTypes?: string[];
    sort?: string;
    sortBy?: string[];
  } = { limit: 25, offset: 0, sort: 'ASC', sortBy: ['name'] };
  dropdownListActions = [];
  subscription;
  initialLoad = true;

  constructor(public store: Store<AppState>, private scheduleService: SchedulesService, private route: ActivatedRoute, private tableFilterService: TableFilterService, private router: Router, private utilsService: UtilsService) {
    this.dropdownListActions = [
      {
        display: 'View Job Details',
        onClick: (scheduleDetail: ScheduleDetail) => {
          if (scheduleDetail.scheduleType === ScheduleType.EXTRACT_FROM_DEFINITION) {
            this.router.navigate([
              this.utilsService.getProductBaseUrl(this.router, this.route),
              scheduleDetail.cabContextId,
              scheduleDetail.dataUniverseId,
              DEFINITION_TXT,
              'view',
              scheduleDetail.scheduleEntityId
            ]);
          } else {
            this.router.navigate([
              this.utilsService.getProductBaseUrl(this.router, this.route),
              scheduleDetail.cabContextId,
              scheduleDetail.dataUniverseId,
              AUDIENCE_TXT,
              'view',
              scheduleDetail.scheduleEntityId
            ]);
          }
        },
      }];
    this.formGroup = tableFilterService.formGroup;
    this.store
      .select(selectDataUniverses)
      .subscribe((dataUniverses) => (this.dataUniverses = dataUniverses));
  }

  ngOnInit(): void {
    this.store.select(selectCampaignExtractsInitialState).pipe(untilDestroyed(this)).subscribe((initialLoad: boolean) => {
      this.initialLoad = initialLoad;
    });
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.search();

    this.store.select(selectActiveDataUniverse).pipe(filter(isDefined)).subscribe((dataUniverse: DataUniverse) => {
      this.resetSearchPayload();
      this.searchPayload.dataUniverseId = dataUniverse.id === 'all' ? '' : dataUniverse.id;
      if (!this.initialLoad) {
        this.search();
      }
    });

    this.tableFilterService.scheduleTypes$.subscribe((jobTypes: any[]) => {
      const jobTypesBE = [];
      jobTypes.forEach(jobType => {
        jobTypesBE.push(jobType.value);
      });
      this.resetSearchPayload();
      this.searchPayload.scheduleTypes = jobTypesBE;
      if (!this.initialLoad) {
        this.search();
      }
    });

    this.subscription = this.formGroup.get('search').valueChanges.pipe(
      auditTime(250),
      startWith('')).subscribe(searchText => {
      this.resetSearchPayload();
      this.searchPayload.displayName = searchText;
      if (!this.initialLoad) {
        this.search();
      }
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new DestroyCampaignExtracts());
    this.subscription.unsubscribe();
  }

  public getDataUniverseDisplayName(dataUniverseId: string) {
    const dataUniverse = this.dataUniverses.find(
      (dataUniverse) => dataUniverse.id === dataUniverseId
    );
    return dataUniverse?.displayName;
  }

  public sortAscDesc(sortType) {
    return sortType === 'ASC' ? 'ascending' : 'descending';
  }

  public sort(sortComponent: TableHeaderColumnComponent, sortOption) {
    this.sortOption = sortOption;
    this.sortDirection = sortComponent.sortDirection;
    this.searchPayload.sort = this.sortDirection;
    this.searchPayload.sortBy = [this.sortOption];
    this.search();
  }

  search(append?) {
    this.scheduleService.search(this.contextId, this.searchPayload).subscribe(res => {
      if(append) {
        res.scheduleDetails.forEach(scheduleDetail => {
          this.scheduleDetailSearch.scheduleDetails.push(scheduleDetail);
        });
        this.scheduleDetailSearch.hasMore = res.hasMore;
      } else {
        this.scheduleDetailSearch = { ...res };
      }
    });
  }

  public limitReached(){
    if(this.scheduleDetailSearch.hasMore){
      this.searchPayload.offset +=  25;
      this.search(true);
    }
  }

  resetSearchPayload(){
    this.searchPayload.limit = 25;
    this.searchPayload.offset = 0;
    this.scheduleDetailSearch.hasMore = false;
    this.scheduleDetailSearch.scheduleDetails = [];
  }
}
