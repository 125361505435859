<coreui-breadcrumbs >
  <a [routerLink]="isFromAudienceListTable ? audiencesRouter : definitionsRouter" coreUIBreadcrumbLink>{{ isFromAudienceListTable ? 'Audiences' : 'Definitions' }}</a>
  <span coreUIBreadcrumbSlash></span>
  <a [routerLink]="isFromAudienceListTable ? audienceListRouter : audienceDefinitionRouter" coreUIBreadcrumbLink> {{audienceDefinitionName}}</a>
  <span coreUIBreadcrumbSlash></span>
  <a href="javascript:void(0)" coreUIBreadcrumbEnd>Deliver To...</a>
</coreui-breadcrumbs>
<h1 >Deliver To...</h1>
<lib-audience-builder-details class="details" [screen]="'Deliver To'"></lib-audience-builder-details>
<form class="Core-Form" [formGroup]="sendTo">
  <coreui-form-field label="Where do you want to send this?" class="select-scroll-option">
    <coreui-select formControlName="connectors" placeholder="Select" [searchable]="true" (searchValueChange)="filterConnectionByName($event)" (valueChange)="connectionChange($event)">
      <div class="Scrolling-InfiniteSelect" coreUIInfiniteScroll [scrollTarget]="scrollSelect"
      [triggerElementOffset]="500" (scrollLimitReached)="selectLimitReached()" #scrollselect>
        <coreui-option *ngFor="let connection of dataHubConnectionPagination?.results | filter: {key: 'displayName', value: filterConnectionName}" [value]="connection" coreUIInfiniteScrollItem>
          <div class="connector">
            <img class="connector-logo" [src]="fetchConnectorLogos(connection.displayName, connection.connectionType)">{{ connection.displayName }}
          </div>
        </coreui-option>
      </div>
    </coreui-select>
    <coreui-form-error>Required</coreui-form-error>
  </coreui-form-field>
  <span class="text-style-muted-3">Not seeing your connection? You can
    <a *ngIf="tenantId; else notenant" class="new-connector" [routerLink]="connectorsRouter">create a new one in Connectors.</a>
    <ng-template #notenant>create a new one in Connectors.</ng-template>
  </span>

  <div *ngIf="showMappingComponent()">
    <div class="mapping">
      <coreui-radio-group orientation="horizontal" (change)="radioSwitch()">
        <coreui-form-field label="Set up new mapping">
          <input type="radio" coreUIRadio value="new" name="mapping" formControlName="mapping"/>
        </coreui-form-field>
        <ng-container>
          <coreui-form-field label="Copy from previous mapping">
            <input type="radio" coreUIRadio value="previous" name="mapping" formControlName="mapping" [attr.disabled]="!this.sendTo.get('connectors').value || !campaignExtracts?.length ? true : null"/>
          </coreui-form-field>
        </ng-container>
      </coreui-radio-group>
    </div>
    <ng-container *ngIf="sendTo.get('mapping').value === 'previous'">
      <coreui-form-field label="Which previous mapping do you want to copy?">
        <coreui-select formControlName="previousMappings" (valueChange)="populateFieldMappings($event)">
          <coreui-option value=""><span>Select Mapping</span></coreui-option>
          <coreui-option *ngFor="let item of campaignExtracts;" [value]="item" [displayValue]="item.displayName" coreUIInfiniteScrollItem>
            <div>
              <span>{{item.displayName}}</span><br>
              <span class="text-style-muted-3">Count {{item.count !== null ? item.count : '—'}} | Started {{item.createdDate | date: 'MM/dd/YY hh:mm a': 'UTC' }} UTC
              | Completed {{item.lastModifiedDate | date: 'MM/dd/YY hh:mm a': 'UTC' }} UTC</span>
            </div>
          </coreui-option>
        </coreui-select>
      </coreui-form-field>
    </ng-container>
    <ng-container *ngIf="(sendTo.get('mapping').value === 'new' || sendTo.get('previousMappings').value) && loadMappingComponent">
      <lib-flatfile-cab-s3-table [schemaFields$]="sourceMappingAttribute$" [fieldMappings$]="fieldMappings$" (fieldMappings)="updateFieldMappings($event)"></lib-flatfile-cab-s3-table>
    </ng-container>
  </div>

  <div hidden>
    <h3 class="schedule">Scheduling</h3>
    <div coreUIDivider class="divider"></div>
    <coreui-radio-group label="When do you want this audience to be sent?" orientation="vertical">
      <coreui-form-field label="On a schedule">
        <input type="radio" coreUIRadio value="schedule" name="scheduling" formControlName="scheduling" [disabled]="true"/>
      </coreui-form-field>
      <coreui-form-field label="On demand">
        <input type="radio" coreUIRadio value="demand" name="scheduling" formControlName="scheduling" />
      </coreui-form-field>
    </coreui-radio-group>
  </div>
</form>

<div coreUIButtonGroup class="send">
  <button coreUIButton (click)="send()" [disabled]="!sendTo.valid">Send</button>
  <button coreUIButton="secondary" (click)="backToDefinitions()">Cancel</button>
</div>
<coreui-notification notificationStyle="error" #sendToOwnedError>
  {{errorMessage}}
</coreui-notification>


<coreui-modal modalWidth="40" #resetFieldMappingsWarning>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon warning"></span> Your Attribute Mappings Will Be Lost!
  </ng-template>

  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="cancel()" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>

  <ng-template #contentTemplate>
    <p>
      You will lose your attribute mappings if you switch.
      <br><br>
      Are you sure you want to make this change?
    </p>
  </ng-template>

  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="cancel()">
      Cancel
    </button>
    <button coreUIButton (click)="continue()">
      Continue
    </button>
  </ng-template>
</coreui-modal>
