import { Component, Input, OnChanges } from '@angular/core';
import { typeToCuikIconRef as iconMap } from '../../../utils/type-icon-utils';


@Component({
  selector: 'lib-type-icon',
  templateUrl: './type-icon.component.html',
  styleUrls: ['./type-icon.component.sass'],
})
export class TypeIconComponent implements OnChanges {
  @Input() type: string;
  @Input() scale = '1.5';

  icon: string;
  tooltipMessage: string;
  color: string;
  customClass: string;
  constructor() {
    // TypeIconComponent constructor
  }

  ngOnChanges(): void {
    const type = this.type?.toLowerCase();
    const details = iconMap.get(type);
    this.icon = details?.icon;
    this.color =details?.color;
    this.customClass =details?.customClass;
    if(details?.tooltip === 'Audience Definition' || details?.tooltip === 'Audience List') {
      this.tooltipMessage = `${details?.tooltip}`;
      return;
    } else if (type === 'exclude' || type === 'include') {
      return;
    } else {
      this.tooltipMessage = `Attribute: ${details?.tooltip}`;
    }
  }
}
