import {
  AudienceExpression,
  AudienceExpressionGroup,
  AudienceExpressionOperand,
  AudienceRuleGroup,
  BuilderAudience,
} from '../../audience-builder/audience-builder.models';

export const mockDataTypeOperators = [
  {
    dataType: 'Number',
    displayName: 'Number',
    dataUniverseId: '12345678',
    id: 'Number',
    operators: [
      {
        displayName: 'between',
        displayText: null,
        sortOrder: 1,
        singleOperand: true,
        dualValue: true,
        multiValue: false,
        comments: 'and',
        separator: false,
        operator: 'BETWEEN',
        attributeAsAdditionalOperand: false,
        relativeOperand: false
      },
    ],
  },
];

export const mockExpressionOperandFirst: AudienceExpressionOperand = {
  valueType: 'Attribute',
  id: '2v2o',
  name: 'age 18-24',
  dataSetId: '2v2n',
};

export const mockExpressionOperandSecond: AudienceExpressionOperand = {
  dataType: 'Number',
  values: ['1', '40'],
  valueType: 'Literal',
};

export const mockExpression: AudienceExpression = {
  expressionType: 'BasicComparison',
  firstOperand: mockExpressionOperandFirst,
  comparisonOperator: 'BETWEEN',
  secondOperand: mockExpressionOperandSecond,
};

export const mockExpressionGroup: AudienceExpressionGroup = {
  displayName: 'mock-attribute',
  expression: mockExpression,
  logicalOperator: 'AND',
};

export const mockRuleGroupEmpty: AudienceRuleGroup = {
  logicalOperator: 'AND',
  typeName: 'Include',
  group: [],
};

export const mockRuleGroup: AudienceRuleGroup = {
  logicalOperator: 'AND',
  typeName: 'Include',
  group: [mockExpressionGroup, mockRuleGroupEmpty],
};

export const mockBuilderAudience: BuilderAudience = {
  id: '5',
  displayName: 'mock audience definition',
  selectExpressions: null,
  includeConditions: [mockRuleGroup],
  excludeConditions: [mockRuleGroup],
  dedupeIdentityType: 'Email_Profile_ID',
  idCount: null,
  countUpdatedOn: null,
  countRequestedAt: null,
  version: 0,
};
