import { Injectable } from '@angular/core';
import { Adapter } from '../../../services/adapter';
import { FieldMapping } from '@epsilon-cdp/connector-lib/mapping';
import { Mapping } from './send-to.models';
import { Path } from './send-to.models';

@Injectable()
export class FieldMappingsAdapter implements Adapter<Mapping[], FieldMapping[]> {

  adapt(mappings: Mapping[]): FieldMapping[] {
    const fieldMappingArray = mappings?.map((mapping: Mapping) => {
      let profilePath: Path;
      mapping.sourceNode.path.forEach((dataSet: Path): void =>{
        if (dataSet.displayName === 'Profile') {
          profilePath = dataSet;
        }
      });
      const childCabId = profilePath?.['childNodeRelationInfo']?.['nodeRelationAttributes']?.[0]?.['childCabId'];
      const childDisplayName = profilePath?.['childNodeRelationInfo']?.['nodeRelationAttributes']?.[0]?.['childDisplayName'];
      let name = '';
      for (const path of mapping.sourceNode.path) {
        name += path.cabId + '-';
      }
     name += mapping.sourceNode.cabId+'-';
     name += mapping.sourceNode.displayName;
     if (childDisplayName) {
      name = name.concat('-', childDisplayName);
     }
      const type = mapping.sourceNode.dataType;
      const metadata = {
        cabId: mapping.sourceNode.cabId,
        dataSetType: mapping.sourceNode.dataSetType,
        displayName: mapping.sourceNode.displayName,
        id: mapping.sourceNode.id,
        path: mapping.sourceNode.path,
        childCabId,
        nativeDataType: mapping.sourceNode.nativeDataType
      };
      return {
        sourceField: name,
        sourceFieldHeader: { name, type, metadata },
        targetField: mapping.targetColumn
      } as FieldMapping;
    });
    return fieldMappingArray;
  }
}
