import { Action } from '@ngrx/store';

export type UnsavedChangesState = 'pending' | 'checking' | 'confirmed' | null;

export class SetUnsavedChangesState implements Action {
  static readonly type = 'APPLICATION:SET_UNSAVED_CHANGES_STATE';
  readonly type = SetUnsavedChangesState.type;

  constructor(public payload: UnsavedChangesState) {}
}

export type ReducerActions = SetUnsavedChangesState;
