import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TabChangeEvent } from '@epsilon/core-ui';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { get } from 'lodash-es';
import { AppState } from '../reducers';
import { selectDataUniverses } from '../data-universe/data-universe.reducer';
import { DataUniverse } from '../data-universe/data-universe.models';
import { DataSetResults } from './admin.models';
import { UtilsService } from '../utils/utilservice';

@UntilDestroy()
@Component({
    selector: 'lib-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.sass']
  })

export class AdminComponent implements OnInit {
  dataUniverses: DataUniverse[];
  activeDataUniverse: DataUniverse;
  showAddTableDrawer: boolean;
  contextId: string;
  @ViewChild('successProductDataSetIds', { static: true }) public successProductDataSetIds;
  @ViewChild('failedProductDataSetIds', { static: true }) public failedProductDataSetIds;

  drawerContentResults: any = {};

  public formGroup = new UntypedFormGroup({
    dataUniverse: new UntypedFormControl({
        value: '',
        disabled: true
    }),
  });
  activeTab = 'admin';

  constructor(
    public store: Store<AppState>,
    public route: ActivatedRoute,
    public router: Router,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    if(this.router.routerState.snapshot.url.includes('enum')) {
      this.activeTab = 'enumeration';
    }
    this.store.select(selectDataUniverses).subscribe((dataUniverses: any) => {
      this.dataUniverses = dataUniverses.filter((item) => {
        return item.displayName !== 'All Data Universes';
      });
      if(this.dataUniverses?.length > 0){
        this.activeDataUniverse = this.dataUniverses[0];
        this.formGroup.get('dataUniverse').setValue(this.activeDataUniverse);
        this.formGroup.get('dataUniverse').enable();
      }
    });
  }

  tabChange(event: TabChangeEvent) {
    const label = get(event, ['tab', 'label']);
    if (label === 'Tables') {
      this.router.navigate([this.utilsService.getProductBaseUrl(this.router, this.route), this.contextId, 'admin']);
    } else {
      this.router.navigate([this.utilsService.getProductBaseUrl(this.router, this.route), this.contextId, 'admin', 'enumerations']);
    }
  }

  onAddTableHandler(){
    this.formGroup.get('dataUniverse').disable();
    this.showAddTableDrawer = true;
  }

  onHideTableHandler(){
    this.formGroup.get('dataUniverse').enable();
  }

  onDataUniverseSelect(dataUniverse: DataUniverse) {
    if (dataUniverse && dataUniverse.id !== this.activeDataUniverse?.id) {
      this.activeDataUniverse = dataUniverse;
    }
  }

  onCloseDrawer($event: DataSetResults) {
    this.drawerContentResults = $event;
    if ($event.successProductDataSetIds && $event.successProductDataSetIds.length > 0) {
      this.successProductDataSetIds.show();
    }
    if ($event.failedProductDataSetIds && $event.failedProductDataSetIds.length > 0) {
      this.failedProductDataSetIds.show();
    } else {
      if($event.shouldCloseTheDrawer){
        this.showAddTableDrawer = false;
        this.formGroup.get('dataUniverse').enable();
      }
    }
  }
}

