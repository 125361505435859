import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FileUploadOptions, FileUploadService } from '@epsilon/core-ui';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map as _map } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { fetchResource } from '../utils/fetch-state';
import {
  DataSet,
  DataSets,
  DataSetAttribute,
  SchemaData,
  Schema,
  EnumData,
  EnumSet,
  EnumerationData
} from './admin.models';
import * as actions from '../admin/admin.actions';
import { CabHttpService, ContextType } from '../services/cab-http.service';
import { CabConstants } from '../cab.constants';

export const FILE_UPLOAD_OPTIONS: FileUploadOptions = {
  url: 'http://localhost:8000/upload',
  formFieldName: 'file',
  options: {
    params: new HttpParams().set('Authorization', '123-XYZ'),
    responseType: 'arraybuffer'
  }
};


@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AdminService extends FileUploadService {

  fetchSchemas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GetSchemas.type),
      fetchResource((action) =>
        this.fetchSchemas(action.cabContextId, action.dataUniverseId).pipe(
          map(
            (schemaData) =>
              new actions.LoadSchemas(schemaData)
          )
        )
      )
    )
  );

  fetchTablesBySchema$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FetchTablesBySchema.type),
      fetchResource((action) =>
        this.fetchTablesBySchema(action.fetchTablesBySchemaPayload).pipe(
          map(
            (dataSets: DataSets) =>
              new actions.LoadSchemaTables(dataSets)
          )
        )
      )
    )
  );

  fetchTablesByDataUniverse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FetchTablesByDataUniverse.type),
      fetchResource((action) =>
        this.fetchTablesByDataUniverse(action.fetchTablesByDataUniversePayload).pipe(
          map(
            (dataUniverseTables) =>
              new actions.LoadDataUniverseTables(dataUniverseTables)
          )
        )
      )
    )
  );

  removeTablesByDataUniverse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.RemoveTableByDatasetId.type),
      fetchResource((action) =>
        this.deleteDataset(action.cabContextId, action.datasetId).pipe(
          map(
            () =>
              new actions.FetchTablesByDataUniverse(action.fetchTablesByDataUniversePayload)
          )
        )
      )
    )
  );

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private httpService: CabHttpService
  ) {
    super(http, FILE_UPLOAD_OPTIONS);
  }


  fetchSchemas(contextId: string, dataUniverseId: string): Observable<SchemaData> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/dataset/schemas?dataUniverseId=${dataUniverseId}`);
    return this.http.get(url, { headers })
      .pipe(
        map((res: any) => {
          const schemas = _map(res, (schema) => new Schema(schema));
          return { schemas };
        })
      );
  }

  getCabDataSet(contextId: string, dataSetId: string): Observable<DataSet> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/dataset/${dataSetId}`);
    return this.http.get(url, { headers }).pipe(
      map(res => new DataSet(res)));
  }

  getSchemaUrl(): string {
    return `${this.getBaseUrl()}/schemas`;
  }

  private getBaseUrl(): string {
    return this.httpService.apiUrl(ContextType.CAB, '/cab/v1/admin/dataset');
  }


  fetchTablesBySchema(searchPayload: any): Observable<DataSets> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = searchPayload.cabContextId;
    return this.http
      .post(`${this.getBaseUrl()}/raw-datasets`, searchPayload, { headers })
      .pipe(
        map((res: any) => {
          const dataSetArray = _map(res.results, (dataSet) => new DataSet(dataSet));
          return { dataSetArray, hasMore: res.hasMore };
        })
      );
  }

  fetchTableAttributes(contextId: string, searchPayload: any): Observable<DataSetAttribute[]> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .post(`${this.getBaseUrl()}/raw-dataset-details`, searchPayload, { headers })
      .pipe(
        map((res: any) => {
          const dataSetAttributeArray = _map(res, (dataset) => new DataSetAttribute(dataset));
          return dataSetAttributeArray;
        })
      );
  }

  fetchTablesByDataUniverse(searchPayload: any): Observable<DataSets> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = searchPayload.cabContextId;
    return this.http
      .post(`${this.getBaseUrl()}/search`, searchPayload, { headers })
      .pipe(
        map((res: any) => {
          return { dataSetArray: _map(res.results, (dataSet) => new DataSet(dataSet)), hasMore: res.hasMore };
        })
      );
  }

  addDataSetToDataUniverse(contextId: string, dataUniverseId: string, productDataSetIdArray: string[]): Observable<any> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .post(`${this.getBaseUrl()}/product-datasets?dataUniverseId=${dataUniverseId}`, productDataSetIdArray, { headers })
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

  fetchDatasetAttributesByDatasetId(contextId: string, dataSetId: string): Observable<DataSetAttribute[]> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/dataset/${dataSetId}`);
    return this.http.get(url, { headers })
      .pipe(
        map((res: any) => {
          const dataSetAttributeArray = _map(res.attributes, (dataset) => new DataSetAttribute(dataset));
          return dataSetAttributeArray;
        })
      );
  }

  updateDatasetInformation(contextId: string, dataSetId: string, updateDatasetPayload: any): Observable<any> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/dataset/${dataSetId}`);
    return this.http.put(url, updateDatasetPayload, { headers });
  }

  deleteDataset(contextId: string, dataSetId: string): Observable<any> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/dataset/${dataSetId}`);
    return this.http.delete(url, { headers });
  }

  fetchListOfEnums(contextId: string, enumPayload): Observable<EnumData> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/entity-enum/search`);
    return this.http.post(url, enumPayload, { headers }).pipe(
      map((res: any) => {
        const enumsetArray = _map(res.results, (enumSet) => new EnumSet(enumSet));
        return new EnumData({ hasMore: res.hasMore, totalCount: res.totalCount, enumSets: enumsetArray });
      })
    );
    
  }

  createEnum(contextId: string, enumPayload): Observable<EnumSet> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    let url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/entity-enum`);
    const enumId = enumPayload.id;
    if (enumId) {
      url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/entity-enum/${enumId}`);
      return this.http.put(url, enumPayload, { headers }).pipe(map((res: any) => {
        return res.entity;
      }));
    } else {
      return this.http.post(url, enumPayload, { headers }).pipe(map((res: any) => {
        return res.entity;
      }));
    }
  }

  fetchRelations(contextId: string, dataUniverseId: string, dataSetId: string): Observable<any> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/dataset/${dataSetId}/getRelations?dataUniverseId=${dataUniverseId}`);
    return this.http.get(url, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateRelations(contextId: string, dataUniverseId: string, dataSetId: string, payload: any): Observable<any> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/dataset/${dataSetId}/createRelations?dataUniverseId=${dataUniverseId}`);
    return this.http.post(url, payload, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  fetchAllDatasets(contextId: string, dataUniverseId: string): Observable<DataSets> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/dataset?dataUniverseId=${dataUniverseId}`);
    return this.http.get(url, { headers })
      .pipe(
        map((res: any) => {
          return { dataSetArray: _map(res.results, (dataSet) => new DataSet(dataSet)), hasMore: res.hasMore };
        })
      );
  }

  getListOfDependentAudienceDefinitions(contextId: string, dataSetId: string, dataUniverseId: string): Observable<any> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/dataset/${dataSetId}/dependentDefinitions?dataUniverseId=${dataUniverseId}`);
    return this.http.get(url, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getEnumAttributeDetails(contextId: string, entityEnumId: string): Observable<EnumerationData> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/entity-enum/${entityEnumId}/attributes-details`);
    return this.http.get(url, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateAssociatedAttributes(contextId: string, entityEnumId: string, payload: any): Observable<any> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/entity-enum/${entityEnumId}/associate-attributes`);
    return this.http.put(url, payload, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  removeEntityEnum(cabContextId: string, entityEnumId: string): Observable<any> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = cabContextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/entity-enum/${entityEnumId}`);
    return this.http.delete(url, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getEnumData(contextId: string, enumId: string): Observable<EnumSet> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/entity-enum/${enumId}`);
    return this.http.get(url, { headers }).pipe(
      map((res: EnumSet) => {
        return res;
      })
    );
  }

  createEnumFromFile(contextId: string, file: File, enumPayload): Observable<any> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    let url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/entity-enum/entity-enum-values`);
    const formData = new FormData();
    formData.append('entityEnumValuesFile', file);
    const enumId = enumPayload.id;
    if (enumId) {
      formData.append('entityEnumUpdate ', new Blob([JSON.stringify(enumPayload)], {type: 'application/json'}));
      url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/admin/entity-enum/${enumId}/entity-enum-values`);
      return this.http.post(url, formData, { headers })
        .pipe(
          map((res: any) => {
            return res;
          })
        );
    } else {
      formData.append('entityEnum', new Blob([JSON.stringify(enumPayload)], {type: 'application/json'}));
      return this.http.post(url, formData, { headers })
        .pipe(
          map((res: any) => {
            return res;
          })
        );
    }
  }
}
