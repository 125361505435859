<coreui-modal #modal>
  <ng-template #titleTemplate>
    <h1>View Audience {{ displayType | titlecase }} Details</h1>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" aria-label="Close" (click)="detailsModalService.close(modal)" coreUIButton="icon-large"><i coreUIIcon="close"></i></button>
  </ng-template>

  <ng-template #contentTemplate>
    <div class="details-model-content" *ngIf="!audience?.id">
      <coreui-load-mask [active]="true">
      </coreui-load-mask>
    </div>
    <ng-container *ngIf="audience?.id">
      <coreui-breadcrumbs class="breadcrumb">
        <ng-template #linkBreadcrumb let-audience="audience" let-index="index">
          <a
            coreUIBreadcrumbLink
            class="name"
            (click)="detailsModalService.showModalHistory(index)"
          >
            <lib-type-icon [type]="dataType"></lib-type-icon>
            <span>{{
              detailsModalService.history[index]?.attribute?.displayName
            }}</span>
          </a>
          <span coreUIBreadcrumbSlash></span>
        </ng-template>

        <ng-template #lastBreadcrumb let-audience="audience">
          <span coreUIBreadcrumbEnd>
            <span class="iconDataType">
            <lib-type-icon [type]="dataType"></lib-type-icon>
            </span>
            {{ audience?.displayName }}
          </span>
        </ng-template>

        <ng-template #ellipsisBreadcrumb>
          <coreui-menu
            coreUIBreadcrumbMenu
            menuButtonStyle="link"
            icon="ellipsis"
            menuAlignment="left"
            showMenuAction="hover"
          >
            <coreui-menu-section
              *ngFor="
                let item of detailsModalService.history.slice(1, -1);
                let index = index
              "
            >
              <a
                class="breadcrumb-menu"
                coreUIMenuItem
                (click)="detailsModalService.showModalHistory(index + 1)"
              >
                <lib-type-icon [type]="dataType"></lib-type-icon>
                {{ item?.['displayName'] }}
              </a>
            </coreui-menu-section>
          </coreui-menu>
          <span coreUIBreadcrumbSlash></span>
        </ng-template>

        <ng-container
          *ngIf="
            detailsModalService.history.length === 1;
            else multipleAudienceBreadcrumb
          "
        >
          <ng-container
            *ngTemplateOutlet="lastBreadcrumb; context: { audience: audience }"
          ></ng-container>
        </ng-container>

        <ng-template #multipleAudienceBreadcrumb>
          <ng-container
            *ngTemplateOutlet="
              linkBreadcrumb;
              context: {
                audience: detailsModalService.history[0]?.attribute,
                index: 0
              }
            "
          ></ng-container>

          <ng-container *ngIf="detailsModalService.history.length === 3">
            <ng-container
              *ngTemplateOutlet="
                linkBreadcrumb;
                context: {
                  audience: detailsModalService.history[1]?.attribute,
                  index: 1
                }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngIf="detailsModalService.history.length > 3">
            <ng-container *ngTemplateOutlet="ellipsisBreadcrumb"></ng-container>
          </ng-container>

          <ng-container
            *ngTemplateOutlet="lastBreadcrumb; context: { audience: audience }"
          ></ng-container>
        </ng-template>
      </coreui-breadcrumbs>

      <lib-detail-body
        class="detail-body"
        [dataTypeOperators]="dataTypeOperators$ | async"
        [audience]="audience"
        [dataUniverse]="dataUniverse"
        [isAudience]="isAudience"
        [dedupeDisplayName]="dedupeDisplayName"
        [attributeDetails]="detailsModalService.attributeDetails$ | async"
      >
      </lib-detail-body>
    </ng-container>
  </ng-template>

</coreui-modal>
