import { CabHttpService, ContextType } from '../../../services/cab-http.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ScheduleDetailSearch, transformScheduleDetail } from './schedules.models';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SchedulesService {
  constructor(private http: HttpClient, private httpService: CabHttpService) {}

  updateScheduleState(contextId: string, scheduleId: string, updatedState: boolean): Observable<any> {
    const headers = {};
    headers['x-cab-context'] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/schedule/updateScheduleState/${scheduleId}?enable=${updatedState}`);
    return this.http.put(url, {}, { headers });
  }

  deleteSchedule(contextId: string, audienceDefinitionId: string, scheduleId: string ): Observable<any> {
    const headers = {};
    headers['x-cab-context'] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/schedule/${audienceDefinitionId}/deleteSchedule/${scheduleId}`);
    return this.http.delete(url, { headers });
  }

  search(contextId: string, payload: any): Observable<ScheduleDetailSearch> {
    const headers = {};
    headers['x-cab-context'] = contextId;
    const url = this.httpService.apiUrl(ContextType.CAB, `/cab/v1/schedule/search`);
    return this.http.post(url, payload, { headers }).pipe(map((res: any) => {
      res.results = transformScheduleDetail(res.results);
      return  {scheduleDetails: res.results, hasMore: res.hasMore} as ScheduleDetailSearch;
    }));
  }
}
