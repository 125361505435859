import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {

  private amsProxyURL: string;
  private cabContextId: string;

  public amsConfigDetails$ = new BehaviorSubject({cabContextId: '', amsProxyURL: ''});

  constructor() {
    this.amsConfigDetails$.subscribe((value: {cabContextId: string, amsProxyURL: string}) => {
      this.amsProxyURL = value.amsProxyURL;
      this.cabContextId = value.cabContextId;
    });
  }
  getProductBaseUrlForCabLib(): string {
    return 'cab-wrapper';
  }

  getAMSProxyURL(): string {
    return this.amsProxyURL;
  }

  getCABContextId(): string {
    return this.cabContextId;
  }
}
