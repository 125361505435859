import { mockDataTypeOperators } from './audience-builder-mocks';

export const mockStoreData = {
  fetchStates: {},
  tenant:{tenant:{}},
  campaignExtracts:{},
  context: {
    context: {
      createdBy: 'user1',
      createdDate: '2021-01-01T00:00:00.000Z',
      defaultDedupeBy: 'id',
      id: '1',
      name: 'Context_Name',
      displayName: 'Context Name',
      lastModifiedBy: 'user1',
      lastModifiedDate: '2021-01-01T00:00:00.000Z',
      pluginApiUrl: 'some_plugin_url',
      productType: 'Customer: MoveIt Shoes',
      productUrl: 'some_product_url',
      tenantId: 'EPCC',
      version: 0,
      exportAudience: true
    }
  },
  dataUniverse: {
    dataUniverses: [],
    activeDataUniverse: {},
  },
  audiences: {
    audienceDefinitions: {},
    audiences: {},
  },
  audienceBuilder: {
    attributeDetails: {},
    dataTypeOperators: mockDataTypeOperators,
    prebuiltAudience: null,
  },
};
