import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { DataSet, DataSetAttribute } from '../../admin.models';
import { AdminService } from '../../admin.service';

@UntilDestroy()
@Component({
  selector: 'lib-dataset-sync-modal',
  templateUrl: './dataset-sync-modal.component.html',
  styleUrls: ['./dataset-sync-modal.component.sass'],
})
export class DatasetSyncModalComponent implements AfterViewInit, OnInit {
  @ViewChild('syncModal') public syncModal;
  @Input() dataset: DataSet;
  @Output() syncModalClosed = new EventEmitter<string>();
  modalSyncForm: UntypedFormGroup = this.formBuilder.group({
  });
  toSyncAttributes: DataSetAttribute[];
  newlyAddedAttributes: DataSetAttribute[];
  sourceAttributes: DataSetAttribute[];
  loadSpinner = false;

  constructor(
    public store: Store<AppState>,
    public formBuilder: UntypedFormBuilder,
    public adminService: AdminService
  ) {}

  ngOnInit(): void {
    const cabContextId = this.dataset?.cabContextId;
    const fetchAttributesPayload = {
      dataSetId: this.dataset?.id,
      dataUniverseId: this.dataset?.dataUniverseId,
      displayName: this.dataset?.displayName,
      name: this.dataset?.name,
      productDataSetId: this.dataset?.productDataSetId
    };
    this.loadSpinner = true;
    this.adminService.fetchTableAttributes(cabContextId, fetchAttributesPayload).subscribe({
      next: (result) => {
        this.sourceAttributes = result;
        this.compareAttributesForSync();
      },
      error: () => {
        this.loadSpinner = false;
      },
  })
    this.adminService.fetchDatasetAttributesByDatasetId(cabContextId, this.dataset?.id).subscribe({
      next: (result) => {
        this.toSyncAttributes = result;
        this.compareAttributesForSync();
      },
      error: () => {
        this.loadSpinner = false;
      }
    });
  }

  ngAfterViewInit() {
    this.syncModal.show();
  }

  compareAttributesForSync() {
    if(this.sourceAttributes?.length > 0 && this.toSyncAttributes?.length > 0) {
      this.loadSpinner = false;
    }
    this.newlyAddedAttributes = this.sourceAttributes?.filter(attribute1 => {return !this.toSyncAttributes?.some(attribute2 => {return attribute1.id === attribute2.id;});});
  }

  closeModal(syncStatus?: string) {
    this.syncModal.hide();
    this.syncModalClosed.emit(syncStatus);
  }

  syncAttributes() {
    const updateDatasetPayload = {
      attributes: this.toSyncAttributes.concat(this.newlyAddedAttributes),
      dataUniverseId: this.dataset.dataUniverseId,
      displayName: this.dataset.displayName,
      id: this.dataset.id,
      productDataSetInfo: this.dataset.productDataSetInfo,
      updateType: 'BASIC_AND_ATTRIBUTES',
      cabContextId: this.dataset.cabContextId,
      version: this.dataset.version
    };
    this.adminService.updateDatasetInformation(this.dataset.cabContextId, this.dataset.dataUniverseId, updateDatasetPayload).subscribe(res => {
      console.log('verifying update', res);
    });
  }
}
