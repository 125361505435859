<coreui-modal modalWidth="40" #definitionTabChangeModal>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="discardChangeIcon"></span> You have unsaved changes!
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" aria-label="Close" (click)="backToEditing()" coreUIButton="icon-large"><i coreUIIcon="close"></i></button>
</ng-template>
  <ng-template #contentTemplate>
    <p>You will lose any work you've done if you continue to leave this page. Are you sure you want to proceed?</p>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button type="button" coreUIButton="secondary" (click)="backToEditing()">
      Back to Editing
    </button>
    <button type="button" coreUIButton="secondary" (click)="discardAndContinue()">
      Discard & Continue
    </button>
  </ng-template>
</coreui-modal>