import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { remove as _remove } from 'lodash-es';

import { NavTreeNode, NavTreeNodeStatus } from '@epsilon/pcbc-nav-tree';

import { CabHttpService, ContextType } from '../services/cab-http.service';
import { httpAbortLoadIndicator } from '../utils/utils';
import {
  FEATURE_NESTED_AUDIENCES,
  FEATURE_NESTED_DEFINITIONS,
} from '../utils/feature-utils';
import { PaginationResponse } from './picker.service.model';
import { DedupeType } from '../models/dedupe-type';
import { FeatureService } from '../utils/feature-service';
import { UtilsService } from '../utils/utilservice';

@Injectable()
export class PickerService {
  nestedAudiencesEnabled = this.featureService.isFeatureEnabled(
    FEATURE_NESTED_AUDIENCES
  );
  nestedDefinitionsEnabled = this.featureService.isFeatureEnabled(
    FEATURE_NESTED_DEFINITIONS
  );

  constructor(
    private http: HttpClient,
    private httpService: CabHttpService,
    public featureService: FeatureService,
    private utilsService: UtilsService
  ) {}

  mockedOptionsUrl(
    dataUniverseId: string,
    parentId: string,
    offset?: number
  ): string {
    // offset = number of last attribute
    return this.httpService.apiUrl(
      ContextType.DISCOVERY,
      `/id_analytics_api/cab/v1/audience-builder/${dataUniverseId}/hierarchy${
        parentId ? `/${parentId}` : ''
      }${offset ? '?offset=' + offset : ''}`
    );
  }

  metaUrl(
    dataUniverseId: string
  ): string {
    return this.httpService.apiUrl(
      ContextType.CAB,
      `/cab/v1/audience-builder/${dataUniverseId}/hierarchy`
    );
  }

  metaUrlHeaders(): Record<string, any> {
    return {};
  }

  fetchAttributes(
    contextId: string,
    dataUniverseId: string,
    dedupeId: DedupeType,
    recordLimit: number,
    path: any[],
    parentId?: string,
    offset?: number,
    searchId?: string
  ): Observable<PaginationResponse> {
    if (this.utilsService.isMocked()) {
      const params = httpAbortLoadIndicator({ cabContextId: contextId });
      return this.http
        .get<PaginationResponse>(
          this.mockedOptionsUrl(dataUniverseId, parentId, offset),
          { params }
        )
        .pipe(
          map((res) => this.parseAudienceResponse(res)),
          filter((parsedResp) =>
            this.validateForNestedFeatures(parsedResp.results)
          )
        ) as Observable<any>;
    } else {
      const headers = this.metaUrlHeaders();
      headers['x-cab-context'] = contextId;
      const requestBody = {
        dedupeIdentityType: dedupeId,
        limit: recordLimit,
        offset: offset ? offset : 0,
        cabId: parentId,
        path,
        searchId
      };

      return this.http
        .post<PaginationResponse>(
          this.metaUrl(dataUniverseId),
          requestBody,
          { headers }
        )
        .pipe(
          map((res) => this.parseAudienceResponse(res))
        ) as Observable<PaginationResponse>;
    }
  }

  searchAttributes(
    contextId: string,
    dataUniverseId: string,
    dedupeId: DedupeType,
    searchBy: string,
    nodeType: string,
    cabId: string,
    path?: any,
    recordLimit?: number,
    startIndex?: number,
    searchId?: string
  ): Observable<PaginationResponse> {
    const headers = this.metaUrlHeaders();
    headers['x-cab-context'] = contextId;

    const requestBody = {
      dedupeIdentityType: dedupeId,
      searchBy,
      nodeType,
      offset: startIndex ?? 0,
      limit: recordLimit ?? 100,
      cabId,
      searchId,
      ...path && {path}
    };

    const url = this.httpService.apiUrl(
      ContextType.CAB,
      `/cab/v1/audience-builder/${dataUniverseId}/search`
    );
    return this.http
      .post<PaginationResponse>(url, requestBody, { headers })
      .pipe(
        map((res) => this.parseAudienceResponse(res, true))
      ) as Observable<PaginationResponse>;
  }

  private parseAudienceResponse(
    pickerData: PaginationResponse,
    isAttributeSearch = false
  ): PaginationResponse {
    const childNodes = pickerData.results.map((attribute) =>
      isAttributeSearch
        ? this.transformAttributeSearchToTreeNode(attribute)
        : this.transformToTreeNode(attribute)
    );
    pickerData.results = childNodes;
    return pickerData;
  }

  private transformAttributeSearchToTreeNode(nodeResponse: any): NavTreeNode {
    let child = [];
    if (!nodeResponse.leaf) {
      const nodeChildren = nodeResponse.children;
      child = nodeChildren.map(this.transformToTreeNode) || [];
    }

    return {
      ...nodeResponse,
      id: nodeResponse.id,
      name: nodeResponse.displayName,
      type: nodeResponse.dataType,
      children: nodeResponse.leaf ? undefined : child,
      status:
        nodeResponse.hasMore || !nodeResponse.leaf
          ? NavTreeNodeStatus.PARTIAL
          : NavTreeNodeStatus.COMPLETE,
      data: nodeResponse,
    } as NavTreeNode;
  }

  private transformToTreeNode(nodeResponse: any): NavTreeNode {
    return {
      ...nodeResponse,
      id: nodeResponse.id,
      name: nodeResponse.displayName,
      type: nodeResponse.dataType,
      children: nodeResponse.leaf
        ? undefined
        : nodeResponse.results?.map(this.transformToTreeNode) || [],
      status:
        nodeResponse.hasMore || !nodeResponse.leaf
          ? NavTreeNodeStatus.PARTIAL
          : NavTreeNodeStatus.COMPLETE,
      data: nodeResponse,
    } as NavTreeNode;
  }

  private validateForNestedFeatures(nodes: NavTreeNode[]): any {
    if (!this.nestedAudiencesEnabled) {
      _remove(nodes, (node) => node['cabId'] === 'cab:audlistcat:audlistcat');
    }
    if (!this.nestedDefinitionsEnabled) {
      _remove(
        nodes,
        (node) => node['cabId'] === 'cab:auddefinitioncat:auddefinitioncat'
      );
    }
    return nodes;
  }
}
