import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest as observableCombineLatest, Observable, Subject } from 'rxjs';
import {
  BE_STATUS_COMPLETE, BE_STATUS_ERROR, BE_STATUS_MONITOR_PROCESSING, BE_STATUS_SUCCESS,
  isDefined,
  MONITOR_TXT,
  UI_STATUS_COMPLETE, UI_STATUS_ERROR
} from '../../../utils/utils';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { TableHeaderColumnComponent } from '@epsilon/core-ui';
import { UntypedFormGroup } from '@angular/forms';
import { TableFilterService } from '../table-filter.service';
import { DataUniverse } from '../../../data-universe/data-universe.models';
import { selectActiveDataUniverse, selectDataUniverses } from '../../../data-universe/data-universe.reducer';
import {DestroyCampaignExtracts, SearchCampaignExtracts} from '../../../campaign-extract/campaign-extract.actions';
import { auditTime, filter, map, startWith, tap } from 'rxjs/operators';
import { values } from 'lodash-es';
import { User } from '../../../user/user.models';
import { CampaignExtract, CampaignExtractStatus } from '../../../campaign-extract/campaign-extract.model';
import moment from 'moment';
import { selectUser } from '../../../user/user.reducer';
import {
  selectCampaignExtracts,
  selectCampaignExtractsInitialState,
  selectHasMore
} from '../../../campaign-extract/campaign-extract.reducer';
import { LoadingService } from '../../../services/loading.service';
import { UtilsService } from '../../../utils/utilservice';

@UntilDestroy()
@Component({
  selector: 'lib-monitor-table',
  templateUrl: './monitor-table.component.html',
  styleUrls: ['./monitor-table.component.sass']
})
export class MonitorTableComponent implements OnInit, OnDestroy {

  formGroup: UntypedFormGroup;
  userDetails: User;
  filteredCampaignExtracts: CampaignExtract[] = [];
  filteredCampaignExtracts$: Observable<CampaignExtract[]>;
  hasMore = true;
  sorted$ = new Subject<void>();
  contextId: string;
  scrollSortOption = 'displayName';
  scrollSortDirection: 'ASC' | 'DESC' = 'ASC';
  dataUniverses: DataUniverse[];
  searchPayload: {
    dataUniverseId?: string;
    baseEntityTypes?: string[];
    displayName?: string;
    fromDateTime?: string;
    limit?: number;
    offset?: number;
    sort?: string;
    sortBy?: string[];
    statuses?: string[];
    toDateTime?: string;
  } = { limit: 25, offset: 0, sortBy: [this.scrollSortOption], sort: this.scrollSortDirection };
  campaignExtracts$ = this.store.select(selectCampaignExtracts).pipe(
    map(values),
    map((audiences) => audiences));
    campaignExtractsHasMore$ = this.store.select(selectHasMore).subscribe((value) => {
      this.hasMore = value;
    });
  initialLoad = true;

  dropdownListActions = [];

  subscriptions = [];

  constructor(public store: Store<AppState>,
              private router: Router,
              private route: ActivatedRoute,
              public tableFilterService: TableFilterService,
              public loadingService: LoadingService,
              private utilsService: UtilsService
              ) {
    this.dropdownListActions = [
      {
        display: 'View Job Details',
        onClick: (job) => {
          this.router.navigate([
            this.utilsService.getProductBaseUrl(this.router, this.route),
            this.contextId,
            job.dataUniverseId,
            MONITOR_TXT,
            'view',
            job.id,
          ]);
        },
      },
    ];
    this.formGroup = tableFilterService.formGroup;
    this.contextId = this.route.snapshot.paramMap.get('contextId');

    this.store
      .select(selectDataUniverses)
      .pipe(untilDestroyed(this))
      .subscribe((dataUniverses) => (this.dataUniverses = dataUniverses));

    this.store
      .select(selectUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => (this.userDetails = user));
  }

  ngOnInit(): void {
    this.store.select(selectCampaignExtractsInitialState).pipe(untilDestroyed(this)).subscribe((initialLoad: boolean) => {
      this.initialLoad = initialLoad;
    });
    this.filteredCampaignExtracts$ = observableCombineLatest([
      this.campaignExtracts$
    ]).pipe(untilDestroyed(this), map(([audiences]: [CampaignExtract[]]) => {
      return audiences;
      }));

    this.filteredCampaignExtracts$
      .pipe(
        tap(this.tableFilterService.resetFormRows.bind(this)),
        untilDestroyed(this)
      )
      .subscribe((audiences) => {
        // eslint-disable-next-line guard-for-in
         for (const audiencesList in audiences){
          this.filteredCampaignExtracts.push(audiences[audiencesList]);
        }
      });

    const statusSubscription = this.tableFilterService.statuses$.subscribe((campaignExtractStatuses: CampaignExtractStatus[]) => {
      const campaignExtractStatusBE = [];
      campaignExtractStatuses.forEach(status => {
        if (status === UI_STATUS_COMPLETE) {
          campaignExtractStatusBE.push(BE_STATUS_COMPLETE);
          campaignExtractStatusBE.push(BE_STATUS_SUCCESS);
        } else if (status === UI_STATUS_ERROR) {
          campaignExtractStatusBE.push(BE_STATUS_ERROR);
        } else {
          campaignExtractStatusBE.push(BE_STATUS_MONITOR_PROCESSING);
        }
      });
      this.resetSearchPayload();
      this.searchPayload.statuses = campaignExtractStatusBE;
      if (!this.initialLoad) {
        this.search();
      }
    });

    const jobTypeSubscription = this.tableFilterService.jobTypes$.subscribe((jobTypes: any[]) => {
      const jobTypesBE = [];
      jobTypes.forEach(jobType => {
        jobTypesBE.push(jobType.value);
      });
      this.resetSearchPayload();
      this.searchPayload.baseEntityTypes = jobTypesBE;
      if (!this.initialLoad) {
        this.search();
      }
    });

    const dateTypeSubscription = this.tableFilterService.dateTypes$.subscribe((dateType: any) => {
      const dateObj = {
        today : 0,
        yesterday: 1,
        recent3Days : 2,
        recent7Days : 6,
        recent30Days : 29
      };
      if (dateType?.length > 0 && dateObj.hasOwnProperty(dateType)) {
        const filterDate: number = dateObj[dateType];
        const format = 'YYYY-MM-DDTHH:mm:ss';
        this.searchPayload.fromDateTime = moment.utc(moment().subtract(filterDate, 'days').hours(0).minutes(0).seconds(0).milliseconds(0)).format(format);
        if (dateType === 'yesterday'){
          this.searchPayload.toDateTime = moment.utc(moment().subtract(filterDate, 'days').hours(23).minutes(59).seconds(59).milliseconds(999)).format(format);
        }else{
          this.searchPayload.toDateTime = moment.utc(moment().hours(23).minutes(59).seconds(59).milliseconds(999)).format(format);
        }
      } else {
        delete this.searchPayload.fromDateTime;
        delete this.searchPayload.toDateTime;
      }
      this.resetSearchPayload();
      if (!this.initialLoad) {
        this.search();
      }
    });

    const searchSubscription = this.formGroup.get('search').valueChanges.pipe(
      auditTime(250),
      startWith('')).subscribe(searchText => {
        this.searchPayload.displayName = searchText;
        this.resetSearchPayload();
        if (!this.initialLoad) {
          this.search();
        }
    });

    this.subscriptions.push(searchSubscription, dateTypeSubscription, jobTypeSubscription, statusSubscription);

    this.store.select(selectActiveDataUniverse).pipe(filter(isDefined)).subscribe((dataUniverse: DataUniverse) => {
      this.searchPayload.dataUniverseId = dataUniverse.id === 'all' ? '' : dataUniverse.id;
      this.resetSearchPayload();
      if (!this.initialLoad) {
        this.search();
      }
    });
    this.search();
  }
  public routeToEditHandler(job){
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      job.dataUniverseId,
      MONITOR_TXT,
      'view',
      job.id,
    ]);
  }
  ngOnDestroy() {
    this.store.dispatch(new DestroyCampaignExtracts());
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  resetSearchPayload(){
    this.searchPayload.limit = 25;
    this.searchPayload.offset = 0;
    this.filteredCampaignExtracts = [];
  }

  public sort(sortComponent: TableHeaderColumnComponent, sortOption) {
    this.scrollSortOption = sortOption;
    this.scrollSortDirection = sortComponent.sortDirection;
    this.searchPayload.sortBy = [sortOption];
    this.searchPayload.sort = this.scrollSortDirection;
    this.resetSearchPayload();
    this.search();
  }

  public getDataUniverseDisplayName(dataUniverseId: string) {
    const dataUniverse = this.dataUniverses.find(
      (dataUniverse) => dataUniverse.id === dataUniverseId
    );
    return dataUniverse?.displayName;
  }

  public search() {
    this.store.dispatch(new SearchCampaignExtracts(this.contextId, this.searchPayload));
  }

  public limitReached(){
    if(this.hasMore){
      this.searchPayload.offset +=  25;
      this.search();
    }
  }
}
