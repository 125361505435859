export class CabConstants {
  public static readonly CAB_CONTEXT_HEADER = 'x-cab-context';
  public static readonly CAB_DATAUNIVERSE_ID = 'x-cab-datauniverse-id';
  public static readonly ADH_TENANT_HEADER = 'x-adh-tenant-code';
  public static readonly DCDP_PRODUCT_NAME = 'DCDP';
  public static readonly PROSPECT_PRODUCT_NAME = 'EPCP';
  public static readonly DATA_TYPE_DATA_SET = 'DATA_SET';
  public static readonly DATA_TYPE_DATA_SET_ATTRIBUTE = 'DATA_SET_ATTRIBUTE';
  public static readonly AUDIENCE_AI_CHAT_INITIATE_MESSAGE = 'Hello';
  public static readonly SAVE_SUCCESS = "Save definition success";
  public static readonly SAVE_FAILURE = "Save definition failure";
  public static readonly DATA_UNIVERSE_ID = "data_universe_id";
  public static readonly USER_SESSION_ID = "user_session_id";
}
