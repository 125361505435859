import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { ModalComponent } from '@epsilon/core-ui';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  combineLatest as observableCombineLatest,
} from 'rxjs';
import { map, take } from 'rxjs/operators';
import { find, get as _get, keyBy, reject, values } from 'lodash-es';

import {
  FetchDataTypeOperators,
  SetPrebuiltAudience,
  ToggleFullSegmentPath,
} from '../../audience-builder/audience-builder.actions';

import {
  AttributeDetails,
  BuilderAudience
} from '../../audience-builder/audience-builder.models';
import {
  DestroyAudience,
  DestroyAudienceDefinition,
  FetchAudiences,
  SaveAudience,
  SaveAudienceDefinition,
} from '../../audience/audience.actions';
import { selectContext, selectContextId } from '../../context/context.reducer';
import { AppState } from '../../reducers';
import { fetchIfUnfetched, fetchOutcome } from '../../utils/fetch-state';
import {
  AUDIENCE_TXT,
  BUILDER_TXT,
  DEFINITION_TXT,
  MONITOR_TXT,
  getStoreState,
  ACTIVITY_TXT,
  showToastNotification,
} from '../../utils/utils';
import { selectPrebuiltAudience } from '../../audience-builder/audience-builder.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { Audience, AudienceDefinition } from '../../audience/audience.models';
import {
  convertAudienceDefinitionToBuilder,
  nameValidators,
} from '../../audience/audience.utils';
import { SetActiveDataUniverse } from '../../data-universe/data-universe.actions';
import { DataUniverse } from '../../data-universe/data-universe.models';
import { selectDedupeTypes } from '../../data-universe/data-universe.reducer';
import { DataType } from '../../enums/data-types';
import { AudienceBuilderService } from '../../audience-builder/audience-builder.service';
import { LoadingService } from '../../services/loading.service';
import { CampaignExtract } from '../../campaign-extract/campaign-extract.model';
import {
  FEATURE_ACTIVATION_SCHEDULES,
  FEATURE_PAID_CONNECTIONS_INFO,
  FEATURE_SCHEDULING,
} from '../../utils/feature-utils';
import { ScheduleDetail } from './schedules/schedules.models';
import { selectDataTypeOperators } from '../../audience-builder/audience-builder.reducer';
import { selectAudiences } from '../../audience/audience.reducer';
import {
  selectDataUniverses,
  selectActiveDataUniverse,
} from '../../data-universe/data-universe.reducer';
import * as audienceActions from '../../audience/audience.actions';
import { CampaignExtractService } from '../../campaign-extract/campaign-extract.service';
import { ActivationDestination } from '../../campaign-extract/ams-activation.model';
import { SendToComponent } from '../overview-table/send-to/send-to.component';
import { FeatureService } from '../../utils/feature-service';
import { CountsService } from '../../counts/counts.service';
import { Actions } from '@ngrx/effects';
import { UtilsService } from '../../utils/utilservice';
import { FeatureAccessService } from '../../feature-access/feature.access.service';
import { Action } from '../../feature-access/feature.access.action';
@UntilDestroy()
@Component({
  selector: 'lib-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.sass'],
})
export class DetailsComponent implements OnInit, OnDestroy {
  @Input() type: 'details-definition' | 'details-audience';
  @Input() audienceForm: UntypedFormGroup;
  @ViewChild('deleteActionModal') public deleteActionModal;
  @ViewChild('createActionModal') public createActionModal;
  @ViewChild('errorNotification', { static: true }) public errorNotification;
  @ViewChild('editScheduleActionModal') public editScheduleActionModal;
  @ViewChild('successCreatingList', { static: true })
  public successCreatingList;
  @ViewChild('destroySuccess', { static: true }) public destroySuccess;

  dataTypeOperators$ = this.store.select(selectDataTypeOperators);
  audience: BuilderAudience;
  audiences: Array<Audience | AudienceDefinition | CampaignExtract>;
  audienceItem: Audience | AudienceDefinition | CampaignExtract;
  campaignExtractItem: CampaignExtract;
  contextId: string;
  definitionId: string;
  audienceId: string;
  dataUniverseId: string;
  activeDataUniverse: DataUniverse;
  dedupeDisplayName: string;
  formGroup: UntypedFormGroup;
  scheduleModalCreateForm: UntypedFormGroup;
  attributeDetails: AttributeDetails;
  // used to handle the focusout event for the name input field
  tempNamePlaceholder = '';
  errorMessage: string | void = '';
  monitorData: any;
  isMonitor = false;
  campaignExtractId: string;
  schedulerEnabled: boolean;
  schedules: ScheduleDetail[];
  schedulingEnabled = FEATURE_SCHEDULING;
  productType: string;
  activityRouter: string;
  audiencesRouter: string;
  monitorRouter: string;
  audienceDefRouter: string;
  showCreateList = false;
  connections: ActivationDestination[];
  audienceEndDate: string;
  paidConnectionInfoEnabled: boolean;
  isEdit: boolean;
  activationSchedulesEnabled: boolean;
  channelType: string;
  isLoader = false;
  definitionDeleteAction = Action.DEFINITION_DELETE;
  successMessage: string;

  constructor(
    public store: Store<AppState>,
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    public builderService: AudienceBuilderService,
    public loadingService: LoadingService,
    public campaignExtractService: CampaignExtractService,
    public featureService: FeatureService,
    public countsService: CountsService,
    private utilsService: UtilsService,
    public featureAccessService: FeatureAccessService
  ) {}

  ngOnInit() {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.dataUniverseId = this.route.snapshot.paramMap.get('dataUniverseId');
    this.definitionId = this.route.snapshot.paramMap?.get('definitionId');
    this.audienceId = this.route.snapshot.paramMap.get('audienceId');
    this.campaignExtractId =
      this.route.snapshot.paramMap.get('campaignExtractId');
    this.activityRouter =
      '/' +
      this.utilsService.getProductBaseUrl(this.router, this.route) +
      '/' +
      this.contextId +
      '/' +
      ACTIVITY_TXT;
    this.audiencesRouter =
      '/' +
      this.utilsService.getProductBaseUrl(this.router, this.route) +
      '/' +
      this.contextId +
      '/' +
      AUDIENCE_TXT;
    this.monitorRouter =
      '/' +
      this.utilsService.getProductBaseUrl(this.router, this.route) +
      '/' +
      this.contextId +
      '/' +
      MONITOR_TXT;
    this.audienceDefRouter =
      '/' +
      this.utilsService.getProductBaseUrl(this.router, this.route) +
      '/' +
      this.contextId +
      '/' +
      DEFINITION_TXT;
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.productType = context?.productType;
        this.schedulerEnabled =
          this.featureService.isFeatureEnabled(FEATURE_SCHEDULING);
        this.paidConnectionInfoEnabled = this.featureService.isFeatureEnabled(
          FEATURE_PAID_CONNECTIONS_INFO
        );
        this.activationSchedulesEnabled = this.featureService.isFeatureEnabled(FEATURE_ACTIVATION_SCHEDULES);
      });
    this.store
      .select(selectAudiences)
      .pipe(
        map((audiences) =>
          this.isAudience()
            ? audiences['audiences']
            : audiences['audienceDefinitions']
        ),
        map(values),
        untilDestroyed(this)
      )
      .subscribe((audiences: Array<AudienceDefinition | Audience>) => {
        this.audiences = audiences;
      });

    this.formGroup = this.formBuilder.group({
      displayName: this.formBuilder.control('', [
        ...nameValidators,
        this.uniqueNameValidator(),
      ]),
    });

    observableCombineLatest([
      this.store.select(selectPrebuiltAudience),
      this.store.select(selectDedupeTypes(this.dataUniverseId)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([audience, dedupeTypes]) => {
        this.audience = audience;
        if (audience) {
          this.formGroup.get('displayName').patchValue(audience?.displayName);
          this.dedupeDisplayName = find(dedupeTypes, {
            identityType: audience.dedupeIdentityType,
          })?.name;
        }
      });

    if (this.audienceId) {
      fetchIfUnfetched(this.store, new FetchAudiences(this.contextId), this);
      this.route.data.subscribe((data: any) => {
        const audienceList = data.audienceData.entity?.audienceList;
        const nodes = data.audienceData.entity?.nodes;
        if (audienceList) {
          this.channelType = audienceList.audienceAttributes?.channelType;
          this.attributeDetails = keyBy(nodes, 'cabId');
          this.audienceItem = this.utilsService.isMocked()
            ? new Audience(audienceList[0])
            : new Audience(audienceList);

          this.store.dispatch(
            new SetPrebuiltAudience(
              convertAudienceDefinitionToBuilder(this.audienceItem),
              this.contextId,
              DataType.AUDIENCE_LIST
            )
          );
        }
      });
    }

    if (this.definitionId) {
      this.isEdit = true;
      this.route.data.subscribe((data: any) => {
        const audienceDefinition = data.audienceData.entity?.audienceDefinition;
        const nodes = data.audienceData.entity?.nodes;
        this.attributeDetails = keyBy(nodes, 'cabId');
        this.audienceItem = this.utilsService.isMocked()
          ? new AudienceDefinition(audienceDefinition[0])
          : new AudienceDefinition(audienceDefinition);

        this.store.dispatch(
          new SetPrebuiltAudience(
            convertAudienceDefinitionToBuilder(this.audienceItem),
            this.contextId,
            DataType.AUDIENCE_DEFINITION
          )
        );
        this.store.dispatch(
          new audienceActions.LoadAudienceForCreateList(this.audienceItem)
        );
      });
    }

    // Monitor view details
    if (this.campaignExtractId) {
      this.isMonitor = true;
      this.route.data.subscribe((data: any) => {
        const campaignExtract = data.audienceData.entity?.campaignExtract;
        const nodes = data.audienceData.entity?.nodes;
        this.attributeDetails = keyBy(nodes, 'cabId');
        if (
          this.paidConnectionInfoEnabled &&
          campaignExtract?.sendToTargetType === 'AMS'
          ) {
          this.isLoader = true;
          this.campaignExtractService
            .getAmsConnectors(
              campaignExtract?.sendToTargetInfo?.amsActivationId
            )
            .subscribe((res) => {
              this.connections = res.activationDestinations;
              this.audienceEndDate = res.audience?.expireAt;
              this.isLoader = false;
            });
        }
        if (data && campaignExtract?.audienceListId) {
          this.campaignExtractItem = new CampaignExtract(campaignExtract);
          this.audienceItem = new Audience(campaignExtract);
          this.store.dispatch(
            new SetPrebuiltAudience(
              convertAudienceDefinitionToBuilder(campaignExtract),
              this.contextId,
              DataType.AUDIENCE_LIST
            )
          );
        } else {
          if (data && campaignExtract?.audienceDefinitionId) {
            this.campaignExtractItem = new CampaignExtract(campaignExtract);
            this.audienceItem = new AudienceDefinition(campaignExtract);
            this.store.dispatch(
              new SetPrebuiltAudience(
                convertAudienceDefinitionToBuilder(campaignExtract),
                this.contextId,
                DataType.AUDIENCE_DEFINITION
              )
            );
          }
        }
      });
    }

    observableCombineLatest([
      this.store.select(selectDataUniverses),
      this.store.select(selectActiveDataUniverse),
      this.store.select(selectContextId),
    ]).subscribe(([dataUniverses, activeDataUniverse, contextId]) => {
      if (activeDataUniverse) {
        this.activeDataUniverse = activeDataUniverse;
      }
      if (
        !activeDataUniverse ||
        activeDataUniverse?.id !== this.dataUniverseId
      ) {
        const universe = find(dataUniverses, { id: this.dataUniverseId });
        if (universe) {
          this.store.dispatch(new SetActiveDataUniverse(universe));
        }
      }
      if (contextId) {
        fetchIfUnfetched(
          this.store,
          new FetchDataTypeOperators(contextId, this.dataUniverseId),
          this
        );
      }
    });
  }

  resetFormUpdateBoolean() {
    this.audienceForm?.markAsPristine();
    this.audienceForm?.markAsUntouched();
    this.builderService.audienceBuilderUpdatedManually$.next(false);
    this.builderService.audienceBuilderCriteriaManually$.next(false);
  }

  get displayType(): string | void {
    if (!this.isMonitor) {
      return this.isAudience() ? 'list' : 'definition';
    }
  }

  isAudience(): boolean {
    return this.audienceId != null;
  }

  backToOverview() {
    if (this.productType === 'DCDP') {
      this.router.navigate([
        this.utilsService.getProductBaseUrl(this.router, this.route),
        this.contextId,
        this.isAudience()
        ? AUDIENCE_TXT : ACTIVITY_TXT,
      ]);
    } else {
      this.router.navigate([
        this.utilsService.getProductBaseUrl(this.router, this.route),
        this.contextId,
        this.isMonitor
          ? MONITOR_TXT
          : this.isAudience()
          ? AUDIENCE_TXT
          : DEFINITION_TXT,
      ]);
    }
  }

  editItem() {
    if (this.isAudience()) {
      return;
    }
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      this.dataUniverseId,
      BUILDER_TXT,
      'edit',
      this.audience.id,
    ]);
  }

  createListItem() {
    this.showCreateList = true;
  }

  deleteItem() {
    this.deleteActionModal.show();
  }

  saveItem() {
    if (!this.formGroup.valid) {
      return;
    }

    const { id } = this.audience;
    const storeState = getStoreState(this.store);
    const key = this.isAudience() ? 'audiences' : 'audienceDefinitions';
    const audience = _get(storeState, ['cab', 'audiences', key, id]);
    audience.displayName = this.formGroup.get('displayName').value.trim();

    if (this.isAudience()) {
      this.store.dispatch(new SaveAudience(audience as Audience));
    } else {
      this.store.dispatch(
        new SaveAudienceDefinition(audience as AudienceDefinition)
      );
    }
    this.store.dispatch(
      new SetPrebuiltAudience(
        convertAudienceDefinitionToBuilder(audience),
        this.contextId,
        this.isAudience()
          ? DataType.AUDIENCE_LIST
          : DataType.AUDIENCE_DEFINITION
      )
    );
  }

  public closeCreateListModal(createListStatus?: 'success' | 'error') {
    if (createListStatus === 'success') {
      showToastNotification(this.successCreatingList);
    }
    this.showCreateList = false;
  }

  deleteAction(modal: ModalComponent) {
    const { id } = this.audience;
    const storeState = getStoreState(this.store);

    if (this.isAudience()) {
      const audience = _get(storeState, ['audiences', 'audiences', id]) || _get(storeState, ['cab', 'audiences', 'audiences', id]) || this.audienceItem;
      this.store.dispatch(new DestroyAudience(audience));
      this.actions$
        .pipe(fetchOutcome(DestroyAudience.type), take(1))
        .subscribe(
          () => {
            this.closeModal(modal);
            this.successMessage = 'List deleted successfully!';
            this.destroySuccess.show();
            setTimeout(() => {
              this.backToOverview();
            }, 3000);
          }, () => {
            this.errorMessage = "Unable to delete the list!";
            this.errorNotification.show();
          });
    } else {
      const definition = _get(storeState, ['cab', 'audiences', 'audienceDefinitions', id]) || _get(storeState, [
        'audiences',
        'audienceDefinitions',
        id,
      ]) || this.audienceItem;
      this.store.dispatch(new DestroyAudienceDefinition(definition));
      this.actions$
        .pipe(fetchOutcome(DestroyAudienceDefinition.type), take(1))
        .subscribe(
          () => {
            this.closeModal(modal);
            this.successMessage = 'List deleted successfully!';
            this.destroySuccess.show();
            setTimeout(() => {
              this.backToOverview();
            }, 3000);
          }, () => {
            this.errorMessage = "Unable to delete the list!";
            this.errorNotification.show();
          });
    }
  }

  closeModal(modal: ModalComponent) {
    modal.hide();
  }

  getNameErrorMessage(name): string | void {
    const errors = this.formGroup.controls['displayName'].errors;
    if (!errors) {
      return;
    }

    const type = this.isAudience() ? 'audience' : 'audience definition';
    const { required, maxlength, nameTaken, whitespace } = errors;
    if (required || whitespace) {
      return `The ${type} name must not be empty.`;
    }
    if (maxlength?.actualLength > maxlength?.requiredLength) {
      return `The ${type} name cannot be longer than 255 characters.`;
    }
    if (nameTaken) {
      return `The ${type} name ${name} is taken.`;
    }
    console.error(errors);
    return `An unknown error occurred when saving the ${type}.`;
  }

  // name input field approve button click
  onNameApprove() {
    // hacky but the only way
    this.formGroup.get('displayName').setValue(this.tempNamePlaceholder);

    if (this.formGroup.get('displayName').errors) {
      this.errorNotification.hide();
      this.errorMessage = this.getNameErrorMessage(this.tempNamePlaceholder);
      this.errorNotification.show();
      this.formGroup.get('displayName').setValue(this.audience.displayName);
    } else {
      this.saveItem();
    }
    this.tempNamePlaceholder = '';
  }

  // name input field focus out event
  onNameFocusout() {
    this.tempNamePlaceholder = this.formGroup.get('displayName').value;
    this.formGroup.get('displayName').setValue(this.audience.displayName);
  }

  private uniqueNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const id = this.utilsService.isMocked()
        ? parseInt(this.audienceId || this.definitionId, 10)
        : this.audienceId || this.definitionId;
      const list: any = reject(this.audiences, { id });
      const nameTaken = list
        ?.map((o) => o.displayName?.toLowerCase())
        .includes(control.value.trim().toLowerCase());
      return nameTaken ? { nameTaken: true } : null;
    };
  }

  showSchedulingTab() {
    if (!this.isMonitor && this.schedulerEnabled && this.definitionId) {
      return true;
    }
    return false;
  }

  cancel() {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      AUDIENCE_TXT,
    ]);
  }

  fetchConnectorLogos(connectionName: string): string | void {
    if (connectionName) {
      connectionName = connectionName?.replace(/\s/g, '').toLowerCase();
      for (const connector of SendToComponent.logos) {
        if (connectionName.includes(connector.name)) {
          return SendToComponent.partnerLogosPath + connector.logo;
        }
      }
      return SendToComponent.partnerLogosPath + SendToComponent.defaultLogo;
    }
  }

  sendTo() {
    this.route.data.subscribe((data: any) => {
      const campaignExtract =
        data.audienceData.entity?.audienceList?.audienceDefinitionId;
      const type =
        data.audienceData.entity?.audienceList?.audienceAttributes?.channelType ===
        'PAID'
          ? 'ams'
          : 'sendTo';
      this.router.navigate([
        this.utilsService.getProductBaseUrl(this.router, this.route),
        this.contextId,
        this.dataUniverseId,
        campaignExtract,
        type,
      ]);
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new SetPrebuiltAudience(null));
    this.store.dispatch(new ToggleFullSegmentPath(null));
  }
}
