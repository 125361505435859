<div class="card container-autoComplete">
  <div>
    <input
      #autoCompleteInput
      id="auto-complete"
      class="auto-complete-input"
      placeholder="Select"
      type="text"
      autocomplete="off"
      (focus)="onSearchFocus()"
      (keyup)="onKeyup($event)"
      [value]="inputValue"
    />
    <i coreUIIcon="stepper" class="dropdown-icon"></i>
  </div>
  <div class="card" style="position: absolute" [hidden]="!showSearches">
    <div class="search" (mouseover)="onSearchFocus()">
      <div
        *ngFor="let result of searchedResults"
        (mousedown)="onOptionClick(result)"
        class="search-result"
      >
        <div>
          {{ getResultDisplayName(result) }}
        </div>
      </div>
    </div>
    <div class="search" *ngIf="searchedResults && searchedResults.length <= 0">
      <span class="search-result">No Result Found</span>
    </div>
  </div>
</div>
