import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { map as _map, values } from 'lodash-es';

import { AppState } from '../reducers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectFetchStates } from '../utils/fetch-state';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public isHttpRequestLoading$ = new BehaviorSubject<boolean>(false);
  public isHttpRequestLoadingFullScreen$ = new BehaviorSubject<boolean>(false);
  public isEntityNotFound$ = new BehaviorSubject<boolean>(false);
  public isServerNotFound$ = new BehaviorSubject<boolean>(false);
  public serverErrorMessage$ = new BehaviorSubject<string>('');
  public isNgrxStoreLoading$ = this.store
    .select(selectFetchStates)
    .pipe(
      map((states) => _map(values(states), (state) => state?.isFetchInFlight))
    )
    .pipe(map((fetchStates) => fetchStates.some(Boolean)));

  public isLoading$ = observableCombineLatest([
    this.isHttpRequestLoading$,
    this.isNgrxStoreLoading$,
  ]).pipe(
    untilDestroyed(this),
    map(
      ([isHttpRequestLoading, isNgrxStoreLoading]) =>
        isHttpRequestLoading || isNgrxStoreLoading
    )
  );

  public isLoadingFullScreen$ = this.isHttpRequestLoadingFullScreen$.pipe(
    untilDestroyed(this),
    map(
      isHttpRequestLoadingFullScreen =>
        isHttpRequestLoadingFullScreen
    )
  );

  constructor(public store: Store<AppState>) {}
}
