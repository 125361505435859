import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { inject, Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivateChildFn, CanActivateFn,
  Router,
  RouterStateSnapshot, UrlTree
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from '../reducers';
import { LoadTenant, ResetTenant } from '../tenant/tenant.actions';
import { selectTenant } from '../tenant/tenant.reducer';
import { Tenant } from '../tenant/tenant.model';
import { TenantService } from '../tenant/tenant.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
class TenantAuthActivateGuardService {
  tenant: Tenant;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tenantService: TenantService,
    private store: Store<AppState>,
  ) {
    this.store
      .select(selectTenant)
      .pipe(untilDestroyed(this))
      .subscribe((tenant) => {
        this.tenant = tenant;
      });
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const tenantId = childRoute.params['tenantId'] || childRoute.parent?.params['tenantId'] || childRoute.parent?.parent?.params['tenantId'] || childRoute.parent?.parent?.parent?.params['tenantId'];
    const contextId = childRoute.params['contextId'] || childRoute.parent?.params['contextId'] || childRoute.parent?.parent?.params['contextId'];
    if (state.url.split('/')[1] === 'tenant' && tenantId) {
      if(tenantId !== this.tenant?.tenantId) {
        this.store.dispatch(new ResetTenant());
        return this.tenantService.fetchTenant('DCDP', tenantId).pipe(
          map((tenant: Tenant) => {
            if(tenant?.tenantId && (!contextId || tenant?.id === contextId)) {
              this.store.dispatch(new LoadTenant(tenant));
              return true;
            }
            return false;
          })
        );
      } else if(contextId && this.tenant.id !== contextId) {
        this.router.navigate(['error', '401']);
        return false;
      }
    }
    if(!contextId) {
      this.router.navigate(['access-denied']);
    }
    return true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (state.url.split('/')[1] === 'tenant' && route.parent.params['tenantId']) {
      if(route.parent.params['tenantId'] !== this.tenant?.tenantId) {
        this.store.dispatch(new ResetTenant());
        //this.preFetch(route);
        return this.tenantService.fetchTenant('DCDP', route.parent.params['tenantId']).pipe(
          map((tenant: Tenant) => {
            if(tenant?.tenantId) {
              this.store.dispatch(new LoadTenant(tenant));
              if (route.parent.params['businessUnitId'] !== undefined)
                return this.router.parseUrl(`/tenant/${tenant.tenantId}/${route.parent.params['businessUnitId'] || 'DEFAULT'}/audiences/${tenant.id}/deliveries`);
              else
                return this.router.parseUrl(`/tenant/${tenant.tenantId}/audiences/${tenant.id}/deliveries`);
            }
            return false;
          })
        );
        //return this.waitForTenantDetailsToLoad();
      } else if(route.params['contextId'] && this.tenant.id !== route.params['contextId']) {
        this.router.navigate(['error', '401']);
        return false;
      }
      if (route.parent.params['businessUnitId'] !== undefined)
        return this.router.parseUrl(`/tenant/${this.tenant?.tenantId}/${route.parent.params['businessUnitId'] || 'DEFAULT'}/audiences/${this.tenant?.id}/deliveries`);
      else
        return this.router.parseUrl(`/tenant/${this.tenant?.tenantId}/audiences/${this.tenant?.id}/deliveries`);
    }
    if(!route.params['contextId']) {
      this.router.navigate(['access-denied']);
    }
    return true;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TenantAuthActivateGuardChild: CanActivateChildFn = (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean => {
  return inject(TenantAuthActivateGuardService).canActivateChild(childRoute, state);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TenantAuthActivateGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(TenantAuthActivateGuardService).canActivate(route, state);
}
