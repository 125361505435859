export class Tenant {
  id: string;
  name: string;
  tenantId: string;
  productType: string;
  dataHubTenantId: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.productType = data.productType;
    this.tenantId = data.tenantId;
  }
}
