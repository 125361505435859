<div [formGroup]="formGroup" class="page-scaffolding">
  <coreui-table class="scroll-table" #scrollTable coreUIInfiniteScroll [scrollTarget]="scrollTable.scrollContainer"
  (scrollLimitReached)="limitReached()" [fixedHeader]="true">
    <thead coreUITableSort>
      <tr coreUITableHeaderRow>
        <th scope="col" class="table-header" coreUITableCheckboxColumn *ngIf="productType !== 'DCDP'"></th>
        <th scope="col" class="table-header" [attr.aria-sort]="sortAscDesc('displayName')">
          <coreui-table-header-column (sort)="scrollSort($event, 'displayName')"
            [active]="scrollSortOption === 'displayName'" [sortDirection]="scrollSortDirection">
            Definition
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header" *ngIf="productType === 'DCDP'">
          <coreui-table-header-column [disableSort]="true">
            Channel
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header" *ngIf="productType !== 'DCDP'" [attr.aria-sort]="sortAscDesc('dataUniverseId')">
          <coreui-table-header-column (sort)="scrollSort($event, 'dataUniverseId')"
            [active]="scrollSortOption === 'dataUniverseId'" [sortDirection]="scrollSortDirection">
            Data Universe
          </coreui-table-header-column>
        </th>
        <th scope="col">
          <coreui-table-header-column [disableSort]="true">
            Audience
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header">
          <coreui-table-header-column
            [active]="scrollSortOption === 'count.count'" [disableSort]="true">
            Count
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header" *ngIf="schedulingEnabled && productType !== 'DCDP'" [attr.aria-sort]="sortAscDesc('numOfSchedules')">
          <coreui-table-header-column (sort)="scrollSort($event, 'numOfSchedules')"
            [active]="scrollSortOption === 'numOfSchedules'" [sortDirection]="scrollSortDirection">
            Schedules
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header" [attr.aria-sort]="sortAscDesc(['lastModifiedDate','lastModifiedBy'])" 
        [attr.aria-describedby]="scrollSortOption === 'lastModifiedDate' ? 'UpdatedDate-Sort' : scrollSortOption === 'lastModifiedBy' ? 'UpdatedBy-Sort' :undefined">
          <coreui-table-multiple-header-column label="Updated">
            <coreui-table-header-column (sort)="scrollSort($event, 'lastModifiedDate')"
              [active]="scrollSortOption === 'lastModifiedDate'" [sortDirection]="scrollSortDirection">
              Update Date
            </coreui-table-header-column>
            <coreui-table-header-column (sort)="scrollSort($event, 'lastModifiedBy')"
              [active]="scrollSortOption === 'lastModifiedBy'" [sortDirection]="scrollSortDirection">
              Updated By
            </coreui-table-header-column>
          </coreui-table-multiple-header-column>
        </th>
        <th scope="col" class="table-header" *ngIf="productType !== 'DCDP'" [attr.aria-sort]="sortAscDesc(['createdDate','createdBy'])">
          <coreui-table-multiple-header-column label="Created">
            <coreui-table-header-column (sort)="scrollSort($event, 'createdDate')"
              [active]="scrollSortOption === 'createdDate'" [sortDirection]="scrollSortDirection">
              Date
            </coreui-table-header-column>
            <coreui-table-header-column (sort)="scrollSort($event, 'createdBy')"
              [active]="scrollSortOption === 'createdBy'" [sortDirection]="scrollSortDirection">
              User
            </coreui-table-header-column>
          </coreui-table-multiple-header-column>
        </th>
        <th scope="col" class="table-header">
          <coreui-table-header-column [disableSort]="true">
          </coreui-table-header-column>
        </th>
      </tr>
    </thead>

    <tbody coreUICheckboxMultipleSelection (multipleSelected)="tableFilterService.onMultipleSelected($event)"
      formArrayName="rows">
      <tr *ngFor="
          let item of filteredDefinitions
            | orderBy: scrollSortOption:scrollSortDirection;
          index as i
        " coreUITableRow [active]="tableFilterService.rowsControl?.at(i)?.value">
        <ng-container *ngIf="isPaidchannelTypeFeatureEnabled ? true : item.audienceAttributes?.channelType !== 'PAID'">
        <td coreUITableCheckboxColumn *ngIf="productType !== 'DCDP'">
          <label><input type="checkbox" coreUICheckbox (change)="checkedCounter($event)"
              [formControl]="formGroup.get('rows').controls[i]" /></label>
        </td>
        <td class="nameCol">
          <span *ngIf="productType !== 'DCDP'">{{ item.displayName }}</span>
          <a class="text-style-link-2" aria-label="Definition List" [routerLink]="absoluteUrl + item.dataUniverseId + '/' + 'builder' + '/' + 'edit' + '/' + item.id" (click)='routeToEditHandler(item)' *ngIf="productType === 'DCDP'">{{
            item.displayName }}</a>
          <div class="tooltip-content" *ngIf="item?.audienceAttributes?.isPVEAudience">
            <a href="javascript: void(0)" [coreUITooltip]="optimizedTooltip" position="right">
              <i coreUIIcon="promo" scale="1.5"></i>
            </a>
            <ng-template #optimizedTooltip>
              <span class="tooltipMessage">
                Optimized Audience
              </span>
            </ng-template>
          </div>
          <div class="tooltip-content" *ngIf="!item?.audienceAttributes?.isPVEAudience">
            <a href="javascript: void(0)" [coreUITooltip]="sharedTooltip" position="right">
              <i coreUIIcon="audiences" scale="1.5"></i>
            </a>
            <ng-template #sharedTooltip>
              <span class="tooltipMessage">
                Shared Audience
              </span>
            </ng-template>
          </div>
        </td>
        <td *ngIf="productType === 'DCDP'"><span> {{item.audienceAttributes?.channelType | titlecase}}</span></td>
        <td *ngIf="productType !== 'DCDP'">
          {{ getDataUniverseDisplayName(item.dataUniverseId) }}</td>
        <td>
          {{ getAudienceDisplayName(item.dedupeIdentityType, productType !== 'DCDP' ? '' :
          item.audienceAttributes?.channelType, item.audienceAttributes?.alternateKeyType) }}</td>
        <td *ngIf="!item.count && !item.countUpdatedOn"><span class="text-style-muted-3">—</span></td>
        <td *ngIf="item.count || item.countUpdatedOn">
          <div *ngIf="item.countStatus === 'SUCCESS'">
            <div class="has-bottom-space">{{ item.idCount | number }}</div>
            <div class="text-style-muted-3"> as of {{ item.countUpdatedOn | date: 'MM/dd/YY hh:mm a': 'UTC' }} UTC
            </div>
          </div>
          <div *ngIf="item.countStatus === 'FAILURE'">
            <ng-template #errorMessage>
              <span class="countErrorMessage">
                {{ item.countErrorMessage }}
              </span>
            </ng-template>
            <coreui-badge
              color="Apple"
              [filled]="true"
              [hasIcon]="true"
              [coreUITooltip]="errorMessage"
            >
            <i coreUIIcon="errorShield">
            </i>Error
            </coreui-badge>
            <div class="text-style-muted-3"> on {{ item.countUpdatedOn | date: 'MM/dd/YY hh:mm a': 'UTC' }} UTC </div>
          </div>
          <div *ngIf="!item.countStatus">
            <coreui-badge color="Slate" [filled]="true" [hasIcon]="true">
              <i coreUIIcon="spinner"></i>Processing
            </coreui-badge>
          </div>
        </td>
        <td *ngIf="schedulingEnabled && productType !== 'DCDP'">{{item.numOfSchedules}} enabled</td>
        <td>
          <div *ngIf="productType !== 'DCDP'">
            <div class="has-bottom-space"> {{ item.lastModifiedDate | coreUILocaleDate }}</div>
            <div class="text-style-muted-3">{{ item.lastModifiedBy }}</div>
          </div>
          <div *ngIf="productType === 'DCDP'">
            <div class="has-bottom-space">{{ item.lastModifiedDate | date: 'MM/dd/YY hh:mm a': 'UTC' }} UTC</div>
            <div class="text-style-muted-3">{{ 'by '+item.lastModifiedBy }}</div>
          </div>
        </td>
        <td *ngIf="productType !== 'DCDP'">
          <div class="has-bottom-space">{{ item.createdDate | coreUILocaleDate }}</div>
          <div class="text-style-muted-3">{{ item.createdBy }}</div>
        </td>
        <td aria-label="actions" coreUITableActionColumn>
          <coreui-menu menuButtonStyle="link" icon="more" *ngIf="getActionsForDefinition(item).length">
            <coreui-menu-section>
              <button type="button" *ngFor="let action of getActionsForDefinition(item)"
                (click)="action.onClick(item)" coreUIMenuItem>
                {{ action.display }}
              </button>
            </coreui-menu-section>
          </coreui-menu>
        </td>
      </ng-container>
      </tr>

      <tr coreUITableRow
        *ngIf="(loadingService.isLoading$ | async)  && !filteredDefinitions.length">
        <td *ngIf="productType !== 'DCDP'"><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td *ngIf="productType !== 'DCDP'"><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td *ngIf="productType !== 'DCDP'"><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td *ngIf="productType !== 'DCDP'"><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
      </tr>
      <tr *ngIf="hasAccess && ((loadingService.isLoading$ | async) === null || (loadingService.isLoading$ | async) === undefined || (loadingService.isLoading$ | async) === false) && !initialLoad && !filteredDefinitions.length" coreUITableRow [disableHover]="true">
        <td colspan="9" class="empty-list">
          <coreui-graphic-message label="No Results Found" graphic="noResults">
            Sorry, we did not find any results for your search.
          </coreui-graphic-message>
        </td>
      </tr>
      <tr *ngIf="!paginationPayload?.displayName && !filteredDefinitions.length && !loadingService.isLoading$"
        coreUITableRow [disableHover]="true">
        <td colspan="9" class="empty-list">
          <coreui-graphic-message label="Nothing Here" graphic="empty">
            There are no saved definitions.
          </coreui-graphic-message>
        </td>
      </tr>
    </tbody>
  </coreui-table>
</div>

<coreui-modal modalWidth="40" #deleteActionModal>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon"></span>
    <span *ngIf=" this.tableFilterService.rowSelected === 1"> Delete Audience Definition? </span>
    <span *ngIf=" this.tableFilterService.rowSelected > 1"> Delete Audience Definitions? </span>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="closeModal(deleteActionModal)" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <span class="text-style-muted-3 template-text">{{(actionInProgress$ | async)?.definitions[0].displayName}} | ID -
      {{(actionInProgress$ | async)?.definitions[0].id}}
    </span>
    <span *ngIf=" this.tableFilterService.rowSelected === 1">
      Are you sure you want to delete this audience definition? This cannot be undone. </span>
    <span *ngIf=" this.tableFilterService.rowSelected > 1">
      Are you sure you want to delete this audience definition? This cannot be undone.</span>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal(deleteActionModal)">
      Cancel
    </button>
    <button *ngIf=" this.tableFilterService.rowSelected > 1" coreUIButton (click)="deleteAction(deleteActionModal)">
      Delete
    </button>
    <button *ngIf=" this.tableFilterService.rowSelected === 1" coreUIButton (click)="deleteAction(deleteActionModal)">
      Delete
    </button>
  </ng-template>
</coreui-modal>



<coreui-modal modalWidth="40" #deleteActionOneModal>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon"></span>
    <span> Delete Audience Definition? </span>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="closeModal(deleteActionOneModal)" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <span class="text-style-muted-3 template-text">{{(actionInProgress$ | async)?.definitions[0].displayName}} | ID -
      {{(actionInProgress$ | async)?.definitions[0].id}}
    </span>
    <span>
      Are you sure you want to delete this audience definition? This cannot be undone.
    </span>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal(deleteActionOneModal)">
      Cancel
    </button>
    <button coreUIButton (click)="deleteAction(deleteActionOneModal)">
      Delete
    </button>
  </ng-template>
</coreui-modal>

<lib-create-list *ngIf="showCreateList" (createListModalClosed)="closeCreateListModal()"></lib-create-list>

<lib-export-audience-modal *ngIf="(actionInProgress$ | async)?.type === 'export'"
  (exportModalClosed)="closeExportModal()"
  [audience]="(actionInProgress$ | async)?.definitions[0]"></lib-export-audience-modal>

<coreui-notification notificationStyle="success" #exportSuccess>
  This export has successfully been initiated!
</coreui-notification>

<coreui-notification notificationStyle="error" #exportError>
  There was a problem initiating this export. Please try again.
</coreui-notification>

<coreui-notification notificationStyle="success" #destroySuccess>
  {{successMessage}}
</coreui-notification>

<coreui-notification notificationStyle="error" #destroyError [header]=destroyErrorHeader>
  {{destroyErrorMessage}}
  <p *ngIf="nestedDefs.length > 0"> "{{nestedDefs}}"</p>
</coreui-notification>

<coreui-notification notificationStyle="success" #successCreatingList>
  <b>List created successfully</b>
  <br />
</coreui-notification>
