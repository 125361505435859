<coreui-modal #syncModal>
  <ng-template #titleTemplate> Sync Table </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeModal()"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <form [formGroup]="modalSyncForm">
      <coreui-form-field label="Table">
        <i coreUIIcon="table" style="padding-right: 10px"></i>
        <b>{{dataset?.displayName}} </b>
      </coreui-form-field>
      <div *ngIf="newlyAddedAttributes?.length > 0">
        <h4 class="u-paddingBottom15">New attributes will be added after sync</h4>
          <div *ngFor="let attribute of newlyAddedAttributes">
            <div class="dataAttributes">
              <lib-type-icon [type]="attribute.dataType.toLowerCase()" [scale]=1.2></lib-type-icon>
              <span>{{attribute.displayName}}</span>
            </div>
          </div>
      </div>
      <coreui-load-mask [active]="loadSpinner">
        <div *ngIf="!(newlyAddedAttributes?.length > 0)">
          <b>Nothing to Sync</b>
          <coreui-graphic-message label="No changes have been made since the last Sync." graphic="noData">
            Try again later.
          </coreui-graphic-message>
        </div>
      </coreui-load-mask>
    </form>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal()">
      Cancel
    </button>
    <button coreUIButton (click)="syncAttributes()" [disabled]="!(newlyAddedAttributes?.length > 0)">
      Confirm & Sync
    </button>
  </ng-template>
</coreui-modal>
