import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { ToggleFullSegmentPath } from '../../../audience-builder/audience-builder.actions';
import { selectDisplayFullSegmentPath } from '../../../audience-builder/audience-builder.reducer';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-segment-path-toggle',
  templateUrl: './segment-path-toggle.component.html',
  styleUrls: ['./segment-path-toggle.component.sass'],
})
export class SegmentPathToggleComponent {
  @Input() toggleLabel = 'Show Full Attribute Path';
  displayFullSegmentPath$: Observable<boolean> = this.store.select(
    selectDisplayFullSegmentPath
  );

  constructor(private store: Store<AppState>) {}

  toggleFullSegmentPath(showPath) {
    this.store.dispatch(new ToggleFullSegmentPath(showPath));
  }
}
