import { OnDestroy } from '@angular/core';
import { Action, Store, select, createFeatureSelector, createSelector } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { untilDestroyed } from '@ngneat/until-destroy';
import { values, get } from 'lodash-es';
import { of as observableOf, Observable, Subscription } from 'rxjs';
import {
  concat,
  publishReplay,
  refCount,
  catchError,
  filter,
  map,
  switchMap,
  mergeMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { AppState } from '../reducers';

export interface FetchState {
  isFetchInFlight?: boolean;
  lastLoadTime?: number;
  lastErrorTime?: number;
  error?: any;
}

// // Actions

export class StartFetch implements Action {
  static readonly type = 'START_FETCH';
  readonly type = StartFetch.type;

  constructor(public fetchAction: Action) {}
}

export class CompleteFetch implements Action {
  static readonly type = 'COMPLETE_FETCH';
  readonly type = CompleteFetch.type;

  constructor(public fetchAction: Action, public result = undefined) {}
}

export class ErrorFetch implements Action {
  static readonly type = 'ERROR_FETCH';
  readonly type = ErrorFetch.type;

  constructor(public fetchAction: Action, public error) {}
}

export class ResetFetchState implements Action {
  static readonly type = 'RESET_FETCH_STATE';
  readonly type = ResetFetchState.type;

  constructor(public fetchAction: Action) {}
}

type FetchAction = StartFetch | CompleteFetch | ErrorFetch | ResetFetchState;

// // Reducer

export function reducer(
  state: FetchState = {},
  action: FetchAction
): FetchState {
  switch (action.type) {
    case StartFetch.type:
      return Object.assign({}, state, { isFetchInFlight: true });
    case CompleteFetch.type:
      return Object.assign({}, state, {
        isFetchInFlight: false,
        lastLoadTime: Date.now(),
        error: null,
      });
    case ErrorFetch.type:
      return Object.assign({}, state, {
        error: action.error,
        isFetchInFlight: false,
        lastErrorTime: Date.now(),
      });
    case ResetFetchState.type:
      return {};
    default:
      return state;
  }
}

// Selectors

export function isFetchInFlight(state: FetchState = {}): boolean {
  return state.isFetchInFlight;
}

export function isLoaded(state: FetchState = {}): boolean {
  return !!state.lastLoadTime;
}

export function isNotYetFetched(state: FetchState = {}): boolean {
  return !isLoaded(state) && !isFetchInFlight(state) && !hasError(state);
}

export function hasError(state: FetchState = {}) {
  return !!state.error;
}

export function selectAllErrors(state: MetaFetchState): any[] {
  return values(state)
    .map((fetchState) => fetchState.error)
    .filter((error) => error!== null && error !== undefined && error.message !== null && error.message !== undefined && error.message !== '[]')
    .filter(Boolean);
}

// // Meta-reducer (delegates to a normal reducer for each fetchActionType)

export interface MetaFetchState {
  [fetchActionType: string]: FetchState;
}

export function fetchMetaReducer(
  state: MetaFetchState = {},
  action: any
): MetaFetchState {
  const fetchActionType = get(action, 'fetchAction.type') as string;
  if (!fetchActionType) {
    return state;
  }

  const fetchState = state[fetchActionType] || {};

  return Object.assign({}, state, {
    [fetchActionType]: reducer(fetchState, action),
  });
}

// Effects helper for emitting all the right fetchState actions during an http call
// By default it will cancel the previous request if the source action$ stream emits a new action of the same type
// before the previous fetch has finished
// To change that behavior so that all fetch requests go through, provide false the 'shouldCancel' parameter
export function fetchResource(
  fetcher: (action) => Observable<any>,
  shouldCancel = true
) {
  const operator = shouldCancel ? switchMap : mergeMap;
  return function (actions$: Observable<Action>): Observable<Action> {
    return actions$.pipe(
      operator((action) => {
        const response = fetcher(action).pipe(publishReplay(), refCount());
        return observableOf(new StartFetch(action)).pipe(
          concat(response.pipe(filter((a) => !!a.type))), // Dispatch the result of the fetcher if it is an action
          concat(response.pipe(map((res) => new CompleteFetch(action, res)))),
          catchError((err) => observableOf(new ErrorFetch(action, err)))
        );
      })
    );
  };
}

export function fetchOutcome(type: string) {
  return function (actions$: Actions): Observable<CompleteFetch> {
    return actions$.pipe(
      ofType(CompleteFetch.type, ErrorFetch.type),
      filter(
        (action: CompleteFetch | ErrorFetch) => action.fetchAction.type === type
      ),
      map((action: FetchAction) => {
        if (action.type === CompleteFetch.type) {
          return action;
        } else {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          throw (<ErrorFetch>action).error;
        }
      })
    );
  };
}

export function fetchIfUnfetched(
  store: Store<AppState>,
  action: Action,
  until: OnDestroy | Observable<null>
): Subscription {
  return store
    .select(selectFetchActionByType(action.type))
    .pipe(
      select(isNotYetFetched),
      withLatestFrom(
        store.select(selectFetchActionByType(action.type)).pipe(select(isFetchInFlight))
      ),
      filter(
        ([notYetFetched, fetchInFlight]) => notYetFetched && !fetchInFlight
      ),
      until instanceof Observable ? takeUntil(until) : untilDestroyed(until)
    )
    .subscribe(() => store.dispatch(action));
}

const cabFeatureSelector = createFeatureSelector<AppState>('cab');

export const selectFetchStates = createSelector(cabFeatureSelector, (state: AppState) => state?.fetchStates);
export const selectFetchActionByType = (actionType: string) => createSelector(selectFetchStates, (state: FetchState) => state[actionType]);
