import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  merge,
  Observable,
  race,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { first, get } from 'lodash-es';

import { AppState } from '../reducers';
import { getStoreState, httpAbortLoadIndicator, isDefined } from '../utils/utils';
import { AudienceDefinition } from '../audience/audience.models';
import { CabHttpService, ContextType } from '../services/cab-http.service';
import { AudienceService } from '../audience/audience.service';
import { ChangeContext } from '../context/context.actions';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { LoadAudienceDefinition } from '../audience/audience.actions';
import { UpdateCountDetails } from '../audience-builder/audience-builder.actions';
import { convertAudienceDefinitionToBuilder } from '../audience/audience.utils';
import { CabConstants } from '../cab.constants';
import { UtilsService } from '../utils/utilservice';

/*
  1. User requests update counts.
  2. Definition is created or updated
  3. updateCount request is made. Here we have the datetime of when the request was made and saved to the Definition `countUpdatedOn`. Definition `idCount` is set to null.
  4. Polling on Definition to see if `idCount` !== null
  4a. If new update counts request is made, repeat steps above and reset cron max timer.
  5. Update `builderCounts$` and `countUpdating$` and use these as the main subjects for updating UI values.

  Some things to consider (not implemented):
  - If the user refreshes the page, we can no longer poll. All cron instances are no longer in-mem. If we want to start polling on page refresh, we can create cron instances based on Definition values for `idCount` and `countUpdatedOn`
  - If user drops a new segment in builder while counts are still being fetched, we still continue to fetch because it is based off the previously saved query. Do we want to update this behavior? We did already save the previous query so behavior may be strange for user.
*/

// 1 seconds
const CRON_FREQUENCY = 1 * 1000;
// 10 minute
const MAX_CRON_TIMER = 10 * 1000 * 60;

class CronCache {
  _cache: {
    [id: string]: {
      contextId: string;
      dataUniverseId: string;
      startTime: string;
      task$: Subscription;
      cancel$: BehaviorSubject<any>;
      result$: BehaviorSubject<AudienceDefinition>;
    };
  } = {};

  constructor() {
    // CronCache constructor
  }

  add(
    id: string,
    contextId: string,
    dataUniverseId: string,
    startTime: string,
    task$: Subscription,
    cancel$: BehaviorSubject<any>,
    result$: BehaviorSubject<AudienceDefinition>
  ) {
    // remove pre-existing reference if available
    if (this._cache[id]) {
      this.remove(id);
    }
    // TODO: refactor, might not need contextId and dataUniverseId
    this._cache[id] = {
      contextId,
      dataUniverseId,
      startTime,
      task$,
      cancel$,
      result$,
    };
  }

  remove(id: string) {
    if (this._cache[id]) {
      const { task$, cancel$, result$ } = this._cache[id];
      task$.unsubscribe();
      cancel$.unsubscribe();
      result$.complete();
      result$.unsubscribe();
      delete this._cache[id];
    }
  }

  get(id: string) {
    return this._cache[id];
  }

  updateDefinition(audience: AudienceDefinition) {
    const { id } = audience;
    if (this._cache[id] && !this._cache[id].result$.closed) {
      this._cache[id].result$.next(audience);
    }
  }

  reset() {
    Object.keys(this._cache).forEach((id) => this.remove(id));
    this._cache = {};
  }
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CountsService implements OnDestroy {
  contextChanges$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChangeContext.type),
        tap(() => this.cleanup())
      ),
    { dispatch: false }
  );

  private _cronCache = new CronCache();
  private ngUnsubscribe = new Subject<void>();
  public countUpdating$ = new BehaviorSubject(false);
  public builderCount$: BehaviorSubject<{
    displayCount: string;
    countUpdatedOn: string;
    errorMessage: string;
    status: string;
  }> = new BehaviorSubject(this.nullCount());
  public saveDisable$ = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private httpService: CabHttpService,
    private actions$: Actions,
    private store: Store<AppState>,
    private audienceService: AudienceService,
    private builderService: AudienceBuilderService,
    private utilsService: UtilsService
  ) {}

  ngOnDestroy() {
    this.cleanup();
  }

  // if the audience definition has a cached cron, start it back up
  restartCron(definition: AudienceDefinition) {
    const { id, idCount, countRequestedAt, countUpdatedOn, countErrorMessage, countStatus } = definition;

    if (idCount || countUpdatedOn) {
      // in case there is still a cached cron instance, we remove it since we have counts now
      const count = { displayCount: idCount?.toString(), countUpdatedOn, errorMessage: countErrorMessage, status: countStatus };
      this.builderCount$.next(count);
      this._cronCache.remove(id);
      this.countUpdating$.next(false);
      return;
    }

    // on count request, BE sets countRequestedAt and we no longer have an idCount
    if (countRequestedAt && !idCount && !countUpdatedOn) {
      // counts are currently being fetched for this definition
      this.startCron(definition);
      this.countUpdating$.next(true);
    }

    const cachedCron = this._cronCache.get(definition.id);
    if (cachedCron) {
      this.startCron(definition);
      this.countUpdating$.next(true);
    } else if(!idCount && !countUpdatedOn && !countRequestedAt) {
      this.countUpdating$.next(false);
    }
  }

  startCron(
    definition: AudienceDefinition
  ) {
    // in case a cron is already in place, we remove pre-existing cron
    this._cronCache.remove(definition.id);

    const cancel$: BehaviorSubject<any> = new BehaviorSubject(null);
    const result$ = new BehaviorSubject(null);
    const subscription = this.createCron(definition, cancel$).subscribe();
    this._cronCache.add(
      definition.id,
      definition.cabContextId,
      definition.dataUniverseId,
      definition.countRequestedAt,
      subscription,
      cancel$,
      result$
    );

    race(result$.pipe(filter(isDefined)), cancel$.pipe(filter(isDefined)))
      .pipe(take(1), takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        let audience;

        if (typeof result === 'object') {
          const { idCount, countUpdatedOn, countErrorMessage, countStatus } = result;
          const count = { displayCount: idCount?.toString(), countUpdatedOn, errorMessage: countErrorMessage, status: countStatus };
          audience = result;
          this.builderCount$.next(count);
          this._cronCache.remove(definition.id);
          const builderAudience = convertAudienceDefinitionToBuilder(
            audience,
            true
          );

          this.store.dispatch(
            new UpdateCountDetails(
              builderAudience
            )
          );
        } else {
          const storeState = getStoreState(this.store);
          audience = get(storeState, [
            'audiences',
            'audienceDefinitions',
            result,
          ]);
          this.builderCount$.next(this.nullCount());
        }
        this.store.dispatch(new LoadAudienceDefinition(audience));
        this.countUpdating$.next(false);
      });
  }

  createCron(
    definition: AudienceDefinition,
    cancel$
  ): Observable<any> {
    return timer(0, CRON_FREQUENCY).pipe(
      takeUntil(
        merge(
          cancel$.pipe(filter(isDefined)),
          timer(MAX_CRON_TIMER).pipe(tap(() => this.stopCron(definition.id)))
        )
      ),
      switchMap(() => this.getCount(definition, true))
      // switchMap(() => this.getAudienceDefinitionCount(definition.id, definition.cabContextId))
    );
  }

  stopCron(id?: string) {
    this.countUpdating$.next(false);
    const definitionId = id || this.builderService.audience?.id;
    const cancel$ = get(this._cronCache.get(definitionId), 'cancel$');
    if (cancel$ && !cancel$.closed) {
      cancel$.next(definitionId);
    }
  }

  updateCount(audience: AudienceDefinition): Observable<AudienceDefinition> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = audience.cabContextId;
    return this.http
      .post(this.updateCountUrl(audience.id), {}, { headers })
      .pipe(
        map((res: any) => {
          const definition = this.utilsService.isMocked()
            ? new AudienceDefinition(first(res?.entity))
            : new AudienceDefinition(res?.entity);
          this.store.dispatch(new LoadAudienceDefinition(definition));
          return definition;
        })
      ) as Observable<AudienceDefinition>;
  }

  resetBuilderCounts() {
    // if the user changes the definition, we set counts back to null
    // TODO: consider a cancel count route for cases where the user changes definition
    // while we are still fetching counts. That could happen in this method call
    this.builderCount$.next(this.nullCount());
  }

  private getAudienceDefinitionCount(id: string, contextId: string) {
    return this.audienceService
      .fetchAudienceDefinition(id, contextId, true)
      .pipe(
        filter(isDefined),
        tap((definition) => {
          if (!definition) {
            return;
          }

          const { idCount, countUpdatedOn } = definition;
          if (!Number.isInteger(idCount) || !countUpdatedOn) {
            return;
          }

          this._cronCache.updateDefinition(definition);
          this.stopCron(id);
        })
      );
  }

  getCount(definition: AudienceDefinition, abortLoadIndicator = false): Observable<any>{
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = definition.cabContextId;
    const params = abortLoadIndicator ? httpAbortLoadIndicator({}) : {};
    return this.http
    .get(`${this.audienceService.baseUrl()}/audience-definition/${definition.id}/count-request/${definition.countRequestId}`, {
      headers,
      params,
    })
    .pipe(
      filter(isDefined),
      tap((countDetails) => {
        if (!countDetails) {
          return;
        }

        const { count, calculatedAt, errorMessage, status, otherIdentityTypeCounts } = countDetails.entity;
        if (!(Number.isInteger(count) && calculatedAt) && !(status === 'FAILURE' || status === 'SUCCESS')) {
          return;
        }
        definition.count = countDetails.entity;
        definition.idCount = definition.dedupeIdentityType === 'Email' ? (Number.isInteger(otherIdentityTypeCounts.ROOT_DATASET_COUNT) ? otherIdentityTypeCounts.ROOT_DATASET_COUNT : count) : count;
        definition.countUpdatedOn = calculatedAt;
        definition.countErrorMessage = errorMessage;
        definition.countStatus = status;
        this._cronCache.updateDefinition(definition);

        this.stopCron(definition.id);
      })
    );
  }

  private updateCountUrl(id: string) {
    if (this.utilsService.isMocked()) {
      return this.httpService.apiUrl(
        ContextType.DISCOVERY,
        `/id_analytics_api/cab/v1/audience-filter/${id}/count`
      );
    }
    return this.httpService.apiUrl(
      ContextType.CAB,
      `/cab/v1/audience-definition/${id}/count`
    );
  }

  private nullCount() {
    return { displayCount: 'N/A', countUpdatedOn: null, errorMessage:null, status: null };
  }

  private cleanup() {
    this._cronCache.reset();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
