import { Component } from '@angular/core';

import { ContextService } from '@epsilon-cdp/cab-lib';

@Component({
  selector: 'app-not-authorize',
  templateUrl: './not-authorize.component.html',
  styleUrls: ['./not-authorize.component.sass']
})
export class NotAuthorizeComponent {

  constructor(private contextService: ContextService) {
    contextService.hideHeader$.next(true);
  }

}
