import { AudienceQuery, AudienceRuleGroup, AudienceAttributes } from '../audience-builder/audience-builder.models';
import {
  BE_STATUS_COMPLETE, BE_STATUS_ERROR, BE_STATUS_PROCESSING,
  completeStatuses,
  UI_STATUS_COMPLETE,
  UI_STATUS_ERROR,
  UI_STATUS_PROCESSING
} from '../utils/utils';
import { ScheduleDetail } from '../audience-overview/details/schedules/schedules.models';
import { Tag } from '@epsilon-cdp/pcm-common-lib';

export type AudienceStatus = typeof UI_STATUS_PROCESSING | typeof UI_STATUS_COMPLETE | typeof UI_STATUS_ERROR;

export class AudienceDefinition {
  id?: string;
  displayName: string;
  name?: string;
  cabContextId: string;
  dataUniverseId: string;
  query: AudienceQuery;
  count?: number;
  numOfSchedules?: number;
  idCount?: number;
  countUpdatedOn?: string;
  countRequestedAt?: string;
  countRequestId?: string;
  createdBy: string;
  lastModifiedBy: string;
  createdDate: string;
  lastModifiedDate: string;
  dedupeIdentityType?: string;
  version: number;
  canAddNestedDefinitions: boolean;
  type?: string;
  taxonomyCode?: string;
  dataType?: string = 'AudienceDefinition';
  audienceDefinitionId?: string;
  audienceListId?: string;
  templateId?: string;
  schedules?: ScheduleDetail[] | [];
  description?: string;
  status?: string;
  audienceAttributes?: AudienceAttributes;
  countStatus?: string;
  countErrorMessage?: string;
  useCaseDisplayName?: string;
  cabId?: string;
  assetId?: string;
  tags?: Tag[];
  constructor(data) {
    this.id = data.id;
    this.displayName = data.displayName;
    this.name = data.name;
    this.cabContextId = data.cabContextId || data.contextId;
    this.dataUniverseId = data.dataUniverseId;
    this.query = data.query || data.definition;
    this.assetId = data?.assetId;
    if(data.count != null && typeof data.count === 'object') {
      this.idCount = Number.isInteger(data.count.count) ? data.count.count: data.idCount;
    } else if(Number.isInteger(data.count)) {
      this.idCount = data.count;
    } else {
      this.idCount = this.idCount;
    }
    if (data.dedupeIdentityType === 'Email') {
      this.idCount =
        data.count && data.count.otherIdentityTypeCounts && Number.isInteger(data.count.otherIdentityTypeCounts.ROOT_DATASET_COUNT)
          ? data.count.otherIdentityTypeCounts.ROOT_DATASET_COUNT
          : this.idCount;
    }
    this.countUpdatedOn =
      (data.count && data.count.calculatedAt) || data.countUpdatedOn;
    this.countRequestedAt =
      (data.count && data.count.requestedAt) || data.requestedAt;
    this.countRequestId =
      (data.count && data.count.requestId) || data.requestId;
    this.countStatus = data.count?.status;
    this.countErrorMessage = data.count?.errorMessage;
    this.count = data.count;
    this.numOfSchedules = data.numOfSchedules;
    this.createdBy = data.createdBy;
    this.lastModifiedBy = data.lastModifiedBy;
    this.createdDate = data.createdDate;
    this.lastModifiedDate = data.lastModifiedDate;
    this.dedupeIdentityType = data.dedupeIdentityType;
    this.version = data.version || 0;
    this.canAddNestedDefinitions = data.canAddNestedDefinitions;
    this.type = data.productExtractInfo?.type;
    this.taxonomyCode = data.productExtractInfo?.taxonomyCode;
    this.audienceDefinitionId = data?.audienceDefinitionId;
    this.templateId = data.productExtractInfo?.templateId;
    this.schedules = data.schedules;
    this.description = data.description;
    this.audienceAttributes = data.audienceAttributes;
    this.status = data.status;
    this.useCaseDisplayName = data.sendToTargetInfo?.useCaseDisplayName;
    this.cabId = data.cabId;
  }

  // Need to map the object back to a BE compatible object when the object is needed in the request body
  public transformToBECompatibleObject() {
    // TODO: if count properties are all null, should count be null? Might conflict with logic done for getting counts
    return {
      id: this.id,
      //name: this.name, // No need to send name as BE will have a way to set it
      displayName: this.displayName,
      cabContextId: this.cabContextId,
      dataUniverseId: this.dataUniverseId,
      query: this.query,
      count: {
        count: this.idCount,
        calculatedAt: this.countUpdatedOn,
        requestedAt: this.countRequestedAt,
      },
      createdBy: this.createdBy,
      numOfSchedules: this.numOfSchedules,
      lastModifiedBy: this.lastModifiedBy,
      createdDate: this.createdDate,
      lastModifiedDate: this.lastModifiedDate,
      dedupeIdentityType: this.dedupeIdentityType,
      version: this.version,
      description: this.description,
      audienceAttributes: this.audienceAttributes,
    };
  }
}

export class AudienceDefinitionSearchData {
  hasMore?: boolean;
  audienceDefinitions: AudienceDefinition[];
}

export class AudienceSearchData {
  hasMore?: boolean;
  audiences: Audience[];
}

export class AudienceLimitControl {
  controlType: string;
  limit: number;
}

export class AudiencePercentageControl {
  controlType: string;
  percentage: number;
}

// AKA Audience List
export class Audience {
  id?: string;
  audienceDefinitionId?: string;
  displayName: string;
  cabContextId: string;
  dataUniverseId: string;
  query: {
    selectExpressions: any | null;
    includeConditions: AudienceRuleGroup[] | null;
    excludeConditions: AudienceRuleGroup[] | null;
    queryFlags?: {
      caseInsensitive: boolean
    },
  };
  idCount?: number;
  count?: number;
  countUpdatedOn?: string;
  createdBy?: string;
  lastModifiedBy?: string;
  createdDate?: string;
  lastModifiedDate?: string;
  status?: AudienceStatus;
  countStatus?: AudienceStatus;
  dedupeIdentityType?: string;
  version?: number;
  canAddNestedDefinitions?: boolean;
  type?: string;
  taxonomyCode?: string;
  dataType?: string = 'Audience';
  audienceListId?: string;
  templateId?: string;
  description?: string;
  audienceAttributes?: AudienceAttributes;
  controls?: any[];
  name?: string;
  cabId: string;
  assetId?: string;
  tags?: Tag[];
  constructor(data) {
    this.name = data?.name;
    this.id = data.id;
    this.displayName = data.displayName;
    this.audienceDefinitionId = data.audienceDefinitionId;
    this.cabContextId = data.cabContextId || data.contextId;
    this.dataUniverseId = data.dataUniverseId;
    this.query = data.query || data.definition;
    this.count = data.count;
    this.idCount = Number.isInteger(data.count) ? data.count : data.idCount;
    this.countUpdatedOn = data.lastRunAt || data.countUpdatedOn || data.calculatedAt;
    this.createdBy = data.createdBy;
    this.lastModifiedBy = data.lastModifiedBy;
    this.createdDate = data.createdDate;
    this.lastModifiedDate = data.lastModifiedDate;
    this.status = this.transformStatus(data.status || data.audienceListStatus);
    this.dedupeIdentityType = data.dedupeIdentityType;
    this.version = data.version || 0;
    this.canAddNestedDefinitions = data.canAddNestedDefinitions;
    this.type = data.productExtractInfo?.type;
    this.taxonomyCode = data.productExtractInfo?.taxonomyCode;
    this.audienceListId = data?.audienceListId;
    this.templateId = data.productExtractInfo?.templateId;
    this.description = data.description;
    this.countStatus = data.audienceListStatus;
    this.audienceAttributes = data.audienceAttributes;
    this.controls = data?.controls;
    this.cabId = data.cabId;
    this.tags = data?.tags;
    this.assetId = data?.assetId
  }

  public transformToBECompatibleObject(isMock: boolean) {
    return {
      id: this.id,
      //name: this.name, // No need to send name as BE will have a way to set it
      displayName: this.displayName,
      cabContextId: this.cabContextId,
      dataUniverseId: this.dataUniverseId,
      query: this.query,
      count: this.idCount,
      lastRunAt: this.countUpdatedOn,
      createdBy: this.createdBy,
      lastModifiedBy: this.lastModifiedBy,
      createdDate: this.createdDate,
      lastModifiedDate: this.lastModifiedDate,
      [isMock ? 'status' : 'audienceListStatus']: this.transformStatusForBE(
        this.status, isMock
      ),
      dedupeIdentityType: this.dedupeIdentityType,
      audienceDefinitionId: this.audienceDefinitionId,
      version: this.version,
      controls: this.controls,
      audienceAttributes: this.audienceAttributes
    };
  }

  public transformToBECompatibleObjectForUpdate() {
    return {
      id: this.id,
      name: this.name,
      displayName: this.displayName,
      cabContextId: this.cabContextId,
      version: this.version,
      audienceAttributes: this.audienceAttributes
    };
  }

  private transformStatus(status: string): AudienceStatus {
    if (completeStatuses.includes(status)) {
      return  UI_STATUS_COMPLETE;
    } else if (status === BE_STATUS_ERROR) {
      return  UI_STATUS_ERROR;
    }
    return UI_STATUS_PROCESSING;
  }

  private transformStatusForBE(status: string, isMocked: boolean): string {
    if (isMocked) {
      return status;
    }
    if (status === UI_STATUS_COMPLETE) {
      return BE_STATUS_COMPLETE;
    }
    else if (status === UI_STATUS_ERROR) {
      return BE_STATUS_ERROR;
    }
    return BE_STATUS_PROCESSING;
  }
}
