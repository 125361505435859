import { Action } from '@ngrx/store';
import { CampaignData, CampaignExtract } from './campaign-extract.model';
import { ScheduleDetail } from '../audience-overview/details/schedules/schedules.models';

export class DestroyCampaignExtract implements Action {
  static readonly type = 'CAMPAIGN_EXTRACT:DESTROY_CAMPAIGN_EXTRACT';
  readonly type = DestroyCampaignExtract.type;

  constructor(public campaignExtract: CampaignExtract) {}
}

export class DestroyCampaignExtracts implements Action {
  static readonly type = 'CAMPAIGN_EXTRACT:DESTROY_CAMPAIGN_EXTRACTS';
  readonly type = DestroyCampaignExtracts.type;

  constructor() {
    // DestroyCampaignExtracts constructor
  }
}

export class FetchCampaignExtracts implements Action {
  static readonly type = 'CAMPAIGN_EXTRACT:FETCH_CAMPAIGN_EXTRACTS';
  readonly type = FetchCampaignExtracts.type;

  constructor(public contextId: string) {}
}

export class SearchCampaignExtracts implements Action {
  static readonly type = 'CAMPAIGN_EXTRACT:SEARCH_CAMPAIGN_EXTRACTS';
  readonly type = SearchCampaignExtracts.type;

  constructor(public contextId: string, public searchRequest: any) {}
}


export class LoadCampaignExtract implements Action {
  static readonly type = 'CAMPAIGN_EXTRACT:LOAD_CAMPAIGN_EXTRACT';
  readonly type = LoadCampaignExtract.type;

  constructor(public campaignExtract: CampaignExtract) {}
}

export class LoadCampaignExtracts implements Action {
  static readonly type = 'CAMPAIGN_EXTRACT:LOAD_CAMPAIGN_EXTRACTS';
  readonly type = LoadCampaignExtracts.type;

  constructor(public campaignData: CampaignData) {}
}

export class SaveCampaignExtract implements Action {
  static readonly type = 'CAMPAIGN_EXTRACT:SAVE_CAMPAIGN_EXTRACT';
  readonly type = SaveCampaignExtract.type;

  constructor(public campaignExtract: CampaignExtract) {}
}

export class SaveCampaignExtractSchedule implements Action {
  static readonly type = 'CAMPAIGN_EXTRACT:SAVE_CAMPAIGN_EXTRACT_SCHEDULE';
  readonly type = SaveCampaignExtractSchedule.type;

  constructor(public scheduleDetail: ScheduleDetail) {}
}

export class CampaignExtractScheduleSavedSuccessfully implements Action {
  static readonly type = 'CAMPAIGN_EXTRACT:CAMPAIGN_EXTRACT_SCHEDULE_SAVED_SUCCESSFULLY';
  readonly type = CampaignExtractScheduleSavedSuccessfully.type;

  constructor(public scheduleDetail: ScheduleDetail) {}
}

export class RemoveCampaignExtract implements Action {
  static readonly type = 'CAMPAIGN_EXTRACT:REMOVE_CAMPAIGN_EXTRACT';
  readonly type = RemoveCampaignExtract.type;

  constructor(public campaignExtract: CampaignExtract) {}
}

export class RemoveCampaignExtracts implements Action {
  static readonly type = 'CAMPAIGN_EXTRACT:REMOVE_CAMPAIGN_EXTRACTS';
  readonly type = RemoveCampaignExtracts.type;

  constructor(public campaignExtracts: CampaignExtract[]) {}
}

export type ReducerActions =
  | DestroyCampaignExtract
  | DestroyCampaignExtracts
  | FetchCampaignExtracts
  | LoadCampaignExtract
  | LoadCampaignExtracts
  | SaveCampaignExtract
  | SaveCampaignExtractSchedule
  | RemoveCampaignExtract
  | RemoveCampaignExtracts
  | CampaignExtractScheduleSavedSuccessfully;
