import { map as _map, orderBy } from 'lodash-es';

export class DedupeType {
  name: string;
  identityType: string;
  dataSetId?: string;
  attributeIds: string[];
  primaryIdentityType: boolean;
  productIdentityInfo?: ProductIdentityInfo;
  runAudienceListDataSetInfo?: string;
  listSupported?: boolean

  constructor(data: any) {
    this.name = data.displayName;
    this.identityType = data.identityType;
    this.dataSetId = data.dataSetId;
    this.attributeIds = data.attributeIds || [];
    this.primaryIdentityType = data.primaryIdentityType;
    this.productIdentityInfo = data.productIdentityInfo;
    this.runAudienceListDataSetInfo = data.runAudienceListDataSetInfo;
    this.listSupported = data.listSupported;
  }
}

export class ProductIdentityInfo {
  channelType: string;
  goldenProfile: boolean;
  profile: boolean;

  constructor(data) {
    this.channelType = data.channelType;
    this.goldenProfile = data.goldenProfile;
    this.profile = data.profile;
  }

}

export function transformDedupeTypes(dedupeTypes): DedupeType[] {
  return orderBy(
    _map(dedupeTypes, (dedupeType) => new DedupeType(dedupeType)),
    'name'
  );
}
