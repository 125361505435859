import { Action } from '@ngrx/store';
import { Context } from './context.models';

export class ChangeContext implements Action {
  static readonly type = 'CONTEXT:CHANGE_CONTEXT';
  readonly type = ChangeContext.type;

  constructor(public contextId: string) {}
}

export class FetchContextDetails implements Action {
  static readonly type = 'CONTEXT:FETCH_DETAILS';
  readonly type = FetchContextDetails.type;

  constructor(public contextId: string) {}
}

export class LoadContextDetails implements Action {
  static readonly type = 'CONTEXT:LOAD_DETAILS';
  readonly type = LoadContextDetails.type;

  constructor(public contextDetails: Context) {}
}

export class ResetContextDetails implements Action {
  static readonly type = 'CONTEXT:RESET_DETAILS';
  readonly type = ResetContextDetails.type;
}

export type ReducerActions =
  | ChangeContext
  | FetchContextDetails
  | LoadContextDetails
  | ResetContextDetails;
