<coreui-modal modalWidth="40" #saveAsNewDefinitionOnVersionChange>
  <!--
    This modal is used when another user has already updated the definition
    and the current user has made their own changes to the definition.
  -->
  <ng-template #titleTemplate>
    New Audience Definition
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="saveAsNewDefinitionOnVersionChange.hide()" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <input [(ngModel)]="builderService.audience.displayName" coreUIInput placeholder="New Audience Definition"
           autocomplete="off"/>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="text-primary">
      Save
    </button>
  </ng-template>
</coreui-modal>
<ng-container>
  <coreui-breadcrumbs>
    <a [routerLink]="audienceDefinitionRouter" coreUIBreadcrumbLink>Definitions</a>
    <span coreUIBreadcrumbSlash *ngIf="audienceDefinitionName"></span>
    <span coreUIBreadcrumbEnd>{{audienceDefinitionName}}</span>
  </coreui-breadcrumbs>
  <h1 class="audienceName">{{audienceDefinitionName}}</h1>
  <div class="stepWizard">
    <coreui-step-wizard wizardStyle="ordered"
      #orderedWizard
      (change)="onStepChange($event)"
      (next)="onNextStepChange($event)"
      (previous)="onPreviousStepChange($event)"
      >
      <coreui-step [status]="isSetUpComplete ? 'verified' : 'enabled'" label="1. Setup" [active]=true #orderedFirstStep></coreui-step>
      <coreui-step [status]="isSetUpComplete ? 'enabled' : 'disabled'" [label]="isProductTypeDcdp ? '2. Criteria Selection' : '2. Audience Selection'" #orderedLastStep></coreui-step>
    </coreui-step-wizard>
  </div>
  <div *ngIf="currentIndex === 0">
    <lib-audience-builder-setup (setUpSaved)="moveToAudienceSelectionStep()" [audienceForm]="audienceForm" (setUpAndContinue)="builderSetupEvent($event)"></lib-audience-builder-setup>
  </div>
  <div *ngIf="currentIndex === 1">
    <lib-audience-selection class="audienceSelection" [audienceForm]="audienceForm"></lib-audience-selection>
    <div class="stepWizardButtonGroup">
      <div class="spacer"></div>
      <div class="buttons">
    <div coreUIButtonGroup class="submitAction">
      <button coreUIButton (click)="audienceDefSaved.emit({ audienceQueryCaseInsensitive })">Submit</button>
      <button coreUIButton="text-primary" (click)="cancel()">{{'Discard'}}</button>
    </div>
    <a class="previousButton" href="javascript:void(0);" (click)="moveToAudienceSetup()">
      <i coreUIIcon="doubleChevronLeft" class="previousAction "></i>Previous</a>
      </div>
    </div>
  </div>

</ng-container>
