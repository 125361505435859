<div class="drop-group builder-container Core-SiteContent" coreUIDropListGroup
    *ngIf="(errorOnLoadState$ | async) === false" #audienceDefinitionScroll>
    <div class="picker-container" *ngIf="!isBuilderView">
    <lib-picker
        *ngIf="contextId" [ngClass]="limitScroll ? 'limitScroll' : ''" [contextId]="contextId" [showAttributeTypekeyLegend]="true" [audienceForm]="audienceForm"
            [dataUniverseId]="dataUniverseId" [activeDedupeType]="audienceForm?.get('dedupeType')?.value" [isProductTypeDcdp]="isProductTypeDcdp" [channelType]="isProductTypeDcdp ? audienceForm.get('channelType')?.value : ''"></lib-picker>
    </div>
    <lib-builder-form [updateCountSuccess]="updateCountSuccess"  class="libBuilderForm" *ngIf="audienceForm" [activeDedupeType]="audienceForm?.get('dedupeType').value" [isEdit]="isEdit" [audienceForm]="audienceForm"
        (handleInvalidDefinitionError)="handleInvalidDefinitionError()" (submitUpdateCount)="submitUpdateCount()"
        >
    </lib-builder-form>
</div>

<coreui-notification notificationStyle="error" [isPersistent]="false" #errorNotification>
    {{ errorMessage }}
  </coreui-notification>
