export const CONTEXT_AUDIENCE_DEFINITION_QUERY_FROM_TEXT = 'audience.definition.query.from.text';
export const CONTEXT_EXPORT_AUDIENCE = 'exportAudience';
export const CONTEXT_EXTRACT_AMS_GENERATE_FILE = 'extract.ams.generate.file';
export const CONTEXT_EXTRACT_CAMPAIGN_METADATA_ENABLED = 'extract.campaign.metadata.enabled';
export const CONTEXT_EXTRACT_FROM_LIST_ENABLED = 'extract.from.list.enabled';
export const CONTEXT_FOUNDATIONS_DOAPI_USE_NAME_AS_ID = 'foundations.doapi.use.name.as.id';
export const CONTEXT_LIST_SUPPORTED = 'list.supported';
export const CONTEXT_SEND_NOTIFICATION_ENABLED = 'send.notification.enabled';
export const CONTEXT_UI_USE_ADH_FOR_LIST_SEND_TO = 'ui.use.adh.for.list.send.to';
export const CONTEXT_UI_USE_TAMS_FOR_ACTIVITY_LISTING = 'ui.use.tams.for.activity.listing';
export const CONTEXT_UI_USE_TAMS_FOR_ACTIVITY_TRACKING = 'ui.use.tams.for.activity.tracking';
export const CONTEXT_AUDIENCE_DEFINITION_AUDIENCE_AI = 'audience.definition.audienceAI';