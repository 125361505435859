import { Component, OnInit, ViewChild,} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableHeaderColumnComponent } from '@epsilon/core-ui';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { AppState } from '../../reducers';
import { DataSet, EnumData, EnumSet, Relations } from '../admin.models';
import { AdminService } from '../admin.service';
import { UtilsService } from '../../utils/utilservice';

@UntilDestroy()
@Component({
  selector: 'lib-dataset',
  templateUrl: './dataset.component.html',
  styleUrls: ['./dataset.component.sass']
})
export class DatasetComponent implements OnInit {

  @ViewChild('failedProductDataSetIds', { static: true }) public failedProductDataSetIds;
  @ViewChild('unSavedProductDataSetIds', { static: true }) public unSavedProductDataSetIds;
  dataSetId: string;
  contextId: string;
  dataset: DataSet;
  originalDataSet: DataSet;
  attributes: any;
  failureNotification= true;
  isSaveDisabled = false;
  onEditDataSet = new Map();
  scrollItems: any[];
  defaultSortOption = 'Product';
  defaultSortDirection = 'ASC';
  relations: Relations[];
  loadEnumDetails = false;
  showSearchLimit = false;
  selectEnumDropDown = new Map<string, Set<EnumSet>>();
  enumSearchForAttribute = new Map();
  validateEnumsDataTypes = new Map();
  datasetForm = new UntypedFormGroup({
      description: new UntypedFormControl(''),
    },
  );
  public setFormGroupForAttributes = new UntypedFormGroup({
  });
  menus: any[] = [
    {
      name: 'Manage Joins',
    },
    {
      name: 'Remove Table',
    }
  ];
  actionInProgress$ = new BehaviorSubject<{
    type: string;
    dataset: DataSet;
  } | null>(null);

  constructor(
    public store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.selectEnumDropDown.set('', (new Set<EnumSet>()).add({id: null, displayName: 'None'}));
    this.dataSetId = this.route.snapshot.paramMap.get('datasetId');
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.adminService.getCabDataSet(this.contextId, this.dataSetId).subscribe((res: DataSet) => {
      this.setFormGroupForAttributes.addControl('displayName',  new UntypedFormControl(res.displayName));
      this.dataset = res;
      this.adminService.fetchRelations(this.contextId, this.dataset.dataUniverseId, this.dataset.id).subscribe((result: any) => {
        this.relations = result;
      });
      this.fetchListOfEnums();
    });
  }

  setFormControls(){
    this.dataset.attributes.forEach(item => {
      this.setFormGroupForAttributes.addControl('attribDispName_inlineEdit_'+item.id, new UntypedFormControl(item.displayName));
      this.setFormGroupForAttributes.addControl('builder_'+item.id, new UntypedFormControl(!item.hidden));
      this.setFormGroupForAttributes.addControl('enum_'+item.id, new UntypedFormControl({value: this.getEnumValue(item.entityEnumId, item.dataType), disabled: false}));
    });
  }
  getEnumValue(enumId, dataType){
    if(this.selectEnumDropDown.has(dataType)){
      const enumsList = Array.from(this.selectEnumDropDown.get(dataType) || []).filter(item => item.id === enumId);
      return enumsList.length > 0 ? enumId : null;
    } else {
      return null;
    }
  }

  onLoadFetchListOfEnums(dataType, id){
    this.showSearchLimit = false;
    this.enumSearchForAttribute.delete(id);
    if(this.validateEnumsDataTypes.has(dataType)){
      return;
    }
    const enumPayload = {
      cabContextId:this.dataset.cabContextId,
      displayName: '',
      limit: 100,
      dataType,
      entityEnumIds: []
    };
    this.adminService.fetchListOfEnums(this.contextId, enumPayload).subscribe((response: EnumData) => {
      this.mapEnumByDataTypes(response);
      this.validateEnumsDataTypes.set(dataType, true);
    });
  }

  mapEnumByDataTypes(data: EnumData){
    data.enumSets.forEach(enumSet => {
      if(!this.selectEnumDropDown.has(enumSet.dataType)){
        this.selectEnumDropDown.set(enumSet.dataType, new Set());
      }
      const enumsList = Array.from(this.selectEnumDropDown.get(enumSet.dataType) || []).filter(item => item.id === enumSet.id);
      if(enumsList.length === 0){
        this.selectEnumDropDown.get(enumSet.dataType).add(enumSet);
      }
    });
  }

  getEnumKeys(dataType, id){
    let loadedEnumDataTypes = this.enumSearchForAttribute.get(id) || this.selectEnumDropDown.get(dataType) || [];
    if(!this.enumSearchForAttribute.get(id)){
      loadedEnumDataTypes = Array.from(this.selectEnumDropDown.get('')).concat(Array.from(loadedEnumDataTypes));
    }
    return loadedEnumDataTypes;
  }

  fetchListOfEnums(){
    const entityEnumIdsList = new Set();
    this.dataset.attributes.map(item => {
      if(item.entityEnumId){
        entityEnumIdsList.add(item.entityEnumId);
      }
    });
    const enumList = Array.from(entityEnumIdsList);
    if(enumList.length === 0){
      this.setFormControls();
      this.loadEnumDetails = true;
      return;
    }
    const enumPayload = {
      cabContextId:this.dataset.cabContextId,
      displayName: '',
      limit: 100,
      entityEnumIds: enumList
    };
    this.adminService.fetchListOfEnums(this.contextId, enumPayload).subscribe((response: EnumData) => {
      this.mapEnumByDataTypes(response);
      this.setFormControls();
      this.loadEnumDetails = true;
    });
  }

  onEnumSearchChange(searchText, id,dataType) {
    searchText = searchText.trim();
    if(searchText.length === 0){
      this.showSearchLimit = false;
      this.enumSearchForAttribute.delete(id);
      return;
    } else if(searchText.length > 0 && searchText.length < 3){
      this.showSearchLimit = true;
      return;
    }else{
      this.enumSearchForAttribute.delete(id);
      this.showSearchLimit = false;
    }

    const enumPayload = {
      cabContextId:this.dataset.cabContextId,
      displayName: searchText,
      dataType,
      limit: 100,
    };
    this.adminService.fetchListOfEnums(this.contextId, enumPayload).subscribe((response: EnumData) => {
      this.enumSearchForAttribute.set(id, response.enumSets);
    });
  }

  backToMetaData() {
    this.backToAdmin();
  }

  defaultSort(sortComponent: TableHeaderColumnComponent, sortOption) {
    this.defaultSortOption = sortOption;
    this.defaultSortDirection = sortComponent.sortDirection;
  }

  sortAscDesc(sortType) {
    return sortType === 'ASC' ? 'ascending' : 'descending';
  }

  onEditHandler($event, dataSet){
    this.onEditDataSet.set(dataSet.id, $event.value);
  }

   onApproveHandler(dataset, formControl){
    dataset.displayName = this.setFormGroupForAttributes.get(formControl).value;
  }

  onValueChange($event, index){
    this.dataset.attributes[index].hidden = !$event;
  }

  onSelectionOfEnum($event, attributes){
    attributes.entityEnumId = $event;
  }

  validateEditedDataSetIds(){
    const results = [];
    const data = [{id: this.dataset.id, displayName: this.dataset.displayName}].concat(this.dataset.attributes);
    data.map(item => {
      const entry = this.onEditDataSet.get(item.id);
      if(entry !== undefined && entry !== item.displayName){
        results.push(entry);
      }
    });
    return results.length > 0;
  }

  onSaveHandler(){
    const editedDataSetIdsValidation = this.validateEditedDataSetIds();
    if(editedDataSetIdsValidation){
      this.unSavedProductDataSetIds.show();
      return;
    }
    const attributespayLoad = {
      attributes : this.dataset.attributes,
      displayName: this.dataset.displayName,
      productDataSetInfo: this.dataset.productDataSetInfo,
      updateType: 'BASIC_AND_ATTRIBUTES',
      version: this.dataset.version,
      id: this.dataSetId,
      dataUniverseId: this.dataset.dataUniverseId,
      cabContextId: this.dataset.cabContextId
    };
    this.adminService.updateDatasetInformation(this.contextId, this.dataSetId, attributespayLoad)
    .subscribe({
        next: () => {
          this.backToAdmin();
        },
        error: () => {
          this.failedProductDataSetIds.show();
        }
      });
  }

  onCancelHandler(){
    this.backToAdmin();
  }

  backToAdmin(){
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      'admin'
    ]);
  }

  showMangeJoinsModal() {
    this.actionInProgress$.next({
      type: 'manageJoins',
      dataset: this.dataset,
    });
  }

  closeManageJoins() {
    this.actionInProgress$.next(null);
  }
}
