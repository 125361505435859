import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ModalComponent, TabGroupComponent } from '@epsilon/core-ui';
import { fetchOutcome } from '../utils/fetch-state';
import { DestroyAudienceDefinition } from '../audience/audience.actions';
import { SetPrebuiltAudience } from '../audience-builder/audience-builder.actions';
import { AppState } from '../reducers';
import { selectAudienceForCreateList } from '../audience/audience.reducer';
import { selectContext } from '../context/context.reducer';
import { BuilderAudience } from '../audience-builder/audience-builder.models';
import { AudienceDefinition } from '../audience/audience.models';
import { DedupeType } from '../models/dedupe-type';
import { MAX_NAME_LENGTH, getAudienceDedupeType } from '../audience/audience.utils';
import {
  FEATURE_AUDIENCES,
  FEATURE_AUDIENCE_QUERY_CASE_INSENSITIVE,
  FEATURE_ACTIVATION_SCHEDULES
} from '../utils/feature-utils';
import { BUILDER_TXT, DEFINITION_TXT, showToastNotification } from '../utils/utils';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { CabConstants } from '../cab.constants';
import { DiscardTypes } from '../enums/discard-types';
import { FeatureService } from '../utils/feature-service';
import { UtilsService } from '../utils/utilservice';
import { selectDataUniverses } from '../data-universe/data-universe.reducer';
import { DataUniverse } from '../data-universe/data-universe.models';
import { FeatureAccessService } from '../feature-access/feature.access.service';
import { Action } from '../feature-access/feature.access.action';
import { AssetService, AssetType } from '@epsilon-cdp/pcm-common-lib';

@UntilDestroy()
@Component({
  selector: 'lib-audience-definition-tab-flow',
  templateUrl: './audience-definition-tab-flow.component.html',
  styleUrls: ['./audience-definition-tab-flow.component.sass'],
})
export class AudienceDefinitionTabFlowComponent implements AfterViewInit, OnDestroy, OnInit,OnChanges {
  @ViewChild(TabGroupComponent, { static: true }) public tabGroup: TabGroupComponent;
  @ViewChild('successCreatingList', { static: true }) public successCreatingList;
  @ViewChild('deleteActionModal') public deleteActionModal;
  @ViewChild('destroyError', { static: true }) public destroyError;
  @Output() audienceDefSaved = new EventEmitter();
  @Output() discardChanges = new EventEmitter();
  @Input() audienceForm: UntypedFormGroup;
  dedupeTypes: DedupeType[];
  dedupeIdentityType: string;
  errorMessage: string | void;
  private tabChangeSubscription: Subscription;
  contextId: string;
  dataUniverseId: string;
  audienceDefinitionRouter: string;
  audienceDefinitionId: string;
  builderAudience: BuilderAudience;
  definitionName: string;
  descriptionName: string;
  isCriteriaTabActive = true;
  productType: string;
  isProductTypeDcdp: boolean;
  audiencesEnabled: boolean;
  showCreateList = false;
  destroyErrorMessage: string;
  destroyErrorHeader: string;
  nestedDefinitions: string[];
  audienceDefinition: AudienceDefinition;
  selectedTab = '';
  showDiscardChanges= false;

  dropdownListActionsPaid: any = [];
  dropdownListActionsOwned: any = [];
  caseInsensitiveEnabled = false;
  isSchedulesTabActive: boolean;
  activationSchedulesEnabled: boolean;
  isBuilderView: boolean;
  dataUniverses: DataUniverse[];
  isEditAudience = false;

  constructor(
    public store: Store<AppState>,
    public formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private actions$: Actions,
    public builderService: AudienceBuilderService,
    public featureService: FeatureService,
    private utilsService: UtilsService,
    public featureAccessService: FeatureAccessService,
    private assetService: AssetService
  ) {
    this.isBuilderView = this.router.url.includes(`${BUILDER_TXT}/view`)
    this.audienceDefinitionId = this.route.snapshot.paramMap.get('definitionId');
    if (this.audienceDefinitionId) {
      this.isEditAudience = true;
    }
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.productType = context?.productType;
        if(context?.productType === CabConstants.DCDP_PRODUCT_NAME) {
          this.isProductTypeDcdp = true;
        }
      });
      this.caseInsensitiveEnabled = this.featureService.isFeatureEnabled(FEATURE_AUDIENCE_QUERY_CASE_INSENSITIVE);
      this.builderAudience = this.builderService?.audience;

    this.store
      .select(selectAudienceForCreateList)
      .pipe(untilDestroyed(this))
      .subscribe(
        (definition) => {
          this.audienceDefinition = definition;
        }
      );
      this.store
      .select(selectDataUniverses)
      .pipe(untilDestroyed(this))
      .subscribe((dataUniverses) => (this.dataUniverses = dataUniverses));
  }

  ngOnInit() {
    if(history?.state?.tab === 'criteria Selection')
    {
      this.isCriteriaTabActive = true;
    }
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.dataUniverseId = this.route.snapshot.paramMap.get('dataUniverseId');
    this.audienceDefinitionRouter =  '/'+this.utilsService.getProductBaseUrl(this.router, this.route)+'/'+ this.contextId+'/'+DEFINITION_TXT;
    this.builderService.audienceBuilderCriteriaManually$.next(false);
    this.audiencesEnabled= this.featureService.isFeatureEnabled(FEATURE_AUDIENCES);
    this.activationSchedulesEnabled = this.featureService.isFeatureEnabled(FEATURE_ACTIVATION_SCHEDULES);
    this.dropdownListActionsPaid = this.dropdownListsActionsPaid();
    this.dropdownListActionsOwned = this.dropdownListsActionsOwned();
  }

  ngAfterViewInit() {
      this.tabChangeSubscription = this.tabGroup.tabChange.subscribe(() => {
        // tabGroup subscribe
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['audienceForm'] && changes['audienceForm'].currentValue) {
      this.definitionName = changes['audienceForm'].currentValue?.get('displayName')?.value;
      this.descriptionName = changes['audienceForm'].currentValue?.get('description')?.value;
    }
  }
  ngOnDestroy() {
    this.store.dispatch(
      new SetPrebuiltAudience(null));
      this.tabChangeSubscription?.unsubscribe();
  }

  cancel() {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      DEFINITION_TXT,
    ]);
  }

  validateSetUpAndSave() {
    if (!this.audienceForm.valid) {
      this.errorMessage = this.nameFieldErrorMessage();
    } else {
      this.saveDefinition();
      if(this.isEditAudience && this.audienceDefinitionId){
        this.associateTags();
      }
    }
  }

  associateTags() {
    const selectedAssetTags = this.audienceForm.get('assetTags')?.value;
    const assetTags$ = this.audienceDefinitionId ? selectedAssetTags?.map(value => ({
      namespace: '',
      tag: value
    })) : [];

    const tagAssociationRequest = {
      assetId: this.audienceDefinitionId,
      tags: assetTags$,
      type: AssetType.AUDIENCE_DEFINITION
    };

    this.assetService.associateTags(tagAssociationRequest).pipe(untilDestroyed(this)).subscribe();
  }

  nameFieldErrorMessage(): string | void {
    const errors = this.audienceForm.controls['displayName'].errors;
    if (!errors) {
      return;
    }
    const {required, maxlength, nameTaken, whitespace} = errors;
    if (required || whitespace) {
      return 'Required';
    }
    if (maxlength?.actualLength > maxlength?.requiredLength) {
      return `Name cannot be longer than ${MAX_NAME_LENGTH} characters.`;
    }
    if (nameTaken) {
      return 'This name is taken.';
    }
    console.error(errors);
    return 'An unknown error occurred.';
  }

  isActivationSchedulesTabActive() {
    this.isSchedulesTabActive = true;
    this.isCriteriaTabActive = false;
  }

  onSaveCallback(){
    this.definitionName = this.audienceForm.get('displayName').value;
    this.descriptionName = this.audienceForm.get('description').value;
  }

  onSaveDefinition() {
    this.saveDefinition();
  }

  saveDefinition() {
    this.audienceDefSaved.emit();
  }

  closeCreateListModal(createListStatus?: 'success' | 'error') {
    if (createListStatus === 'success') {
      showToastNotification(this.successCreatingList);
    }
    this.showCreateList = false;
  }

  public deleteAction(modal: ModalComponent) {
    this.store.dispatch(new DestroyAudienceDefinition(this.audienceDefinition));
    this.actions$
      .pipe(fetchOutcome(DestroyAudienceDefinition.type), take(1))
      .subscribe({
        next: () => {
          this.router.navigate(
            [
              this.utilsService.getProductBaseUrl(this.router, this.route),
              this.contextId,
              DEFINITION_TXT
            ],
            { state: { showDeleteDefinitionSuccess: true, audienceDefinitionName: this.audienceDefinition.displayName } }
          );
        },
        error: ({ error }) => {
          const errorDetails: Array<{ errorMessage: string }> = JSON.parse(error).errorDetails;
          const errorReferences: Array<string> = errorDetails?.[0]?.errorMessage?.split('is referenced in ');
          this.nestedDefinitions = errorReferences?.[1]?.split(',').map(nestedDef => nestedDef.trim());
          this.destroyErrorHeader = 'Unable to delete this Object';
          if (this.nestedDefinitions?.length > 1) {
            this.destroyErrorMessage = 'This object can’t be deleted because they’re nested in the following ' + this.nestedDefinitions.length + ' definitions or lists.';
          } else {
            this.destroyErrorMessage = 'This object can’t be deleted because it\'s nested in the following definition.';
          }
          this.destroyError.show();
        }
      });
    this.closeModal(modal);
  }

  closeModal(modal: ModalComponent) {
    modal.hide();
  }

  canDeActivateTab(tab: any) {
    this.selectedTab = tab.label;
    const isFormDirty = this.selectedTab ==='Setup' ? this.audienceForm.dirty : this.builderService.audienceBuilderCriteriaManually$.value;
    if(isFormDirty){
      this.showDiscardChanges = true;
    } else {
      this.isCriteriaTabActive = (this.selectedTab ==='Setup');
    }
    return !isFormDirty;
  }

  public canDeActivateTabThis = this.canDeActivateTab.bind(this);


  onDiscardEventChanges($event){
    if($event === DiscardTypes.DISCARD_AND_CONTINUE){
      this.discardAndContinue();
    } else if ($event === DiscardTypes.BACK_TO_EDITING){
      this.backToEditing();
    }
  }

  discardAndContinue(){
    this.builderService.audienceBuilderCriteriaManually$.next(false);
    if(!this.isCriteriaTabActive) {
      this.audienceForm.get('displayName').setValue(this.audienceDefinition?.displayName);
      this.audienceForm.get('description').setValue(this.audienceDefinition?.description);
    }
    this.discardChanges.emit();
    this.audienceForm.markAsPristine();
    this.audienceForm.markAsUntouched();
    this.isCriteriaTabActive = !(this.isCriteriaTabActive);
    this.showDiscardChanges = false;
  }
  backToEditing(){
    this.showDiscardChanges = false;
  }

  public getActionsForDefinition(
    definition: AudienceDefinition,
    dropdownListActions: { display: string }[]
  ): { display: string }[] {
    const { dataUniverseId, dedupeIdentityType } = definition;
    const dedupeTypeListSupported = getAudienceDedupeType(
      dataUniverseId,
      dedupeIdentityType,
      this.dataUniverses
    );
    if (!dedupeTypeListSupported?.listSupported) {
      dropdownListActions = dropdownListActions.filter(
        (item) => item.display !== 'Create Audience List'
      );
    }
    return dropdownListActions;
  }

  dropdownListsActionsPaid() {
    if (this.featureAccessService.hasAccess(Action.AUDIENCE_DEFINITION_WRITE)) {
      this.dropdownListActionsPaid.push({
        display: 'Create Audience List',
        onClick: () => {
          this.showCreateList = true;
        },
        show: this.audiencesEnabled
      });
    }
    if (this.featureAccessService.hasAccess(Action.AUDIENCE_PAID_RUN)) {
      this.dropdownListActionsPaid.push({
        display: 'Deliver To...',
        onClick: (definition) => {
          this.router.navigate([
            this.utilsService.getProductBaseUrl(this.router, this.route),
            this.contextId,
            this.route.snapshot.paramMap.get('dataUniverseId'),
            this.route.snapshot.paramMap.get('definitionId'),
            'ams'
          ], {queryParams: {audienceId: definition.audienceAttributes.amsAudienceId}});
        },
        show: true
      });
    }
    if (this.featureAccessService.hasAccess(Action.DEFINITION_DELETE)) {
      this.dropdownListActionsPaid.push({
        display: 'Delete',
        onClick: () => {
          this.deleteActionModal.show();
        },
        show: true
      });
    }
    return this.dropdownListActionsPaid;
  }

  dropdownListsActionsOwned() {
    if (this.featureAccessService.hasAccess(Action.AUDIENCE_DEFINITION_WRITE)) {
      this.dropdownListActionsOwned.push({
        display: 'Create Audience List',
        onClick: () => {
          this.showCreateList = true;
        },
        show: this.audiencesEnabled
      });
    }
    if (this.featureAccessService.hasAccess(Action.AUDIENCE_OWNED_RUN)) {
      this.dropdownListActionsOwned.push({
        display: 'Deliver To...',
        onClick: (definition) => {
          this.router.navigate([
            this.utilsService.getProductBaseUrl(this.router, this.route),
            this.contextId,
            this.route.snapshot.paramMap.get('dataUniverseId'),
            this.route.snapshot.paramMap.get('definitionId'),
            'sendTo'
          ], {queryParams: {cabId: definition.cabId, type: 'defintion'}});
        },
        show: true
      });
    }
    if (this.featureAccessService.hasAccess(Action.DEFINITION_DELETE)) {
      this.dropdownListActionsOwned.push({
        display: 'Delete',
        onClick: () => {
          this.deleteActionModal.show();
        },
        show: true
      });
    }
    return this.dropdownListActionsOwned;
  }
}
