import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CabHttpService, ContextType } from '../services/cab-http.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  constructor(private http: HttpClient, private httpService: CabHttpService) {}

  updateMetadata(contextId: string): Observable<any> {
    const headers = {};
    headers['x-cab-context'] = contextId;
    const url = this.httpService.apiUrl(
      ContextType.CAB,
      '/cab/v1/admin/dataset/sync-metadata');
    return this.http.post(url, {}, { headers }).pipe(
      map(res => res)
    );
  }
}
