<div class="setup-container">
  <form *ngIf="!isBuilderView" class="Core-Form" [formGroup]="audienceForm">
    <coreui-form-field label="Name" [includeBottomMargin]="false">
      <input coreUIInput maxlength="200" formControlName="displayName" (blur)="validateDefinitionName()"
        autocomplete="off" />
      <coreui-form-error>{{ errorMessage }}</coreui-form-error>
    </coreui-form-field>
    <coreui-form-field [label]="optionalLabel" [includeBottomMargin]="false">
      <ng-template #optionalLabel>Description <span class="text-style-muted-4">(optional)</span></ng-template>
        <textarea rows="5" coreUIInput formControlName="description"></textarea>
    </coreui-form-field>
    <lib-pcm-common-tags *ngIf="allowTagsEnabled" [readonly]="isTagsReadOnly" [tags]="addedTags" [allowCreateTag]="true" [assetType]="AssetType.AUDIENCE_DEFINITION" (selectedTags)="selectedTags($event)"></lib-pcm-common-tags>
    <ng-container *ngIf="productType === 'DCDP'">
      <coreui-radio-group [label]="channelType" orientation="vertical" [includeBottomMargin]="false">
        <ng-template #channelType>
          <span>Communication Channel for this Audience</span>
        </ng-template>
        <ng-container *ngIf="isPaidchannelTypeFeatureEnabled">
          <coreui-form-field label="Paid channels (ie. Digital TV, CTV, etc.)" [includeBottomMargin]="false">
            <input type="radio" coreUIRadio value="PAID" name="channelType" (click)="!isEditAudience" formControlName="channelType" checked/>
          </coreui-form-field>
        </ng-container>
        <coreui-form-field label="Owned channels (Email, Direct Mail, Etc.)">
          <input type="radio" coreUIRadio value="OWNED" name="channelType" (click)="!isEditAudience" formControlName="channelType"/>
        </coreui-form-field>
      </coreui-radio-group>
    </ng-container>

    <div *ngIf="audienceForm.get('channelType').value === 'OWNED' && productType === 'DCDP'">
      <coreui-radio-group [label]="profileType" orientation="vertical" [includeBottomMargin]="false">
        <ng-template #profileType>
          <span>This audience consists of:</span>
        </ng-template>
        <coreui-form-field label="Golden profiles" *ngIf="profile360GoldenProfileEnabled">
          <input type="radio" coreUIRadio value="goldenprofile" name="profileType" formControlName="profileType" />
        </coreui-form-field>
        <coreui-form-field label="All profiles">
          <input type="radio" coreUIRadio value="allprofiles" name="profileType" formControlName="profileType" />
        </coreui-form-field>
        <coreui-form-field *ngIf="isProspectEnabled" label="Prospects">
          <input type="radio" coreUIRadio value="prospects" name="profileType" formControlName="profileType" />
        </coreui-form-field>
      </coreui-radio-group>

      <ng-container *ngIf="(audienceForm.get('profileType')?.value === 'allprofiles')">
        <coreui-checkbox-group orientation="horizontal" class="is-dedupe-check" [includeBottomMargin]="false">
          <coreui-form-field label="Dedupe">
            <input type="checkbox" coreUICheckbox formControlName="dedupeAllProfiles" />
          </coreui-form-field>
        </coreui-checkbox-group>
        
      <div class="Dedupe-container">
        <ng-container *ngIf="audienceForm.get('dedupeAllProfiles')?.value">
          <coreui-radio-group orientation="vertical" [includeBottomMargin]="false">
            <ng-container *ngFor="let dedupeType of allProfileDedupeTypes.allProfiles">
              <coreui-form-field [label]=" 'By '+dedupeType.name" *ngIf="isIdentityTypeEnabled(dedupeType.identityType)" [includeBottomMargin]="false">
                <input type="radio" coreUIRadio [value]="dedupeType.identityType" name="identityType" class="dedupeByType" formControlName="identityType">
              </coreui-form-field>
              <div class="alternateKeyDropdown" *ngIf="dedupeType.identityType === 'AlternateKey' && audienceForm.get('identityType').value === 'AlternateKey'">
                <ng-container >
                  <coreui-form-field>
                  <coreui-select placeholder="Not Selected" formControlName="alternateKey"
                  #selectInError>
                  <coreui-option *ngFor="let alternativeKey of alternativeKeyList" [value]="alternativeKey">
                    {{ alternativeKey }}
                  </coreui-option>
                </coreui-select>
                <coreui-form-error>{{ alernateKeyErrorMessage }}</coreui-form-error>
              </coreui-form-field>
                </ng-container>
              </div>  
            </ng-container>
          </coreui-radio-group>
        </ng-container>
      </div>
      </ng-container>
      <ng-container *ngIf="isGoldenProfileDedupeEnabled && audienceForm.get('profileType')?.value === 'goldenprofile'">
        <coreui-checkbox-group orientation="horizontal" class="is-dedupe-check">
          <coreui-form-field label="Dedupe">
            <input type="checkbox" coreUICheckbox formControlName="dedupeGoldenProfile" />
          </coreui-form-field>
        </coreui-checkbox-group>
        <ng-container *ngIf="audienceForm.get('dedupeGoldenProfile')?.value">
          <coreui-radio-group orientation="vertical">
            <ng-container *ngFor="let dedupeType of allProfileDedupeTypes.goldenprofile">
              <coreui-form-field [label]=" 'By '+dedupeType.name" >
                <input type="radio" coreUIRadio [value]="dedupeType.identityType" name="identityType" class="dedupeByType" formControlName="identityType">
              </coreui-form-field>
              <div class="alternateKeyDropdown" *ngIf="dedupeType.identityType === 'GoldenAlternateKey' && audienceForm.get('identityType').value === 'GoldenAlternateKey'">
                <ng-container >
                  <coreui-form-field>
                  <coreui-select placeholder="Not Selected" formControlName="alternateKey"
                  #selectInError>
                  <coreui-option *ngFor="let alternativeKey of alternativeKeyList" [value]="alternativeKey">
                    {{ alternativeKey }}
                  </coreui-option>
                </coreui-select>
                <coreui-form-error>{{ alernateKeyErrorMessage }}</coreui-form-error>
              </coreui-form-field>
                </ng-container>
              </div> 
            </ng-container>
          </coreui-radio-group>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="(audienceForm.get('profileType')?.value === 'prospects')">
        <coreui-checkbox-group orientation="horizontal" class="is-dedupe-check">
          <coreui-form-field label="Dedupe" >
            <input type="checkbox" coreUICheckbox formControlName="dedupeAllProfiles" />
          </coreui-form-field>
        </coreui-checkbox-group>
        <ng-container *ngIf="audienceForm.get('dedupeAllProfiles')?.value">
          <coreui-radio-group orientation="vertical">
            <ng-container *ngFor="let dedupeType of allProfileDedupeTypes.prospects">
              <coreui-form-field [label]=" 'By '+dedupeType.name" >
                <input type="radio" coreUIRadio [value]="dedupeType.identityType" name="identityType" class="dedupeByType" formControlName="identityType">
              </coreui-form-field>
            </ng-container>
          </coreui-radio-group>
        </ng-container>
      </ng-container>
    </div>

    <div
      class="dedupe-select"
      *ngIf="dedupeTypes?.length && productType !== 'DCDP'  && !isBuilderView"
      [formGroup]="audienceForm"
    >
      <coreui-form-field label="Dedupe Type">
        <coreui-select [disabled]="isEditAudience"
                       formControlName="identityType"
        >
          <ng-container *ngFor="let dedupeType of dedupeTypes">
            <coreui-option
              [value]="dedupeType.identityType"
              class="text-style-5"
              [displayValue]="dedupeType.name"
            >
              {{ dedupeType.name }}
            </coreui-option>
          </ng-container>
        </coreui-select>
      </coreui-form-field>
    </div>
  </form>
  <div *ngIf="isBuilderView">
    <div class="viewContainer">
      <div>Name</div>
      <div class="viewValue">{{audienceForm.get('displayName').value}}</div>
    </div>
    <div class="viewContainer">
      <div>Description</div>
      <div class="viewValue">{{audienceForm.get('description')?.value || '--'}}</div>
    </div>
    <div class="viewContainer">
      <div>Communication Channel for this Audience</div>
      <div class="viewValue" *ngIf="isPaidchannelTypeFeatureEnabled">Paid channels (ie. Digital TV, CTV, etc.)</div>
    </div>

  </div>
  <div coreUIButtonGroup *ngIf="!isEditAudience" class="submitAction">
    <button coreUIButton *ngIf="!isBuilderView" (click)="validateSetUpAndContinue()">{{ isEditAudience ? 'Save Changes' : 'Save &amp; Continue'}}</button>
    <button coreUIButton="text-primary" (click)="cancel()">{{isEditAudience ? 'Back to all definitions' :'Discard'}}</button>
  </div>
</div>
<coreui-modal modalWidth="40" #updateDedupeValueWarning>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon warning"></span> Are you sure?
  </ng-template>

  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="cancelDedupeChanges()" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>

  <ng-template #contentTemplate>
    <p>
      Changing the Dedupe type will remove all Include and Exclude conditions. Would you like to proceed?
    </p>
  </ng-template>

  <ng-template #buttonsTemplate>
    <button coreUIButton="text-primary" (click)="saveDedupeWarningChanges()">
      Continue
    </button>
    <button coreUIButton="secondary" (click)="cancelDedupeChanges()">
      Cancel
    </button>
  </ng-template>
</coreui-modal>
<coreui-modal modalWidth="40" #updateDedupeValueWarningDcdp>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon warning"></span> Your Attribute Selection Will Be Lost!
  </ng-template>

  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="cancelDedupeChanges()" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>

  <ng-template #contentTemplate>
    <p>
      You will lose your attribute selection on the next tab if you switch between golden profiles and all profiles.
      <br><br>
      Are you sure you want to make this change?
    </p>
  </ng-template>

  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="cancelDedupeChanges()">
      Cancel
    </button>
    <button coreUIButton (click)="saveDedupeWarningChanges()">
      Continue with Changes
    </button>
  </ng-template>
</coreui-modal>
