<div [formGroup]="formGroup">
  <coreui-table class="scroll-table" #scrollTable coreUIInfiniteScroll [scrollTarget]="scrollTable.scrollContainer" [fixedHeader]="true">
    <thead coreUITableSort>
    <tr coreUITableHeaderRow>
      <th scope="col" class="table-header">
        <coreui-table-header-column [sortDirection]="scrollSortDirection">
          Feed
        </coreui-table-header-column>
      </th>
      <th scope="col" class="table-header">
        <coreui-table-header-column [sortDirection]="scrollSortDirection">
          Status
        </coreui-table-header-column>
      </th>
      <th scope="col" class="table-header">
        <coreui-table-header-column [sortDirection]="scrollSortDirection">
          Data Flow
        </coreui-table-header-column>
      </th>
      <th scope="col" class="table-header">
        <coreui-table-multiple-header-column label="Updated">
          <coreui-table-header-column coreUIMenuItem [sortDirection]="scrollSortDirection">
            Date
          </coreui-table-header-column>
          <coreui-table-header-column coreUIMenuItem [sortDirection]="scrollSortDirection">
            Updated By
          </coreui-table-header-column>
        </coreui-table-multiple-header-column>
      </th>
      <th scope="col" class="table-header">
        <coreui-table-header-column [sortDirection]="scrollSortDirection">
          Last Run
        </coreui-table-header-column>
      </th>
      <th scope="col" class="table-header">
        <coreui-table-header-column [sortDirection]="scrollSortDirection">
          Connection
        </coreui-table-header-column>
      </th>
    </tr>
    </thead>

    <tbody coreUICheckboxMultipleSelection formArrayName="rows">

    <tr coreUITableRow [disableHover]="true">
      <td colspan="9" class="empty-list">
        <coreui-graphic-message label="No Data Available" graphic="noData">
          Try again later.
        </coreui-graphic-message>
      </td>
    </tr>

    </tbody>
  </coreui-table>
</div>
