<coreui-breadcrumbs>
  <a [routerLink]="audienceDefinitionRouter" coreUIBreadcrumbLink>Definitions</a>
  <span coreUIBreadcrumbSlash></span>
  <a href="javascript:void(0)" coreUIBreadcrumbEnd>{{definitionName}}</a>
</coreui-breadcrumbs>
<div class="definitionHeadingContainer" [ngClass]="!descriptionName && audienceDefinition?.audienceAttributes?.isPVEAudience && 'mr-bottom-lg'">
  <div class="definitionIconClass">
    <i *ngIf="audienceDefinition?.audienceAttributes?.isPVEAudience" coreUIIcon="promo" scale="2.2"></i>
    <i *ngIf="!audienceDefinition?.audienceAttributes?.isPVEAudience" coreUIIcon="audiences" scale="2.2"></i>
  </div>
  <h1>{{definitionName}}</h1>
</div>
<p *ngIf="descriptionName" class="descriptionText text-style-muted-3" [ngClass]="audienceDefinition?.audienceAttributes?.isPVEAudience && 'mr-bottom-lg'">{{descriptionName}}</p>
<lib-audience-builder-details (saveDefinition)="onSaveDefinition()" *ngIf="!audienceDefinition?.audienceAttributes?.isPVEAudience" ></lib-audience-builder-details>
<coreui-tab-group aria-label="horizontal-tab-group">
  <coreui-tab label="Setup" [active]="!isCriteriaTabActive" [canDeactivate]="canDeActivateTabThis">
    <div [ngClass]="isBuilderView && 'viewClass'">
      <lib-audience-builder-setup [audienceForm]="audienceForm"
      [errorMessage]="errorMessage"></lib-audience-builder-setup>
    </div>
   
    <div *ngIf="!isBuilderView" coreUIButtonGroup class="submitActions">
      <button coreUIButton (click)="validateSetUpAndSave()">Save Changes</button>
      <ng-template [ngTemplateOutlet]="audienceActions"></ng-template>
    </div>
    <a (click)="cancel()" class="backLink">
      <i coreUIIcon="doubleChevronLeft"></i>Back to all definitions</a>
  </coreui-tab>
  <coreui-tab [label]="isProductTypeDcdp ? 'Criteria Selection' : 'Audience Selection'" class="audienceSelectionTab"
    [active]="isCriteriaTabActive" [canDeactivate]="canDeActivateTabThis">
    <div class="setup-container"  [ngClass]="isBuilderView && 'viewClass'">
      <lib-audience-selection *ngIf="audienceForm && audienceForm.controls['dedupeType']"
        [audienceForm]="audienceForm"></lib-audience-selection>
    </div>
    <div class="audienceFormActions">
      <div *ngIf="!isBuilderView" class="spacer"></div>
      <div class="actions">
        <div class="submitActions"  *ngIf="!isBuilderView">
          <div coreUIButtonGroup>
            <button coreUIButton (click)="validateSetUpAndSave()">Save Changes</button>
            <ng-template [ngTemplateOutlet]="audienceActions"></ng-template>
          </div>
        </div>
        <a [routerLink]="audienceDefinitionRouter" class="backLink">
          <i coreUIIcon="doubleChevronLeft"></i>Back to all definitions</a>
      </div>
    </div>
  </coreui-tab>
  <coreui-tab *ngIf="audienceForm?.get('channelType').value === 'OWNED'" label="Activation Schedules"
              [active]="!isCriteriaTabActive && isSchedulesTabActive && activationSchedulesEnabled"
              (activeChange)="isActivationSchedulesTabActive()">
    <div class="tabs"  [ngClass]="isBuilderView && 'viewClass'">
      <lib-audience-activation-schedules></lib-audience-activation-schedules>
    </div>
    <div *ngIf="!isBuilderView" coreUIButtonGroup class="submitActions">
      <button coreUIButton (click)="validateSetUpAndSave()">Save Changes</button>
      <ng-template [ngTemplateOutlet]="audienceActions"></ng-template>
    </div>
    <a (click)="cancel()" class="backLink">
      <i coreUIIcon="doubleChevronLeft"></i>Back to all definitions</a>
  </coreui-tab>
</coreui-tab-group>
<ng-template #audienceActions>
  <coreui-menu [disabled]="isBuilderView" class="actionsDropDown" buttonLabel="Actions" buttonType="primary" menuButtonStyle="button"
    *ngIf="audienceForm.get('channelType').value === 'PAID'">
    <coreui-menu-section>
      <ng-container *ngFor="let action of getActionsForDefinition(audienceDefinition,  dropdownListActionsPaid)">
        <button type="button" coreUIMenuItem *ngIf="action.show" (click)="action.onClick(audienceDefinition)">{{action.display}}</button>
      </ng-container>
    </coreui-menu-section>
  </coreui-menu>
  <coreui-menu [disabled]="isBuilderView" class="actionsDropDown" buttonLabel="Actions" buttonType="secondary" menuButtonStyle="button"
    *ngIf="audienceForm.get('channelType').value === 'OWNED'">
    <coreui-menu-section>
      <ng-container *ngFor="let action of getActionsForDefinition(audienceDefinition,  dropdownListActionsOwned)">
        <button type="button" coreUIMenuItem *ngIf="action.show" (click)="action.onClick(audienceDefinition)">{{action.display}}</button>
      </ng-container>
    </coreui-menu-section>
  </coreui-menu>
</ng-template>
<lib-create-list *ngIf="showCreateList" (createListModalClosed)="closeCreateListModal()"></lib-create-list>


<coreui-modal modalWidth="40" #deleteActionModal>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon"></span>
    <span> Delete Audience Definition? </span>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="closeModal(deleteActionModal)" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <span class="text-style-muted-3 template-text">{{audienceDefinition.displayName}} | ID -
      {{audienceDefinition.id}}
    </span>
    <span>
      Are you sure you want to delete this audience definition? This cannot be undone.
    </span>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal(deleteActionModal)">
      Cancel
    </button>
    <button coreUIButton (click)="deleteAction(deleteActionModal)">
      Delete
    </button>
  </ng-template>
</coreui-modal>

<coreui-notification notificationStyle="error" #destroyError [header]=destroyErrorHeader>
  {{destroyErrorMessage}}
  <p *ngIf="nestedDefinitions?.length > 0">"{{nestedDefinitions.join(', ')}}"</p>
</coreui-notification>

<coreui-notification notificationStyle="success" #successCreatingList>
  <b>List created successfully</b>
  <br />
</coreui-notification>

<lib-discard-changes [show]="showDiscardChanges"
  (onEditOrDiscardEvent)="onDiscardEventChanges($event)"></lib-discard-changes>
