import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers/index';
import * as actions from './admin.actions';
import { DataSets, SchemaData } from './admin.models';

export interface State {
  schemaData: SchemaData;
  schemaDataSets: DataSets;
  dataUniverseTables: DataSets;
}

const defaultState = {
  schemaData: {},
  schemaDataSets: {dataSetArray: [], hasMore: false},
  dataUniverseTables: {dataSetArray: [], hasMore: false},
};

export function reducer(
  state: State = defaultState,
  action: actions.ReducerActions
): State {
  switch (action.type) {
    case actions.LoadSchemas.type:
      return {
        ...state,
        schemaData: action.schemaData
      };
    case actions.LoadSchemaTables.type:
      return {
        ...state,
        schemaDataSets: action.dataSets
      };
    case actions.LoadDataUniverseTables.type:
      return {
        ...state,
        dataUniverseTables: action.dataUniverseTables
      };
    case actions.ResetDatsets.type:
      return {
        ...state,
        dataUniverseTables: {dataSetArray: [], hasMore: false}
      };
    default:
      return state;
  }
}

const cabFeatureSelector = createFeatureSelector<AppState>('cab');

export const adminFeatureSelector = createSelector(cabFeatureSelector, (state: AppState) => state.admin);
export const selectSchemas = createSelector(adminFeatureSelector, (state: State) => state.schemaData);
export const selectTablesBySchema = createSelector(adminFeatureSelector, (state: State) => state.schemaDataSets);
export const selectTablesByDataUniverse = createSelector(adminFeatureSelector, (state: State) => state.dataUniverseTables);
