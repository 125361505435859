import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '@epsilon/core-ui';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { find as _find, get as _get } from 'lodash-es';

import {
  Audience,
  AudienceDefinition,
} from '../../../audience/audience.models';
import { DataUniverse } from '../../../data-universe/data-universe.models';
import { selectDataUniverses } from '../../../data-universe/data-universe.reducer';
import { DataTypeOperator } from '../../../models/data-type-operator';
import { AppState } from '../../../reducers';
import { DetailsModalService } from '../../../services/details-modal.service';
import { DataType } from '../../../enums/data-types';
import { selectDataTypeOperators } from '../../../audience-builder/audience-builder.reducer';

@UntilDestroy()
@Component({
  selector: 'lib-details-modal',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.sass'],
})
export class DetailsModalComponent implements OnInit {
  @ViewChild('modal', { static: true }) private modal: ModalComponent;

  displayType: string;
  isAudience: boolean;
  dataTypeOperators: DataTypeOperator[];
  dataUniverse: DataUniverse;
  audience: Audience | AudienceDefinition;
  dataType: DataType;
  dedupeDisplayName: string;

  audience$: Observable<Audience | AudienceDefinition> =
    this.detailsModalService.audience$;
  dataTypeOperators$: Observable<DataTypeOperator[]> = this.store.select(selectDataTypeOperators);
  dataUniverses$: Observable<DataUniverse[]> =
    this.store.select(selectDataUniverses);

  constructor(
    public store: Store<AppState>,
    public detailsModalService: DetailsModalService
  ) {
    detailsModalService.show$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.displayType =  data === 'audiencedefinition' ? 'definition' : 'list' ;
      this.modal.show();
    });
  }

  ngOnInit(): void {
    observableCombineLatest([this.audience$, this.dataUniverses$])
      .pipe(
        untilDestroyed(this),
        filter(([audience, dataUniverses]) => {
          return !!audience && !!dataUniverses.length;
        })
      )
      .subscribe(([audience, dataUniverses]) => {
        this.audience = audience;
        this.dataUniverse = _find(dataUniverses, {
          id: this.audience?.dataUniverseId,
        });
        this.dedupeDisplayName = _find(
          _get(this.dataUniverse, ['dedupeTypes']),
          { identityType: audience?.dedupeIdentityType }
        )?.name;
        this.dataType = this.displayType !== 'definition'
          ? DataType.AUDIENCE_LIST
          : DataType.AUDIENCE_DEFINITION;
      });
  }

}
