import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-multi-select-all',
  templateUrl: './multi-select-all.component.html',
  styleUrl: './multi-select-all.component.sass',
})
export class MultiSelectAllComponent implements OnInit, AfterViewInit {
  @ViewChild('selectAllOption') selectAllOption: ElementRef;
  @Input() fieldLabel: string;
  @Input() items: string[];
  @Output() selectedValues = new EventEmitter<[]>();
  @Input() defaultSelectItems: string[];
  public selectAllCheckboxIndeterminate: boolean;
  public selectAll = false;
  public get multiSelectAllFilteredItems$(): BehaviorSubject<string[]> {
    return this._multiSelectAllFilteredItems;
  }
  public get multiSelectAllSelectedItems$(): BehaviorSubject<string[]> {
    return this._multiSelectAllSelectedItems;
  }

  public multiSelectFormGroup: FormGroup;


  private _multiSelectedItems: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);

  public get multiSelectedItems$(): BehaviorSubject<string[]> {
    return this._multiSelectedItems;
  }

  private _multiSelectAllFilteredItems: BehaviorSubject<string[]>;
  private _multiSelectAllSelectedItems: BehaviorSubject<string[]> =
    new BehaviorSubject<string[]>([]);

  constructor(private changeDetector: ChangeDetectorRef) {
    this.selectAllCheckboxIndeterminate = false;
  }

  ngOnInit() {
    this.multiSelectFormGroup = new FormGroup({
      selectDefault: new FormControl({
        value: this.defaultSelectItems,
        disabled: false,
      }),
    });
    this._multiSelectAllFilteredItems = new BehaviorSubject<string[]>([
      ...this.items,
    ]);
    this.validateSomeChecked(this.multiSelectFormGroup.value);

    this.multiSelectFormGroup.valueChanges.subscribe((val: any) => {
      this.validateSomeChecked(val);
    });
  }

  public onSelectBlur(): void {
    this.setMultiValues();
    const selectDefault = this.multiSelectFormGroup.get('selectDefault').value;
    if (selectDefault.length == 1 && selectDefault[0] === 'All') {
      this.selectedValues.emit([]);
    } else {
      this.selectedValues.emit(selectDefault);
    }
  }

  public ngAfterViewInit(): void {
    // If there is any pre selection
    this.setMultiValues();
    this.changeDetector.detectChanges();
  }

  private setMultiValues(): void {
    const multiSelectSelectedItems = this.multiSelectFormGroup.controls[
      'selectDefault'
    ].value as string[];
    const multiSelectFilteredItems = this.items.filter(
      (item) => multiSelectSelectedItems.indexOf(item) < 0
    );

    this.multiSelectedItems$.next([
      ...multiSelectSelectedItems,
      ...multiSelectFilteredItems,
    ].sort());

    this.changeDetector.detectChanges();
  }

  private validateSomeChecked(formGroup): void {
    const getSelcteDefaultList = formGroup['selectDefault'];
    if (getSelcteDefaultList.length == 1 && getSelcteDefaultList[0] === 'All') {
      return;
    }
    if (getSelcteDefaultList[getSelcteDefaultList.length - 1] == 'All') {
      this.multiSelectFormGroup.get('selectDefault').patchValue(['All']);
    } else {
      this.onSelectValueChange(formGroup['selectDefault']);
    }
  }

  public onSelectValueChange(event): void {
    this.selectAllCheckboxIndeterminate = false;
    const isAllSelected = event.includes('All');
    if (isAllSelected) {
      const filterAllValue = event.filter((item) => item !== 'All');
      this.multiSelectFormGroup.get('selectDefault').patchValue(filterAllValue);
      this.selectAll = false;
    }
  }

  controlEnable() {
    this.multiSelectFormGroup.get('selectDefault').enable();
  }
  controlDisable() {
    this.multiSelectFormGroup.get('selectDefault').disable();
  }
  formReset(initialValues: string[]) {
    this.multiSelectFormGroup.reset({ selectDefault: initialValues });
    this.setMultiValues();
  }
}
