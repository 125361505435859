import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'lib-autocomplete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.sass'],
})
export class AutoCompleteComponent implements OnChanges {
  @ViewChild('autoCompleteInput') autoCompleteInput: ElementRef;
  @Output() searchResultClick = new EventEmitter();
  @Output() searchValueChange = new EventEmitter();
  @Input() searchedResults: any[];
  @Input() value: string;

  isSearching = false;
  showSearches = false;
  inputValue = '';

  constructor(private eRef: ElementRef) {}

  ngOnChanges(): void {
    this.inputValue = this.value;
  }

  @HostListener('document:click', ['$event'])
  click(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      event.preventDefault();
      event.stopPropagation();
      this.showSearches = false;
    }
  }

  onSearchFocus() {
    this.showSearches = true;
  }

  onKeyup(event: any) {
    this.searchValueChange.emit(event.target.value);
  }

  getResultDisplayName(result: any): string {
    if (!result) {
      return '';
    }

    if (!result.displayName || result.displayName.trim() === '') {
      return result.id;
    }

    if (result.displayName !== result.id) {
      return `${result.displayName} (${result.id})`;
    }

    return result.displayName;
  }

  onOptionClick(result: any) {
    if (result) {
      const selectedValue = result.displayName || result.id;
      this.autoCompleteInput.nativeElement.value = selectedValue;
      this.searchResultClick.emit(selectedValue);
      this.showSearches = false;
      this.searchValueChange.emit(selectedValue);
    }
  }
}
