import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
class PageNotFoundGuardService {
  constructor(private router: Router) {}

  public canActivate(): Observable<boolean> | boolean {
    this.router.navigateByUrl('/error/404');
    return false;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PageNotFoundGuard: CanActivateFn = ():
  | Observable<boolean>
  | boolean => {
  return inject(PageNotFoundGuardService).canActivate();
};
