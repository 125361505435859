import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { get } from 'lodash-es';

import { selectContext } from '../context/context.reducer';
import { UtilsService } from './utilservice';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private productType: string;

  constructor(private store: Store, private utilsService: UtilsService) {
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.productType = context?.productType;
      });
  }

  isFeatureEnabled(featureName: string): boolean {
    const features = this.utilsService.environmentValue('features') || {};
    const contextFeatures = get(features, this.productType);
    if (!contextFeatures || !contextFeatures.hasOwnProperty(featureName)) {
      const defaultContextFeatures = get(features, 'DEFAULT');
      return defaultContextFeatures && defaultContextFeatures[featureName];
    } else {
      return contextFeatures[featureName];
    }
  }
}
