import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ContextType } from '@epsilon-cdp/cab-lib';
import { handleError } from 'projects/cab/src/lib/utils/errors';
import { get } from 'lodash-es';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ContextsService {
  public hideHeader = false;
  public hideHeader$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT')
    readonly environment: string,
    @Inject('environmentConfigApp')
    readonly environmentConfigMap: any
  ) {}

  fetchCabContexts(): Observable<any[]> {
    const headers = {
      // TODO: need to remove okta token related code in separate PR // NOSONAR
      // eslint-disable-next-line @typescript-eslint/naming-convention
      // Authorization: 'Bearer ' + oktaToken || '',
    };
    return this.http.get<any[]>(this.contextUrl(), { headers }).pipe(
      map((data) => data['results'].map((data) => data)),
      catchError(handleError)
    );
  }

  contextUrl() {
    if (this.isMocked()) {
      return this.apiUrl(
        ContextType.DISCOVERY,
        '/id_analytics_api/cab/v1/cab-context'
      );
    }
    return this.apiUrl(ContextType.CAB, '/cab/v1/cab-context');
  }

  apiUrl(context: ContextType, path: string): string {
    return this.cabApiUrl(path, context);
  }

  cabApiUrl(path: string, context: ContextType = null): string {
    const urls = this.environmentValue('cabApiUrl');
    if (!this.isMocked() && urls) {
      return get(urls, context) ? get(urls, context) + path : path;
    }
    return path;
  }

  isMocked(): boolean {
    return this.environmentValue('useMockData')?.toString() === 'true' || false;
  }

  environmentValue(key: any) {
    try {
      return get(this.environmentConfigMap, key);
    } catch (e) {
      return '';
    }
  }
}
