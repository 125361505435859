import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { first } from 'lodash-es';
import {
  CampaignData,
  CampaignExtract,
  CampaignTemplate,
  transformCampaignExtracts,
  transformCampaignTemplates,
} from './campaign-extract.model';
import { fetchResource } from '../utils/fetch-state';
import { CabHttpService, ContextType } from '../services/cab-http.service';
import * as actions from '../campaign-extract/campaign-extract.actions';
import { ScheduleDetail } from '../audience-overview/details/schedules/schedules.models';
import { CabConstants } from '../cab.constants';
import { UtilsService } from '../utils/utilservice';

@Injectable({
  providedIn: 'root',
})
export class CampaignExtractService {
  fetchCampaignExtracts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FetchCampaignExtracts.type),
      fetchResource((action) =>
        this.fetchCampaignExtracts(action.contextId).pipe(
          map(
            (campaignExtracts) =>
              new actions.LoadCampaignExtracts(campaignExtracts)
          )
        )
      )
    )
  );

  searchCampaignExtract$ = createEffect( () =>
    this.actions$.pipe(
      ofType(actions.SearchCampaignExtracts.type),
      fetchResource((action) => this.searchCampaignExtracts(action.contextId, action.searchRequest).pipe(
        map(
          (campaignData) => new actions.LoadCampaignExtracts(campaignData)
        ))))
  );


  saveCampaignExtract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SaveCampaignExtract.type),
      fetchResource((action) =>
        this.saveCampaignExtract(action.campaignExtract).pipe(
          map(
            (campaignExtract) =>
              new actions.LoadCampaignExtract(campaignExtract)
          )
        )
      )
    )
  );


  saveCampaignExtractSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SaveCampaignExtractSchedule.type),
      fetchResource((action) =>
        this.saveCampaignExtractSchedule(action.scheduleDetail).pipe(
          map(
            (scheduleDetail) =>
              new actions.CampaignExtractScheduleSavedSuccessfully(scheduleDetail)
          )
        )
      )
    )
  );

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private httpService: CabHttpService,
    private utilsService: UtilsService
  ) {}

  fetchCampaignExtracts(contextId: string): Observable<CampaignData> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .get(this.getCampaignExtractsUrl(), { headers })
      .pipe(map((res: any) => {
        res.results = transformCampaignExtracts(res.results);
        return res;
      }));
  }

  searchCampaignExtracts(contextId: string, searchRequest: any): Observable<CampaignData> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .post(this.getCampaignExtractsUrl() + '/search', searchRequest, { headers })
      .pipe(map((res: any) => {
        res.results = transformCampaignExtracts(res.results);
        return {campaignExtracts: res.results, hasMore: res.hasMore} as CampaignData;
      }));
  }

  fetchCampaignTemplates(
    contextId: string,
    dataUniverseId: string
  ): Observable<CampaignTemplate[]> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .get(this.getCampaignTemplatesUrl(dataUniverseId), { headers })
      .pipe(map((res: any) => transformCampaignTemplates(res)));
  }

  saveCampaignExtract(
    campaignExtract: CampaignExtract
  ): Observable<CampaignExtract> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = campaignExtract.cabContextId;
    const verb = campaignExtract.id ? 'put' : 'post';
    return this.http[verb](
      this.saveCampaignExtractUrl(verb, campaignExtract.id),
      campaignExtract,
      { headers,
        params: {
          fullPageLoader: true
        } }
    ).pipe(
      map((res: any) => {
        return this.utilsService.isMocked()
          ? new CampaignExtract(first(res?.entity))
          : new CampaignExtract(res?.entity);
      })
    ) as Observable<CampaignExtract>;
  }

  saveCampaignExtractSchedule(
    scheduleDetail: ScheduleDetail
  ): Observable<ScheduleDetail> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = scheduleDetail.cabContextId;
    const verb = scheduleDetail.scheduleId ? 'put' : 'post';
    const url = this.saveCampaignExtractScheduleUrl(verb, scheduleDetail.scheduleEntityId, scheduleDetail.scheduleId);
    scheduleDetail.cabContextId = undefined;
    scheduleDetail.scheduleEntityId = undefined;
    return this.http[verb](url,
      scheduleDetail,
      { headers,
        params: {
          fullPageLoader: true
        } }
    ).pipe(
      map((res: any) => {
        return new ScheduleDetail(res?.entity);
      })
    ) as Observable<ScheduleDetail>;
  }

  saveCampaignExtractUrl(verb: string, audienceId?: string): string {
    const childPath = verb === 'put' ? `/${audienceId}` : '';
    return `${this.getCampaignExtractsUrl()}${childPath}`;
  }

  saveCampaignExtractScheduleUrl(verb: string, audienceId: string, scheduleId?: string): string {
    const childPath = verb === 'put' ? `/updateSchedule/${scheduleId}` : '/createSchedule';
    return `${this.baseV1Url()}/schedule/${audienceId}${childPath}`;
  }

  getCampaignExtractsUrl(id: string = null): string {
    const idPath = id ? `/${id}` : '';
    return `${this.baseV1Url()}/campaign-extract${idPath}`;
  }

  getCampaignTemplatesUrl(id: string = null): string {
    return `${this.getCampaignExtractsUrl(id)}/templates`;
  }

  sendTo(contextId: string, audienceDefinitionId: string, payload: any): Observable<any> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .post(`${this.baseV1Url()}/audience-definition/send-to/${audienceDefinitionId}`, payload, { headers,
        params: {
          fullPageLoader: true
        } })
      .pipe(
        map((res: any) => {
        return res;
        })
      );
  }

  sendToForCampaignExtract(contextId: string, payload: any): Observable<any> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .post(`${this.baseV1Url()}/campaign-extract`, payload, { headers,
        params: {
          fullPageLoader: true
        } })
      .pipe(
        map((res: any) => {
        return res;
        })
      );
  }

  getAmsConnectors(activationId: string): Observable<any> {
    return this.http
      .get(`${this.baseUrl()}/proxy/ams/activation_api/v1/activations/${activationId}`)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  private baseUrl(): string {
    if (this.utilsService.isMocked()) {
      return this.httpService.apiUrl(
        ContextType.DISCOVERY,
        '/id_analytics_api/cab/v1'
      );
    }
    return this.httpService.apiUrl(ContextType.CAB, '/cab');
  }

  private baseV1Url(): string {
    return this.baseUrl() + '/v1';
  }

}
