import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { UnsavedChangesState } from './hasUnsavedChanges.actions';
import * as actions from './hasUnsavedChanges.actions';

export interface IApplicationState {
  unsavedChangesState: UnsavedChangesState;
}

const defaultState = {
  unsavedChangesState: null,
};

export function reducer(
  state: IApplicationState = defaultState,
  action: actions.ReducerActions
): IApplicationState {
  switch (action.type) {
    case actions.SetUnsavedChangesState.type:
      return { unsavedChangesState: action.payload };
    default:
      return state;
  }
}

const contextFeatureSelector = createFeatureSelector<AppState>('cab');

const applicationFeatureSelector = createSelector(contextFeatureSelector, (state: AppState) => {
  return state.application;
});
export const selectApplicationContext = createSelector(applicationFeatureSelector, (state: IApplicationState) => state.unsavedChangesState);
export const selectHasUnsavedChanges = createSelector(
  applicationFeatureSelector,
  (state: IApplicationState) =>
    state.unsavedChangesState !== null &&
    state.unsavedChangesState !== 'confirmed'
);
