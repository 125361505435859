import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { first, get as _get, isEqual, keyBy, last } from 'lodash-es';
import { AppState } from '../reducers';
import {
  AttributeDetails,
  AudienceExpressionGroup,
} from '../audience-builder/audience-builder.models';
import { NavTreeNode } from '@epsilon/pcbc-nav-tree';
import { DataType } from '../enums/data-types';
import { ModalComponent } from '@epsilon/core-ui';
import { selectActiveDataUniverseId } from '../data-universe/data-universe.reducer';
import { selectContextId } from '../context/context.reducer';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { Audience, AudienceDefinition } from '../audience/audience.models';
import { UtilsService } from '../utils/utilservice';

@UntilDestroy()
@Injectable()
export class DetailsModalService {
  attributeData$ = new BehaviorSubject<{
    type: DataType.AUDIENCE_DEFINITION | DataType.AUDIENCE_LIST;
    id: string;
  }>(null);
  attributeDetails$ = new BehaviorSubject<AttributeDetails>({});
  show$ = new Subject<string>();

  history: Array<{
    attribute: Audience | AudienceDefinition;
    attributeDetails: AttributeDetails;
  }> = [];
  dataUniverseId: string;
  contextId: string;

  audience$ = this.attributeData$.pipe(
    untilDestroyed(this),
    mergeMap((attributeData) => {
      if (!attributeData) {
        return of(new Audience({}));
      }
      const { type, id } = attributeData;

      if (isEqual(id, last(this.history)?.attribute?.id)) {
        const { attribute, attributeDetails } = last(this.history);
        this.attributeDetails$.next(attributeDetails);
        return of(attribute);
      }

      return this.builderService
        .fetchCabAttributeDetails(type, this.contextId, this.dataUniverseId, id)
        .pipe(
          map((data: any) => {
            const { audienceDefinition, audienceList, nodes } = _get(
              data,
              'entity'
            );
            let attribute;
            if (audienceDefinition) {
              attribute = new AudienceDefinition(
                this.utilsService.isMocked() ? first(audienceDefinition) : audienceDefinition
              );
            } else {
              attribute = new Audience(
                this.utilsService.isMocked() ? first(audienceList) : audienceList
              );
            }

            const attributeDetails = keyBy(nodes, 'cabId');
            this.attributeDetails$.next(attributeDetails);
            if (!isEqual(attribute, last(this.history)?.attribute)) {
              this.history.push({ attribute, attributeDetails });
            }
            return attribute;
          })
        );
    })
  );

  constructor(
    public store: Store<AppState>,
    public builderService: AudienceBuilderService,
    private utilsService: UtilsService
  ) {
    this.store
      .select(selectActiveDataUniverseId)
      .pipe(untilDestroyed(this))
      .subscribe((id) => (this.dataUniverseId = id));

    this.store
      .select(selectContextId)
      .pipe(untilDestroyed(this))
      .subscribe((id) => (this.contextId = id));
  }

  // used by builder-attribute, detail-node
  showModalUsingExpressionGroup(group: AudienceExpressionGroup) {
    const operand: any = _get(group, ['expression', 'operand']);
    if (operand?.valueType === 'AudienceList') {
      this.show(DataType.AUDIENCE_LIST, operand.audienceListId);
    } else if (operand?.valueType === 'AudienceDefinition') {
      this.show(DataType.AUDIENCE_DEFINITION, operand.audienceDefinitionId);
    }
  }

  // used by picker
  showModalUsingPickerNode(node: NavTreeNode) {
    if (node['dataType'].toLowerCase() === DataType.AUDIENCE_LIST) {
      this.show(DataType.AUDIENCE_LIST, node.id);
    } else if (node['dataType'].toLowerCase() === DataType.AUDIENCE_DEFINITION) {
      this.show(DataType.AUDIENCE_DEFINITION, node.id);
    }
  }

  // used by breadcrumb
  showModalHistory(index: number) {
    this.history.splice(index + 1);
    const { attribute } = last(this.history);
    this.show(DataType.AUDIENCE_LIST, attribute?.id);
  }

  close(modal: ModalComponent) {
    this.history = [];
    this.attributeData$.next(null);
    modal.hide();
  }

  private show(type, id) {
    this.attributeData$.next({ type, id });
    this.show$.next(type);
  }
}
