import { cabApiUrl, ContextType } from '../services/cab-http.service';
import {
  ACCESS_TOKEN,
  DEFAULT_ROUTE,
  OKTA_TOKEN,
} from './utils';
import Cookies from 'js-cookie';
import { LocalStorageService } from '../local-storage.service';
import { UtilsService } from './utilservice';

export function logout(
  storage: LocalStorageService = new LocalStorageService(), utilsService: UtilsService = new UtilsService('dev')
): void {
  const domain = getDomain(utilsService);
  const apiDomain = getApiDomain(utilsService);

  storage.remove(OKTA_TOKEN);
  Cookies.remove(OKTA_TOKEN, { domain });
  Cookies.remove({ domain });
  Cookies.remove({ apiDomain });
}

//pass returnUrl = '' in the below function as an argument commenting out to fix lint issue
export function logoutUrl( utilsService: UtilsService) {
  //TODO: pass on returnURL when backend supports it
  //return cabApiUrl('/cab/logout') + (returnUrl && `?returnUrl=${encodeURIComponent(returnUrl)}`);
  return cabApiUrl('/cab/logout', ContextType.CAB, utilsService);
}

export function setRequestDetails(storage: LocalStorageService, utilsService: UtilsService): string {
  const oktaToken = Cookies.get(OKTA_TOKEN) || storage.get(OKTA_TOKEN) || '';
  const domain = getDomain(utilsService);
  Cookies.remove(OKTA_TOKEN, { domain });
  storage.set(OKTA_TOKEN, oktaToken);

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has(ACCESS_TOKEN)) {
    history.replaceState(null, null, DEFAULT_ROUTE);
  }

  return oktaToken;
}

function getDomain(utilsService): string {
  return utilsService.environmentValue('envName') === 'dev'
    ? 'localhost'
    : 'peoplecloud.epsilon.com';
}

function getApiDomain(utilsService): string {
  return utilsService.environmentValue('baseApiUrl')
    .replace('http://', '')
    .replace('https://', '');
}
