import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent, TableHeaderColumnComponent } from '@epsilon/core-ui';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { AppState } from '../../../reducers';
import { FetchTablesByDataUniverse, ResetDatsets } from '../../admin.actions';
import { selectTablesByDataUniverse } from '../../admin.reducer';
import { DataSet, DataSets } from '../../admin.models';
import { AudienceDefinition } from '../../../audience/audience.models';
import { DataUniverse } from '../../../data-universe/data-universe.models';
import { AdminService } from '../../admin.service';
import { TableFilterService } from '../../../audience-overview/overview-table/table-filter.service';
import { UtilsService } from '../../../utils/utilservice';

@UntilDestroy()
@Component({
  selector: 'lib-dataset-table',
  templateUrl: './dataset-table.component.html',
  styleUrls: ['./dataset-table.component.sass'],
  providers: [TableFilterService]
})
export class DatasetTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() contextId: string;
  @Input() dataUniverse: DataUniverse;
  @Output() addTableHandler = new EventEmitter();
  @ViewChild('syncSuccess', { static: true }) public syncSuccess;
  @ViewChild('syncError', { static: true }) public syncError;
  @ViewChild('removeTableModal') public removeTableModal;
  @ViewChild('cannotRemoveTableModal') public cannotRemoveTableModal;
  @ViewChild('successSavingJoins', { static: true }) public successSavingJoins;
  @ViewChild('successDeleteDataset', { static: true }) public successDeleteDataset;
  @ViewChild('errorDeleteDataset', { static: true }) public errorDeleteDataset;
  formGroup: UntypedFormGroup;
  hasMore = false;
  selectedDataSetDetails: any;
  public sortOption = 'name';
  public sortDirection = 'asc';
  datasetDetailSearch: DataSets = {hasMore: false, dataSetArray: []};
  searchPayload: {
    cabContextId?: string;
    dataUniverseId?: string;
    displayName?: string;
    limit: number;
    offset: number;
    scheduleTypes?: string[];
    sort?: string;
    sortBy?: string[];
  } = {displayName: '', limit: 25, offset: 0, sort: 'ASC', sortBy: ['name']};
  tableToRemove: DataSet;
  totalCount: number;
  audienceDefinitions: AudienceDefinition[];
  errorMessage = 'Error occurred while deleting dataset';

  actionInProgress$ = new BehaviorSubject<{
    type: string;
    dataset: DataSet;
  } | null>(null);

  modalSyncForm: UntypedFormGroup;
  dropdownListActions = [
    {
      display: 'Edit Table',
      onClick: (dataset) => {
        this.router.navigate([
          this.utilsService.getProductBaseUrl(this.router, this.route),
          this.contextId,
          'admin',
          'dataset',
          dataset.id,
          'edit'
        ]);
      }
    },
    {
      display: 'Sync Table',
      onClick: (dataset) => {
        this.actionInProgress$.next({
          type: 'sync',
          dataset,
        });
      },
    },
    {
      display: 'Manage Joins',
      onClick: (dataset) => {
        this.actionInProgress$.next({
          type: 'manageJoins',
          dataset
        });
      }
    },
    {
      display: 'Remove This Table',
      onClick: (dataset) => {
        this.tableToRemove = dataset;
        this.fetchDependentAudienceDefinitions(this.tableToRemove);
      },
    }
  ];
  isLoader = false;

  constructor(public store: Store<AppState>, public tableFilterService: TableFilterService, private router: Router, private route: ActivatedRoute, private adminService: AdminService, private utilsService: UtilsService) {
    this.formGroup = tableFilterService.formGroup;
  }

  ngOnInit(): void {
    this.searchPayload.cabContextId = this.contextId;
    this.searchPayload.dataUniverseId = this.dataUniverse?.id;

    this.selectedDataSetDetails = this.store.select(selectTablesByDataUniverse).subscribe((dataSets: DataSets) => {
      dataSets.dataSetArray.forEach(datasetDetail => {
        this.datasetDetailSearch.dataSetArray.push(datasetDetail);
      });
      this.datasetDetailSearch.hasMore = dataSets.hasMore;
      this.isLoader = false;
    });

    this.formGroup.get('search').valueChanges.subscribe(searchText => {
      this.searchPayload.displayName = searchText;
      this.resetSearchPayload();
      this.dispatchFetchTablesByDataUniverseStore();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataUniverse?.id !== changes['dataUniverse']?.previousValue?.id) {
      this.searchPayload.cabContextId = this.contextId;
      this.searchPayload.dataUniverseId = changes['dataUniverse']?.currentValue.id;
      this.dispatchFetchTablesByDataUniverseStore();
      this.resetTableData();
    }
  }

  ngOnDestroy(): void {
    this.selectedDataSetDetails.unsubscribe();
    this.store.dispatch(new ResetDatsets());
  }

  public getDataSetByDataUniversePayload() {
    return this.searchPayload;
  }

  public sortAscDesc(sortType) {
    return sortType === 'ASC' ? 'ascending' : 'descending';
  }

  public sort(sortComponent: TableHeaderColumnComponent, sortOption) {
    this.sortOption = sortOption;
    this.sortDirection = sortComponent.sortDirection;
    this.searchPayload.sort = this.sortDirection;
    this.searchPayload.sortBy = [this.sortOption];
    this.searchPayload.cabContextId = this.contextId;
    this.searchPayload.dataUniverseId = this.dataUniverse?.id;
    this.dispatchFetchTablesByDataUniverseStore();
  }

  public limitReached() {
    if (this.datasetDetailSearch.hasMore) {
      this.searchPayload.offset += 25;
      this.dispatchFetchTablesByDataUniverseStore();
    }
  }

  resetSearchPayload() {
    this.searchPayload.limit = 25;
    this.searchPayload.offset = 0;
    this.datasetDetailSearch.hasMore = false;
    this.datasetDetailSearch.dataSetArray = [];
  }

  dispatchFetchTablesByDataUniverseStore() {
    this.isLoader = true;
    this.store.dispatch(
      new FetchTablesByDataUniverse(
        this.searchPayload
      )
    );
  }

  addTable() {
    this.addTableHandler.emit();
  }

  closeModal(modal: ModalComponent) {
    modal.hide();
    this.resetActionInProgress();
  }

  removeTable(modal: ModalComponent) {
    this.adminService.deleteDataset(this.dataUniverse.cabContextId, this.tableToRemove.id).subscribe({
      next: (res) => {
        if (res?.resultStatus === 'FAILURE') {
          if (res?.errorDetails) {
            this.errorMessage = res.errorDetails[0]?.errorMessage;
          }
          this.errorDeleteDataset.show();
        } else {
          this.closeModal(modal);
          this.resetTableData();
          this.dispatchFetchTablesByDataUniverseStore();
          this.successDeleteDataset.show();
        }
      },
      error: (error) => {
        if (error.errorDetails) {
          this.errorMessage = error.errorDetails[0]?.errorMessage;
        }
        this.errorDeleteDataset.show();
      }
    });
  }

  fetchDependentAudienceDefinitions(dataSet: DataSet) {
    this.adminService.getListOfDependentAudienceDefinitions(dataSet.cabContextId, dataSet.id, dataSet.dataUniverseId).subscribe((res) => {
      this.totalCount = res.totalCount;
      this.audienceDefinitions = res.results;
      if (this.totalCount > 0) {
        this.cannotRemoveTableModal.show();
      } else {
        this.removeTableModal.show();
      }
    });
  }

  closeSyncModal() {
    this.resetActionInProgress();
  }

  public resetActionInProgress() {
    this.actionInProgress$.next(null);
  }

  closeManageJoins(manageJoinStatus?: string) {
    this.resetActionInProgress();
    if (manageJoinStatus === 'SUCCESS') {
      this.successSavingJoins.show();
    }
  }

  resetTableData() {
    this.datasetDetailSearch.dataSetArray = [];
    this.datasetDetailSearch.hasMore = false;
  }
}
