import { Tenant } from './tenant.model';
import * as actions from './tenant.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export interface State {
  tenant: Tenant;
  redirectTenant: boolean;
}

const defaultState = {
  tenant: null,
  redirectTenant: true
};

export function reducer(
  state: State = defaultState,
  action: actions.ReducerActions
): State {
  switch (action.type) {
    case actions.ResetTenant.type:
      return { ...state, tenant: null, redirectTenant: true };
    case actions.LoadTenant.type:
      return { ...state, tenant: action.tenant, redirectTenant: true };
    case actions.LoadDataHubTenantId.type:
      return { ...state,  tenant: { ...state.tenant, dataHubTenantId: action.dataHubTenantId} };
    case actions.UpdateTenantRedirectStatus.type:
      return { ...state, redirectTenant: action.redirectTenant };
    default:
      return state;
  }
}

const cabFeatureSelector = createFeatureSelector<AppState>('cab');
const tenantFeatureSelector = createSelector(cabFeatureSelector, (state: AppState) => state.tenant);
export const selectTenant = createSelector(tenantFeatureSelector, (state: State) => state.tenant);
export const selectTenantContext = createSelector(tenantFeatureSelector, (state: State) => state.tenant?.id);
export const selectRedirectTenantStatus = createSelector(tenantFeatureSelector, (state: State) => state.redirectTenant);
