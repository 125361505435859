<div class="attribute-container" [attr.data-cabDisplayName]="attribute?.displayName" [ngStyle]="{'width': fromAggregationGroup? '95%' : '100%'}"
  (mouseenter)="displayActions = true" (mouseleave)="onHoverLeave()" [formGroup]="attributeForm"
  *ngLet="displayFullSegmentPath$ | async as showPath">
  <div class="attribute-data-container">
    <div class="dragAndContent">
      <div *ngIf="!isBuilderView"  class="handle-name">
        <i coreUIIcon="dragHandle" scale="2.8" class="drag-handle" [class.preview]="isPreview"></i>
      </div>
      <div class="draggableContent">
        <div class="typeIconAndPath" *ngIf="!isCabAttribute">
          <lib-type-icon [type]="aggregationAction?.toggled ? attribute?.expression?.secondOperand?.attributeDataType?.toLowerCase() : expressionDataType(attribute.expression)"></lib-type-icon>
          <div class="name-filter-segment-path-container" [class.has-compared-attribute]="isAttributeCompare()"
            [class.showing-path]="showPath" *ngLet="attributePath$ | async as path">
            <ng-container *ngIf="showPath">
              <div class="full-segment-path text-style-muted-3" *libCheckOverflow="let hasOverflow = hasXOverflow">
                <ng-template #segmentPathTooltip>
                  <span *ngIf="hasOverflow" [coreUITooltip]="segmentPathTooltip">{{
                    path
                    }}</span>
                </ng-template>
                {{ path }}
                <span *ngIf="!isCabAttribute">
                  <b *ngIf = "path"> / {{ attribute.displayName }} </b>
                  <b *ngIf = "!path"> {{ attribute.displayName }} </b>
                </span>
              </div>
            </ng-container>
            <ng-container *ngIf="!showPath">
              <span *ngIf="!isCabAttribute" class="text-style-muted-3">
                {{ attribute.displayName }}
              </span>
            </ng-container>
          </div>
        </div>
        <div>
          <span [ngClass]="{'name-filter-container': !isBuilderView, 'inlineContainer': isBuilderView}" [class.showing-path]="showPath">
            <div *ngIf="aggregationAction?.toggled" class="aggregation-dropdown-attr-pill" libDisableDrag>
              <coreui-select formControlName="aggregation" placeholder="Select an aggregation">
                <div class="aggregation-dropdown-label-attr-pill text-style-muted-3">
                  Aggregation Operators
                </div>
                <coreui-option *ngFor="let aggregation of aggregations" [value]="aggregation">
                  {{ aggregation?.displayName }}
                </coreui-option>
              </coreui-select>
            </div>
            <div class="filter-inputs" *ngIf="operators">
              <div class="Core-Form" [formGroup]="attributeForm">
                <div class="InlineForm-field">
                  <coreui-form-field *ngIf="!isBuilderView" class="select-form-field" libDisableDrag>
                    <coreui-select placeholder="Select an option"
                      formControlName="activeFilter">
                      <coreui-tab-group *ngIf="!isCabAttribute" tabStyle="secondary"
                        (tabChange)="tabChange($event, group, index)">
                        <coreui-tab *ngFor="let tabName of activeOperatorTabs()" [label]="tabName"
                          [active]="isActiveTab(tabName)">
                        </coreui-tab>
                      </coreui-tab-group>
                      <ng-container *ngFor="
                        let filter of filteredFilters$ | async;
                        let idx = index
                      ">
                        <coreui-menu-item-divider *ngIf="operators[idx]?.separator"></coreui-menu-item-divider>
                        <coreui-option [value]="filter" class="text-style-5">
                          {{ filter.displayName }}
                        </coreui-option>
                      </ng-container>
                    </coreui-select>
                  </coreui-form-field>
                  <span *ngIf="isBuilderView" class="viewValues">{{attributeForm.get('activeFilter').value.displayName}}</span>
                  <ng-container *ngIf="relativeDatesEnabled && isRelativeValue()">
                    <lib-relative-date-menu [attributeForm]="attributeForm" [attribute]="attribute"
                      [label$]="dateRelativeLabel$" [relativeDate$]="relativeDate$"
                      [activeOperatorTab$]="activeOperatorTab$"></lib-relative-date-menu>
                    <span class="comments" [class.multi]="(activeFilter$ | async)?.dualValue" *ngIf="
                      (activeFilter$ | async)?.comments &&
                      (activeFilter$ | async)?.dualValue
                    ">{{ (activeFilter$ | async).comments }}</span>
                    <lib-relative-date-menu *ngIf="(activeFilter$ | async)?.dualValue" [attributeForm]="attributeForm"
                      [attribute]="attribute" [label$]="dateRelativeLabelMulti$" [relativeDate$]="relativeDateMulti$"
                      [activeOperatorTab$]="activeOperatorTab$" [isMulti]="true"></lib-relative-date-menu>
                  </ng-container>
                  <ng-container *ngIf="!isAttributeCompare() && !isRelativeValue()">
                    <ng-template #renderFormFieldsTemplate let-controlName="controlName"  let-isMultiFilterValue="isMultiFilterValue">
                      <ng-container  *ngIf="controlName && attributeDataType() !== dataTypeRef.DATE && (!isEnumFeatureEnabled || (isEnumFeatureEnabled && (!isEnumMultiDropDownAttribute && !isEnumSingleDropDownAttribute) || (attributeEnumValues?.length === 0 || !attributeEnumValues)))">
                        <input type="text" coreUIInput
                        [formControlName]="controlName"
                        [attr.maxLength]="attributeDataType() === dataTypeRef.STRING ? 256 : null"
                        *ngIf="(!(activeFilter$ | async)?.multiValue) && (attribute?.displayName?.toLowerCase()?.includes('amount') ||
                        attribute?.displayName?.toLowerCase()?.includes('price') ||
                        attribute?.displayName?.toLowerCase()?.includes('dollar')) else notAnDecimalMask"
                        coreUIDecimalMask
                        />
                        <ng-template #notAnDecimalMask>
                          <input type="text" coreUIInput
                          [formControlName]="controlName"
                          [attr.maxLength]="attributeDataType() === dataTypeRef.STRING ? 256 : null" />
                        </ng-template>
                      </ng-container>
                        <input *ngIf="attributeDataType() === dataTypeRef.DATE && !isMultiFilterValue && dateNumeric(activeFilter$ | async)"
                        type="text" coreUIInput
                        [formControlName]="controlName" coreUIIntegerMask />
                        <coreui-date-picker *ngIf="attributeDataType() === dataTypeRef.DATE && (isMultiFilterValue || !dateNumeric(activeFilter$ | async))"
                        [maxDate]="
                        !isMultiFilterValue && (activeFilter$ | async)?.dualValue
                          ? (minDatePickerMaxDate$ | async)
                          : null
                      "  [minDate]="isMultiFilterValue ? (maxDatePickerMinDate$ | async) : null"
                       [formControlName]="controlName"></coreui-date-picker>
                        <ng-container *ngIf="isEnumFeatureEnabled">
                          <ng-template [ngTemplateOutlet]="singleDropDownAttribute"></ng-template>
                          <ng-template [ngTemplateOutlet]="multiDropDownAttribute"></ng-template>
                        </ng-container>

                    </ng-template>
                    <coreui-form-field *ngIf="
                      ((activeFilter$ | async)?.singleOperand ||
                      ((activeFilter$ | async)?.dualValue &&
                        !isAttributeCompare())) && !isBuilderView
                    " libDisableDrag>
                    <ng-container *ngTemplateOutlet="renderFormFieldsTemplate; context: {controlName: 'filterValue'}"></ng-container>
                    </coreui-form-field>
                    <span *ngIf="isBuilderView" class="viewValues">{{attributeForm.get('filterValue').value}}</span>
                    <span class="comments" [class.multi]="(activeFilter$ | async)?.dualValue"
                      *ngIf="(activeFilter$ | async)?.comments">{{ (activeFilter$ | async).comments }}</span>
                    <coreui-form-field *ngIf="(activeFilter$ | async)?.dualValue && !isBuilderView"
                      libDisableDrag>
                       <ng-container *ngTemplateOutlet="renderFormFieldsTemplate; context: {controlName: 'multiFilterValue', isMultiFilterValue: true}"></ng-container>
                    </coreui-form-field>
                    <span *ngIf="isBuilderView" class="viewValues">{{attributeForm.get('multiFilterValue').value}}</span>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="cab-attribute" *ngIf="operators && isCabAttribute">
              <div class="details" [class.showing-path]="showPath">
                <lib-type-icon [type]="aggregationAction?.toggled ? attributeDetail?.dataType : expressionDataType(attribute.expression)"></lib-type-icon>
                <ng-container *ngIf="showPath">

                  <div class="name-filter-segment-path-container" [class.has-compared-attribute]="isAttributeCompare()"
                    [class.showing-path]="showPath" *ngLet="attributePath$ | async as path">
                    <div class="full-segment-path text-style-muted-3">{{ path }}
                    </div>
                  </div>
                </ng-container>
                <div class="display-name text-style-muted-3">
                  {{ getCabAttributeDisplayName(attribute) }}
                </div>
                <!--div class="deduped-by text-style-muted-3">Deduped by {{ dedupeType.value }}</div-->
              </div>
            </div>
            <span *ngIf="isBuilderView" ><span class="viewValues"  *ngLet="{
              path: (comparedAttributePath$ | async),
              comparedAttribute: (comparedAttribute$ | async)
            } as opts">{{ opts?.comparedAttribute?.displayName}}</span></span>
            <div *ngIf="!isBuilderView" class="compared-attribute-wrapper">
              <div class="compared-attribute-container text-style-muted-1" *ngLet="{
                path: (comparedAttributePath$ | async),
                comparedAttribute: (comparedAttribute$ | async)
              } as opts" [class.showing-path]="showPath">
                    <ng-container *ngIf="showPath && opts.comparedAttribute">
                      <span class="full-segment-path text-style-muted-3" *libCheckOverflow="let hasOverflow = hasXOverflow">
                        <ng-template #comparedSegmentPathTooltip>{{ opts.path }}</ng-template>
                        <span *ngIf="hasOverflow" [coreUITooltip]="comparedSegmentPathTooltip">{{ opts.path }}</span>{{
                        opts.path }}
                      </span>
                    </ng-container>
      
                    <div [class.hidden]="!isAttributeCompare() || isCabAttribute" class="drop-list comp-attr" coreUIDropList
                      [data]="[opts.comparedAttribute]" [connectedTo]="dragDropService.dropLists$ | async"
                      (dropped)="onDropped($event, group, index)" (entered)="isDropAllowed($event)"
                      [class.drop-disabled]="dropDisabled$ | async" [class.has-attribute]="opts.comparedAttribute"
                      (exited)="enableDrop()" [enterPredicate]="isDropAllowedEnterPredicate" [class.showing-path]="showPath">
                      <div *ngIf="opts.comparedAttribute" class="compared-attribute-drop" [class.withPath]="showPath">
                        <div class="compared-attribute-name " *libCheckOverflow="let hasOverflow = hasXOverflow">
                          <ng-template #compAttrDisplayNameTooltip>
                            {{ opts.comparedAttribute?.displayName }}
                          </ng-template>
                          <span *ngIf="hasOverflow" [coreUITooltip]="compAttrDisplayNameTooltip">{{
                            opts.comparedAttribute?.displayName }}</span>
                          {{ opts.comparedAttribute?.displayName }}
                        </div>
                        <i class="remove-compared-attribute" coreUIIcon="close" scale="1.5"
                          (click)="removeComparedAttribute(group, index)"></i>
                      </div>
                    </div>
                  </div>
            </div>
           
            <!--div
            class="actions"
            *ngIf="displayActions"
            [class.showing-path]="showPath"
            libDisableDrag
        -->
            <div *ngIf="!isBuilderView" class="actions" [class.showing-path]="showPath" libDisableDrag>
              <coreui-menu
                *ngIf=" !fromAggregationGroup && ((aggregationsEnabled && !isCabAttribute) || (isCabAttribute && showCabAttributeActions))  "
                menuButtonStyle="link" icon="more" verticalPosition="bottom" #actionsMenu>
                <ng-container *ngIf="!isCabAttribute">
                  <coreui-menu-section class="aggregation-disabled-hover-info" *ngFor="let action of actions">
                    <button *ngIf="!action.toggled"
                      [disabled]="action.disabled"
                      type="button"
                      coreUIMenuItem
                      [coreUITooltip]="tooltip"
                      [tooltipEnabled]="action.disabled && action.display === 'Add Aggregation'"
                      (click)="toggleAction(action)">
                      {{ action.display }}
                    </button>
                    <ng-template #tooltip>
                      <span class="tooltipMessage">
                        Aggregation is not supported for this data type.
                      </span>
                  </ng-template>
                  </coreui-menu-section>
                </ng-container>
                <ng-container *ngIf="isCabAttribute">
                  <coreui-menu-section>
                    <button type="button" coreUIMenuItem (click)="
                      detailsModalService.showModalUsingExpressionGroup(attribute)
                    ">
                      <span class="action-menu-item-icon">
                        View {{ cabAttributeValueType }} Details
                      </span>
                    </button>
                  </coreui-menu-section>
                </ng-container>
              </coreui-menu>
              <button *ngIf="!hideTrashIcon" coreUIButton="icon-large" (click)="removeAudienceDefinition(group, index)">
                <i coreUIIcon="trash"></i>
              </button>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #singleDropDownAttribute>
  <div [formGroup]="attributeForm" class="singleDropDownAttribute">
    <lib-autocomplete
        *ngIf="isEnumSingleDropDownAttribute && attributeEnumValues?.length > 0"
        [value]="filterValueOnEdit"
        (searchValueChange)="onEnumSearchChangeInAutoComplete($event)"
        [searchedResults]="attributeFilteredEnumValues"
        (searchResultClick)="onEnumSelectionChange($event)">
    </lib-autocomplete>
  </div>
</ng-template>

<ng-template #multiDropDownAttribute>
  <div [formGroup]="attributeForm"  class="multiSelectDropDownAttribute">
    <coreui-select class="selectEnumVal" *ngIf="isEnumMultiDropDownAttribute && attributeEnumValues?.length > 0"
                   formControlName="filterValue" placeholder="Select options" [multiple]="true"
                   [searchable]="true" (searchValueChange)="onEnumSearchChange($event)">
      <ng-template #multipleSelectedTpl let-selectedOptions="selectedOptionsCount"
                   let-value="value">
        <coreui-select-tag [value]="selectedOptions"
                           [attr.aria-label]="selectedOptions + ' options selected'"></coreui-select-tag>
        <div>{{getSelectedDataSetEnumAttributes()}}</div>
      </ng-template>
      <coreui-option *ngFor="let itm of attributeFilteredEnumValues" [value]="itm.id"
                     [displayValue]="itm.displayName">{{itm.displayName}} ({{itm.id}})</coreui-option>
    </coreui-select>
  </div>
</ng-template>
