import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userEmail$ = new BehaviorSubject<string>('');

  public getUserEmail$(): Observable<string> {
    return this.userEmail$.asObservable();
  }
}
