import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { selectContextId } from '../context/context.reducer';
import {
  BUILDER_TXT,
  DEFINITION_TXT,
  isDefined,
} from '../utils/utils';
import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  Observable,
  Subject
} from 'rxjs';
import {
  filter,
  take,
  map,
  distinctUntilChanged,
  takeUntil
} from 'rxjs/operators';
import { Actions } from '@ngrx/effects';
import {
  convertAudienceBuilderToDefinition,
  convertAudienceDefinitionToBuilder,
  errorIncludes,
  MAX_NAME_LENGTH,
  nameValidators,
} from '../audience/audience.utils';
import { SaveAudienceDefinition } from '../audience/audience.actions';
import { fetchIfUnfetched, fetchOutcome } from '../utils/fetch-state';
import { Audience, AudienceDefinition } from '../audience/audience.models';
import { find as _find, reject, values } from 'lodash-es';
import { AudienceBuilderService } from './audience-builder.service';
import {
  FetchDataTypeOperators,
  SetPrebuiltAudience,
  ToggleFullSegmentPath,
} from './audience-builder.actions';
import { selectPrebuiltAudience } from './audience-builder.reducer';
import { AudienceService } from '../audience/audience.service';
import { CountsService } from '../counts/counts.service';
import { selectActiveDataUniverseId } from '../data-universe/data-universe.reducer';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SetActiveDataUniverse } from '../data-universe/data-universe.actions';
import {
  BuilderAudience, emptyAudienceRuleGroup,
  ERROR_DEDUPE_TYPE,
  ERROR_GENERIC,
  ERROR_NAME,
  ERROR_NO_ATTRIBUTE,
  ERROR_NO_ATTRIBUTE_VALUE
} from './audience-builder.models';
import { DataType } from '../enums/data-types';
import { DataUniverse } from '../data-universe/data-universe.models';
import { DataTypeOperator } from '../models/data-type-operator';
import { UrlService } from '../services/url.service';
import { get as _get, keyBy } from 'lodash-es';
import { DatePipe } from '@angular/common';
import { DedupeType } from '../models/dedupe-type';
import { find, first } from 'lodash-es';
import * as actions from './audience-builder.actions';
import { selectAudienceDefinitions } from '../audience/audience.reducer';
import { selectDataTypeOperators } from './audience-builder.reducer';
import { selectDataUniverses } from '../data-universe/data-universe.reducer';
import { UtilsService } from '../utils/utilservice';
@UntilDestroy()
@Component({
  selector: 'lib-audience-builder',
  templateUrl: './audience-builder.component.html',
  styleUrls: ['./audience-builder.component.sass'],
  // providers: [AudienceBuilderService, DatePipe],
})
export class AudienceBuilderComponent implements OnDestroy, OnInit {
  @ViewChild('successNotification', { static: true })
  public successNotification;
  @ViewChild('errorNotification', { static: true }) public errorNotification;
  @ViewChild('showNestedDefinitionError', { static: true }) public showNestedDefinitionError;
  @ViewChild('showNestingLimitError', { static: true }) public showNestingLimitError;
  @ViewChild('saveDefinitionOnVersionChange')
  public saveDefinitionOnVersionChange;
  @ViewChild(('saveAsNewDefinitionOnVersionChange'))
  public saveAsNewDefinitionOnVersionChange;
  @ViewChild('nestedLimitModal')
  public nestedLimitModal;
  @ViewChild('updateDedupeValueWarning')
  public updateDedupeValueWarning;
  audienceForm: UntypedFormGroup;
  audiences: AudienceDefinition[];
  prebuiltAudience: BuilderAudience;
  contextId: string;
  activeDataUniverseId: string;
  nonActiveDefinitions: AudienceDefinition[] = [];
  errorOnLoadState$ = new BehaviorSubject<boolean>(false);
  errorMessage = '';
  error$ = new BehaviorSubject<string>('');
  activeDedupeType: DedupeType;
  private readonly ngUnsubscribe$ = new Subject<void>();
  dedupeTypes: DedupeType[];

  audiences$ = this.store.select(selectAudienceDefinitions);
  dataTypeOperators$: Observable<DataTypeOperator[]> = this.store.select(selectDataTypeOperators);
  currentAudienceDefinitionId: string;
  tempDedupeValue: DedupeType;
  isEdit: boolean;
  createdDetails: string;
  modifiedDetails: string;
  dependentDefinitions: AudienceDefinition[];
  currentDependentDefinition: AudienceDefinition;
  maxNestedDefsReachedError: boolean;
  maxNestedDefsReachedErrorMsg: string;
  maxNestingLimitDepthReachedErrorMsg: string;
  coreUILocaleDate = 'MM/dd/YY';

  constructor(
    public store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private actions$: Actions,
    private urlService: UrlService,
    public builderService: AudienceBuilderService,
    public audienceService: AudienceService,
    public countsService: CountsService,
    private datePipe: DatePipe,
    private utilsService: UtilsService
  ) {
    observableCombineLatest([
      this.builderService.activeDataUniverseId$,
      store.select(selectContextId).pipe(filter(isDefined)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([dataUniverseId, contextId]) => {
        this.contextId = contextId;
        this.activeDataUniverseId = dataUniverseId;
        fetchIfUnfetched(
          this.store,
          new FetchDataTypeOperators(this.contextId, this.activeDataUniverseId),
          this
        );
      });
    observableCombineLatest([
      this.audiences$.pipe(map(values)),
      this.store.select(selectPrebuiltAudience).pipe(filter(isDefined)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([audiences, prebuiltAudience]: [
          AudienceDefinition[],
          BuilderAudience
        ]) => {
          this.audiences = audiences;
          this.nonActiveDefinitions = reject(audiences, {
            id: prebuiltAudience?.id,
          });
          this.prebuiltAudience = prebuiltAudience;
          const { idCount, countUpdatedOn, errorMessage, countStatus } = this.prebuiltAudience;

          if (this.prebuiltAudience) {
            this.builderService.checkForAttribute();
          }

          if (Number.isInteger(idCount)) {
            const count = idCount.toString();
            this.countsService.builderCount$.next({
              displayCount: count,
              countUpdatedOn,
              errorMessage,
              status: countStatus
            });
          }
        }
      );

    this.audienceForm = new UntypedFormGroup({
      displayName: new UntypedFormControl('', [
        ...nameValidators,
        this.uniqueNameValidator(),
      ]),
      includeConditions: new UntypedFormControl(
        this.builderService.audience.includeConditions
      ),
      excludeConditions: new UntypedFormControl(
        this.builderService.audience.excludeConditions
      ),
      dedupeType: new UntypedFormControl(
        this.builderService.audience.dedupeIdentityType,
        [Validators.required]
      ),
      audienceQueryCaseInsensitive: new UntypedFormControl(!this.builderService.audience?.audienceQueryCaseInsensitive)
    });
  }

  ngOnInit(): void {
    const audienceDefinitionId =
      this.route.snapshot.paramMap.get('definitionId');
    if (audienceDefinitionId) {
      this.isEdit = true;
    }

    this.audienceForm.get('dedupeType')
      ?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((value: DedupeType) => {
        if (this.tempDedupeValue === undefined || this.activeDedupeType === value || this.isEdit) {
          this.activeDedupeType = value;
          this.tempDedupeValue = null;
        } else {
          this.tempDedupeValue = value;
          this.updateDedupeValueWarning.show();
        }
      });

    observableCombineLatest([
      this.builderService.dedupeTypes$?.pipe(filter(isDefined)),
      this.builderService.audience$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([dedupeTypes]) => {
        if (dedupeTypes.length) {
          this.dedupeTypes = dedupeTypes;
          this.setDefaultValue(dedupeTypes);
        }
      });

    observableCombineLatest([
      this.route.params.pipe(distinctUntilChanged()),
      this.store.select(selectDataUniverses),
      this.store.select(selectActiveDataUniverseId),
      this.store.select(selectContextId).pipe()
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([params, dataUniverses, activeDataUniverseId]: [
          Params,
          DataUniverse[],
          string,
          any
        ]) => {
          const dataUniverseId = params['dataUniverseId'];

          if (activeDataUniverseId === 'all') {
            const dataUniverse = _find(dataUniverses, { id: dataUniverseId });
            if (!dataUniverse) {
              this.errorOnLoadState$.next(true);
              console.error(
                'an error because there was no matching data universe'
              );
              return;
            }
            this.store.dispatch(new SetActiveDataUniverse(dataUniverse));
          } else if (audienceDefinitionId && this.contextId) {
            this.builderService
              .fetchCabAttributeDetails(
                DataType.AUDIENCE_DEFINITION,
                this.contextId,
                dataUniverseId,
                audienceDefinitionId
              )
              .pipe(take(1), untilDestroyed(this))
              .subscribe((data: any) => {
                const { audienceDefinition, canAddNestedDefinitions, nodes, isUsed } = _get(data, 'entity');
                if (!canAddNestedDefinitions) {
                  setTimeout(() => {
                    const elements: Element[] = Array.from(document.querySelectorAll('.Core-Accordion-paneHeader'));
                    elements.forEach((el: HTMLButtonElement, index) => {
                      if (el.innerText.includes('Audience Definitions')) {
                        const defDIv = (document.querySelectorAll('.Core-Accordion-paneHeader')[index] as HTMLButtonElement);
                        const defText = (document.querySelectorAll('.Core-Accordion-paneHeaderText')[index] as HTMLButtonElement);
                        const chevronDiv = (document.querySelectorAll('.Core-Accordion-paneHeaderIcon')[index] as HTMLElement);
                        defDIv.insertAdjacentHTML('beforeend','<div style="pointer-events: all;z-index: 9999; position: relative;" class="Core-Accordion-paneHeaderIcon ng-tns-c50-6"><div style="align-items: center; vertical-align: middle" class="Core-Accordion-paneHeaderIconWrapper ng-tns-c50-6"><i style="transform: scale(1.2);" class="Core-Icon Core-Icon--help"></i><span class="hoverme"> Why definitions are disabled ? <br> Current definition is used in other definitions. You can’t nest other definitions in the current one. View “where this being used” for more details.</span></div></div>');
                        defDIv.disabled = true;
                        defText.style.color = '#666666';
                        defDIv.style.pointerEvents = 'none';
                        chevronDiv.style.display = 'none';
                      }
                    });
                  }, 1000);
                }
                this.createdDetails = this.datePipe.transform(audienceDefinition.createdDate, this.coreUILocaleDate) + ' | ' + audienceDefinition.createdBy;
                this.modifiedDetails = this.datePipe.transform(audienceDefinition.lastModifiedDate, this.coreUILocaleDate) + ' | ' + audienceDefinition.lastModifiedBy;

                let builderAudience = null;

                if(audienceDefinition) {
                  builderAudience = convertAudienceDefinitionToBuilder(
                    new AudienceDefinition(audienceDefinition),
                    true
                  );
                  builderAudience = {...builderAudience, isUsed};
                } else {
                  builderAudience = convertAudienceDefinitionToBuilder(
                    new Audience(audienceDefinition),
                    true
                  );
                }

                this.store.dispatch(new actions.LoadAttributeDetails(keyBy(nodes, 'cabId')));
                this.store.dispatch(new actions.LoadPrebuiltAudience(builderAudience));

                // attempt to restart cron if any
                this.countsService.restartCron(builderAudience);
              });
            this.builderService.fetchCabDependentDefinitions(this.contextId, audienceDefinitionId).subscribe((data) => {
              this.dependentDefinitions = _get(data, 'results');
            });
          }
        }
      );

    this.errorOnLoadState$
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe(() => {
        this.errorNotification.show();
        this.audienceForm.disable();
      });

    this.error$.pipe(untilDestroyed(this)).subscribe((message) => {
      this.errorMessage = message;
      this.errorNotification.show();
    });
    this.builderService.audienceBuilderUpdatedManually$.next(false);
  }

  ngOnDestroy() {
    const prebuiltAudienceId = this.prebuiltAudience?.id;
    if (prebuiltAudienceId) {
      this.countsService.stopCron(prebuiltAudienceId);
    }
    this.activeDedupeType = this.tempDedupeValue;
    this.tempDedupeValue = null;
    this.countsService.resetBuilderCounts();
    this.store.dispatch(new SetPrebuiltAudience(null));
    this.store.dispatch(new ToggleFullSegmentPath(null));
  }

  canSaveDefinition() {
    this.builderService.checkForAttributeValues();

    return this.isAudienceValid();
  }

  onSaveDefinition(event: boolean) {
    this.save(event);
  }

  save(saveAsNewOnVersionUpdate: boolean) {
    if (this.canSaveDefinition()) {
      this.resetFormUpdateBoolean();
      this.builderService.audience.audienceQueryCaseInsensitive = !this.audienceForm?.get('audienceQueryCaseInsensitive')?.value;
      const definition = convertAudienceBuilderToDefinition(
        this.builderService.audience,
        this.contextId,
        this.activeDataUniverseId
      );
      this.store.dispatch(new SaveAudienceDefinition(definition));
      this.actions$
        .pipe(fetchOutcome(SaveAudienceDefinition.type), take(1))
        .subscribe(
          ({ result }) => {
            const savedDefinition = result.audienceDefinition;
            this.setPrebuiltAudience(savedDefinition);
            this.successNotification.show();
            this.isEdit = true;
            if (!definition.id && !saveAsNewOnVersionUpdate) {
              this.urlService.replaceUrl(
                ['edit', savedDefinition.id],
                this.route
              );
            }
            else if (!definition.id && saveAsNewOnVersionUpdate) {
              this.saveDefinitionOnVersionChange.hide();
              this.saveAsNewDefinitionOnVersionChange.hide();
              this.router.navigateByUrl(this.router.url.replace(this.currentAudienceDefinitionId, savedDefinition.id));
            }
          },
          (error) => {
            if (errorIncludes(error.error, 'Operation failed, possibly because the entity was updated in the meanwhile by another user')) {
              this.openSaveDefinitionVersionChangeModal();
            } else if (errorIncludes(error.error, 'Maximum number of nested Audience Definitions')) {
              for (const errorObj of error.error.errorDetails) {
                this.errorMessage = errorObj.errorMessage;
              }
              this.showNestedDefinitionError.show();
            }
            else {
              this.maxNestedDefsReachedError = errorIncludes(error.error, 'Please reduce to 10 and try again');
              const maxNestDepthLimitReached = errorIncludes(error.error, 'already has 5 levels of nesting; it cannot be nested');
              if(this.maxNestedDefsReachedError|| maxNestDepthLimitReached) {
                for (const errorObj of error.error.errorDetails) {
                  if(errorObj.errorMessage.includes('Please reduce to 10 and try again'))
                  {
                    this.maxNestedDefsReachedError = true;
                    this.showNestedDefinitionError.show();
                    this.maxNestedDefsReachedErrorMsg = errorObj.errorMessage;
                  }
                  else{
                    this.maxNestedDefsReachedError = false;
                    this.showNestingLimitError.show();
                    this.maxNestingLimitDepthReachedErrorMsg = errorObj.errorMessage;
                  }
                }
              }
              else{
                this.parseApiErrorMessage(error);
              }
              // TODO: utilize backend error messages
            }
          }
        );
    } else {
      this.handleInvalidDefinitionError();
    }
  }

  setPrebuiltAudience(definition) {
    const audience = convertAudienceDefinitionToBuilder(definition, true);

    this.store.dispatch(
      new SetPrebuiltAudience(
        audience,
        this.contextId,
        DataType.AUDIENCE_DEFINITION,
        true
      )
    );
  }

  isNameValid(): boolean {
    return this.audienceForm.get('displayName').valid;
  }

  isDedupeTypeValid(): boolean {
    return this.audienceForm.get('dedupeType').valid;
  }

  handleInvalidDefinitionError() {
    this.audienceForm.get('displayName').updateValueAndValidity();
    const errorMsg = !this.builderService.definitionHasAttribute$.value
      ? ERROR_NO_ATTRIBUTE
      : !this.builderService.allExpressionsHaveValues$.value
        ? ERROR_NO_ATTRIBUTE_VALUE
        : this.isNameValid()
          ? this.isDedupeTypeValid()
            ? ERROR_DEDUPE_TYPE
            : ERROR_GENERIC
          : ERROR_NAME;

    this.error$.next(errorMsg);
  }

  cancel() {
    this.builderService.audienceBuilderUpdatedManually$.next(false);
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      DEFINITION_TXT,
    ]);
    this.store.dispatch(new SetPrebuiltAudience(null));
  }

  openSaveDefinitionVersionChangeModal() {
    this.countsService.stopCron();
    this.countsService.resetBuilderCounts();
    this.saveDefinitionOnVersionChange.show();
  }

  discardAudienceDefinitionChanges(isVersionChangeError: boolean) {
    this.builderService
      .fetchCabAttributeDetails(
        DataType.AUDIENCE_DEFINITION,
        this.contextId,
        this.activeDataUniverseId,
        this.route.snapshot.paramMap.get('definitionId')
      )
      .pipe(take(1), untilDestroyed(this))
      .subscribe((data: any) => {
        const { audienceDefinition } = _get(data, 'entity');
        const audienceItem = this.utilsService.isMocked()
          ? new AudienceDefinition(audienceDefinition[0])
          : new AudienceDefinition(audienceDefinition);

        this.store.dispatch(
          new SetPrebuiltAudience(
            convertAudienceDefinitionToBuilder(audienceItem),
            this.contextId,
            DataType.AUDIENCE_DEFINITION
          )
        );
        if (isVersionChangeError) {
          this.saveDefinitionOnVersionChange.hide();
        }
      });
  }

  saveAsNewAudienceDefinition() {
    this.builderService.audience.displayName = '';
    this.currentAudienceDefinitionId = this.builderService.audience.id;
    this.builderService.audience.id = '';
    this.builderService.audience.version = 0;
    this.countsService.builderCount$.next({ displayCount: 'N/A', countUpdatedOn: null, errorMessage: null, status: null });
    this.saveAsNewDefinitionOnVersionChange.show();
  }

  isAudienceValid() {
    return (
      this.builderService.definitionHasAttribute$.value &&
      this.builderService.allExpressionsHaveValues$.value &&
      this.audienceForm.valid
    );
  }

  isSaveButtonDisabled() {
    return !this.builderService.definitionHasAttribute$.value;
  }

  saveButtonErrorTooltip() {
    const messages = [];
    const controls = this.audienceForm.controls;
    if (controls['displayName']?.status === 'INVALID') {
      messages.push(ERROR_NAME);
    }
    if (!this.builderService.definitionHasAttribute$.value) {
      messages.push(ERROR_NO_ATTRIBUTE);
    }
    if (controls['dedupeTypes']?.status === 'INVALID') {
      messages.push(ERROR_DEDUPE_TYPE);
    }
    return messages;
  }

  nameFieldErrorMessage(): string | void {
    const errors = this.audienceForm.controls['displayName'].errors;
    if (!errors) {
      return;
    }
    const { required, maxlength, nameTaken, whitespace } = errors;
    if (required || whitespace) {
      return 'Required';
    }
    if (maxlength?.actualLength > maxlength?.requiredLength) {
      return `Name cannot be longer than ${MAX_NAME_LENGTH} characters.`;
    }
    if (nameTaken) {
      return 'This name is taken.';
    }
    console.error(errors);
    return 'An unknown error occurred.';
  }

  private uniqueNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const nameTaken = this.nonActiveDefinitions
        .map((o) => o?.displayName?.toLowerCase())
        .includes(control.value?.trim()?.toLowerCase());
      return nameTaken ? { nameTaken: true } : null;
    };
  }

  parseApiErrorMessage(error) {
    // TODO: utilize backend error messages
    let errorMsg =
      'Something went wrong saving this audience. Please try again later.';
    // TODO: temporary solution until we can properly parse errorDetails
    if (errorIncludes(error, 'Dedupe type of Nested')) {
      errorMsg =
        'Cannot use an attribute with dedupe type that does not match the definitions dedupe type.';
    } else if (
      errorIncludes(error, 'number of nested Audience Definitions') ||
      errorIncludes(error?.error, 'Number of definitions exceeded')
    ) {
      errorMsg = 'Maximum number of nested definitions exceeded.';
    } else if (
      errorIncludes(
        error,
        'Either includeConditions or excludeConditions is required.'
      )
    ) {
      errorMsg = ERROR_NO_ATTRIBUTE;
    } else if (errorIncludes(error, 'Null or Blank value')) {
      errorMsg = ERROR_NO_ATTRIBUTE_VALUE;
    } else if(errorIncludes(error?.error, 'already exists in')) {
      errorMsg = error?.error?.errorDetails[0].errorMessage;
    }
    this.error$.next(errorMsg);
  }

  saveDedupeWarningChanges() {
    this.activeDedupeType = this.tempDedupeValue;
    this.tempDedupeValue = null;
    this.updateDedupeValueWarning.hide();
    this.countsService.resetBuilderCounts();
    this.builderService.audience.excludeConditions = [emptyAudienceRuleGroup('Exclude')];
    this.builderService.audience.includeConditions = [emptyAudienceRuleGroup('Include')];
    this.builderService.rules = [this.builderService.audience.includeConditions, this.builderService.audience.excludeConditions];
  }

  cancelDedupeChanges() {
    this.tempDedupeValue = null;
    this.audienceForm.get('dedupeType').setValue(this.activeDedupeType);
    this.updateDedupeValueWarning.hide();
  }

  private getAudienceDefinition(id: string, contextId: string) {
    return this.audienceService
      .fetchAudienceDefinition(id, contextId, true)
      .toPromise();
  }

  showNestedLimitModal()
  {
    this.nestedLimitModal.show();
  }

  private routeTONewDefinition() {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      this.activeDataUniverseId,
      BUILDER_TXT,
      'edit',
      this.currentDependentDefinition.id,
    ]).then(() => {
      window.location.reload();
    });
  }

  onDedupeTypeSelectChange(dedupeType) {
    this.builderService.audience.dedupeIdentityType = dedupeType;
  }

  setDefaultValue(dedupeTypes: DedupeType[]) {
    const defaultValue =
      this.builderService.audience.dedupeIdentityType ||
      find(dedupeTypes, { primaryIdentityType: true })?.identityType ||
      first(dedupeTypes)?.identityType;
    this.audienceForm.get('dedupeType').setValue(defaultValue);
  }

  private resetFormUpdateBoolean() {
    this.audienceForm.markAsPristine();
    this.audienceForm.markAsUntouched();
    this.builderService.audienceBuilderUpdatedManually$.next(false);
  }
}
