<form [formGroup]="formGroup">
    <coreui-table-search
      class="tableSearchBox"
      placeholder="Search by table name/category"
      formControlName="search"
    ></coreui-table-search>
  <coreui-table
    class="table tableScroll u-marginTop15"
    #scrollTable
    coreUIInfiniteScroll
    [scrollTarget]="scrollTable.scrollContainer"
    [fixedHeader]="true"
    (scrollLimitReached)="limitReached()"
  >
    <thead coreUITableSort>
    <tr coreUITableHeaderRow>
      <th scope="col" coreUITableStickyColumn class="table-header" [attr.aria-sort]="sortOption==='displayName' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'createdBy')">
          Table Name
        </coreui-table-header-column>
      </th>
      <th scope="col" class="table-header">
        <coreui-table-header-column >
          Original Name
        </coreui-table-header-column>
      </th>

      <th scope="col" class="table-header" [attr.aria-sort]="sortOption==='lastModifiedDate' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-multiple-header-column label="Last Modified"></coreui-table-multiple-header-column>
      </th>
      <th scope="col" class="table-header" [attr.aria-sort]="sortOption==='createdDate' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-multiple-header-column label="Created"></coreui-table-multiple-header-column>
      </th>
      <th scope="col" class="table-header">
        <coreui-table-header-column [disableSort]="true">
          Number of Joins
        </coreui-table-header-column>
      </th>
      <th scope="col" class="table-header" coreUITableStickyColumn="last">
        <coreui-table-header-column [disableSort]="true">
        </coreui-table-header-column>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of datasetDetailSearch?.dataSetArray; let idx=index" coreUITableRow>
      <td>{{item.displayName}}</td>
      <td>{{item.productDataSetInfo.tableName}}</td>
      <td>
        {{ item.lastModifiedDate | date: "MM/dd/yyyy hh:mm a" }}
        <div class="text-style-muted-3">{{ item.lastModifiedBy }}</div>
      </td>
      <td>
        {{ item.createdDate | date: "MM/dd/yyyy hh:mm a" }}
        <div class="text-style-muted-3">{{ item.createdBy }}</div>
      </td>
      <td>
        <div *ngIf="(item.relations && item.relations?.length > 0) else noJoins">{{ item.relations?.length}} joins</div>
        <ng-template #noJoins>
          <i coreUIIcon="radioFill"></i> No join path found
        </ng-template>
      </td>
      <td>
        <coreui-menu menuButtonStyle="link" icon="more">
          <coreui-menu-section>
            <button
              type="button"
              *ngFor="let action of dropdownListActions"
              (click)="action.onClick(item)"
              coreUIMenuItem
            >
              {{ action.display }}
            </button>
          </coreui-menu-section>
        </coreui-menu>
      </td>
      </tr>
    <tr coreUITableRow *ngIf="isLoader">
      <td colspan="2"><coreui-loading-table-column></coreui-loading-table-column></td>
      <td><coreui-loading-table-column></coreui-loading-table-column></td>
      <td><coreui-loading-table-column></coreui-loading-table-column></td>
      <td><coreui-loading-table-column></coreui-loading-table-column></td>
      <td><coreui-loading-table-column></coreui-loading-table-column></td>
    </tr>
    </tbody>
    <tbody *ngIf="!isLoader && datasetDetailSearch?.dataSetArray.length === 0">
    <tr>
      <td colspan="8" class="empty-list">
        <coreui-graphic-message graphic="empty" label="Nothing Here">
          There are no tables in this data universe.
          <button class="u-marginTop30" type="button" (click)="addTable()" coreUIButton="text-primary">
            Add Tables
            <i coreUIIcon="add"></i>
          </button>
        </coreui-graphic-message>
      </td>
    </tr>
  </tbody>
  </coreui-table>
</form>

<coreui-modal #removeTableModal>
  <ng-template #titleTemplate>
    <i coreUIIcon="warning" class="removeActionWarning"></i>
    This will remove this table from the audience builder
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeModal(removeTableModal)"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    Are you sure you want to remove this table from the builder context "{{dataUniverse?.displayName}}"?
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal(removeTableModal)">
      Cancel
    </button>
    <button coreUIButton (click)="removeTable(removeTableModal)">
      Remove
    </button>
  </ng-template>
</coreui-modal>
<lib-dataset-sync-modal
  *ngIf="(actionInProgress$ | async)?.type === 'sync'"
  (syncModalClosed)="closeSyncModal()"
  [dataset]="(actionInProgress$ | async)?.dataset"
></lib-dataset-sync-modal>
<lib-manage-joins-modal
  *ngIf="(actionInProgress$ | async)?.type === 'manageJoins'"
  (closeManageJoinsModal)="closeManageJoins($event)"
  (resetTableData)="resetTableData()"
  [dataset]="(actionInProgress$ | async)?.dataset"
></lib-manage-joins-modal>

<coreui-modal #cannotRemoveTableModal>
  <ng-template #titleTemplate>
    <i coreUIIcon="warning" class="removeActionError"></i>
    Unable to remove this table
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeModal(cannotRemoveTableModal)"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    Attributes from this table are used in one or more of the following Audience Definitions. Before removing this table,
    you must delete all audience definitions that use attributes from this table or edit them so they no longer use those attributes.
    <div>
      <ul>
        <li *ngFor="let audienceDefinition of audienceDefinitions?.slice(0, 5)">
          <b>{{audienceDefinition.displayName}}</b>
        </li>
      </ul>
    </div>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal(cannotRemoveTableModal)">
      Cancel
    </button>
    <button coreUIButton (click)="removeTable(cannotRemoveTableModal)" [disabled]=true>
      Remove
    </button>
  </ng-template>
</coreui-modal>

<coreui-notification notificationStyle="success" #syncSuccess>
  This sync has successfully been done!
</coreui-notification>

<coreui-notification notificationStyle="error" #syncError>
  There was a problem with the sync. Please try again.
</coreui-notification>

<coreui-notification notificationStyle="success" #successSavingJoins>
  <b>Joins saved successfully</b>
  <br/>
</coreui-notification>
<coreui-notification notificationStyle="success" #successDeleteDataset>
  <b>Dataset deleted successfully</b>
  <br/>
</coreui-notification>
<coreui-notification notificationStyle="error" #errorDeleteDataset>
  <b>{{ errorMessage }}</b>
  <br/>
</coreui-notification>
