import { map as _map } from 'lodash-es';

export class DataTypeOperator {
  comments?: string;
  displayName: string;
  displayText?: string;
  singleOperand: boolean; // has one input field
  dualValue: boolean; // has multiple input fields
  multiValue: boolean; // has multiple values in single input field (eg. comma separated)
  separator: boolean;
  operator: string;
  sortOrder: number;
  attributeAsAdditionalOperand: boolean; // available for attribute comparison
  relativeOperand: boolean; // available for relative date

  constructor(data: any) {
    this.comments = data.comments;
    this.displayName = data.displayName;
    this.displayText = data.displayText;
    this.singleOperand = !data.singleOperand; // Originally meant has one input field. Now data.singleOperand: true means secondOperand === null (applies to is_null & is_not_null operators)
    this.dualValue = data.dualValue;
    this.multiValue = data.multiValue;
    this.separator = data.separator;
    this.operator = data.operator;
    this.sortOrder = data.sortOrder;
    this.attributeAsAdditionalOperand = data.attributeAsAdditionalOperand;
    this.relativeOperand = data.relativeOperand;
  }
}

export class FunctionTypeOperator {
  displayName: string;
  displayText?: string;
  sortOrder: number;
  functionType: string;
  comments?: string;
  separator: boolean;
  supported: boolean;
  returnDataType: string;

  constructor(data: any) {
    this.displayName = data.displayName;
    this.displayText = data.displayText;
    this.sortOrder = data.sortOrder;
    this.functionType = data.functionType || data.function;
    this.comments = data.comments;
    this.separator = data.separator;
    this.supported = data.supported;
    this.returnDataType = data.returnDataType;
  }
}

export function transformDataTypeOperators(operators) {
  return _map(operators, (operator) => {
    return {
      ...operator,
      operators: _map(operator.operators, (o) => new DataTypeOperator(o)),
      functions: _map(operator.functions, (o) => new FunctionTypeOperator(o)),
    };
  });
}
