import { Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { selectActiveDataUniverse } from '../../data-universe/data-universe.reducer';
import { filter } from 'rxjs/operators';
import { cloneDeep, keyBy } from 'lodash-es';
import { isDefined } from '../../utils/utils';
import { DataUniverse } from '../../data-universe/data-universe.models';
import {
  AudienceDefinitionGenerateAIResponse, AudienceDefinitionGenerateAIResponseEntity, AudienceRuleGroup,
  BuilderAudience,
  GenerateAudienceQueryFromTextRequest
} from '../../audience-builder/audience-builder.models';
import { AudienceBuilderService } from '../../audience-builder/audience-builder.service';
import { selectContext } from '../../context/context.reducer';
import * as actions from '../../audience-builder/audience-builder.actions';
import { selectPrebuiltAudience } from '../../audience-builder/audience-builder.reducer';
import { SetPrebuiltAudience } from '../../audience-builder/audience-builder.actions';
import { DataType } from '../../enums/data-types';
import { convertAudienceDefinitionToBuilder } from '../../audience/audience.utils';
import { AudienceDefinition } from '../../audience/audience.models';
import { MultiSelectAllComponent } from '../../shared/components/multi-select-all/multi-select-all.component';

@UntilDestroy()
@Component({
  selector: 'lib-generate-criteria',
  templateUrl: './generate-criteria.component.html',
  styleUrls: ['./generate-criteria.component.sass']
})
export class GenerateCriteriaComponent implements OnInit {
  @ViewChild('generateCriteriaModal') public generateCriteriaModal;
  @ViewChild('confirmGeneratedCriteriaModal') public confirmGeneratedCriteriaModal;
  @ViewChild('errorGeneratingQueryFromText', { static: true }) public errorGeneratingQueryFromText;
  @ViewChild(MultiSelectAllComponent) multiSelectAllComponent!: MultiSelectAllComponent
  modalGenerateCriteriaForm: UntypedFormGroup;
  contextId;
  dataUniverseId;
  dedupeIdentityType;
  errorMessage = 'There was a problem generating the criteria. Please try again.';
  generateAIQueryFromTextInProgress = false;
  type = 'include';
  placeHolderText;
  prebuiltAudience: BuilderAudience;
  audienceBuilderQuery: AudienceDefinitionGenerateAIResponseEntity;
  criteriaPromptRetention;
  datasetCategoryRetention;
  datasetCategories = [];
  datasetCategoriesMultiSelectList = [];
  datasetCategorySelectChange = false;

  constructor(
    public store: Store<AppState>,
    public formBuilder: UntypedFormBuilder,
    private builderService: AudienceBuilderService
  ) {
    this.modalGenerateCriteriaForm = this.formBuilder.group({
      textPrompt: new UntypedFormControl(),
      datasetCategory: new UntypedFormControl(),
    });
  }

  ngOnInit(): void {
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.contextId = context?.id;
      });
    this.store.select(selectActiveDataUniverse).pipe(filter(isDefined), untilDestroyed(this)).subscribe((dataUniverse: DataUniverse) => {
     this.dataUniverseId = dataUniverse?.id;
    });
    this.store.select(selectPrebuiltAudience).pipe(filter(isDefined)).subscribe((prebuiltAudience: BuilderAudience) => {
      this.prebuiltAudience = cloneDeep(prebuiltAudience);
    });

    this.builderService.getGenAIDatasetCategories(this.contextId).subscribe((resposne: any) => {
      if (resposne?.resultStatus === 'SUCCESS'){
        this.datasetCategories = resposne.entity;
        this.datasetCategoriesMultiSelectList = ['All',...this.datasetCategories.map(item=> item.description)];
      } else{
        this.datasetCategories = [];
        this.datasetCategoriesMultiSelectList = []
      }
      this.modalGenerateCriteriaForm.get('datasetCategory')?.setValue(['All']);
    });
    if (this.datasetCategoryRetention) {
      this.modalGenerateCriteriaForm.get('datasetCategory')?.setValue(this.datasetCategoryRetention);
    }
  }

  showModal(type: string, dedupeType: string) {
    this.type = type;
    this.dedupeIdentityType = dedupeType;
    if (this.type === 'Include') {
      this.placeHolderText = 'e.g. Millenials who own homes in Chicago';
    } else {
      this.placeHolderText = 'e.g. Townhome owners';
    }
    if (this.datasetCategoryRetention?.length) {
      this.multiSelectAllComponent?.formReset(this.getResetList(this.datasetCategoryRetention));
      this.modalGenerateCriteriaForm.get('datasetCategory')?.setValue(this.datasetCategoryRetention);
    } else {
      this.multiSelectAllComponent?.formReset(['All']);
      this.modalGenerateCriteriaForm.get('datasetCategory')?.setValue(['All']);
    }
  
    this.generateCriteriaModal.show();
  }

  closeModal() {
    this.criteriaPromptRetention = this.modalGenerateCriteriaForm.get('textPrompt').value;
    this.datasetCategoryRetention = this.modalGenerateCriteriaForm.get('datasetCategory').value;
    this.modalGenerateCriteriaForm.reset();
    this.generateCriteriaModal.hide();
  }

  closeConfirmModal() {
    this.confirmGeneratedCriteriaModal.hide();
  }

  generateQueryFromText() {
    this.criteriaPromptRetention = this.modalGenerateCriteriaForm.get('textPrompt').value;
    this.datasetCategoryRetention = this.modalGenerateCriteriaForm.get('datasetCategory').value;
    this.audienceBuilderQuery = null;
    this.errorMessage = 'No matching attributes found for your query.';
    this.generateAIQueryFromTextInProgress = true;
    this.modalGenerateCriteriaForm.get('textPrompt')?.disable();
    this.modalGenerateCriteriaForm.get('datasetCategory')?.disable();
    this.multiSelectAllComponent.controlDisable();
    const generateFromTextRequest: GenerateAudienceQueryFromTextRequest = {
      cabContextId: this.contextId,
      dataUniverseId: this.dataUniverseId,
      dedupeIdentityType: this.dedupeIdentityType,
      queryText: this.modalGenerateCriteriaForm.get('textPrompt').value,
      queryForExcludingAudiences: this.type === 'Exclude',
      categories: this.datasetCategorySelectChange ? this.datasetCategoryRetention: this.setDatasetCategoryValues(this.datasetCategoryRetention)
    };
    this.builderService.generateAudienceDefinitionFromText(generateFromTextRequest).subscribe({
      next: (res: AudienceDefinitionGenerateAIResponse) => {
        this.generateAIQueryFromTextInProgress = false;
        this.modalGenerateCriteriaForm.get('textPrompt')?.enable();
        this.modalGenerateCriteriaForm.get('datasetCategory')?.enable();
        this.multiSelectAllComponent.controlEnable();
    
        if (res?.entity && res.entity.query && res.entity.nodes) {
          this.audienceBuilderQuery = res.entity;
          this.closeModal();
          this.confirmGeneratedCriteriaModal.show();
        } else {
          if (res?.errorDetails?.length > 0) {
            this.errorMessage = res.errorDetails[0].errorMessage;
          }
          this.errorGeneratingQueryFromText.show();
        }
      },
      error: () => {
        this.generateAIQueryFromTextInProgress = false;
        this.modalGenerateCriteriaForm.get('textPrompt')?.enable();
        this.modalGenerateCriteriaForm.get('datasetCategory')?.enable();
        this.multiSelectAllComponent.controlEnable();
        this.errorGeneratingQueryFromText.show();
      }
    });
  }

  acceptAICriteria() {
    this.modalGenerateCriteriaForm.setValue({textPrompt: this.criteriaPromptRetention, datasetCategory: this.datasetCategoryRetention});

    this.audienceBuilderQuery.query['includeConditions'] = this.processConditionGroup(this.audienceBuilderQuery.query.includeConditions, this.audienceBuilderQuery.genAIRequestId);
    this.audienceBuilderQuery.query['excludeConditions'] = this.processConditionGroup(this.audienceBuilderQuery.query.excludeConditions, this.audienceBuilderQuery.genAIRequestId);
    const audienceDefinition: AudienceDefinition = new AudienceDefinition({
      query: this.audienceBuilderQuery.query,
      version: 0,
      canAddNestedDefinitions: false
    });
    const convertedBuilderAudience = convertAudienceDefinitionToBuilder(audienceDefinition, true);
    if(this.type === 'Exclude') {
      convertedBuilderAudience['includeConditions'] = this.builderService.rules[0];
    } else {
      convertedBuilderAudience['excludeConditions'] = this.builderService.rules[1];
    }
    if(!this.prebuiltAudience) {
      this.prebuiltAudience = convertedBuilderAudience;
    } else {
      this.prebuiltAudience.selectExpressions = convertedBuilderAudience?.selectExpressions;
      this.prebuiltAudience.includeConditions = convertedBuilderAudience?.includeConditions;
      this.prebuiltAudience.excludeConditions = convertedBuilderAudience?.excludeConditions;
    }
    this.builderService.rules = [convertedBuilderAudience?.includeConditions, convertedBuilderAudience?.excludeConditions];
    this.store.dispatch(new actions.LoadAttributeDetails(keyBy(this.audienceBuilderQuery.nodes, 'cabId')));
    this.store.dispatch(new actions.LoadPrebuiltAudience(this.prebuiltAudience));
    this.store.dispatch(new SetPrebuiltAudience(this.prebuiltAudience, this.contextId, DataType.AUDIENCE_DEFINITION));
    this.confirmGeneratedCriteriaModal.hide();
  }

  processConditionGroup(conditionGroupArray, genAIRequestId) {
    const updatedConditionGroupArray = [];
    conditionGroupArray?.forEach((condition: AudienceRuleGroup, index) => {
      if (index === conditionGroupArray.length - 1)
        condition.genAIRequestId = genAIRequestId;
      if(!condition.group) {
        const updatedIncludeCondition = {
          logicalOperator: condition.logicalOperator,
          group: []
        };
        const group = [];
        group.push(condition);
        updatedIncludeCondition.group = group;
        updatedConditionGroupArray.push(updatedIncludeCondition);
      } else {
        updatedConditionGroupArray.push(condition);
      }
    });
    return updatedConditionGroupArray;
  }

  datasetCategorySelect(event: string[]){
    const getFormValues = this.setDatasetCategoryValues(event);
    this.modalGenerateCriteriaForm.get('datasetCategory')?.setValue(getFormValues);
    this.datasetCategorySelectChange =true;
  }

  setDatasetCategoryValues(multiSelectList: string[]) {
    return multiSelectList.length ? this.datasetCategories.filter(item=> multiSelectList.includes(item.description)).map(item=> item.name) :  []
  }

  getResetList(selectedNames: string[]){
    return this.datasetCategories.filter(item=> selectedNames.includes(item.name)).map(item=> item.description)
  }
}
