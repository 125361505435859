import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { selectContext } from '../context/context.reducer';
import { Context } from '../context/context.models';
import { FeatureService } from '../utils/feature-service';
import { Inject, Injectable } from '@angular/core';
import { CabConstants } from '../cab.constants';
import { Action, PermissionResource } from './feature.access.action';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureAccessService {
  context: Context;

  constructor(
    private store: Store<AppState>,
    private environmentFeatureService: FeatureService,
    @Inject('FEATURE_ENABLED') private featureFlagService: any,
    @Inject('USER_PERMISSIONS') private permissionService: any
  ) {
    this.store.select(selectContext).subscribe((context) => {
      this.context = context;
    });
  }

  hasAccess(action: Action): boolean {
    if (!action) return true;

    const environmentFlagName = action.environmentFlagName;
    const contextFlagName = action.contextFlagName;
    const featureFlagName = action.featureFlagName;
    const permissionResource: PermissionResource = action.permissionResource;

    let isEnvironmentFlagEnabled = true;
    let isContextFeatureEnabled = true;
    let isFeatureEnabled = true;
    let isPermissionEnabled = true;

    if (environmentFlagName) {
      isEnvironmentFlagEnabled =
        this.environmentFeatureService.isFeatureEnabled(environmentFlagName);
    }

    if (contextFlagName) {
      isContextFeatureEnabled = this.context[contextFlagName];
    }

    if (
      featureFlagName &&
      this.context.productType == CabConstants.DCDP_PRODUCT_NAME
    ) {
      isFeatureEnabled =
        this.featureFlagService?.isFeatureEnabled(featureFlagName)?.pipe(take(1)).subscribe(isEnabled => {
            isFeatureEnabled = isEnabled;
        });
    }

    if (this.context?.enableFoundationRolePermission && this.context?.productType == CabConstants.DCDP_PRODUCT_NAME && permissionResource) {
      isPermissionEnabled = permissionResource.resourceAccess.some(
         (recourceOp) => {
            return this.permissionService.userHasResourcePermission(
               permissionResource.resourceName,
               recourceOp
            )
         }
      );
   }

    return (
      isEnvironmentFlagEnabled &&
      isContextFeatureEnabled &&
      isFeatureEnabled &&
      isPermissionEnabled
    );
  }
}
