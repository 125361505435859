import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import {
  capitalize,
  compact,
  cloneDeep,
  every,
  first,
  find as _find,
  get,
  isEmpty,
  isNull,
  omit,
  pick,
  some,
} from 'lodash-es';
import {
  AudienceExpressionGroup,
  AudienceExpressionOperand,
  AudienceRuleGroup,
  BuilderAttribute,
  BuilderAudience,
  BuilderAudienceTypeNameType,
  ERROR_GENERIC,
  emptyAudienceRuleGroup,
} from '../audience-builder/audience-builder.models';
import { Audience, AudienceDefinition } from './audience.models';
import {
  formatCalendarDates,
  isCalendarDateType,
  isRelativeDate,
} from '../utils/utils';
import { BehaviorSubject } from 'rxjs';
import { DataSetType, DataType } from '../enums/data-types';
import { CampaignExtract } from '../campaign-extract/campaign-extract.model';
import { DedupeType } from '../models/dedupe-type';
import { DataUniverse } from '../data-universe/data-universe.models';

export const MAX_NAME_LENGTH = 255;
export const nameValidators = [
  Validators.required,
  nameMaxLength(),
  noWhitespaceValidator(),
];

// max character validator for audience/definition name field, ignoring whitespace characters before and after valid characters
export function nameMaxLength(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const currentLength = control.value && control.value.trim().length;
    return currentLength < MAX_NAME_LENGTH + 1
      ? null
      : {
          maxlength: {
            requiredLength: MAX_NAME_LENGTH,
            actualLength: currentLength,
          },
        };
  };
}

// validates entry is not only white spaces
export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const name = control.value?.trim();
    return name && name.length > 0 ? null : { whitespace: true };
  };
}

// helper function for handleEmptyGroups
function shouldFilter(item) {
  if (item.expression) {
    return true;
  }
  if (item.group.length === 0 && item.aggregationConditions.length === 0) {
    return false;
  }
  return true;
}
// recursively remove empty groups from query
export function handleEmptyGroups(
  conditions,
  editing,
  conditionType?: BuilderAudienceTypeNameType
) {
  if (!conditions) {
    return [];
  }
  for (let i = 0; i < conditions.length; i++) {
    // recurse and then filter
    conditions[i].typeName = conditionType;
    if (conditions[i].group && conditions[i].group.length) {
      conditions[i].group = handleEmptyGroups(
        conditions[i].group,
        editing,
        conditionType
      );
    }
  }
  conditions = conditions.filter(shouldFilter);
  if (editing) {
    conditions.push(emptyAudienceRuleGroup(conditionType));
  }
  return conditions;
}

// BuilderAudience -> AudienceDefinition
export function convertAudienceBuilderToDefinition(
  audience: BuilderAudience,
  cabContextId: string,
  dataUniverseId: string
): AudienceDefinition {
  const query = cloneDeep(omit(audience, 'displayName'));
  query.includeConditions = handleEmptyGroups(query.includeConditions, false);
  query.excludeConditions = handleEmptyGroups(query.excludeConditions, false);
  [query.includeConditions, query.excludeConditions].forEach(
    (conditionGroup) => {
      formatDefinition(conditionGroup, true);
    }
  );

  return new AudienceDefinition({
    id: audience.id,
    displayName: audience.displayName?.trim(),
    cabContextId,
    dataUniverseId,
    dedupeIdentityType: audience.dedupeIdentityType,
    query: {
      selectExpressions: audience.selectExpressions,
      includeConditions: query.includeConditions,
      excludeConditions: query.excludeConditions,
      version: audience.version,
      queryFlags: {
        caseInsensitive: query.audienceQueryCaseInsensitive
      },
    },
    idCount: audience.idCount,
    countUpdatedOn: audience.countUpdatedOn,
    countRequestedAt: audience.countRequestedAt,
    version: audience.version,
    description: audience.description,
    audienceAttributes: audience.audienceAttributes,
    createdBy: audience.createdBy,
    createdDate: audience.createdDate,
    lastModifiedBy: audience.lastModifiedBy,
    lastModifiedDate: audience.lastModifiedDate,
  });
}

// AudienceDefinition -> BuilderAudience
export function convertAudienceDefinitionToBuilder(
  audience: AudienceDefinition | Audience | CampaignExtract,
  editing = false
): BuilderAudience {
  const include = handleEmptyGroups(
    cloneDeep(get(audience, ['query', 'includeConditions'], [])),
    editing,
    'Include'
  );
  const exclude = handleEmptyGroups(
    cloneDeep(get(audience, ['query', 'excludeConditions'], [])),
    editing,
    'Exclude'
  );
  const caseInsensitiveFlag = get(audience, ['query', 'queryFlags', 'caseInsensitive'], false);

  const selectExpressions = get(audience, 'selectExpessions', null);

  [include, exclude].forEach((conditionGroup) =>
    formatDefinition(conditionGroup)
  );

  return {
    id: audience?.id,
    displayName: audience.displayName,
    includeConditions: include,
    excludeConditions: exclude,
    selectExpressions,
    dedupeIdentityType: audience['dedupeIdentityType'],
    idCount: audience['idCount'],
    dataUniverseId: audience.dataUniverseId,
    countUpdatedOn: audience['countUpdatedOn'],
    countRequestedAt: (audience as AudienceDefinition).countRequestedAt,
    countRequestId: (audience as AudienceDefinition).countRequestId,
    type: audience['type'],
    version: audience.version,
    canAddNestedDefinitions: audience['canAddNestedDefinitions'],
    cabContextId: audience.cabContextId,
    description: audience.description,
    createdBy: audience.createdBy,
    lastModifiedBy: audience.lastModifiedBy,
    createdDate: audience.createdDate,
    lastModifiedDate: audience.lastModifiedDate,
    audienceAttributes: (audience as AudienceDefinition).audienceAttributes,
    countErrorMessage: (audience as AudienceDefinition).countErrorMessage,
    countStatus: (audience as AudienceDefinition).countStatus,
    audienceQueryCaseInsensitive: caseInsensitiveFlag
  } as BuilderAudience;
}

// forBackEnd formats the definition for API ingestion (otherwise format for UI)
function formatDefinition(
  ruleGroups: AudienceRuleGroup[],
  forBackEnd = false
) {
  ruleGroups?.forEach(
    (ruleGroup: AudienceRuleGroup | AudienceExpressionGroup) => {
      if (instanceOfAudienceExpressionGroup(ruleGroup)) {
        const expression = ruleGroup.expression;
        // Handle IS_NULL and IS_NOT_NULL

        if (
          isNull(get(expression, 'secondOperand')) ||
          isNull(get(expression, 'secondOperand.values'))
        ) {
          if (forBackEnd) {
            expression.secondOperand = null;
          }
        }
        // Format Calendar Dates
        if (
          isCalendarDateType(expression, expression.comparisonOperator) &&
          !isRelativeDate(expression) &&
          !isNull(expression.secondOperand)
        ) {
          expression.secondOperand.values = formatCalendarDates(
            expression,
            forBackEnd
          );
        }

        // Set secondOperand comparedAttribute
        if (expression.secondOperand) {
          // Handle relative dates
          if (isRelativeDate(expression)) {
            const relativeDate = expression.secondOperand.relativeDate;
            if (forBackEnd) {
              expression.secondOperand.values = relativeDate;
              expression.secondOperand = omit(expression.secondOperand, [
                'relativeDate',
              ]);
            } else {
              expression.secondOperand.relativeDate =
                expression.secondOperand.values;
            }
          }

          if (expression.secondOperand.valueType === 'Attribute') {
            if (forBackEnd) {
              expression.secondOperand = expression.secondOperand
                .comparedAttribute as AudienceExpressionOperand;
              expression.expressionType = 'BasicComparison';
              expression.secondOperand = omit(expression.secondOperand, [
                'comparedAttribute',
              ]);
            } else {
              const comparedAttribute =
                expression.secondOperand as BuilderAttribute;
              expression.secondOperand = pick(expression.secondOperand, [
                'dataType',
                'valueType',
                'values',
              ]);
              expression.secondOperand.comparedAttribute = comparedAttribute;
            }
          } else {
            expression.secondOperand = omit(expression.secondOperand, [
              'comparedAttribute',
            ]);
            expression.expressionType = 'BasicComparison';
          }
        }

        // Set firstOperand Aggregate Function
        if (expression.firstOperand) {
          if (expression.firstOperand.valueType === 'Function') {
            if (forBackEnd) {
              expression.firstOperand = {
                valueType: 'Function',
                function: expression.firstOperand.function,
                arguments: [
                  omit({ ...expression.firstOperand, valueType: 'Attribute' }, ['function']) as AudienceExpressionOperand,
                ],
              };
            } else {
              const firstOperand = expression.firstOperand;
              expression.firstOperand = {
                ...first(firstOperand.arguments),
                function: firstOperand.function,
              };
            }
          } else {
            expression.firstOperand = omit(expression.firstOperand, [
              'function',
            ]);
          }
        }

        if (expression.firstOperand) {
          // Set displayName to expression name
          ruleGroup.displayName = expression.firstOperand?.name;
        }
      } else {
        formatDefinition(ruleGroup.group, forBackEnd);
        formatDefinition(ruleGroup.aggregationConditions, forBackEnd);
      }
    }
  );
}

// recursively check if all query expressions have values
export function expressionsHaveValues(
  ruleGroups: AudienceRuleGroup[],
  dataTypeOperators,
  result?
) {
  result = result || [];
  ruleGroups.forEach(
    (ruleGroup: AudienceRuleGroup | AudienceExpressionGroup): any | void => {
      if (instanceOfAudienceExpressionGroup(ruleGroup)) {
        const expression = ruleGroup.expression;
        const isAudience = get(expression, 'operand.valueType');
        if (isAudience) {
          return true;
        }
        const activeOperator = _find(dataTypeOperators, {
          dataType: capitalize(expression.secondOperand.dataType),
        });
        const activeFilter = _find(activeOperator.operators, {
          operator: expression.comparisonOperator,
        });
        const values = get(expression, 'secondOperand.values');
        const comparedAttribute = get(
          expression,
          'secondOperand.comparedAttribute'
        );
        const relativeDate = get(expression, 'secondOperand.relativeDate');
        let hasValue;
        if (relativeDate) {
          hasValue =
            compact(relativeDate).length > (activeFilter.dualValue ? 1 : 0);
        } else if (isNull(values) || !isEmpty(comparedAttribute)) {
          hasValue = true;
        } else if (activeFilter.dualValue) {
          hasValue = values.length >= 2;
        } else if (activeFilter.singleOperand || activeFilter.multiValue) {
          hasValue = !!compact(values).length;
        }

        result.push(hasValue);
      } else {
        expressionsHaveValues(ruleGroup.group, dataTypeOperators, result);
      }
    }
  );
  return every(result, (r) => r === true);
}

function instanceOfAudienceExpressionGroup(
  object: any
): object is AudienceExpressionGroup {
  return 'expression' in object;
}

function instanceOfAudienceAggregationGroup(
  object: any
): object is AudienceExpressionGroup {
  return 'aggregationConditions' in object;
}

export function hasExpressionGroup(group): boolean {
  return group.some((item) => instanceOfAudienceExpressionGroup(item));
}

export function hasAggregationGroup(group): boolean {
  return group.some((item) => instanceOfAudienceAggregationGroup(item));
}

export function errorIncludes(error, message: string): boolean {
  return some(error.errorDetails, (errorDetail) =>
    errorDetail.errorMessage.includes(message)
  );
}

export function getAttributeDetail(attributeDetails, expression) {
  return attributeDetails[get(expression, 'firstOperand.cabId')];
}

export function getCabAttributeDetail(attributeDetails, expression) {
  return attributeDetails[get(expression, 'operand.cabId')];
}

export function setFullSegmentPath(
  attributePath$: BehaviorSubject<string>,
  attributeDetail
) {
  attributePath$.next(getFullSegmentPath(attributeDetail));
}

export const cabPathList = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'cab:auddefcat:auddefcat': DataSetType.AUDIENCE_DEFINITION,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'cab:audlistcat:audlistcat': DataSetType.AUDIENCE_LIST,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'cab:datasetcat:datasetcat': DataSetType.DATA_SET_ATTRIBUTE
};

export function getFullSegmentPath(attributeDetail): string {
  const attributePathSize = attributeDetail?.path?.length;
  let skipPathUptoIndex = attributePathSize > 2 ? 1 : 0;
  return attributeDetail?.path?.map((subPath, index) => {
    if(subPath.cabId.indexOf('cab:datasetsubcat:') !== -1) {
      skipPathUptoIndex = attributePathSize > 3 ? 2 : 1;
    }
    if(!Object.keys(cabPathList).includes(subPath.cabId) && subPath.cabId.indexOf('cab:datasetsubcat:') === -1 && index > skipPathUptoIndex) {
      return subPath.displayName;
    }
   return '';
  }).filter(value => value !== '').join(' / ');
}

export function cabAttributeTooltip(dataType: DataType): string {
  return `View audience ${dataType.replace(/audience/, '')} details`;
}


export function checkForSendToTargetType(item) {
  if(item?.sendToTargetType === 'AMS'){
    return 'Paid Connector';
  }else if (item?.sendToTargetType === 'DATAHUB_FEED' && item?.sendToTargetInfo.targetConnector === 'S3'){
    return 'Amazon S3';
  }
  return item?.sendToTargetType ?? '-';
}

export function getConnectorRoute(item, tenantId){
  return `/tenant/${tenantId}/connectors/${item?.sendToTargetInfo?.targetConnector}/connection/${item?.sendToTargetInfo?.connectionId}`;
}

export const getParsedApiErrorMessage = (errorValue): string => {
  if(errorValue?.error?.errorDetails?.[0]?.errorMessage){
    return errorValue.error.errorDetails[0].errorMessage;
  }
  return ERROR_GENERIC;
}

export const getAudienceDedupeType = (
  dataUniverseId: string,
  dedupeIdentityType: string,
  dataUniverses: DataUniverse[]
): DedupeType => {
  const dataUniverse = dataUniverses.find(
    (dataUniverse) => dataUniverse.id === dataUniverseId
  );
  return dataUniverse?.dedupeTypes.find(
    (dedupeType) => dedupeType.identityType === dedupeIdentityType
  );
};