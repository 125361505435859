import * as actions from './data-universe.actions';
import { DataUniverse } from './data-universe.models';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState } from '../reducers/index';
import { find, get } from 'lodash-es';

export interface State {
  dataUniverses: DataUniverse[];
  activeDataUniverse?: DataUniverse;
}

const defaultState = {
  dataUniverses: [],
};

export function reducer(
  state: State = defaultState,
  action: actions.ReducerActions
): State {
  switch (action.type) {
    case actions.LoadDataUniverses.type:
      return { ...state, dataUniverses: action.dataUniverses };
    case actions.ResetDataUniverses.type:
      return { ...state, dataUniverses: [], activeDataUniverse: null };
    case actions.SetActiveDataUniverse.type:
      return { ...state, activeDataUniverse: action.dataUniverse };
    default:
      return state;
  }
}

const cabFeature = createFeatureSelector<AppState>('cab');

export const selectDataUniverseFeature = createSelector(cabFeature, (state: AppState) => state.dataUniverse);
export const selectActiveDataUniverse = createSelector(selectDataUniverseFeature, (state: State) => state.activeDataUniverse);
export const selectActiveDataUniverseId = createSelector(selectActiveDataUniverse, (activeDataUniverse) => activeDataUniverse?.id);
export const selectDataUniverses = createSelector(selectDataUniverseFeature, (state: State) => state.dataUniverses);
export const selectDedupeTypes = (dataUniverseId: string) => {
  return createSelector(selectDataUniverses, (dataUniverses) =>
    get(find(dataUniverses, { id: dataUniverseId }), 'dedupeTypes', [])
  );
};
