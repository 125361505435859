import { AttributeDetails, BuilderAudience } from './audience-builder.models';
import * as actions from './audience-builder.actions';
import { DataTypeOperator } from '../models/data-type-operator';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState } from '../reducers/index';
import { AudienceDefinition } from '../audience/audience.models';

export interface State {
  dataTypeOperators: DataTypeOperator[];
  prebuiltAudience: BuilderAudience;
  attributeDetails: AttributeDetails;
  displayFullSegmentPath: boolean;
  dependentDefinitions: AudienceDefinition[];
}

const defaultState = {
  dataTypeOperators: [],
  prebuiltAudience: null,
  attributeDetails: {},
  displayFullSegmentPath: false,
  dependentDefinitions: []
};

export function reducer(
  state: State = defaultState,
  action: actions.ReducerActions
): State {
  switch (action.type) {
    case actions.LoadDataTypeOperators.type:
      return { ...state, dataTypeOperators: action.operators };
    case actions.ClearDataTypeOperators.type:
      return { ...state, dataTypeOperators: [] };
    case actions.LoadDependentDefinitions.type:
      return { ...state, dependentDefinitions: action.dependentDefinitions };
    case actions.LoadPrebuiltAudience.type:
      return { ...state, prebuiltAudience: action.audience };
    case actions.LoadAttributeDetails.type:
      return { ...state, attributeDetails: action.details };
    case actions.ToggleFullSegmentPath.type:
      return { ...state, displayFullSegmentPath: action.displayPath };
    case actions.UpdateCountDetails.type:
      return { ...state, prebuiltAudience: { ...state.prebuiltAudience, idCount: action.countDetails.idCount, countUpdatedOn: action.countDetails.countUpdatedOn, countRequestedAt: action.countDetails.countRequestedAt, countStatus: action.countDetails.countStatus }};
    default:
      return state;
  }
}

const cabFeatureSelector = createFeatureSelector<AppState>('cab');

export const audienceBuilderFeatureSelector = createSelector(cabFeatureSelector, (state: AppState) => state.audienceBuilder);
export const selectDataTypeOperators = createSelector(audienceBuilderFeatureSelector, (state: State) => state.dataTypeOperators);
export const selectDependentDefinitions = createSelector(audienceBuilderFeatureSelector, (state: State) => state.dependentDefinitions);
export const selectPrebuiltAudience = createSelector(audienceBuilderFeatureSelector, (state: State) => state.prebuiltAudience);
export const selectPrebuiltAudienceId = createSelector(selectPrebuiltAudience, (prebuiltAudience) => prebuiltAudience?.id);
export const selectAttributeDetails = createSelector(audienceBuilderFeatureSelector, (state: State) => state.attributeDetails);
export const selectDisplayFullSegmentPath = createSelector(audienceBuilderFeatureSelector, (state: State) => state.displayFullSegmentPath);
