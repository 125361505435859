<coreui-modal #manageJoins>
  <ng-template #titleTemplate> Manage Joins </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeModal()"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <ng-container>
      <form [formGroup]="addJoin">
        <div *ngFor="let joinItem of relations; index as joinIdx" class="joinGroup">
          <coreui-form-field label="Data set Name" class="selectDropdown">
            <input coreUIInput formControlName="selectTableDisabled"/>
          </coreui-form-field>
          <span class="font-weight-bold" class="joinWithLabel">join</span>
          <coreui-form-field label="Select another Data set" class="selectDropdown">
            <coreui-select
              placeholder="Select"
              [searchable]="true"
              (searchValueChange)="searchTargetTables($event)"
              (valueChange)="onTargetDatasetChange($event, joinIdx)"
              [formControlName]="'joinTargetSelect'+joinIdx"
            >
              <coreui-option
                *ngFor="let dataset of dataSetArray"
                [value]="dataset.id">
                {{dataset.displayName}}
              </coreui-option>
              <span *ngIf="showSearchLimit" style="color:red">Input at least 3 characters to search.</span>
              <span *ngIf="dataSetArray?.length === 0 && !showSearchLimit" class="no-results">No results found...</span>
            </coreui-select>
          </coreui-form-field>
          <i coreUIIcon="trash" scale="1.5" class="trash" (click)="removeJoin(joinIdx, relations[joinIdx].targetDataSetId)"></i>
          <ng-container>
            <div *ngFor="let attributeJoins of joinItem.attributeRelationsDetails; index as attributeJoinIdx">
              <div>
                <coreui-form-field label="Join attributes for first Data set" class="selectDropdown">
                  <coreui-select
                    placeholder="Select"
                    [searchable]="true"
                    (searchValueChange)="searchTextSourceAttributes($event)"
                    (valueChange)="sourceAttributeValueChange($event, joinIdx, attributeJoinIdx)"
                    [formControlName]="'attributeSourceJoinSelect'+relations[joinIdx].targetDataSetId+attributeJoinIdx+joinIdx"
                  >
                    <coreui-option
                      *ngFor="let attribute of sourceDatasetAttributes"
                      [value]="attribute.id">
                      {{attribute.displayName}}
                    </coreui-option>
                    <span *ngIf="showSearchLimit" style="color:red">Input at least 3 characters to search.</span>
                    <span *ngIf="sourceDatasetAttributes?.length === 0 && !showSearchLimit" class="no-results">No results found...</span>
                  </coreui-select>
                </coreui-form-field>
                <span class="joinWithLabel">with</span>
                <coreui-form-field label="Join attributes for second Data set" class="selectDropdown">
                  <coreui-select
                    placeholder="Select"
                    [searchable]="true"
                    (searchValueChange)="searchTextTargetAttributes($event, joinIdx)"
                    (valueChange)="targetAttributeValueChange($event, joinIdx, attributeJoinIdx)"
                    [formControlName]="'attributeTargetJoinSelect'+relations[joinIdx].targetDataSetId+attributeJoinIdx+joinIdx"
                  >
                    <coreui-option
                      *ngFor="let attribute of targetDatasetAttributes[joinIdx]"
                      [value]="attribute.id">
                      {{attribute.displayName}}
                    </coreui-option>
                    <span *ngIf="showSearchLimit" style="color:red">Input at least 3 characters to search.</span>
                    <span *ngIf="targetDatasetAttributes[joinIdx]?.length === 0 && !showSearchLimit" class="no-results">No results found...</span>
                  </coreui-select>
                </coreui-form-field>
                <i (click)="removeJoinAttribute(joinIdx, attributeJoinIdx, relations[joinIdx].targetDataSetId)" coreUIIcon="trash" scale="1.5" class="trash"></i>
              </div>
            </div>
          </ng-container>
          <button coreUIButton="text-primary" withIcon="left" [disabled]="!relations[joinIdx].targetDataSetId" class="addPair" (click)="onAttributeJoinTrigger(joinIdx, relations[joinIdx].attributeRelationsDetails.length, relations[joinIdx].targetDataSetId)">
            <i coreUIIcon="add"></i>Add another pair of attributes
          </button>
          <div coreUIDivider></div>
        </div>
        <button coreUIButton="secondary" class="addJoin" (click)="onJoinTrigger(relations.length)">Add Another Join</button>
      </form>
    </ng-container>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal()">
      Cancel
    </button>
    <button coreUIButton (click)="saveJoins()">
      Save
    </button>
  </ng-template>
</coreui-modal>
<coreui-notification notificationStyle="error" #failedSavingJoins>
  <b>{{ errorMessage }}</b>
  <br/>
</coreui-notification>
