import { Injectable } from '@angular/core';

import { SchemaField } from '@epsilon-cdp/connector-lib/mapping';
import { CabConstants } from '../../../cab.constants';
import { AttributeModel } from '../../../audience/attribute.model';
import { Path } from './send-to.models';
import { Adapter } from '../../../services/adapter';

@Injectable()
export class SchemaFieldsAdapter implements Adapter<AttributeModel[], SchemaField[]>{

  adapt(sourceAttributes: AttributeModel[]): SchemaField[] {
    const transformedSourceAttributes: SchemaField[] =  [{
      name: 'Attributes from Data Objects',
      displayName: 'Attributes from Data Objects',
      dataType: '',
      required: false,
      entitySchemaFields: [],
      metadata: {
        cabId: 'cab:datasetcat:datasetcat',
        id: 'cab:datasetcat:datasetcat',
        path: [],
        childCabId: '',
        nativeDataType: ''
      }
    }];

    sourceAttributes.forEach((sourceAttribute: AttributeModel) => {
      const { displayName, cabId, id, dataSetType, dataType, nativeDataType, path, required } = sourceAttribute;
      const modifiedPath = path.length > 2 ? path.filter(dataSet => dataSet.displayName !== 'Profile'): path;
      let profilePath: Path;
      let profileIndex: number;
      path.forEach((dataSet: Path,index:number): void =>{
        if (dataSet.displayName === 'Profile') {
          profilePath = dataSet;
          profileIndex = index;
        }
      });
      const childCabId = profilePath?.['childNodeRelationInfo']?.['nodeRelationAttributes']?.[0]?.['childCabId'];
      const childDisplayName = profilePath?.['childNodeRelationInfo']?.['nodeRelationAttributes']?.[0]?.['childDisplayName'];
      if (modifiedPath[profileIndex]) {
        modifiedPath[profileIndex]['childCabId'] = childCabId;
      }
      const parent = this.findDataSetAttributeParent(transformedSourceAttributes, modifiedPath, (dataSetType === CabConstants.DATA_TYPE_DATA_SET), cabId);
     let name = path.map((path: Path) => path.cabId).join('-').concat('-', cabId).concat('-', displayName);
     if (childDisplayName) {
      name = name.concat('-', childDisplayName);
      }
      const schemaField: SchemaField = {
        name,
        displayName,
        dataType,
        required,
        entitySchemaFields: [],
        metadata: { cabId, id, displayName, dataSetType, path, childCabId, nativeDataType }
      };
      const foundSchemaField = parent[0].entitySchemaFields.filter(filterSchemaField =>
        filterSchemaField?.metadata?.['cabId'] === cabId && filterSchemaField?.metadata?.['childCabId'] === childCabId
      );
      if(foundSchemaField.length > 0 && dataSetType === CabConstants.DATA_TYPE_DATA_SET) {
        foundSchemaField[0].name = name;
        foundSchemaField[0].dataType = dataType;
        foundSchemaField[0].required = false;
        foundSchemaField[0].displayName = displayName;
        foundSchemaField[0].metadata = { cabId, id, displayName, dataSetType, path, childCabId, nativeDataType };
      } else {
        parent[0].entitySchemaFields.push(schemaField);
      }
    });
    return transformedSourceAttributes;
  }

  findDataSetAttributeParent(schemaFieldArray: SchemaField[], pathArray: Path[], isDataSet, cabId) {
    const schemaField = schemaFieldArray.filter(filterSchemaField => {
      if(pathArray?.[0]?.['childCabId']) {
        return filterSchemaField?.metadata?.['childCabId'] === pathArray?.[0]?.['childCabId'];
      } else {
        return filterSchemaField?.metadata?.['cabId'] === pathArray?.[0]?.['cabId'];
      }
    });
    let entitySchemaFields;
    if(schemaField.length === 0) {
      const schemaFieldObj: SchemaField = {
        name: pathArray?.[0]?.['displayName'],
        displayName: pathArray?.[0]?.['displayName'],
        dataType: '',
        required: false,
        entitySchemaFields: [],
        metadata: { cabId: pathArray?.[0]?.['cabId'], childCabId: pathArray?.[0]?.['childCabId'] }
      };
      schemaFieldArray.push(schemaFieldObj);
      entitySchemaFields = schemaFieldObj.entitySchemaFields;
    } else {
      entitySchemaFields = schemaField[0]['entitySchemaFields'];
    }
    if(pathArray.length > 1 && (!isDataSet || cabId !== pathArray?.[1]?.['cabId'])) {
      return this.findDataSetAttributeParent(entitySchemaFields, [...pathArray].splice(1, pathArray.length), isDataSet, cabId);
    }
    return schemaField;
  }
}
