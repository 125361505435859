import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';

/**
 * Custom pipe used for Audience Overview screen
 */
@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(source: any[], sortOption: string, sortDirection: string): any[] {
    switch (sortOption) {
      // string types
      case 'name':
      case 'displayName':
      case 'dataUniverseId':
      case 'status':
      case 'dedupeIdentityType':
      case 'lastModifiedBy':
      case 'createdBy':
      case 'channelType':
      case 'connector':
      case 'connection':
      case 'sendFor':
        source.sort((a, b) => {
          const valueA = a[sortOption]?.toUpperCase();
          const valueB = b[sortOption]?.toUpperCase();
          return this.getSortedBasedOnDirection(sortDirection, valueA, valueB);
        });
        break;
      // all other types
      case 'idCount':
      case 'coreIds':
      case 'countUpdatedOn':
      case 'lastModifiedDate':
      case 'createdDate':
      case 'numOfSchedules':
        source.sort((a, b) => {
          return this.getSortedBasedOnDirection(
            sortDirection,
            a[sortOption],
            b[sortOption]
          );
        });
        break;
    }
    return source;
  }

  private getSortedBasedOnDirection(sortDirection, a, b): any | void {
    switch (sortDirection) {
      case 'ASC':
        if (isNil(b) || a < b) {
          return -1;
        }
        if (isNil(a) || a > b) {
          return 1;
        }
        return 0;
      case 'DESC':
        if (isNil(a) || a < b) {
          return 1;
        }
        if (isNil(b) || a > b) {
          return -1;
        }
        return 0;
    }
  }
}
