import { Injectable } from '@angular/core';
import { isArray, last, uniq, orderBy } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { scan } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/*
Adds Core UI drop lists to the DropListDirective connectedTo input
connectedTo: other draggable containers that this container is connected to and into which the container's items can be transferred
See DropListDirective in Core UI docs for more details: https://core-ui.conversantmedia.com/primary/components/drag-drop/api#DropListDirective
*/

@UntilDestroy()
@Injectable()
export class DragDropService {
  addAggregationButtonClick: boolean;
  dropLists = new BehaviorSubject([]);

  // Tracks list of connectedTo drop lists
  dropLists$ = this.dropLists.pipe(
    untilDestroyed(this),
    scan((acc, newVals) => {
      let newList = newVals;
      if (newList.length) {
        newList = newList.sort(function (a, b) {
          return (
            Number(last(b.split('core-drop-list-'))) -
            Number(last(a.split('core-drop-list-')))
          );
        });
      }
      return newList;
    }, [])
  );

  // Registers existing drop lists then updates dropLists$
  public register(dropList: string | string[]) {
    let newList;
    if (isArray(dropList)) {
      newList = uniq(
        orderBy([...this.dropLists.getValue(), ...dropList], ['id'], ['desc'])
      );
    } else {
      newList = uniq(
        orderBy([...this.dropLists.getValue(), dropList], ['id'], ['desc'])
      );
    }
    newList = newList.sort(function (a, b) {
      return (
        Number(last(b.split('core-drop-list-'))) -
        Number(last(a.split('core-drop-list-')))
      );
    });

    this.dropLists.next(newList);
  }

  constructor() {
    // DragDropService constructor
  }
}
