import { Action } from '@ngrx/store';
import { User } from './user.models';

export class FetchUserDetails implements Action {
  static readonly type = 'USER:FETCH_DETAILS';
  readonly type = FetchUserDetails.type;
}

export class FetchUserPermissions implements Action {
  static readonly type = 'USER:FETCH_PERMISSIONS';
  readonly type = FetchUserPermissions.type;

  constructor(public contextId: string) {}
}

export class LoadUserDetails implements Action {
  static readonly type = 'USER:LOAD_DETAILS';
  readonly type = LoadUserDetails.type;

  constructor(public userDetails: User) {}
}

export class LoadUserPermissions implements Action {
  static readonly type = 'USER:LOAD_PERMISSIONS';
  readonly type = LoadUserPermissions.type;

  constructor(public userPermissions: string[]) {}
}

export type ReducerActions = FetchUserDetails | LoadUserDetails | FetchUserPermissions | LoadUserPermissions;
