<div class="Core-Site">
  <lib-core-activation *ngIf="amsAudience"
                       [audience]="amsAudience"
                       [returnRoute]="returnRoute"
                       [appReturnRoute]="returnRoute"
                       [redirectOnSaveRoute]="redirectOnSaveRoute"
                       [createDestinationRoute]="createDestinationRoute"
                       [showBackButton] = "true"
                       [amsActivationType]="'standard'"
  ></lib-core-activation>
</div>

