import { FieldMapping } from '@epsilon-cdp/connector-lib/mapping';
import { Adapter } from '../../../services/adapter';
import { Mapping } from './send-to.models';
import { Injectable } from '@angular/core';

@Injectable()
export class MappingsAdapter implements Adapter<FieldMapping[], Mapping[]> {

  adapt(fieldMappings: FieldMapping[]): Mapping[] {
    const mappingArray = fieldMappings?.map((fieldMapping: FieldMapping) => {
      const { cabId, id, displayName, dataSetType, path, nativeDataType } = fieldMapping.sourceFieldHeader.metadata;
      return {
        sourceNode: { cabId, id, dataSetType, path, displayName, dataType: fieldMapping.sourceFieldHeader.type, nativeDataType, leaf: true },
        targetColumn: fieldMapping.targetField
      } as Mapping;
    });
    return mappingArray;
  }
}
