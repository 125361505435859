import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first, values } from 'lodash-es';

import { selectAudienceForCreateList, selectAudiences } from '../../../audience/audience.reducer';
import { Audience, AudienceDefinition } from '../../../audience/audience.models';
import { selectPrebuiltAudience } from '../../../audience-builder/audience-builder.reducer';
import { FetchAttributeDetails } from '../../../audience-builder/audience-builder.actions';
import { DataType } from '../../../enums/data-types';
import { fetchOutcome } from '../../../utils/fetch-state';
import { convertAudienceBuilderToDefinition } from '../../../audience/audience.utils';
import { ScheduleDetail, ScheduleType } from '../../details/schedules/schedules.models';
import { SaveCampaignExtractSchedule } from '../../../campaign-extract/campaign-extract.actions';
import { SaveAudience } from '../../../audience/audience.actions';
import { BuilderAudience } from '../../../audience-builder/audience-builder.models';
import { FEATURE_SCHEDULING  } from '../../../utils/feature-utils';
import { AppState } from '../../../reducers';
import { selectContext } from '../../../context/context.reducer';
import { FeatureService } from '../../../utils/feature-service';
import { AssetService, AssetType, TagSearchResponse, TagService } from '@epsilon-cdp/pcm-common-lib';

@UntilDestroy()
@Component({
  selector: 'lib-create-list',
  templateUrl: './create-list.component.html',
  styleUrls: ['./create-list.component.sass']
})
export class CreateListComponent implements AfterViewInit, OnInit {
  @ViewChild('createActionModal') public createActionModal;
  @ViewChild('libScheduleAudienceModal') libScheduleAudienceModal;
  @ViewChild('errorCreatingList', { static: true }) public errorCreatingList;
  @Output() createListModalClosed = new EventEmitter<string>();

  actionInProgress$ = new BehaviorSubject<{
    type: string;
    definitions: AudienceDefinition[];
  } | null>(null);
  audiences: Audience[];
  audienceDefinition: AudienceDefinition;
  prebuiltAudience: BuilderAudience;
  modalCreateForm: UntypedFormGroup;
  schedulerEnabled: boolean;
  errorMessage = 'There was a problem creating this list. Please try again.';
  isAudienceListNameDuplicate: boolean;
  isListSizeLimit = "";
  sizeLimitOptions = [{label: "Yes", value: "Yes"}, {label:"No", value: "No"}];
  productType: string;
  addedTags: string[];
  protected readonly AssetType = AssetType;
  constructor(
    public store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,
    private actions$: Actions,
    private featureService: FeatureService,
    public tagService: TagService, 
    private assetService: AssetService
  ) {

    this.store
      .select(selectAudiences)
      .pipe(map(values), untilDestroyed(this))
      .subscribe((audiences: Audience[]) => (this.audiences = audiences));

    this.modalCreateForm = this.formBuilder.group({
      audienceName: new UntypedFormControl(),
      listSizeLimit: new UntypedFormControl(),
      limitByCountOrPercentage: new UntypedFormControl('limitByCount'),
      limitByCountValue: new UntypedFormControl(),
      limitByPercentageValue: new UntypedFormControl(),
    });


    this.store
      .select(selectPrebuiltAudience)
      .pipe(untilDestroyed(this))
      .subscribe(
        (prebuiltAudience) => (this.prebuiltAudience = prebuiltAudience)
      );

    this.store
      .select(selectAudienceForCreateList)
      .pipe(untilDestroyed(this))
      .subscribe((definition) => {
        this.audienceDefinition = definition;
        if (definition.tags?.length) {
          this.addAssetTags();
        } else if (definition.tags == undefined) {
          this.addAssetTagsRequest();
        }
      });
  }

  addAssetTagsRequest() {
    this.tagService
    .search({assetId: this.audienceDefinition.id, type: AssetType.AUDIENCE_DEFINITION}).pipe(untilDestroyed(this))
    .subscribe((res: TagSearchResponse) => {
      this.audienceDefinition.tags = res?.tags?.map(tag => ({tag, namespace: ''}));
      this.addAssetTags();
    });
  }

  ngOnInit() {
    this.modalCreateForm?.controls['limitByCountOrPercentage'].valueChanges.subscribe((val) => {
      if (val === 'limitByCount') {
        this.modalCreateForm?.controls['limitByPercentageValue'].setValue(new UntypedFormControl(), { onlySelf: true, emitEvent: false });
      } else {
        this.modalCreateForm?.controls['limitByCountValue'].setValue(new UntypedFormControl(), { onlySelf: true, emitEvent: false });
      }
    });
    this.isAudienceListNameDuplicate = false;
    this.store
    .select(selectContext)
    .pipe(untilDestroyed(this))
    .subscribe((context) => {
      this.productType = context?.productType;
      if(context?.productType) {
        this.schedulerEnabled = this.featureService.isFeatureEnabled(FEATURE_SCHEDULING);
      }
    });

    const displayName = this.audienceDefinition.displayName || this.audienceDefinition.name;
    if (this.audienceDefinition.query) {
      this.setCreateInProgress(this.audienceDefinition);
    } else {
      this.store.dispatch(
        new FetchAttributeDetails(
          this.audienceDefinition,
          this.audienceDefinition.cabContextId,
          DataType.AUDIENCE_DEFINITION
        )
      );
      this.actions$
        .pipe(fetchOutcome(FetchAttributeDetails.type), take(1))
        .subscribe(() => {
          const defn = convertAudienceBuilderToDefinition(
            this.prebuiltAudience,
            this.audienceDefinition.cabContextId,
            this.audienceDefinition.dataUniverseId
          );
          this.setCreateInProgress({ ...defn, displayName });
        });
    }
  }

  ngAfterViewInit() {
      this.createActionModal.show();
  }

  addAssetTags(){
    if(!this.audienceDefinition.assetId && !this.audienceDefinition.id){
      return;
    }
    this.addedTags = this.audienceDefinition.tags.map(item => item.tag);
    this.modalCreateForm.addControl('assetTags', new FormControl(''));
  }

  associateTags(cabId: string) {
    const selectedAssetTags = this.modalCreateForm.get('assetTags')?.value;
    const assetTags$ = cabId ? [{
      namespace: '',
      tag: selectedAssetTags
    }] : [];

    const tagAssociationRequest = {
      assetId: cabId,
      tags: assetTags$,
      type: AssetType.AUDIENCE_LIST
    };
    this.assetService
      .associateTags(tagAssociationRequest)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.closeModal('success');
        },
        (error) => {
          this.errorMessage = 'Something went wrong while associating tags!';
          if (error.error?.errorDetails) {
            this.errorMessage = error.error?.errorDetails[0]?.errorMessage;
          }
          this.errorCreatingList.show();
        }
      );
  }

  setCreateInProgress(definition) {
    this.actionInProgress$.next({
      type: 'create',
      definitions: [definition],
    });
    // set default value on form for audience list name
    this.modalCreateForm.patchValue({
      audienceName: definition.displayName
    });
    this.modalCreateForm.markAllAsTouched();
  }

  private uniqueNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const nameTaken = this.audiences && this.audiences
        .map((o) => o.displayName?.toLowerCase())
        ?.includes(control.value?.trim()?.toLowerCase());
      return nameTaken ? { nameTaken: true } : null;
    };
  }


  public createAction() {
    this.modalCreateForm.markAllAsTouched();
    this.libScheduleAudienceModal?.checkSchedulerFormValidation();

    if (!this.modalCreateForm.valid) {
      return;
    }

    const displayName = this.modalCreateForm.get('audienceName').value.trim();
    const actionDetails = this.actionInProgress$.getValue();
    const limitByCountOrPercentage =  this.modalCreateForm.get('limitByCountOrPercentage').value;
    const limitByCountValue =  this.modalCreateForm.get('limitByCountValue').value;
    const limitByPercentageValue =  this.modalCreateForm.get('limitByPercentageValue').value;

    let controls = [];

    if(this.isListSizeLimit === 'Yes'){
      if(limitByCountOrPercentage === 'limitByCount'){
        controls = [
          {
              "controlType": "LIMIT_BY_COUNT",
              "limit": limitByCountValue
          }
        ];
      }
      if(limitByCountOrPercentage === 'limitByPercentage') {
        controls = [
          {
              "controlType": "LIMIT_BY_PERCENTAGE",
              "percentage": limitByPercentageValue
          }
        ];
      }
    }

    const { id, cabContextId, query, dataUniverseId, dedupeIdentityType } =
      first(actionDetails.definitions);
    const audience = {
      displayName,
      audienceDefinitionId: id,
      cabContextId,
      dataUniverseId,
      query,
      dedupeIdentityType,
      controls
    } as Audience;

    if(this.libScheduleAudienceModal?.isFutureSchedule) {
      const scheduleDetail = new ScheduleDetail();
      scheduleDetail.name = this.libScheduleAudienceModal.getName();
      scheduleDetail.displayName = this.libScheduleAudienceModal.getName();
      scheduleDetail.cabContextId = cabContextId;
      scheduleDetail.scheduleEntityId = id;
      scheduleDetail.scheduleType = ScheduleType.AUDIENCE_LIST;
      scheduleDetail.scheduleParams = {
        name: displayName,
        displayName
      };
      scheduleDetail.scheduleIntervalInfo = this.libScheduleAudienceModal.getScheduleInfo();
      this.store.dispatch(new SaveCampaignExtractSchedule(scheduleDetail));
      this.actions$
        .pipe(fetchOutcome(SaveCampaignExtractSchedule.type), take(1))
        .subscribe(
          () => this.closeModal('success'),
          error => {
            if (error.error?.errorDetails) {
              this.errorMessage = error.error?.errorDetails[0]?.errorMessage;
            }
            this.errorCreatingList.show();
          }
        );
    } else {
      this.store.dispatch(new SaveAudience(audience));
      this.actions$
        .pipe(fetchOutcome(SaveAudience.type), take(1))
        .subscribe(
          (data) => {
            const selectedAssetTags = this.modalCreateForm.get('assetTags')?.value;
            if (this.productType === 'DCDP' && selectedAssetTags) {
              const listId = data?.result?.audience?.id || "";
              this.associateTags(listId);
            } else {
              this.closeModal('success');
            }
          },
          error => {
            if (error.error?.errorDetails) {
              this.errorMessage = error.error?.errorDetails[0]?.errorMessage;
            }
            if(error.status === 400 && this.errorMessage.includes('already exists')) {
                this.isAudienceListNameDuplicate = true;
            } else {
              this.errorCreatingList.show();
            }
          }
        );
    }
  }

  public closeModal(createListStatus?: string) {
    this.modalCreateForm.reset();
    this.resetActionInProgress();
    this.createListModalClosed.emit(createListStatus);
  }

  public resetActionInProgress() {
    this.actionInProgress$.next(null);
  }

  public onSizeLimitOptionSelect(event) {
    if(event !== null && event !== undefined && event !== "") {
      this.isListSizeLimit = event;
    }
  }

  createListDisabled() {
    if (this.productType !== 'DCDP') {
      return !this.modalCreateForm.get('audienceName').value;
    } else {
      return !(this.modalCreateForm.get('audienceName').value && this.isListSizeLimit
        && (this.isListSizeLimit === 'No' || (this.isListSizeLimit === 'Yes'
        && (this.modalCreateForm.get('limitByCountOrPercentage').value === 'limitByCount' ? !!this.modalCreateForm.get('limitByCountValue').value : !!this.modalCreateForm.get('limitByPercentageValue').value)))
        && (this.addedTags?.length > 0 ? !!this.modalCreateForm.get('assetTags')?.value : true));
    }
  }
}
