import { DedupeType, transformDedupeTypes } from '../models/dedupe-type';

export class DataUniverse {
  cabContextId: string;
  createdBy?: string;
  createdDate?: string;
  dbType?: string;
  displayName: string;
  id: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  name: string;
  version: number;
  dedupeTypes: DedupeType[];

  constructor(data) {
    this.cabContextId = data.cabContextId;
    this.createdBy = data.createdBy;
    this.createdDate = data.createdDate;
    this.dbType = data.dbType;
    this.displayName = data.displayName;
    this.id = data.id;
    this.lastModifiedBy = data.lastModifiedBy;
    this.lastModifiedDate = data.lastModifiedDate;
    this.name = data.name;
    this.version = data.version;
    this.dedupeTypes = transformDedupeTypes(data.identityTypes);
  }
}

export function getAllDataUniverse(contextId: string): DataUniverse {
  return {
    id: 'all',
    cabContextId: contextId,
    displayName: 'All Data Universes',
    name: 'All Data Universe',
    version: 0,
    dedupeTypes: [],
  };
}
