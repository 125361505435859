<div [formGroup]="formGroup">
  <coreui-table class="table tableScroll" #scrollTable coreUIInfiniteScroll [scrollTarget]="scrollTable.scrollContainer"
    (scrollLimitReached)="limitReached()" [fixedHeader]="true">
    <thead coreUITableSort>
      <tr coreUITableHeaderRow>
        <th scope="col" coreUITableStickyColumn class="table-header">
          <coreui-table-header-column (sort)="sort($event, 'displayName')" [active]="scrollSortOption === 'displayName'"
            [sortDirection]="scrollSortDirection">
            Job Name
          </coreui-table-header-column>
        </th>

        <th scope="col" class="table-header">
          <coreui-table-header-column (sort)="sort($event, 'audienceDefinitionId')"
            [active]="scrollSortOption === 'audienceDefinitionId'" [sortDirection]="scrollSortDirection">
            Job Type
          </coreui-table-header-column>
        </th>

        <th scope="col" class="table-header">
          <coreui-table-header-column (sort)="sort($event, 'status')" [active]="scrollSortOption === 'status'"
            [sortDirection]="scrollSortDirection">
            Status
          </coreui-table-header-column>
        </th>

        <th scope="col" class="table-header">
          <coreui-table-multiple-header-column label="Start Time">
            <coreui-table-header-column (sort)="sort($event, 'createdDate')"
              [active]="scrollSortOption === 'createdDate'" [sortDirection]="scrollSortDirection">
              Start Time
            </coreui-table-header-column>
            <coreui-table-header-column (sort)="sort($event, 'createdBy')"
              [active]="scrollSortOption === 'createdBy'" [sortDirection]="scrollSortDirection">
              User
            </coreui-table-header-column>
          </coreui-table-multiple-header-column>
        </th>

        <th scope="col" class="table-header">
          <coreui-table-header-column (sort)="sort($event, 'lastModifiedDate')"
            [active]="scrollSortOption === 'lastModifiedDate'" [sortDirection]="scrollSortDirection">
            Completion Time
          </coreui-table-header-column>
        </th>

        <th scope="col" class="table-header">
          <coreui-table-header-column (sort)="sort($event, 'dataUniverseId')"
            [active]="scrollSortOption === 'dataUniverseId'" [sortDirection]="scrollSortDirection">
            Data Universe
          </coreui-table-header-column>
        </th>

        <th scope="col" class="table-header">
          <coreui-table-header-column [active]="scrollSortOption === 'count'" [disableSort]="true">
            Count
          </coreui-table-header-column>
        </th>

        <th scope="col" class="table-header" coreUITableStickyColumn="last">
          <coreui-table-header-column [disableSort]="true">
          </coreui-table-header-column>
        </th>
      </tr>
    </thead>
    <tbody formArrayName="rows">
      <tr *ngFor="let item of filteredCampaignExtracts; let i = index" coreUITableRow>
        <td coreUITableStickyColumn>
          <a class="selectedDefinition" (click)='routeToEditHandler(item)'>{{ item.displayName }}</a>
        </td>
        <td>{{ item.audienceDefinitionId ? 'Export Definition': 'Export List' }}</td>
        <td>
          <coreui-badge class="badgeWithTime" *ngIf="item.status === 'Completed'" color="Lime" [hasIcon]="true"><i coreUIIcon="successShield"></i>Completed</coreui-badge>
          <coreui-badge class="badgeWithTime" *ngIf="item.status === 'Failed'"  color="Apple" [hasIcon]="true"><i coreUIIcon="errorShield"></i>Failed</coreui-badge>
          <coreui-badge class="badgeWithTime" *ngIf="item.status === 'Processing'"  color="Slate" [hasIcon]="true"><i coreUIIcon="spinner"></i>Processing</coreui-badge>
        </td>
        <td>
          {{ item.createdDate | coreUILocaleDate}}
          <div class="text-style-muted-3">{{ item.createdBy }}</div>
        </td>
           <td>
          {{ item.lastModifiedDate | coreUILocaleDate}}
        </td>
        <td>{{ getDataUniverseDisplayName(item.dataUniverseId) }}</td>
        <td>{{ item.count | number }}</td>



        <td coreUITableStickyColumn="last">
          <coreui-menu menuButtonStyle="link" icon="more">
            <coreui-menu-section>
              <button type="button" *ngFor="let action of dropdownListActions" (click)="action.onClick(item)"
                coreUIMenuItem>
                {{ action.display }}
              </button>
            </coreui-menu-section>
          </coreui-menu>
        </td>
      </tr>
      <tr coreUITableRow *ngIf="(loadingService.isLoading$ | async) && !searchPayload?.displayName">
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
      </tr>
      <tr *ngIf="((loadingService.isLoading$ | async) === null || (loadingService.isLoading$ | async) === undefined || (loadingService.isLoading$ | async) === false) && !filteredCampaignExtracts.length" coreUITableRow [disableHover]="true">
        <td colspan="9" class="empty-list">
          <coreui-graphic-message label="No Results Found" graphic="noResults">
            Sorry, we did not find any results.
          </coreui-graphic-message>
        </td>
      </tr>
    </tbody>
  </coreui-table>
</div>
