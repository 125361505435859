import { Directive, HostListener } from '@angular/core';

/*
Prevents dragging on elements decorated with this directive
Usage:
  Apply directive on an html element
  <div libDisableDrag></div>
*/

@Directive({ selector: '[libDisableDrag]' })
export class DisableDragDirective {
  constructor() {
     // DisableDragDirective constructor
  }

  @HostListener('mousedown', ['$event'])
  mousedown(event) {
    event.stopPropagation();
  }
}
