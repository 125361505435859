<form class="Core-Form" [formGroup]="multiSelectFormGroup">
  <coreui-form-field [label]="fieldLabel">
    <coreui-select
      formControlName="selectDefault"
      data-qa="select-multi"
      placeholder="Select options"
      [multiple]="true"
      (blur)="onSelectBlur()"
    >
      <ng-template
        #multipleSelectedTpl
        let-selectedOptions="selectedOptionsCount"
        let-value="value"
      >
        <coreui-select-tag
          [value]="selectedOptions"
          [attr.aria-label]="selectedOptions + ' options selected'"
        ></coreui-select-tag>
        <div>{{ value?.sort().join(", ") }}</div>
      </ng-template>
      <coreui-menu-item-divider
        *ngIf="(multiSelectAllSelectedItems$ | async).length"
      ></coreui-menu-item-divider>
      <coreui-option
        *ngFor="let item of multiSelectedItems$ | async"
        [value]="item"
        >{{ item }}</coreui-option
      >
      <coreui-menu-item-divider
        *ngIf="multiSelectedItems$.getValue().length > 0"
      ></coreui-menu-item-divider>
    </coreui-select>
  </coreui-form-field>
</form>
