import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from './user.models';
import * as actions from './user.actions';
import { AppState } from '../reducers';

export type State = User;

const defaultState = null;

export function reducer(
  state: State = defaultState,
  action: actions.ReducerActions
) {
  switch (action.type) {
    case actions.LoadUserDetails.type:
      return {...state, ...action.userDetails};
    case actions.LoadUserPermissions.type:
      return { ...state, permissions: action.userPermissions };
    default:
      return state;
  }
}

const cabFeatureSelector = createFeatureSelector<AppState>('cab');

export const selectUser = createSelector(cabFeatureSelector, (state: AppState) => state?.user);
export const selectUserFullName = createSelector(selectUser, (user) =>
  (user && (user.firstName || user.lastName))? `${user.firstName} ${user.lastName}` : ''
);
