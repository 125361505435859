<div [formGroup]="formGroup">
  <div [formGroup]="formGroup" class="page-scaffolding">
    <coreui-table class="scroll-table" #scrollTable coreUIInfiniteScroll [scrollTarget]="scrollTable.scrollContainer"
    (scrollLimitReached)="limitReached()" [fixedHeader]="true">
    <thead coreUITableSort >
      <tr coreUITableHeaderRow>
        <th scope="col" class="table-header" coreUITableCheckboxColumn *ngIf="productType !== 'DCDP'"></th>
        <th scope="col" class="table-header" [attr.aria-sort]="sortAscDesc('displayName')">
          <coreui-table-header-column
            (sort)="scrollSort($event, 'displayName')"
            [active]="scrollSortOption === 'displayName'"
            [sortDirection]="scrollSortDirection"
          >
            List
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header"  *ngIf="productType !== 'DCDP'" [attr.aria-sort]="sortAscDesc('dataUniverseId')">
          <coreui-table-header-column
            (sort)="scrollSort($event, 'dataUniverseId')"
            [active]="scrollSortOption === 'dataUniverseId'"
            [sortDirection]="scrollSortDirection"
          >
            Data Universe
          </coreui-table-header-column>
        </th>
        <th scope="col">
          <coreui-table-header-column [disableSort]="true">
            Consists of
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header">
          <coreui-table-header-column
          [active]="scrollSortOption === 'count'"
          [disableSort]="true"
        >
          Count
        </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header" *ngIf="productType !== 'DCDP'" [attr.aria-sort]="sortAscDesc(['createdDate','createdBy'])">
          <coreui-table-multiple-header-column label="Created">
            <coreui-table-header-column
              (sort)="scrollSort($event, 'createdDate')"
              [active]="scrollSortOption === 'createdDate'"
              [sortDirection]="scrollSortDirection"
            >
              Date
            </coreui-table-header-column>
            <coreui-table-header-column
              (sort)="scrollSort($event, 'createdBy')"
              [active]="scrollSortOption === 'createdBy'"
              [sortDirection]="scrollSortDirection"
            >
              User
            </coreui-table-header-column>
          </coreui-table-multiple-header-column>
        </th>
        <th scope="col" class="table-header" [attr.aria-sort]="sortAscDesc(['lastModifiedDate','lastModifiedBy'])"
        [attr.aria-describedby]="scrollSortOption === 'lastModifiedDate' ? 'UpdatedDate-Sort' : scrollSortOption === 'lastModifiedBy' ? 'UpdatedBy-Sort' :undefined">
          <coreui-table-multiple-header-column label="Updated">
            <coreui-table-header-column
              (sort)="scrollSort($event, 'lastModifiedDate')"
              [active]="scrollSortOption === 'lastModifiedDate'"
              [sortDirection]="scrollSortDirection"
            >
              Updated Date
            </coreui-table-header-column>
            <coreui-table-header-column
              (sort)="scrollSort($event, 'lastModifiedBy')"
              [active]="scrollSortOption === 'lastModifiedBy'"
              [sortDirection]="scrollSortDirection"
            >
              Updated By
            </coreui-table-header-column>
          </coreui-table-multiple-header-column>
        </th>
        <th scope="col">
          <coreui-table-header-column [disableSort]="true">
            Status
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header" *ngIf="enabledDropdownListActions()?.length">
          <coreui-table-header-column [disableSort]="true">
          </coreui-table-header-column>
        </th>
      </tr>
    </thead>
    <tbody
      coreUICheckboxMultipleSelection
      (multipleSelected)="tableFilterService.onMultipleSelected($event)"
      formArrayName="rows"
    >
      <tr
        *ngFor="
          let item of filteredAudience
            | orderBy: scrollSortOption:scrollSortDirection;
          index as i
        "
        coreUITableRow
        [active]="tableFilterService.rowsControl?.at(i)?.value"
      >
      <ng-container *ngIf="isPaidchannelTypeFeatureEnabled ? true : item.audienceAttributes?.channelType !== 'PAID'">
        <td coreUITableCheckboxColumn *ngIf="productType !== 'DCDP'">
          <label
            ><input
              type="checkbox"
              coreUICheckbox
              (change)="checkedCounter($event)"
              [checked]="tableFilterService.checkedAllStatus"
              [formControl]="formGroup.get('rows').controls[i]"
          /></label>
        </td>
        <td class="nameCol">
          <a aria-label="List" *ngIf="productType === 'DCDP'" class="text-style-link-2" routerLink="{{getDetailUrl(item)}}" (click)="routeToViewDetails(item)">
              {{ item.displayName }}
          </a>
          <div *ngIf="productType !== 'DCDP'">
            {{ item.displayName }}
          </div>
        </td>
        <td *ngIf="productType !== 'DCDP'">{{ getDataUniverseDisplayName(item.dataUniverseId) }}</td>
        <td>
          <div class="u-fontSecondary" *ngIf="productType !== 'DCDP'">
            {{ getDedupeTypeDisplayName(item.dataUniverseId, item.dedupeIdentityType) }}
          </div>
          <div *ngIf="productType === 'DCDP'">
             {{ getAudienceDisplayName(item.dedupeIdentityType, item.audienceAttributes?.channelType) }}
          </div>
        </td>
        <td *ngIf="!item.countUpdatedOn"><span class="text-style-muted-3">—</span></td>
        <td *ngIf="item.countUpdatedOn">
          <div class="has-bottom-space">{{ item.idCount | number }}</div>
          <div class="text-style-muted-3"> as of {{ item.countUpdatedOn | coreUILocaleDate }} </div>
        </td>
        <td *ngIf="productType !== 'DCDP'">
          <div class="has-bottom-space">{{ item.createdDate | coreUILocaleDate }}</div>
          <div class="text-style-muted-3">{{ item.createdBy }}</div>
        </td>
        <td>
          <div *ngIf="productType !== 'DCDP'">
            <div class="has-bottom-space">{{ item.lastModifiedDate | coreUILocaleDate }}</div>
            <div class="text-style-muted-3">{{ item.lastModifiedBy }}</div>
          </div>
          <div *ngIf="productType === 'DCDP'">
            <div class="has-bottom-space">{{ item.lastModifiedDate | date: 'MM/dd/YY hh:mm a': 'UTC' }} UTC</div>
            <div class="text-style-muted-3">{{ 'by '+item.lastModifiedBy }}</div>
          </div>
        </td>
        <td>
          <coreui-badge class="badgeWithTime" *ngIf="item.status === 'Completed'" color="Lime" [hasIcon]="true"><i coreUIIcon="successShield"></i>Completed</coreui-badge>
          <coreui-badge class="badgeWithTime" *ngIf="item.status === 'Failed'"  color="Apple" [hasIcon]="true"><i coreUIIcon="errorShield"></i>Failed</coreui-badge>
          <coreui-badge class="badgeWithTime" *ngIf="item.status === 'Processing'"  color="Slate" [hasIcon]="true"><i coreUIIcon="spinner"></i>Processing</coreui-badge>
        </td>
        <td *ngIf="enabledDropdownListActions(item)?.length">
          <coreui-menu menuButtonStyle="link" icon="more">
            <coreui-menu-section>
              <ng-template #exportTooltip
                >Cannot export an incomplete Audience List</ng-template
              >
                <button
                *ngFor="let action of enabledDropdownListActions(item)"
                  type="button"
                  (click)="action.onClick(item)"
                  coreUIMenuItem
                  [class.disabled]="incompleteExport(action, item) || isFailedStatus(action, item)"
                >
                  <span
                    *ngIf="incompleteExport(action, item)"
                    [coreUITooltip]="exportTooltip"
                    >{{ action.display }}</span
                  >
                  <span *ngIf="!incompleteExport(action, item)">{{
                    action.display
                  }}</span>
                </button>
            </coreui-menu-section>
          </coreui-menu>
        </td>
      </ng-container>
      </tr>
      <tr coreUITableRow *ngIf="!filteredAudience.length && isLoader">
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td *ngIf="productType !== 'DCDP'"><coreui-loading-table-column></coreui-loading-table-column></td>
        <td colspan="2" *ngIf="productType !== 'DCDP'"><coreui-loading-table-column></coreui-loading-table-column></td>
        <td *ngIf="enabledDropdownListActions()?.length"><coreui-loading-table-column></coreui-loading-table-column></td>
      </tr>
      <tr *ngIf="hasAccess && ((loadingService.isLoading$ | async) === null || (loadingService.isLoading$ | async) === undefined || (loadingService.isLoading$ | async) === false)  && !initialLoad && !filteredAudience.length" coreUITableRow [disableHover]="true">
        <td colspan="9" class="empty-list">
          <coreui-graphic-message label="No Results Found" graphic="noResults">
            Sorry, we did not find any results for your search.
          </coreui-graphic-message>
        </td>
      </tr>
      <tr *ngIf="!searchPayload?.displayName && !filteredAudience.length && !loadingService.isLoading$" coreUITableRow [disableHover]="true">
        <td colspan="9" class="empty-list">
          <coreui-graphic-message label="Nothing Here" graphic="empty">
            There are no saved lists.
          </coreui-graphic-message>
        </td>
      </tr>
    </tbody>
  </coreui-table>
</div>

<coreui-modal modalWidth="40" #deleteActionModal>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon"></span>
    <span  *ngIf=" this.tableFilterService.rowSelected === 1"> Delete Audience List? </span>
    <span *ngIf=" this.tableFilterService.rowSelected > 1"> Delete Audience Lists? </span>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeModal(deleteActionModal)"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <span  *ngIf=" this.tableFilterService.rowSelected === 1" >
      {{ this.tableFilterService.rowSelected}} list will be deleted.  This action cannot be undone.  Are you sure you want to continue?    </span>
      <span  *ngIf=" this.tableFilterService.rowSelected > 1" >
      {{ this.tableFilterService.rowSelected}} lists will be deleted.  This action cannot be undone.  Are you sure you want to continue?    </span>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal(deleteActionModal)">
      Cancel
    </button>
    <button *ngIf=" this.tableFilterService.rowSelected > 1" coreUIButton (click)="deleteAction(deleteActionModal)">
      Delete {{ this.tableFilterService.rowSelected}} Lists
    </button>
    <button *ngIf=" this.tableFilterService.rowSelected === 1" coreUIButton (click)="deleteAction(deleteActionModal)">
      Delete {{ this.tableFilterService.rowSelected}} List
    </button>
  </ng-template>
</coreui-modal>

<coreui-modal modalWidth="40" #deleteActionOneModal>
  <ng-template #titleTemplate>
    <span   coreUIIcon="warning" class="Modal-titleIcon" ></span>
      <span> Delete Audience List? </span>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeModal(deleteActionOneModal)"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <span class="text-style-muted-3 template-text">{{(actionInProgress$ | async)?.audiences[0].displayName}} | ID -
      {{(actionInProgress$ | async)?.audiences[0].id}}
    </span>
    <span>
      Are you sure you want to delete this audience list?  This cannot be undone.
    </span>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal(deleteActionOneModal)">
      Cancel
    </button>
    <button coreUIButton (click)="deleteAction(deleteActionOneModal)">
      Delete
    </button>
  </ng-template>
</coreui-modal>

<lib-export-audience-modal
  *ngIf="(actionInProgress$ | async)?.type === 'export'"
  (exportModalClosed)="closeExportModal()"
  [audience]="(actionInProgress$ | async)?.audiences[0]"
></lib-export-audience-modal>

<coreui-notification notificationStyle="success" #exportSuccess>
  This export has successfully been initiated!
</coreui-notification>

<coreui-notification notificationStyle="error" #exportError>
  There was a problem initiating this export. Please try again.
</coreui-notification>


<coreui-notification notificationStyle="success" #destroySuccess>
  {{successMessage}}
</coreui-notification>

<coreui-notification notificationStyle="error" #destroyError [header]=destroyErrorHeader>
  {{destroyErrorMessage}}
  <p *ngIf="nestedDefs.length > 0"> "{{nestedDefs}}"</p>
</coreui-notification>
