import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

import { fetchResource } from '../utils/fetch-state';
import * as actions from './context.actions';
import { Context } from './context.models';
import { CabHttpService, ContextType } from '../services/cab-http.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { handleError } from '../utils/errors';
import { UtilsService } from '../utils/utilservice';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ContextService {
  public hideHeader = false;
  public hideHeader$ = new BehaviorSubject<boolean>(false);
  fetchContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FetchContextDetails.type),
      fetchResource((action) =>
        this.fetchContext(action.contextId).pipe(
          mergeMap((contextDetails) => [
            // NOTE: order matters
            new actions.ChangeContext(contextDetails.id),
            new actions.LoadContextDetails(contextDetails),
          ])
        )
      )
    )
  );

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private httpService: CabHttpService,
    private utilsService: UtilsService
  ) {}

  fetchContext(contextId: string): Observable<Context> {
    return this.http.get(this.contextUrl() + '/' + contextId).pipe(
      map((res) => new Context(res)),
      catchError(handleError)
    );
  }

  fetchCabContexts(): Observable<Context[]> {
    return this.http.get<Context[]>(this.contextUrl()).pipe(
      map((data) => data['results'].map((data) => new Context(data))),
      catchError(handleError)
    );
  }

  contextUrl() {
    if (this.utilsService.isMocked()) {
      return this.httpService.apiUrl(
        ContextType.DISCOVERY,
        '/id_analytics_api/cab/v1/cab-context'
      );
    }
    return this.httpService.apiUrl(ContextType.CAB, '/cab/v1/cab-context');
  }
}
