import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { selectTenant } from '../tenant.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Tenant } from '../tenant.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../utils/utilservice';

@UntilDestroy()
@Component({
  selector: 'lib-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.sass']
})
export class TenantComponent implements OnInit {

  constructor(private store: Store<AppState>, private router: Router, private route: ActivatedRoute, private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.store
      .select(selectTenant)
      .pipe(untilDestroyed(this))
      .subscribe((tenant: Tenant) => {
        this.router.navigate([this.utilsService.getProductBaseUrl(this.router, this.route), tenant.id, 'definition'] );
      });
  }

}
