<div>
  <coreui-table
    class="table tableScroll"
    #scrollTable
    coreUIInfiniteScroll
    [scrollTarget]="scrollTable.scrollContainer"
    [fixedHeader]="true"
    (scrollLimitReached)="limitReached()"
  >
    <thead coreUITableSort>
    <tr coreUITableHeaderRow>
      <th scope="col" [attr.aria-sort]="sortOption==='name' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'name')" [active]="true" sortDirection="ASC">
          Schedule Name
        </coreui-table-header-column>
      </th>
      <th scope="col" [attr.aria-sort]="sortOption==='scheduleParams.name' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'scheduleParams.name')">
          Job Name
        </coreui-table-header-column>
      </th>
      <th scope="col" [attr.aria-sort]="sortOption==='scheduleType' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'scheduleType')">
          Job Type
        </coreui-table-header-column>
      </th>
      <th scope="col" [attr.aria-sort]="sortOption==='scheduleIntervalInfo.scheduleDescription' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'scheduleIntervalInfo.scheduleDescription')">
          Recurrence
        </coreui-table-header-column>
      </th>
      <th scope="col" [attr.aria-sort]="sortOption==='scheduleIntervalInfo.startDate' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'scheduleIntervalInfo.startDate')">
          Start Time
        </coreui-table-header-column>
      </th>
      <th scope="col" *ngIf='false' [attr.aria-sort]="sortOption==='scheduleIntervalInfo.endDate' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'scheduleIntervalInfo.endDate')">
          End Time
        </coreui-table-header-column>
      </th>
      <th scope="col" [attr.aria-sort]="sortOption==='scheduleState' ? sortAscDesc(sortDirection):undefined">
        <coreui-table-header-column (sort)="sort($event, 'scheduleState')">
          Status
        </coreui-table-header-column>
      </th>
      <th scope="col">
        <coreui-table-header-column [disableSort]="true">
          Actions
        </coreui-table-header-column>
      </th>
      <th scope="col">

      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of scheduleDetailSearch?.scheduleDetails; let idx=index" coreUITableRow>
      <td>{{item.scheduleParams.name}}</td>
      <td>{{item.name}}</td>
      <td>{{item.scheduleType}}</td>
      <td>{{item.scheduleIntervalInfo.scheduleDescription}}</td>
      <td>{{item.scheduleIntervalInfo.startDate}}</td>
      <td *ngIf='false'>{{item.scheduleIntervalInfo.endDate}}</td>
      <td>
        <coreui-badge [color]="item.scheduleState === 'DISABLE' ? 'Slate' : 'Lime'">
          {{item.scheduleState === 'DISABLE' ? 'Disabled' : 'Enabled'}}
        </coreui-badge>
      </td>
      <td>
        <form [formGroup]="schedulesDetailForm" class="stack Core-Form">
          <div class="action-button-container">
            <coreui-form-field>
              <coreui-toggle formControlName="actionsToggle" [checked]="item.scheduleState === 'DISABLE' ? false : true"  (valueChange)="toggleActiveInactive($event, idx)"></coreui-toggle>
            </coreui-form-field>
            <button class="edit-btn-icon-container" coreUIButton="icon-large" (click)="editScheduler(item)"><i coreUIIcon="edit"></i></button>
          </div>
        </form>
      </td>
      <td>
        <button type="button" (click)="deleteScheduleItem(idx)" coreUIButton="icon-large">
        <i coreUIIcon="trash"></i>
        </button>
      </td>
    </tr>
    </tbody>

    <tbody *ngIf="!scheduleDetailSearch?.scheduleDetails">
      <tr>
        <td colspan="8" class="empty-list">
          <coreui-graphic-message graphic="empty" label="Nothing Here">
            There are no schedules.
          </coreui-graphic-message>
        </td>
      </tr>
    </tbody>
  </coreui-table>
</div>


<coreui-modal modalWidth="40" #deleteScheduleActionModal>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon"></span>
      Are you sure to delete this Schedule?
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
      <button type="button" (click)="closeModal(deleteScheduleActionModal)" coreUIButton="icon-large"><i coreUIIcon="close"></i></button>
  </ng-template>
  <ng-template #contentTemplate>
      <span>Are you sure to delete this Schedule?</span>
  </ng-template>
  <ng-template #buttonsTemplate>
      <button coreUIButton="secondary" (click)="closeModal(deleteScheduleActionModal)">Cancel</button>
      <button coreUIButton="primary" (click)="deleteScheduleAction()">Delete</button>
  </ng-template>
</coreui-modal>

<coreui-modal #editSchedulerAudienceListModal>
  <ng-template #titleTemplate> Edit Scheduler </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeModal(editSchedulerAudienceListModal)"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <form class="Core-Form modalForm" [formGroup]="modalEditSchedulerForm">
      <coreui-form-field label="Audience Definition Name">
        <b> {{audienceItem?.displayName}} </b>
      </coreui-form-field>
      <coreui-form-field class="list-alignment" label="Audience List Name">
        <input coreUIInput formControlName="audienceName" [disabled]="true"/>
      </coreui-form-field>
      <lib-schedule-audience-modal #libScheduleAudienceModal dataType="createList" *ngIf="schedulerEnabled" [scheduleDetails]="selectedScheduler" [isEditScheduler]="true"></lib-schedule-audience-modal>
    </form>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal(editSchedulerAudienceListModal)">
      Cancel
    </button>
    <button coreUIButton (click)="createAction(editSchedulerAudienceListModal)">
      Save
    </button>
  </ng-template>
</coreui-modal>


<div *ngIf="selectedScheduler?.scheduleType === 'EXTRACT_FROM_DEFINITION'">
  <lib-export-audience-modal (exportModalClosed)="closeExportModal()"
                             [audience]="audienceItem" [scheduleDetails]="selectedScheduler"></lib-export-audience-modal>

</div>
