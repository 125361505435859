import { inject, Injectable } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable, of } from 'rxjs';
import { SetUnsavedChangesState } from '../hasUnsavedChanges/hasUnsavedChanges.actions';
import { selectApplicationContext, selectHasUnsavedChanges } from '../hasUnsavedChanges/hasUnsavedChanges.reducer';
import { AppState } from '../reducers';


@Injectable({
  providedIn: 'root'
})
class CanDeactivateGuardService {

  constructor(private store: Store<AppState>) { }

  public canDeactivate(): Observable<boolean> {
    let hasUnsavedChanges: boolean;
    this.store.select(selectHasUnsavedChanges).subscribe((val) => {
      hasUnsavedChanges = val;
    });
    if (!hasUnsavedChanges) {
      return of(true);
    }
    this.store.dispatch(new SetUnsavedChangesState('checking'));
    return this.store.select(selectApplicationContext).pipe(
      filter(
        (val) =>
          val === 'pending' || val === 'confirmed'
      ),
      map((val) => val === 'confirmed')
    );
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CanDeactivateGuard: CanDeactivateFn<any> = () : Observable<boolean> => {
  return inject(CanDeactivateGuardService).canDeactivate();
}
