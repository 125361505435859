import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { StepChangeEvent } from '@epsilon/core-ui';
import { AppState } from '../reducers';
import { selectContext } from '../context/context.reducer';
import { DEFINITION_TXT } from '../utils/utils';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { CabConstants } from '../cab.constants';
import { FeatureService } from '../utils/feature-service';
import { FEATURE_AUDIENCE_QUERY_CASE_INSENSITIVE } from '../utils/feature-utils';
import { UtilsService } from '../utils/utilservice';

@UntilDestroy()
@Component({
  selector: 'lib-audience-builder-wizard',
  templateUrl: './audience-builder-wizard.component.html',
  styleUrls: ['./audience-builder-wizard.component.sass'],
})
export class AudienceBuilderWizardComponent implements OnInit, OnChanges {
  @Input() audienceForm: UntypedFormGroup;
  @Output()
  audienceDefSaved = new EventEmitter();

  @ViewChild('orderedWizard')
  public orderedWizard;
  isSetUpComplete: boolean;
  audienceDefinitionName = 'New Audience Definition';
  audienceDisplayName: string;
  version = 0;
  orderedWizardIndex = 0;
  audienceDefinitionRouter: string;
  contextId: string;
  isProductTypeDcdp: boolean;
  constructor(
    public builderService: AudienceBuilderService,
    private route: ActivatedRoute,
    private router: Router,
    public store: Store<AppState>,
    public featureService: FeatureService,
    private utilsService: UtilsService
  ) {}

  currentIndex = 0;  
  caseInsensitiveEnabled = false;

  ngOnInit(): void {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.caseInsensitiveEnabled = this.featureService.isFeatureEnabled(FEATURE_AUDIENCE_QUERY_CASE_INSENSITIVE);
    this.audienceDefinitionRouter =
      '/' +
      this.utilsService.getProductBaseUrl(this.router, this.route) +
      '/' +
      this.contextId +
      '/definition';
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        if (context?.productType === CabConstants.DCDP_PRODUCT_NAME) {
          this.isProductTypeDcdp = true;
        }
      });

    this.audienceForm?.valueChanges.subscribe( data => {
      this.audienceDisplayName = data?.displayName;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['audienceForm'] && changes['audienceForm'].currentValue) {
      this.audienceForm = changes['audienceForm'].currentValue;
    }
  }

  moveToAudienceSetup() {
    this.orderedWizard.previousStep();
    this.isSetUpComplete = false;
    this.orderedWizardIndex = 0;
  }
  moveToAudienceSelectionStep() {
    this.orderedWizard.nextStep();
    this.isSetUpComplete = true;
  }
  public onStepChange(stepChangeEvent: StepChangeEvent) {
    if (stepChangeEvent.index <= this.currentIndex) {
      this.orderedWizard.previousStep();
    } else {
      this.orderedWizard.nextStep();
    }
    this.isSetUpComplete = true;
    this.currentIndex = stepChangeEvent.index;
  }
  public onNextStepChange(stepChangeEvent: StepChangeEvent) {
    this.currentIndex = stepChangeEvent.index;
  }
  public onPreviousStepChange(stepChangeEvent: StepChangeEvent) {
    this.currentIndex = stepChangeEvent.index;
  }

  builderSetupEvent(data) {
    if (data) {
      this.audienceDefinitionName = this.audienceDisplayName;
    } else {
      this.audienceDefinitionName = 'New Audience Definition';
    }
  }
  cancel() {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      DEFINITION_TXT,
    ]);
  }
}
